import React, { useState, useEffect } from "react";
import useQuery from "../../../../hooks/useQuery";
import { Button, Collapse, Space, Tag, Typography } from "antd";
import { getPatientPackagesApi } from "../../../../services/receptionist.services";
import PatientPackageTable from "./PatientPackageTable";
import btnStyles from "../../../../styles/Custom/Button.module.css";
import dayjs from "dayjs";
import EndPackageModal from "./EndPackageModal";
import { formatToINR } from "../../../../helpers/utility";

const PatientPackageDetails = ({ refresh }) => {
  const params = useQuery();
  const [packages, setPackages] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [editData, setEditData] = useState(null);
  const [refreshPack, setRefreshPack] = useState(false);

  const refreshPackage = () => {
    setRefreshPack((prev) => !prev);
  };

  useEffect(() => {
    getPatientPackagesApi(params?.patient)
      .then((res) => {
        setPackages(res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [refresh, refreshPack]);

  return (
    <>
      {packages?.map((item, index) => (
        <Collapse
          expandIconPosition="right"
          defaultActiveKey={index}
          data={item}
          className="custom-collapse"
          style={{ marginTop: "10px" }}
        >
          <Collapse.Panel
            header={
              <div className="flex-between">
                <Space>
                  <Typography>{item?.package?.name}</Typography>

                  <Tag color="blue">
                    {dayjs(item?.start_date).format("MMM DD")}
                    {item?.end_date &&
                      ` - ${dayjs(item?.end_date).format("MMM DD")}`}
                  </Tag>
                  <Tag color="orange">
                    {formatToINR(item?.package?.total_price)}
                  </Tag>
                  {/* {item?.end_date && (
                    <Tag color="blue">
                      {dayjs(item?.end_date).format("MMM DD, YYYY")}
                    </Tag>
                  )} */}
                </Space>
                {/* {!item?.end_date && (
                  <Button
                    type="primary"
                    danger
                    ghost
                    className={btnStyles.mdBtn}
                    onClick={() => {
                      setOpenModal(true);
                      setEditData(item);
                    }}
                  >
                    Stop Package
                  </Button>
                )} */}
              </div>
            }
            key={0}
          >
            <PatientPackageTable data={item} />
          </Collapse.Panel>
          {openModal && (
            <EndPackageModal
              item={editData}
              open={openModal}
              setOpen={setOpenModal}
              refreshPackage={refreshPackage}
            />
          )}
        </Collapse>
      ))}
    </>
  );
};

export default PatientPackageDetails;
