import React, { useContext, useState, useEffect } from "react";
import { Form, Radio, InputNumber } from "antd";
import { noYesOptions } from "../../../../Options/commonOptions";
import {
  foleysCatheterTypeOptions,
  urinaryCatheterTypeOptions,
} from "../../../../Options/genExamOptions";

const UrinaryCatheter = ({ context }) => {
  const { formData } = useContext(context);

  const [usingCatheter, setUsingCatheter] = useState(
    formData?.general_examination?.urinary_catheter === "yes" ||
      formData?.examination?.urinary_catheter === "yes"
  );

  const [urinaryCatheterType, setUrinaryCatheterType] = useState(
    formData?.general_examination?.urinary_catheter_type ===
      "foleys_catheter" ||
      formData?.examination?.urinary_catheter_type === "foleys_catheter"
  );

  useEffect(() => {
    setUsingCatheter(
      formData?.general_examination?.urinary_catheter === "yes" ||
        formData?.examination?.urinary_catheter === "yes"
    );
    setUrinaryCatheterType(
      formData?.general_examination?.urinary_catheter_type ===
        "foleys_catheter" ||
        formData?.examination?.urinary_catheter_type === "foleys_catheter"
    );
  }, [formData]);
  return (
    <>
      <Form.Item label="Urinary Catheter" name="urinary_catheter">
        <Radio.Group
          options={noYesOptions}
          onChange={(e) => setUsingCatheter(e.target.value === "yes")}
        />
      </Form.Item>

      {usingCatheter && (
        <div className="subsection-card">
          <Form.Item name="urinary_catheter_type">
            <Radio.Group
              options={urinaryCatheterTypeOptions}
              onChange={(e) =>
                setUrinaryCatheterType(e.target.value === "foleys_catheter")
              }
            />
          </Form.Item>

          {urinaryCatheterType && (
            <Form.Item name="foleys_catheter_type" label="Type">
              <Radio.Group options={foleysCatheterTypeOptions} />
            </Form.Item>
          )}

          <Form.Item label="No of Days on the Catheter" name="days_on_catheter">
            <InputNumber addonBefore="No.of Days" style={{ width: "15%" }} />
          </Form.Item>
        </div>
      )}
    </>
  );
};

export default UrinaryCatheter;
