import React, { useContext } from "react";
import DoctorHistorySummary from "../../InspectionSummaries/HistorySummary/DoctorHistorySummary";
import DrGeneralExaminationSummary from "../../InspectionSummaries/GeneralExaminationSummary/DrGeneralExaminationSummary";
import DrSystemicExaminationSummary from "../../InspectionSummaries/SystemicExaminationSummary/DrSystemicExaminationSummary";
import { DoctorInitialContext } from "../../InspectionForms/HistoryForm/DoctorHistoryFormProvider";
import { Col, Row } from "antd";
import DrFunctionalExaminationSummary from "../../InspectionSummaries/FunctionalExaminationSummary/DrFunctionalExaminationSummary";
import DrClinicalImpressionSummary from "../../InspectionSummaries/ClinicalImpressionSummary/DrClinicalImpressionSummary";
import "../../summary.css";
import { checkEmpty } from "../../../../helpers/utility";

const InitialInspectionSummary = () => {
  const { formData, setFormData } = useContext(DoctorInitialContext);
  console.log(formData?.general_examination);
  console.log(checkEmpty(formData?.general_examination));
  return (
    <Row className="summaries" gutter={[16, 16]}>
      <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
        {checkEmpty(formData?.history) && (
          <DoctorHistorySummary formData={formData} />
        )}
        {checkEmpty(formData?.clinical_diagnosis) && (
          <DrClinicalImpressionSummary context={DoctorInitialContext} />
        )}
      </Col>
      <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
        {checkEmpty(formData?.general_examination) && (
          // (checkEmpty(formData?.general_examination?.gcs) ||
          //   formData?.general_examination?.drain?.length > 0) &&
          <DrGeneralExaminationSummary />
        )}
        {checkEmpty(formData?.systemic_examination) && (
          // (checkEmpty(
          //   formData?.systemic_examination?.open_wounds?.length > 0
          // ) ||
          //   checkEmpty(formData?.systemic_examination?.soft_tissue_injury) ||
          //   formData?.systemic_examination?.pressure_sore?.length > 0) &&
          <DrSystemicExaminationSummary />
        )}
        {checkEmpty(formData?.functional_examination) && (
          <DrFunctionalExaminationSummary />
        )}
      </Col>
    </Row>
  );
};

export default InitialInspectionSummary;
