import React from "react";
import { Form, Slider } from "antd";
import { DislikeOutlined, LikeOutlined } from "@ant-design/icons";
const Gcs = ({ initial = false, handleChange }) => {
  return (
    <div className={initial ? "physio-scales initial" : "physio-scales"}>
      <Form.Item
        label="Eye Opening Response"
        name={
          initial ? ["gcs", "eye_opening_response"] : "eye_opening_response"
        }
      >
        <Slider
          marks={
            // initial
            //   ?
            { 0: "NT", 1: "1", 2: "2", 3: "3", 4: "4" }
            // : { 0: "NT", 1: "", 2: "", 3: "", 4: "4" }
          }
          // tooltip={{
          //   color: "#7f56d9",
          //   open: true,

          // }}
          step={1}
          defaultValue={0}
          max={4}
          min={0}
          style={{ width: initial ? "50%" : "90%" }}
          onChange={(value) => handleChange("eye_opening_response", value)}
          className="selection-slider"
        />
      </Form.Item>
      <br />

      <Form.Item
        label="Verbal Response"
        name={initial ? ["gcs", "verbal_response"] : "verbal_response"}
      >
        <Slider
          marks={
            // initial
            //   ?
            { 0: "NT", 1: "1", 2: "2", 3: "3", 4: "4", 5: "5" }
            // : { 0: "NT", 1: "", 2: "", 3: "", 4: "", 5: "5" }
          }
          step={1}
          defaultValue={0}
          max={5}
          min={0}
          style={{ width: initial ? "50%" : "90%" }}
          onChange={(value) => handleChange("verbal_response", value)}
          // onChangeComplete={}
          className="selection-slider"
        />
      </Form.Item>
      <br />

      <Form.Item
        label="Motor Response"
        name={initial ? ["gcs", "motor_response"] : "motor_response"}
      >
        <Slider
          marks={
            // initial
            //   ?
            { 0: "NT", 1: "1", 2: "2", 3: "3", 4: "4", 5: "5", 6: "6" }
            // : { 0: "NT", 1: "", 2: "", 3: "", 4: "", 5: "", 6: "6" }
          }
          step={1}
          defaultValue={0}
          max={6}
          min={0}
          style={{ width: initial ? "50%" : "90%" }}
          onChange={(value) => handleChange("motor_response", value)}
          className="selection-slider"
        />
      </Form.Item>
    </div>
  );
};

export default Gcs;
