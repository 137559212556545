import { Form, Radio } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { notAssessableAndAssessableOptions } from "../../../Options/commonOptions";
import RPEScale from "../../Examination/PhysioScales/RPEScale";
import SixMinuteWalkTest from "../../Examination/PhysioScales/SixMinuteWalkTest";
import RPESelection from "../../Examination/PhysioScales/RPEScaleComponents/RPESelection";
import SixMinWalkTestItems from "../../Examination/PhysioScales/SixMinWalkTest/SixMinWalkTestItems";

const Endurance = ({ context }) => {
  const { formData } = useContext(context);
  const [showEndurance, setShowEndurance] = useState(
    formData?.functional_status?.endurance?.status === "assessable"
  );
  const [states, setStates] = useState({});
  const calculateDistance = () => {
    const { gender, age, height, weight } = states;
    return gender === "male adult"
      ? 7.57 * height - 5.02 * age - 1.76 * weight - 309
      : 2.11 * height - 2.29 * weight - 5.78 * age + 667;
  };

  const handleChange = (key, value) => {
    setStates((prev) => ({
      ...prev,
      [key]: value,
    }));
  };
  const predictedDistance = calculateDistance().toFixed(0);

  useEffect(() => {
    setShowEndurance(
      formData?.functional_status?.endurance?.status === "assessable"
    );
    setStates({
      gender: formData?.functional_status?.endurance?.smwt?.gender,
      height: formData?.functional_status?.endurance?.smwt?.height,
      age: formData?.functional_status?.endurance?.smwt?.age,
      weight: formData?.functional_status?.endurance?.smwt?.weight,
    });
  }, [formData]);
  return (
    <>
      <Form.Item label="Endurance" name={["endurance", "status"]}>
        <Radio.Group
          options={notAssessableAndAssessableOptions}
          onChange={(e) => {
            setShowEndurance(e.target.value === "assessable");
          }}
        />
      </Form.Item>
      {showEndurance && (
        <>
          <RPESelection nameKey={"endurance"} />
          <SixMinWalkTestItems
            predictedDistance={predictedDistance}
            handleChange={handleChange}
            states={states}
            nameKey={"endurance"}
          />
        </>
      )}
    </>
  );
};

export default Endurance;
