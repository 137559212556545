import { Descriptions, Image, Space } from "antd";
import React, { useContext } from "react";
import { RespiratoryInitialContext } from "../../RespiratoryTherapist/InitialInspection/RespiratoryTherapistInitialAssessmentProvider";
import { getLabel } from "../constructTextFromValues";
import specialInstructionsIcon from "../../../../assets/doctorIcons/specialInstructions.svg";

const RespiratorySpecialInstructionSummary = () => {
  const { formData } = useContext(RespiratoryInitialContext);
  const constructSpecialSummaryItems = () => {
    const specialSummaryItems = [];
    if (formData?.special_instruction?.special_instruction) {
      specialSummaryItems.push({
        key: "1",
        label: "Instructions",
        children: (
          <>{getLabel(formData?.special_instruction?.special_instruction)}</>
        ),
      });
    }
    return specialSummaryItems;
  };
  const specialSummaryItems = constructSpecialSummaryItems();
  return (
    <>
      <Descriptions
        bordered
        column={1}
        title={
          <span style={{ fontWeight: "500", marginLeft: ".2rem" }}>
            <Space>
              <Image src={specialInstructionsIcon} width={18} preview={false} />
              <span style={{ fontSize: "16px", fontWeight: "normal" }}>
                Special Instructions
              </span>
            </Space>
          </span>
        }
        items={specialSummaryItems}
        style={{ margin: "0", border: "none" }}
        className="initial-insp-desc"
      />
    </>
  );
};

export default RespiratorySpecialInstructionSummary;
