import React, { useContext } from "react";
import { RespiratoryInitialContext } from "../../RespiratoryTherapist/InitialInspection/RespiratoryTherapistInitialAssessmentProvider";
import {
  getLabelTag,
  getLabelTagInput,
  getLabelTagsWithDescription,
} from "../constructTextFromValues";
import {
  chestXRayOptions,
  pulmonaryOptions,
  surgicalStatusOptions,
} from "../../Options/RespiratoryTherapistOptions";
import { Descriptions, Image, Space } from "antd";
import chartAssessmentIcon from "../../../../assets/doctorIcons/chartAssessment.svg";

const RespiratoryChartAssessmentSummary = () => {
  const { formData } = useContext(RespiratoryInitialContext);
  const constructChartAssessmentSummaryItems = () => {
    const chartAssessmentSummaryItems = [];
    const chart = formData?.chart_assessment;
    const pushChartItem = (key, label, children) => {
      chartAssessmentSummaryItems.push({ key, label, children });
    };
    if (chart?.pulmonary) {
      pushChartItem(
        "1",
        "Pulmonary",
        <>
          {getLabelTag(pulmonaryOptions, chart?.pulmonary_details)}
          {getLabelTagInput("Others", chart?.pulmonary_others)}
        </>
      );
    }
    if (chart?.surgical_status) {
      pushChartItem(
        "2",
        "Surgical Status",
        <>
          {getLabelTagsWithDescription(
            surgicalStatusOptions,
            chart?.surgical_status_details
          )}
        </>
      );
    }
    if (chart?.chest_x_ray) {
      pushChartItem(
        "3",
        "Chest X Ray",
        <>
          {getLabelTagsWithDescription(
            chestXRayOptions,
            chart?.chest_x_ray_details
          )}
        </>
      );
    }
    return chartAssessmentSummaryItems;
  };

  const chartAssessmentSummaryItems = constructChartAssessmentSummaryItems();
  return (
    <>
      <Descriptions
        bordered
        column={1}
        title={
          <span style={{ fontWeight: "500", marginLeft: ".2rem" }}>
            <Space>
              <Image src={chartAssessmentIcon} width={18} preview={false} />
              <span style={{ fontSize: "16px", fontWeight: "normal" }}>
                Chart Assessment
              </span>
            </Space>
          </span>
        }
        items={chartAssessmentSummaryItems}
        style={{ margin: "0", border: "none" }}
        className="initial-insp-desc"
      />
    </>
  );
};

export default RespiratoryChartAssessmentSummary;
