import axiosInstance from "../config/axios.config";

export const getUploadUrlApi = (params) => {
  return axiosInstance.get(`/careservices/get-upload-url/?${params}`);
};
export const downloadUrlApi = (params) => {
  return axiosInstance.get(`/careservices/get-download-url/?${params}`);
};

export const downloadUrlsApi = (params) => {
  return axiosInstance.get(`/careservices/get-download-url/`, {
    params: { path_keys: params },
  });
};
export const getOrgInvestigationsSearchApi = (params) => {
  return axiosInstance.get(
    `/careservices/search/organization-investigations/?query=${params}`
  );
};
export const getOrgServicesSearchApi = (params) => {
  return axiosInstance.get(
    `/careservices/search/organization-services/?query=${params}`
  );
};
