import React, { useEffect } from "react";
import ModeOfArrival from "./ModeOfArrival/ModeOfArrival";
import { Divider } from "antd";
import Informant from "./Informant/Informant";
import ChiefComplaints from "./ChiefComplaints/ChiefComplaints";
import PresentIllness from "./PresentIllness/PresentIllness";
import TreatmentHistory from "./TreatmentHistory/TreatmentHistory";
import PastMedicalHistory from "./PastMedicalHistory/PastMedicalHistory";
import PastSurgicalHistory from "./PastSurgicalHistory/PastSurgicalHistory";
import PersonalHistory from "./PersonalHistory/PersonalHistory";
import FunctionalCapacity from "./FunctionalCapacity/FunctionalCapacity";
import KnownAllergies from "./KnownAllergies/KnownAllergies";
import { NurseInitialContext } from "./NurseInitialAssessmentFormProvider";
import { getDoctorInspectionAPI } from "../../../../services/doctor.services";

const NurseHistoryForm = ({ form, formData, patientData, editId }) => {
  // console.log(patientData);
  useEffect(() => {
    const fetchDoctorHistoryData = async () => {
      try {
        if (!editId && patientData?.doctor_intial_assessment) {
          const doctorData = await getDoctorInspectionAPI(
            patientData?.doctor_intial_assessment
          );
          const history = doctorData?.data?.data?.history || {};
          form.setFieldsValue(history);
        } else {
          form.setFieldsValue(formData?.history || {});
        }
      } catch (error) {
        console.log(`Error:${error}`);
      }
    };

    fetchDoctorHistoryData();
  }, [form, formData, editId, patientData]);
  return (
    <>
      <ModeOfArrival />
      <Divider />
      <Informant context={NurseInitialContext} />
      <Divider />
      <ChiefComplaints context={NurseInitialContext} />
      <Divider />
      <PresentIllness context={NurseInitialContext} />
      <Divider />
      <TreatmentHistory context={NurseInitialContext} />
      <Divider />
      <PastMedicalHistory context={NurseInitialContext} />
      <Divider />
      <PastSurgicalHistory context={NurseInitialContext} />
      <Divider />
      <PersonalHistory />
      <Divider />
      <FunctionalCapacity />
      <Divider />
      <KnownAllergies context={NurseInitialContext} />
    </>
  );
};

export default NurseHistoryForm;
