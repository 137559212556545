// MySlots.js
import React from "react";
import {
  Form,
  Switch,
  TimePicker,
  Button,
  Row,
  Col,
  Card,
  Typography,
  Select,
} from "antd";
import inputStyles from "../../../styles/Custom/Input.module.css";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
const { RangePicker } = TimePicker;

const slotDurations = [
  { label: "15 minutes", value: 15 },
  { label: "30 minutes", value: 30 },
  { label: "45 minutes", value: 45 },
  { label: "60 minutes", value: 60 },
];

const MySlots = ({ form }) => {
  const days = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Form.Item
          label="Slot Duration"
          name="slot_duration"
          rules={[{ required: true, message: "Please select a slot duration" }]}
        >
          <Select options={slotDurations} className={inputStyles.mdSelect} />
        </Form.Item>
      </Col>
      {days.map((day) => (
        <Col key={day} xs={24} sm={8} md={6} xl={6}>
          <Form.Item shouldUpdate>
            {({ getFieldValue }) => {
              const status = getFieldValue([day, "availability"]);
              return (
                <Card
                  title={
                    <Row type="flex" align="middle">
                      <Col span={12}>
                        <Typography>{day}</Typography>
                      </Col>
                      <Col span={12} style={{ textAlign: "right" }}>
                        <Form.Item
                          name={[day, "availability"]}
                          valuePropName="checked"
                          initialValue={day !== "Sunday"}
                        >
                          <Switch defaultChecked={day !== "Sunday"} />
                        </Form.Item>
                      </Col>
                    </Row>
                  }
                  bodyStyle={{ display: status ? "block" : "none" }}
                >
                  <Form.List name={[day, "availability_timings"]}>
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map((field) => (
                          <Row key={field.key} gutter={8} align="middle">
                            <Col span={20}>
                              <Form.Item
                                {...field}
                                rules={[
                                  {
                                    required: status,
                                    message: "Please select a time slot",
                                  },
                                ]}
                              >
                                <RangePicker
                                  use12Hours
                                  format="h:mm A"
                                  minuteStep={15}
                                  needConfirm={false}
                                />
                              </Form.Item>
                            </Col>
                            <Col span={4}>
                              {fields?.length > 1 && (
                                <Button
                                  type="danger"
                                  onClick={() => remove(field.name)}
                                  icon={<DeleteOutlined />}
                                />
                              )}
                            </Col>
                          </Row>
                        ))}
                        <Button
                          type="primary"
                          ghost
                          onClick={() => add()}
                          block
                          icon={<PlusOutlined />}
                          style={{ width: "100px" }}
                        >
                          Add Slot
                        </Button>
                      </>
                    )}
                  </Form.List>
                </Card>
              );
            }}
          </Form.Item>
        </Col>
      ))}
    </Row>
  );
};

export default MySlots;
