import { Descriptions, Image, Space } from "antd";
import React from "react";
import {
  getLabelTagInput,
  getLabelTagsArray,
} from "../constructTextFromValues";
import {
  cardioDiagnosisOptions,
  neuroDiagnosisOptions,
  orthoDiagnosisOptions,
  sportsDiagnosisOptions,
} from "../../Options/physioOptions";
import diagnosisIcon from "../../../../assets/doctorIcons/diagnosis.svg";

const PhysioFunctionalDiagnosisSummary = ({ formData }) => {
  const constructFunctionalDiagnosisSummaryItems = () => {
    const functionalDiagnosisSummaryItems = [];
    const diagnosis = formData?.functional_diagnosis || {};
    const pushFunctionalItem = (key, label, children) => {
      functionalDiagnosisSummaryItems.push({ key, label, children });
    };
    if (diagnosis?.cardio) {
      pushFunctionalItem(
        "1",
        "Cardio",
        getLabelTagsArray(cardioDiagnosisOptions, diagnosis?.cardio_options)
      );
    }
    if (diagnosis?.ortho) {
      pushFunctionalItem(
        "2",
        "Ortho",
        getLabelTagsArray(orthoDiagnosisOptions, diagnosis?.ortho_options)
      );
    }
    if (diagnosis?.sports) {
      pushFunctionalItem(
        "3",
        "Sports",
        <>
          {getLabelTagsArray(sportsDiagnosisOptions, diagnosis?.sports_options)}
          {getLabelTagInput("Other Details", diagnosis?.sports_description)}
        </>
      );
    }
    if (diagnosis?.neuro) {
      pushFunctionalItem(
        "4",
        "Neuro",
        <>
          {getLabelTagsArray(neuroDiagnosisOptions, diagnosis?.neuro_options)}
          {getLabelTagInput("Other Details", diagnosis?.neuro_description)}
        </>
      );
    }
    return functionalDiagnosisSummaryItems;
  };
  const functionalDiagnosisSummaryItems =
    constructFunctionalDiagnosisSummaryItems();
  return (
    <div>
      <Descriptions
        bordered
        column={1}
        title={
          <span style={{ fontWeight: "500", marginLeft: ".2rem" }}>
            <Space>
              <Image src={diagnosisIcon} width={18} preview={false} />
              <span style={{ fontSize: "16px", fontWeight: "normal" }}>
                Functional Diagnosis
              </span>
            </Space>
          </span>
        }
        items={functionalDiagnosisSummaryItems}
        style={{ margin: "0", border: "none" }}
        className="initial-insp-desc"
      />
    </div>
  );
};

export default PhysioFunctionalDiagnosisSummary;
