import React, { useState } from "react";
import { Button, Space, Empty, Image, Typography, Modal } from "antd";
import { Document, Page, pdfjs } from "react-pdf";
import LoadingIndicator from "../../../../Loaders/LoadingIndicator";
// import LoadingIndicator from "../../../Loaders/LoadingIndicator";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const ScalesInfoViewer = ({
  data,
  open,
  setOpen,
  width = "60%",
  height = "700px",
}) => {
  const [pageNumber, setPageNumber] = useState(1);
  const [numPages, setNumPages] = useState(null);
  const [loadError, setLoadError] = useState(false);

  const onDocumentLoadSuccess = (pdf) => {
    setNumPages(pdf.numPages);
    setPageNumber(1); // Start on the first page when loaded
  };

  const goToPrevPage = () => {
    setPageNumber((prevPage) => Math.max(prevPage - 1, 1));
  };

  const goToNextPage = () => {
    setPageNumber((prevPage) => Math.min(prevPage + 1, numPages));
  };

  return (
    <div style={{ display: "flex", justifyContent: "center", height }}>
      <Modal
        title={
          <>
            {!loadError && numPages > 1 && (
              <Space
                align="end"
                style={{ display: "flex", justifyContent: "flex-start" }}
              >
                <Button onClick={goToPrevPage} disabled={pageNumber <= 1}>
                  Prev
                </Button>
                <Button
                  onClick={goToNextPage}
                  disabled={pageNumber >= numPages}
                >
                  Next
                </Button>
              </Space>
            )}
            <Typography className="inv-slider-filename">
              {data.name} {data.path}
            </Typography>
          </>
        }
        open={open}
        onCancel={() => setOpen(false)}
        width={width}
        // height={height}
        footer={null}
      >
        {data.type === "image" ? (
          <div className="inv-slider-img-container">
            <Image src={data.url} className="inv-slider-img" preview={false} />
          </div>
        ) : (
          <div style={{ overflow: "auto", height }}>
            <Document
              file={data.url}
              loading={
                <LoadingIndicator message="Loading your document, please wait..." />
              }
              onLoadSuccess={onDocumentLoadSuccess}
              onLoadError={() => setLoadError(true)}
              error={
                loadError ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                    }}
                  >
                    <Empty description="Cannot load PDF file" />
                  </div>
                ) : (
                  "Failed to load PDF"
                )
              }
            >
              <Page
                noData="Unable to read file"
                pageNumber={pageNumber}
                renderAnnotationLayer={false}
                renderTextLayer={false}
                size="A4"
                scale={1.5}
              />
            </Document>

            {/* {!loadError && numPages > 1 && (
              <Space
                align="end"
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <Button onClick={goToPrevPage} disabled={pageNumber <= 1}>
                  Prev
                </Button>
                <Button
                  onClick={goToNextPage}
                  disabled={pageNumber >= numPages}
                >
                  Next
                </Button>
              </Space>
            )} */}
          </div>
        )}
      </Modal>
    </div>
  );
};

export default ScalesInfoViewer;
