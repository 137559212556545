import React, { useState, useEffect, useContext } from "react";
import { Button, Statistic, Table } from "antd";
import { getIpServicesListApi } from "../../../../services/receptionist.services";
import useQuery from "../../../../hooks/useQuery";
import dayjs from "dayjs";
import AssignServiceModal from "./AssignServiceModal";
import { getFacilityServicesApi } from "../../../../services/service.services";
import { getCookie } from "../../../../helpers/localStorage";
import { UserContext } from "../../../../context/UserContext";
import { FacilityID } from "../../../../constants/defaultKeys";
import { formatToINR } from "../../../../helpers/utility";

const ServicesIpTable = ({ patientData }) => {
  const { userDetails } = useContext(UserContext);
  const params = useQuery();
  const [services, setServices] = useState([]);
  const [open, setOpen] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [editData, setEditData] = useState(null);
  const [loading, setLoading] = useState(false);
  const admission = patientData?.admission?.is_active;

  const refreshServices = () => {
    setRefresh((prev) => !prev);
  };

  useEffect(() => {
    setLoading(true);
    getFacilityServicesApi(
      Number(getCookie(FacilityID)) || userDetails?.currFacility,
      `ip_admission=${params?.patient}`
    )
      .then((res) => {
        setServices(res?.data?.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, [refresh]);

  const columns = [
    {
      title: "Service Name",
      dataIndex: "name",
      key: "name",
    },
    // {
    //   title: "Duration",
    //   dataIndex: "till_date",
    //   key: "till_date",
    //   render: (_, record) => {
    //     const orderedOn = dayjs(record?.ordered_on);
    //     const tillDate = dayjs(record?.till_date);
    //     const diff = tillDate.diff(orderedOn, "days"); // Calculate the difference in days
    //     return diff;
    //   },
    // },
    { title: "Description", dataIndex: "description", key: "description" },
    // },
    {
      title: "Category",
      dataIndex: "category",
      key: "description",
      render: (_, record) => <span> {record?.category?.name}</span>,
    },
    {
      title: "Price",
      dataIndex: "cash_price",
      key: "cash_price",
      render: (value) => formatToINR(value),
    },

    ...(admission
      ? [
          {
            title: "Action",
            dataIndex: "action",
            key: "action",
            render: (_, record) => (
              <>
                {admission && (
                  <Button
                    onClick={() => {
                      setOpen(true);
                      setEditData(record);
                    }}
                  >
                    Assign
                  </Button>
                )}
              </>
            ),
          },
        ]
      : []),
  ];

  return (
    <>
      <Table
        dataSource={services}
        columns={columns}
        pagination={false}
        loading={loading}
        style={{ marginTop: "15px" }}
      />
      {open && (
        <AssignServiceModal
          refresh={refreshServices}
          open={open}
          setOpen={setOpen}
          editData={editData}
        />
      )}
    </>
  );
};

export default ServicesIpTable;
