import React, { useContext, useEffect, useState } from "react";
import {
  cardioDiagnosisOptions,
  checkboxOptions,
  neuroDiagnosisOptions,
  orthoDiagnosisOptions,
  sportsDiagnosisOptions,
} from "../../Options/physioOptions";
import { Checkbox, Form, Input } from "antd";

const FunctionalDiagnosis = ({ context }) => {
  const { formData } = useContext(context);
  const [checkboxes, setCheckboxes] = useState({});
  const [showOthers, setShowOthers] = useState({});

  const handleCheckboxChange = (value) => {
    setCheckboxes((prev) => ({
      ...prev,
      [value]: !prev[value],
    }));
  };

  const handleSubCheckboxChange = (values, mainCategory) => {
    const hasOthers = values.includes("others");
    setShowOthers((prev) => ({
      ...prev,
      [mainCategory]: hasOthers,
    }));
  };

  const getOptions = (value) => {
    switch (value) {
      case "cardio":
        return cardioDiagnosisOptions;
      case "ortho":
        return orthoDiagnosisOptions;
      case "sports":
        return sportsDiagnosisOptions;
      case "neuro":
        return neuroDiagnosisOptions;
      default:
        return [];
    }
  };
  useEffect(() => {
    const initialCheckboxes = checkboxOptions.reduce((acc, checkbox) => {
      acc[checkbox.value] =
        formData?.functional_diagnosis?.[checkbox.value] || false; // Initialize checkboxes based on formData
      return acc;
    }, {});
    setCheckboxes(initialCheckboxes);

    // Initialize showOthers state
    const initialShowOthers = checkboxOptions.reduce((acc, checkbox) => {
      const hasDescription =
        formData?.functional_diagnosis?.[`${checkbox.value}_description`];
      acc[checkbox.value] = !!hasDescription;
      return acc;
    }, {});
    setShowOthers(initialShowOthers);
  }, [formData]);
  return (
    <>
      {checkboxOptions.map((option) => (
        <div key={option.value}>
          <Form.Item name={option.value} valuePropName="checked">
            <Checkbox
              onChange={() => handleCheckboxChange(option.value)}
              checked={checkboxes[option.value]}
            >
              {option.label}
            </Checkbox>
          </Form.Item>
          {checkboxes[option.value] && (
            <div className="subsection-card">
              <Form.Item name={`${option.value}_options`}>
                <Checkbox.Group
                  onChange={(values) =>
                    handleSubCheckboxChange(values, option.value)
                  }
                >
                  {getOptions(option.value).map((subOption) => (
                    <div style={{ minWidth: "240px", padding: "6px" }}>
                      <Checkbox key={subOption.value} value={subOption.value}>
                        {subOption.label}
                      </Checkbox>
                    </div>
                  ))}
                </Checkbox.Group>
              </Form.Item>

              {showOthers[option.value] && (
                <Form.Item name={`${option.value}_description`}>
                  <Input placeholder="Please enter the description" />
                </Form.Item>
              )}
            </div>
          )}
        </div>
      ))}
    </>
  );
};

export default FunctionalDiagnosis;
