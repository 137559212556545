import React, { useState } from "react";
import { Table, Space, Typography, Radio } from "antd";
import { formatToINR } from "../../../../helpers/utility";
import dayjs from "dayjs";

const ServicesTableIp = ({ billData }) => {
  const [value, setValue] = useState("summary");

  const onChange = (e) => {
    setValue(e.target.value);
  };
  const columns = [
    {
      title: "Service",
      dataIndex: "service",
      key: "service",
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      render: (value) => formatToINR(value),
    },
    ...(value === "summary"
      ? [
          {
            title: "Count",
            dataIndex: "count",
            key: "count",
          },
          {
            title: "Total",
            dataIndex: "total_price",
            key: "total_price",
            render: (value) => formatToINR(value),
          },
        ]
      : [
          {
            title: "Date",
            dataIndex: "date",
            key: "date",
            render: (value) => dayjs(value).format("DD MMM, YY"),
          },
        ]),
  ];
  return (
    <>
      <Table
        columns={columns}
        dataSource={
          value === "summary"
            ? billData?.data?.service_details?.services
            : billData?.data?.detailed_services
        }
        pagination={false}
        title={() => (
          <Space>
            <Typography>Services Billing</Typography>
            <Radio.Group onChange={onChange} value={value}>
              <Radio value="summary">Summary</Radio>
              <Radio value="detailed">Detailed</Radio>
            </Radio.Group>
          </Space>
        )}
      />
    </>
  );
};

export default ServicesTableIp;
