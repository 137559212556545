import React from "react";
import NutritionistInitialInspection from "../IpEMR/Nutritionist/NutritionistInitialInspection/NutritionistInitialInspection";
import NutritionistInitialAssessmentProvider from "../IpEMR/InspectionForms/HistoryForm/NutritionistInitialAssessmentProvider.js";
import RiskAssessment from "../IpEMR/Nutritionist/RiskAssessment/RiskAssessment.js";
import RiskAssessmentFormProvider from "../IpEMR/Nutritionist/RiskAssessment/RiskAssessmentFormProvider.js";

const NutritionistIP = ({ patientData, params, refreshPage }) => {
  return (
    <div>
      {params?.type === "ia" ? (
        <NutritionistInitialAssessmentProvider>
          <NutritionistInitialInspection
            refreshPage={refreshPage}
            editId={patientData?.nutrition_initial_assessment}
            patientData={patientData}
          />
        </NutritionistInitialAssessmentProvider>
      ) : (
        params?.type === "risk_assessment" && (
          <RiskAssessmentFormProvider>
            <RiskAssessment
              riskAssessment={patientData?.nutrition_risk_assessment}
              iAssessment={patientData?.nutrition_initial_assessment}
              refreshPage={refreshPage}
              patientData={patientData}
            />
          </RiskAssessmentFormProvider>
        )
      )}
    </div>
  );
};

export default NutritionistIP;
