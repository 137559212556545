import { Form, Input, Radio, Typography } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { deglutitionOptions } from "../../../../Options/speechSwallowOptions";
import { yesNoOptions } from "../../../../Options/commonOptions";

const DirectSwallowingTestLiquid = ({ handleGussStates, context, form }) => {
  const { formData } = useContext(context);
  const [states, setStates] = useState({
    deglutition: 0,
    coughing: 0,
    drooling: 0,
    voice_change: 0,
  });

  useEffect(() => {
    const sum = Object.values(states).reduce((acc, cur) => acc + cur, 0);
    form.setFieldsValue({ direct_swallowing_liquid: { score: sum } });
    handleGussStates("swallowingLiquid", sum);
  }, [states]);

  const handleChange = (key, value) => {
    setStates((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const getScoreMessage = (sum) => {
    return sum < 5
      ? "Stop the test see GUSS-Evaluation"
      : "Continue Direct Swallowing Test (Solid)";
  };
  const currentScore = Object.values(states).reduce((acc, cur) => acc + cur, 0);

  useEffect(() => {
    setStates({
      deglutition:
        formData?.scales?.direct_swallowing_liquid?.deglutition ===
        "swallowing_delayed"
          ? 1
          : formData?.scales?.direct_swallowing_liquid?.deglutition ===
            "swallowing_successful"
          ? 2
          : 0,
      coughing:
        formData?.scales?.direct_swallowing_liquid?.coughing === "no" ? 1 : 0,

      drooling:
        formData?.scales?.direct_swallowing_liquid?.drooling === "no" ? 1 : 0,
      voice_change:
        formData?.scales?.direct_swallowing_liquid?.voice_change === "no"
          ? 1
          : 0,
    });
  }, [formData]);

  return (
    <>
      <Typography>Direct Swallowing Test (Liquid):{currentScore}</Typography>
      <Typography>{getScoreMessage(currentScore)}</Typography>
      <Form.Item
        name={["direct_swallowing_liquid", "score"]}
        hidden={true}
        initialValue={currentScore}
      >
        <Input value={currentScore} />
      </Form.Item>
      <div className="subsection-card">
        <Form.Item
          label="Deglutition"
          name={["direct_swallowing_liquid", "deglutition"]}
        >
          <Radio.Group
            options={deglutitionOptions}
            onChange={(e) =>
              handleChange(
                "deglutition",
                e.target.value === "swallowing_delayed"
                  ? 1
                  : e.target.value === "swallowing_successful"
                  ? 2
                  : 0
              )
            }
          />
        </Form.Item>
        <Form.Item
          label="Coughing"
          name={["direct_swallowing_liquid", "coughing"]}
        >
          <Radio.Group
            options={yesNoOptions}
            onChange={(e) =>
              handleChange("coughing", e.target.value === "no" ? 1 : 0)
            }
          />
        </Form.Item>
        <Form.Item
          label="Drooling"
          name={["direct_swallowing_liquid", "drooling"]}
        >
          <Radio.Group
            options={yesNoOptions}
            onChange={(e) =>
              handleChange("drooling", e.target.value === "no" ? 1 : 0)
            }
          />
        </Form.Item>
        <Form.Item
          label="Voice Change"
          name={["direct_swallowing_liquid", "voice_change"]}
        >
          <Radio.Group
            options={yesNoOptions}
            onChange={(e) =>
              handleChange("voice_change", e.target.value === "no" ? 1 : 0)
            }
          />
        </Form.Item>
      </div>
    </>
  );
};

export default DirectSwallowingTestLiquid;
