export const abdomenAppearanceOptions = [
  { label: "Normal", value: "normal" },
  { label: "Scaphoid", value: "scaphoid" },
  { label: "Distended", value: "distended" },
];

export const bowelMovementOptions = [
  { label: "Normal", value: "normal" },
  { label: "Diarrhoea", value: "diarrhoea" },
  { label: "Constipation", value: "constipation" },
];

export const heartSoundsOptions = [
  //S1, S3, S4, S2, M
  { label: "S1", value: "s1" },
  { label: "S2", value: "s2" },
  { label: "S3", value: "s3" },
  { label: "S4", value: "s4" },
  { label: "M", value: "m" },
];

export const jvpOptions = [
  { label: "Normal", value: "normal" },
  { label: "Raised", value: "raised" },
];

export const periPulsesVolumeOptions = [
  { label: "Bounding", value: "bounding" },
  { label: "Normal Volume", value: "normal" },
  { label: "Feeble", value: "feeble" },
];

export const cranialNervesOptions = [
  { label: "No Abnormality Detected", value: "no_abnormality_detected" },
  { label: "Abnormal", value: "abnormal" },
  { label: "Not Assessable", value: "not_assessable" },
];

//==================START===========================
// We should create a custom CheckBoxList
// with Left Right Normal, Affected Checkboxes
export const cranialNervesAbnormalOptions = [
  { label: "I (Olfactory N)", value: "i_olfactory_n" },
  { label: "II (Optic N)", value: "ii_optic_n" },
  { label: "III (Oculomotor N)", value: "iii_oculomotor_n" },
  { label: "IV (Trochlear N)", value: "iv_trochlear_n" },
  { label: "V (Trigeminal N)", value: "v_trigeminal_n" },
  { label: "VI (Abducens N)", value: "vi_abducens_n" },
  { label: "VII (Facial N)", value: "vii_facial_n" },
  { label: "VIII (Auditory N)", value: "viii_auditory_n" },
  { label: "IX (Glossopharyngeal)", value: "ix_glossopharyngeal" },
  { label: "X (Vagus N)", value: "x_vagus_n" },
  { label: "XI (Accessory N)", value: "xi_accessory_n" },
  { label: "XII (Hypoglossal N)", value: "xii_hypoglossal_n" },
];
export const cranialNerveSeverityOptions = [
  {
    label: "Left",
    name_key: "left",
    options: [
      { label: "Normal", value: "normal" },
      { label: "Affected", value: "affected" },
    ],
  },
  {
    label: "Right",
    name_key: "right",
    options: [
      { label: "Normal", value: "normal" },
      { label: "Affected", value: "affected" },
    ],
  },
];
//==================END===========================

export const sensorySystemOptions = [
  { label: "No Abnormality Detected", value: "no_abnormality_detected" },
  { label: "Assessable", value: "assessable" },
  { label: "Not Assessable", value: "not_assessable" },
];

//==================START===========================
// We should create a custom CheckBoxList
// with Left Right Normal, Affected Checkboxes

export const sensorySystemAbnormalOptions = [
  { label: "Right Upper Extremity", value: "sensory_right_upper_extremity" },
  { label: "Right Lower Extremity", value: "sensory_right_lower_extremity" },
  { label: "Left Upper Extremity", value: "sensory_left_upper_extremity" },
  { label: "Left Lower Extremity", value: "sensory_left_lower_extremity" },
];

export const sensoryExtremitiesOptions = [
  {
    label: "Pain / Temperature",
    name_key: "pain",
    options: [
      { label: "Normal", value: "normal" },
      { label: "Absent", value: "absent" },
      { label: "Reduced", value: "reduced" },
      { label: "Exaggerated", value: "exaggerated" },
    ],
  },
  {
    label: "Touch/Pressure",
    name_key: "touch",
    options: [
      { label: "Normal", value: "normal" },
      { label: "Absent", value: "absent" },
      { label: "Reduced", value: "reduced" },
      { label: "Exaggerated", value: "exaggerated" },
    ],
  },
  {
    label: "Vibration",
    name_key: "vibration",
    options: [
      { label: "Normal", value: "normal" },
      { label: "Absent", value: "absent" },
      { label: "Reduced", value: "reduced" },
      { label: "Exaggerated", value: "exaggerated" },
    ],
  },
  {
    label: "Proprioception",
    name_key: "proprioception",
    options: [
      { label: "Normal", value: "normal" },
      { label: "Absent", value: "absent" },
      { label: "Reduced", value: "reduced" },
      { label: "Exaggerated", value: "exaggerated" },
    ],
  },
];

//==================END===========================

export const motorSystemOptions = [
  { label: "No Abnormality Detected", value: "no_abnormality_detected" },
  { label: "Abnormal", value: "abnormal" },
  { label: "Not Assessable", value: "not_assessable" },
];

//==================START===========================
// We should create a custom CheckBoxList
// with different options for radio buttons underneath
//

export const motorSystemAbnormalOptions = [
  { label: "Right Upper Extremity", value: "motor_right_upper_extremity" },
  { label: "Right Lower Extremity", value: "motor_right_lower_extremity" },
  { label: "Left Upper Extremity", value: "motor_left_upper_extremity" },
  { label: "Left Lower Extremity", value: "motor_left_lower_extremity" },
];

export const motorAbnormalRadioItems = [
  {
    label: "Bulk",
    name_key: "bulk",
    options: [
      { label: "Normal", value: "normal" },
      { label: "Wasting", value: "wasting" },
    ],
  },
  {
    label: "Tone",
    name_key: "tone",
    options: [
      { label: "Normal", value: "normal" },
      { label: "Flaccid", value: "flaccid" },
      { label: "Rigid", value: "rigid" },
      { label: "Spastic", value: "spastic" },
    ],
  },
  {
    label: "Power",
    name_key: "power",
    options: [
      { label: "0", value: "0" },
      { label: "1", value: "1" },
      { label: "2", value: "2" },
      { label: "3", value: "3" },
      { label: "4", value: "4" },
      { label: "5", value: "5" },
    ],
  },
  {
    label: "Involuntary Movements",
    name_key: "involuntary_movements",
    options: [
      { label: "No", value: "no" },
      { label: "Yes", value: "yes" },
      { label: "Tremors", value: "tremors" },
      { label: "Fasciculations", value: "fasciculations" },
    ],
  },
];
//==================END===========================

export const reflexesOptions = [
  { label: "0 (No Response)", value: "0" },
  { label: "0.5+ (Elicited with reinforcement)", value: "0.5" },
  { label: "1+ (Low normal/Diminished)", value: "1" },
  { label: "2+ (Normal)", value: "2" },
  { label: "3+ (Brisk)", value: "3" },
  { label: "4+ (Very brisk)", value: "4" },
  { label: "5+ (Sustained Clonus)", value: "5" },
];

export const flexorExtensorOptions = [
  { label: "Flexor", value: "flexor" },
  { label: "Extensor", value: "extensor" },
];

export const coordinationOptions = [
  { label: "Normal", value: "normal" },
  { label: "Abnormal", value: "abnormal" },
  { label: "Not Assessable", value: "not_assessable" },
];
export const intactImpairedOptions = [
  { label: "Intact", value: "intact" },
  { label: "Impaired", value: "impaired" },
];

export const coordinationTests = [
  { label: "Finger-to-nose test", name: "finger_to_nose_test" },
  { label: "Finger-to-finger test", name: "finger_to_finger_test" },
  { label: "Adiadokokinesia or dysdiadokokinesia", name: "adiadokokinesia" },
  { label: "Rebound phenomena", name: "rebound_phenomena" },
  {
    label: "Walking along a straight line",
    name: "walking_along_a_straight_line",
  },
  { label: "Rombergs test", name: "rombergs_test" },
  { label: "Heel on shin test", name: "heel_on_shin_test" },
];

export const gaitOptions = [
  { label: "Normal", value: "normal" },
  { label: "Abnormal", value: "abnormal" },
  { label: "Not Assessable", value: "not_assessable" },
];
export const abnormalGaitOptions = [
  { label: "Hemiplegic Gait", value: "hemiplegic_gait" },
  { label: "Diplegic Gait", value: "diplegic_gait" },
  { label: "Parkinson Gait", value: "parkinson_gait" },
  { label: "Ataxic Gait", value: "ataxic_gait" },
  { label: "Myopathic Gait", value: "myopathic_gait" },
  { label: "Spastic Gait", value: "spastic_gait" },
  { label: "Scissoring Gait", value: "scissoring_gait" },
  { label: "Steppage Gait", value: "steppage_gait" },
  { label: "High Stepping Gait", value: "high_stepping_gait" },
  { label: "Waddling Gait", value: "waddling_gait" },
  { label: "Propulsive Gait", value: "propulsive_gait" },
  { label: "Lurching Gait", value: "lurching_gait" },
  { label: "Choreiform Gait", value: "choreiform_gait" },
  { label: "Sensory Gait", value: "sensory_gait" },
  { label: "Arthritic Gait", value: "arthritic_gait" },
  { label: "Others", value: "others" },
];

export const mentalAbnormalOptions = [
  { label: "Lethargic", value: "lethargic" },
  { label: "Confused", value: "confused" },
  { label: "Drowsy", value: "drowsy" },
  { label: "Delirious", value: "delirious" },
  { label: "Stuporous", value: "stuporous" },
  { label: "Agitated", value: "agitated" },
  { label: "Comatose", value: "comatose" },
  { label: "Others", value: "others" },
];

export const mentalStatusOptions = [
  { label: "Conscious & Coherent", value: "conscious_coherent" },
  { label: "Abnormal", value: "abnormal" },
  { label: "Not Assessable", value: "not_assessable" },
];

export const leftRightOptions = [
  { label: "Left", value: "left" },
  { label: "Right", value: "right" },
  { label: "Left & Right", value: "left_right" },
];

export const contractureAreas = [
  "Elbow",
  "Wrist",
  "Shoulder",
  "Hip",
  "Knee",
  "Ankle",
];

//==================START===========================
// We should write a loop for nested checkbox with the option
//of radio buttons
export const fractureAreasOptions = [
  { label: "Shoulder", value: "shoulder" },
  { label: "Humerus", value: "humerus" },
  { label: "Fore Arm", value: "fore_arm" },
  { label: "Wrist", value: "wrist" },
  { label: "Hand", value: "hand" },
  { label: "Hip", value: "hip" },
  { label: "Femur", value: "femur" },
  { label: "Leg", value: "leg" },
  { label: "Foot", value: "foot" },
  { label: "Clavicle", value: "clavicle" },
];
export const fractureTypeOptions = [
  {
    label: "",
    name_key: "type",
    options: [
      { label: "Fixed", value: "fixed" },
      { label: "Immobilized", value: "immobilized" },
      { label: "Needs evaluation", value: "needs_evaluation" },
      { label: "External splint", value: "external_splint" },
      { label: "Cast Traction", value: "cast_traction" },
    ],
  },
];

//==================START===========================
// Checklist followed by multiselect items
export const jointPainLocationOptions = [
  { label: "Elbow", value: "jp_elbow" },
  { label: "Wrist", value: "jp_wrist" },
  { label: "Shoulder", value: "jp_shoulder" },
  { label: "Hip", value: "jp_hip" },
  { label: "Knee", value: "jp_knee" },
  { label: "Ankle", value: "jp_ankle" },
  { label: "Small joints of hand", value: "jp_small_joints_of_hand" },
  { label: "Small Joints of Foot", value: "jp_small_joints_of_foot" },
  { label: "Others", value: "jp_others" },
];

export const jointPainSeverityOptions = [
  {
    label: "Left",
    name_key: "left",
    options: [
      { label: "Tenderness", value: "tenderness" },
      { label: "Swelling", value: "swelling" },
      { label: "Decreased ROM", value: "decreased_rom" },
    ],
  },
  {
    label: "Right",
    name_key: "right",
    options: [
      { label: "Tenderness", value: "tenderness" },
      { label: "Swelling", value: "swelling" },
      { label: "Decreased ROM", value: "decreased_rom" },
    ],
  },
];
//==================END===========================

export const limbDeformityOptions = [
  { label: "Right Upper Limb", value: "right_upper_limb" },
  { label: "Left Upper Limb", value: "left_upper_limb" },
  { label: "Right Lower Limb", value: "right_lower_limb" },
  { label: "Left Lower Limb", value: "left_lower_limb" },
  { label: "Others", value: "limb_deformity_others" },
];

export const skullOptions = [
  { label: "Normal", value: "normal" },
  { label: "Fracture", value: "fracture" },
  { label: "Soft Tissue Injury", value: "soft_tissue_injury" },
];

export const spineOptions = [
  { label: "Normal", value: "normal" },
  { label: "Fracture Vertebrae", value: "fracture_vertebrae" },
  { label: "Cervical Spondylosis", value: "cervical_spondylosis" },
  { label: "Kyphosis", value: "kyphosis" },
  { label: "Scoliosis", value: "scoliosis" },
  { label: "Others", value: "others" },
];

export const typeOfBreathingOptions = [
  { label: "Normal", value: "normal" },
  { label: "Apnea", value: "apnea" },
  { label: "Tachypnea", value: "tachypnea" },
  { label: "Bradypnea", value: "bradypnea" },
  { label: "Hyperpnea", value: "hyperpnea" },
  { label: "Hyperventilation", value: "hyperventilation" },
  { label: "Hypoventilation", value: "hypoventilation" },
  { label: "Orthopnea", value: "orthopnea" },
  { label: "Paradoxical ventilation", value: "paradoxical_ventilation" },
  { label: "Biot's Respiration", value: "biots_respiration" },
  { label: "Cheyne-Stoke's Breathing", value: "cheyne_stokes_breathing" },
  { label: "Kussmaul's Breathing", value: "kussmauls_breathing" },
  { label: "Sighing", value: "sighing" },
  { label: "Hoover's sign", value: "hoovers_sign" },
  { label: "Others", value: "others" },
];

export const auscultationOptions = [
  { label: "Normal Breath Sounds", value: "normal_breath_sounds" },
  { label: "Abnormal Breath Sounds", value: "abnormal_breath_sounds" },
];
//==================START===========================
//We should create a custom CheckBoxList with singleselect
// as additional form item
export const abnormalBreathZoneOptions = [
  { label: "Right Upper Zone", value: "right_upper_zone" },
  { label: "Right Middle Zone", value: "right_middle_zone" },
  { label: "Right Lower Zone", value: "right_lower_zone" },
  { label: "Left Upper Zone", value: "left_upper_zone" },
  { label: "Left Middle Zone", value: "left_middle_zone" },
  { label: "Left Lower Zone", value: "left_lower_zone" },
  { label: "Others", value: "abnormal_breath_others" },
];
export const breathSoundsOptions = [
  { label: "Normal Breath sounds", value: "normal_breath_sounds" },
  { label: "Decreased Breath sounds", value: "decreased_breath_sounds" },
  { label: "Bronchial Breathing", value: "bronchial_breathing" },
  { label: "Crepitations", value: "crepitations" },
  { label: "Wheeze", value: "wheeze" },
  { label: "Conducted sounds", value: "conducted_sounds" },
  { label: "Others", value: "others" },
];
//==================END===========================

export const pressureSoreAreaOptions = [
  { label: "Left Scapular", value: "left_scapular" },
  { label: "Right Scapular", value: "right_scapular" },
  { label: "Left Arm", value: "left_arm" },
  { label: "Right Arm", value: "right_arm" },
  { label: "Left Fore Arm", value: "left_fore_arm" },
  { label: "Right Fore Arm", value: "right_fore_arm" },
  { label: "Sacral", value: "sacral" },
  { label: "Left Gluteal", value: "left_gluteal" },
  { label: "Right Gluteal", value: "right_gluteal" },
  { label: "Left Hamstring", value: "left_hamstring" },
  { label: "Right Hamstring", value: "right_hamstring" },
  { label: "Left Heel", value: "left_heel" },
  { label: "Right Heel", value: "right_heel" },
  { label: "Others", value: "others" },
];

export const pressureSoreGradeOptions = [
  { label: "Grade 1", value: "grade_1" },
  { label: "Grade 2", value: "grade_2" },
  { label: "Grade 3", value: "grade_3" },
  { label: "Grade 4", value: "grade_4" },
];

export const openWoundAreaOptions = [
  { label: "Scalp", value: "scalp" },
  { label: "Face", value: "face" },
  { label: "Chest", value: "chest" },
  { label: "Abdomen", value: "abdomen" },
  { label: "Back", value: "back" },
  { label: "Hips", value: "hips" },
  { label: "Left Shoulder", value: "left_shoulder" },
  { label: "Right Shoulder", value: "right_shoulder" },
  { label: "Left Arm", value: "left_arm" },
  { label: "Right Arm", value: "right_arm" },
  { label: "Left Fore Arm", value: "left_fore_arm" },
  { label: "Right Fore Arm", value: "right_fore_arm" },
  { label: "Left Hand", value: "left_hand" },
  { label: "Right Hand", value: "right_hand" },
  { label: "Left Buttock", value: "left_buttock" },
  { label: "Right Buttock", value: "right_buttock" },
  { label: "Left Thigh", value: "left_thigh" },
  { label: "Right Thigh", value: "right_thigh" },
  { label: "Left Leg", value: "left_leg" },
  { label: "Right Leg", value: "right_leg" },
  { label: "Left Foot", value: "left_foot" },
  { label: "Right Foot", value: "right_foot" },
  { label: "Others", value: "others" },
];
//Should be a custom checkbox list followed by a text input for others

export const softTissueInjuryAreaOptions = [
  { label: "None", value: "none" },
  { label: "Abrasions", value: "abrasions" },
  { label: "Contusions", value: "contusions" },
  { label: "Bruises", value: "bruises" },
  { label: "Lacerations", value: "lacerations" },
  { label: "Others", value: "others" },
];

export const bodypartOptions = [
  { label: "0 (No Response)", value: "0 (No Response)" },
  {
    label: "0.5+ (Elicited with reinforcement)",
    value: "0.5+ (Elicited with reinforcement)",
  },
  {
    label: "1+ (Low normal/Diminished)",
    value: "1+ (Low normal/Deminished)",
  },
  { label: "2+ (Normal)", value: "2+ (Normal)" },
  { label: "3+ (Brisk)", value: "3+ (Brisk)" },
  { label: "4+ (Very Brisk)", value: "4+ (Very Brisk)" },
  { label: "5+ (Sustained clonus)", value: "5+ (Sustained clonus)" },
];

// Options for Babinski's reflex
export const babinskisOptions = [
  { label: "Flexor", value: "flexor" },
  { label: "Extensor", value: "extensor" },
];

// List of reflexes to be included in the form
export const reflexes = [
  { label: "Biceps", value: "biceps" },
  { label: "Brachioradialis", value: "brachioradialis" },
  { label: "Triceps", value: "triceps" },
  { label: "Knee", value: "knee" },
  { label: "Ankle", value: "ankle" },
  { label: "Babinski's", value: "babinskis" },
];

export const limbExtremityOptions = [
  { label: "Right Upper Extremity", value: "right_upper_extremity" },
  { label: "Right Lower Extremity", value: "right_lower_extremity" },
  { label: "Left Upper Extremity", value: "left_upper_extremity" },
  { label: "Left Lower Extremity", value: "left_lower_extremity" },
];

export const nursePressureSoreGradeOptions = [
  {
    name_key: "size",
    label: "Size",
    options: [
      { label: "Grade 1", value: "grade_1" },
      { label: "Grade 2", value: "grade_2" },
      { label: "Grade 3", value: "grade_3" },
      { label: "Grade 4", value: "grade_4" },
    ],
  },
];

export const genderOptions = [
  { label: "Child", value: "child" },
  { label: "Adult Male", value: "male adult" },
  { label: "Adult Female", value: "female adult" },
];
