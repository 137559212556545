import React, { useContext } from "react";
import {
  getLabelTagInput,
  getLabelTagsArray,
  getLabelTagsWithDescription,
  getPastMedicalHistorySummary,
} from "../../InspectionSummaries/constructTextFromValues";
import { Descriptions, Image } from "antd";
import { DailyReviewContext } from "../../Doctor/DailyReview/DailyReviewFormProvider";
import {
  knownAllergiesOptions,
  pastMedicalOptions,
  presentIllnessOptions,
} from "../../Options/historyOptions";
import historyIcon from "../../../../assets/images/nurseIcons/history.svg";
import { HistoryOutlined } from "@ant-design/icons";
const DrHistorySummaryDR = () => {
  const { formData } = useContext(DailyReviewContext);

  const constructDrHistorySummaryDR = () => {
    const historySummaryDrItems = [];

    if (getLabelTagsWithDescription(presentIllnessOptions, formData?.history)) {
      historySummaryDrItems.push({
        key: "1",
        label: "Present Illness",
        children: getLabelTagsWithDescription(
          presentIllnessOptions,
          formData?.history
        ),
      });
    }
    if (getPastMedicalHistorySummary(pastMedicalOptions, formData?.history)) {
      historySummaryDrItems.push({
        key: "2",
        label: "Past Medical History",
        children: getPastMedicalHistorySummary(
          pastMedicalOptions,
          formData?.history
        ),
      });
    }
    if (getLabelTagsWithDescription(knownAllergiesOptions, formData?.history)) {
      historySummaryDrItems.push({
        key: "3",
        label: "Known Allergies",
        children: getLabelTagsWithDescription(
          knownAllergiesOptions,
          formData?.history
        ),
      });
    }
    if (formData?.history?.present_complaints) {
      historySummaryDrItems.push({
        key: "4",
        label: "Present Complaints",
        children: getLabelTagInput(
          "Details",
          formData?.history?.present_complaints
        ),
      });
    }
    return historySummaryDrItems;
  };
  const historySummaryItems = constructDrHistorySummaryDR();
  return (
    <div>
      {historySummaryItems.length > 0 && (
        <Descriptions
          bordered
          title={
            <span style={{ fontWeight: "500", fontSize: "16px" }}>
              <HistoryOutlined style={{ color:"#7F56D9",fontWeight:"bold" }} /> History
            </span>
          }
          column={1}
          items={historySummaryItems}
          style={{padding: "10px", textAlign: "left" }}
          className="initial-insp-desc"
        />
      )}
    </div>
  );
};

export default DrHistorySummaryDR;
