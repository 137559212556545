import { Table, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { getPackageDetailsApi } from "../../../services/receptionist.services";

const PackageTable = ({ selectPackageId, setViewDetails, viewDetails }) => {
  const [packageData, setPackageData] = useState({
    package_investigations: [],
    package_services: [],
    room_type: null,
    room_price: null,
  });

  useEffect(() => {
    getPackageDetailsApi(selectPackageId)
      .then((res) => {
        setPackageData(res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [selectPackageId]);

  const columns = [
    {
      title: "Item",
      render: (_, record) =>
        record?.investigation?.name ||
        record?.service?.name ||
        record?.room_type || // Display room type here
        "N/A",
    },
    {
      title: "Type",
      render: (_, record) =>
        record?.investigation?.name
          ? "Investigation"
          : record?.service?.name
          ? "Service"
          : record?.room_type
          ? "Room Type"
          : "-",
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      render: (value, record) => value || (record?.room_type ? 1 : "N/A"), // Set quantity to 1 for room type
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      render: (value, record) => value || record?.room_price || "N/A", // Display room price here
    },
    {
      title: "Discount",
      dataIndex: "discount",
      key: "discount",
      render: (value, record) => value || "0.00", // Default discount to 0.00 for room type
    },
    {
      title: "Total Price",
      render: (_, record) => {
        const price = record?.price || record?.room_price || 0;
        const discount = record?.discount || 0;
        const quantity = record?.quantity || 1;
        return record?.is_percentage
          ? quantity * price - (quantity * price * discount) / 100
          : quantity * price - discount;
      },
    },
  ];

  const tableData = [
    ...packageData?.package_investigations,
    ...packageData?.package_services,
  ];

  // Include room details if they exist
  if (packageData?.room_type) {
    tableData.push({
      room_type: packageData?.room_type,
      room_price: packageData?.room_price,
      discount: packageData?.room_discount,
      is_percentage: packageData?.room_is_percentage,
    });
  }

  return (
    <div>
      <Table
        style={{ marginBottom: "10px" }}
        bordered
        pagination={false}
        dataSource={tableData}
        columns={columns}
      />
      {viewDetails && (
        <Typography.Link onClick={() => setViewDetails(false)}>
          View Less Details
        </Typography.Link>
      )}
    </div>
  );
};

export default PackageTable;
