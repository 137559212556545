import React, { useContext, useEffect, useState } from "react";
import { Form, Radio, Input } from "antd";
import { yesNoOptions } from "../../../../Options/commonOptions";

const Swellings = ({ context }) => {
  const { formData } = useContext(context);
  const [showSwellingsDetails, setShowSwellingsDetails] = useState(
    formData?.systemic_examination?.swellings === "yes"
  );
  const handleSwellingsChange = (e) => {
    if (e.target.value === "yes") {
      setShowSwellingsDetails(true);
    } else {
      setShowSwellingsDetails(false);
    }
  };

  useEffect(() => {
    setShowSwellingsDetails(
      formData?.systemic_examination?.swellings === "yes"
    );
  }, [formData]);
  return (
    <>
      <Form.Item label="Swellings" name="swellings">
        <Radio.Group options={yesNoOptions} onChange={handleSwellingsChange} />
      </Form.Item>
      {showSwellingsDetails && (
        <Form.Item name="swelling_description">
          <Input type="text" placeholder="Description" style={{width:"30%"}} />
        </Form.Item>
      )}
    </>
  );
};

export default Swellings;
