import React, { useContext, useEffect, useState } from "react";
import Visual from "../Examination/PhysioScales/MOCAScaleComponents/Visual";
import Memory from "../Examination/PhysioScales/MOCAScaleComponents/Memory";
import Language from "../Examination/PhysioScales/MOCAScaleComponents/Language";
import DelayedRecall from "../Examination/PhysioScales/MOCAScaleComponents/DelayedRecall";
import Orientation from "../Examination/PhysioScales/MOCAScaleComponents/Orientation";
import Abstraction from "../Examination/PhysioScales/MOCAScaleComponents/Abstraction";
import Naming from "../Examination/PhysioScales/MOCAScaleComponents/Naming";
import Attention from "../Examination/PhysioScales/MOCAScaleComponents/Attention";
import { Col, Collapse, Form, Input, List, Row, Typography } from "antd";
import { PsychologistReviewContext } from "../../Psychologist/Review/PsychologistReviewProvider";
const { Panel } = Collapse;

const PsychologistMocaScale = ({ form }) => {
  const { formData } = useContext(PsychologistReviewContext);
  // console.log(formData);
  useEffect(() => {
    setVisual(formData?.scales?.moca?.visual_executive_data || {});
    setNaming(formData?.scales?.moca?.naming_data || {});
    setAttention(formData?.scales?.moca?.attention_data || {});
    setLanguage(formData?.scales?.moca?.language_data || {});
    setAbstraction(formData?.scales?.moca?.abstraction_data || {});
    setDelayedRecall(formData?.scales?.moca?.delayed_recall_data || {});
    setOrientation(formData?.scales?.moca?.orientation_data || {});
  }, [formData]);

  const [visual, setVisual] = useState({});
  const [naming, setNaming] = useState({});
  const [attention, setAttention] = useState({});
  const [language, setLanguage] = useState({});
  const [abstraction, setAbstraction] = useState({});
  const [delayedRecall, setDelayedRecall] = useState({});
  const [orientation, setOrientation] = useState({});

  const calculateAggregate = (states, special) => {
    let sum = 0;
    if (special === false) {
      sum = Object.values(states).reduce(
        (acc, cur) => acc + (cur === "yes" ? 1 : 0),
        0
      );
    } else {
      sum = Object.values(states).reduce(
        (acc, cur) => acc + (cur === "yes" ? 1 : 0),
        0
      );
      const len = states?.attention_4?.length || 0;
      sum += len >= 4 ? 3 : len >= 2 ? 2 : len;
    }
    // form.setFieldsValue({ moca: { aggregate: sum } });
    return sum;
  };
  const visualAggregate = calculateAggregate(visual, false);
  const namingAggregate = calculateAggregate(naming, false);
  const attentionAggregate = calculateAggregate(attention, true);
  const languageAggregate = calculateAggregate(language, false);
  const abstractionAggregate = calculateAggregate(abstraction, false);
  const delayedRecallAggregate = calculateAggregate(delayedRecall, false);
  const orientationAggregate = calculateAggregate(orientation, false);
  const aggregate =
    (visualAggregate || 0) +
    (namingAggregate || 0) +
    (attentionAggregate || 0) +
    (languageAggregate || 0) +
    (abstractionAggregate || 0) +
    (delayedRecallAggregate || 0) +
    (orientationAggregate || 0);

  const interpretation =
    aggregate >= 26 ? "Normal Cognition" : "Abnormal Cognition";
  return (
    <>
      {/* <div className="physio-scales"> */}
      <List bordered>
        <List.Item>
          <Typography.Text strong>
            MOCA Score: {aggregate}
            /30{form.setFieldsValue({ moca: { score: aggregate } })}
          </Typography.Text>
          <Form.Item name={["moca", "score"]} hidden>
            <Input />
          </Form.Item>
        </List.Item>
        <List.Item>
          <Typography.Text strong>
            Interpretation: {interpretation}
            {form.setFieldsValue({ moca: { interpretation: interpretation } })}
          </Typography.Text>
          <Form.Item name={["moca", "interpretation"]} hidden>
            <Input />
          </Form.Item>
        </List.Item>
      </List>
      <Collapse defaultActiveKey={["1", "2", "3", "4", "5", "6", "7", "8"]}>
        <Panel
          header={
            <Row>
              <Col span={12}>Visual/Executive</Col>
              <Col span={12} style={{ textAlign: "right" }}>
                Score: {visualAggregate} / 5
              </Col>
            </Row>
          }
          key="1"
        >
          <Visual psych={true} setVisual={setVisual} />
        </Panel>
        <Panel
          header={
            <Row>
              <Col span={12}>Naming</Col>
              <Col span={12} style={{ textAlign: "right" }}>
                Score: {namingAggregate} / 3
              </Col>
            </Row>
          }
          key="2"
        >
          <Naming psych={true} setNaming={setNaming} />
        </Panel>
        <Panel header="Memory" key="3">
          <Memory psych={true} />
        </Panel>
        <Panel
          header={
            <Row>
              <Col span={12}>Attention</Col>
              <Col span={12} style={{ textAlign: "right" }}>
                Score: {attentionAggregate} / 6
              </Col>
            </Row>
          }
          key="4"
        >
          <Attention psych={true} setAttention={setAttention} />
        </Panel>
        <Panel
          header={
            <Row>
              <Col span={12}>Language</Col>
              <Col span={12} style={{ textAlign: "right" }}>
                Score: {languageAggregate} / 3
              </Col>
            </Row>
          }
          key="5"
        >
          <Language psych={true} setLanguage={setLanguage} />
        </Panel>
        <Panel
          header={
            <Row>
              <Col span={12}>Abstraction</Col>
              <Col span={12} style={{ textAlign: "right" }}>
                Score: {abstractionAggregate} / 2
              </Col>
            </Row>
          }
          key="6"
        >
          <Abstraction psych={true} setAbstraction={setAbstraction} />
        </Panel>
        <Panel
          header={
            <Row>
              <Col span={12}>Delayed Recall</Col>
              <Col span={12} style={{ textAlign: "right" }}>
                Score: {delayedRecallAggregate} / 5
              </Col>
            </Row>
          }
          key="7"
        >
          <DelayedRecall psych={true} setDelayedRecall={setDelayedRecall} />
        </Panel>
        <Panel
          header={
            <Row>
              <Col span={12}>Orientation</Col>
              <Col span={12} style={{ textAlign: "right" }}>
                Score: {orientationAggregate} / 6
              </Col>
            </Row>
          }
          key="8"
        >
          <Orientation psych={true} setOrientation={setOrientation} />
        </Panel>
      </Collapse>
      {/* </div> */}
    </>
  );
};

export default PsychologistMocaScale;
