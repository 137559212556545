import React, { useState, useEffect } from "react";
import { Button, Row, Col, Segmented, Space } from "antd";
import btnStyles from "../../../../styles/Custom/Button.module.css";
import DailyReviewForm from "./DailyReviewForm";
import dayjs from "dayjs";
import { EditOutlined, PlusOutlined } from "@ant-design/icons";
import DailyReviewSummary from "./DailyReviewSummary";
import NoDataPage from "../../../NoDataPages/NoDataPage";
import noReview from "../../../../assets/doctorIcons/noReview.svg";
import LoadingIndicator from "../../../Loaders/LoadingIndicator";
const DailyReview = ({ refreshPage, reviewData, iAssessment, patientData }) => {
  const [loading, setLoading] = useState(true);
  const [visible, setVisible] = useState(false);
  const [activeSegment, setActiveSegment] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [addMode, setAddMode] = useState(false);
  // const [newReview, setNewReview] = useState(false);
  const [dailyRefresh, setDailyRefresh] = useState(false);
  const setDailyReviewRefresh = () => {
    setDailyRefresh((prev) => !prev);
  };

  useEffect(() => {
    if (reviewData) setLoading(false);
    if (reviewData?.length > 0) {
      setActiveSegment(reviewData[0]?.id);
    }
  }, [reviewData, visible]);

  const segmentData = reviewData?.map((item) => ({
    label: `${dayjs(item?.created_at).format("MMM DD")}, ${dayjs(
      item?.created_at
    ).format("h:mm A")}`,
    value: item?.id,
  }));

  const handleAddReview = () => {
    setEditMode(false);
    setActiveSegment(null);
    setVisible(true);
    setAddMode(true);
    // setNewReview(reviewData.length === 0);
  };

  const handleEditReview = () => {
    setEditMode(true);
    setVisible(true);
  };

  return (
    <>
      {loading ? (
        <LoadingIndicator message={"Loading..."} />
      ) : (
        <>
          {reviewData?.length > 0 ? (
            <Row gutter={[12, 12]} style={{ marginTop: "0px" }}>
              <Col span={24} style={{ textAlign: "right" }}>
                <Space
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    <Button
                      type="primary"
                      onClick={handleAddReview}
                      className={`${btnStyles.lgBtn} inv-btn `}
                      style={{ marginRight: "15px", fontWeight: 500 }}
                    >
                      <PlusOutlined /> Review
                    </Button>
                    <Segmented
                      className="segment-wrap"
                      value={activeSegment}
                      options={segmentData}
                      onChange={setActiveSegment}
                      size="middle"
                      style={{
                        boxShadow: "2px 2px 3px #e3e4e6",
                        maxWidth: "89%",
                      }}
                    />
                  </div>

                  <Button
                    type="primary"
                    onClick={handleEditReview}
                    className={`${btnStyles.lgBtn} inv-btn `}
                    disabled={!activeSegment}
                    style={{ textAlign: "right", fontWeight: 500 }}
                  >
                    <EditOutlined />
                    Edit
                  </Button>
                </Space>
              </Col>
              <Col span={24} style={{ textAlign: "right" }}></Col>
              <Col span={24} style={{ textAlign: "center" }}>
                <DailyReviewSummary
                  editId={activeSegment}
                  dailyRefresh={setDailyReviewRefresh}
                />
              </Col>
            </Row>
          ) : (
            <Row
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                minHeight: "80vh",
              }}
            >
              <Col span={24} style={{ textAlign: "center" }}>
                <NoDataPage
                  title={"Review Not Yet Submitted "}
                  subtitle={"Kindly, upload review here"}
                  image={noReview}
                />
                <Button
                  type="primary"
                  onClick={handleAddReview}
                  className={`${btnStyles.lgBtn} inv-btn `}
                >
                  Review Form
                </Button>
              </Col>
            </Row>
          )}
        </>
      )}

      {visible && (
        // <DailyReviewFormProvider>
        <DailyReviewForm
          visible={visible}
          setVisible={setVisible}
          refreshPage={refreshPage}
          editId={editMode ? activeSegment : null}
          // newReview={reviewData.length === 0}
          iAssessment={iAssessment}
          // reviewId={reviewId}
          addMode={addMode}
          setAddMode={setAddMode}
          setActiveSegment={setActiveSegment}
          reviewData={reviewData}
          setDailyReviewRefresh={setDailyReviewRefresh}
          dailyRefresh={dailyRefresh}
          segmentData={segmentData}
          patientData={patientData}
        />
        // </DailyReviewFormProvider>
      )}
    </>
  );
};

export default DailyReview;
