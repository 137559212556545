import React from "react";
import { tcmOptions } from "../../../../Options/physioOptions";
import { Form, InputNumber } from "antd";

const TCMItems = ({ handleChange, nameKey = null }) => {
  return (
    <>
      {tcmOptions.map((option) => (
        <Form.Item
          key={option.value}
          label={option.label}
          name={nameKey ? [nameKey, option.value] : option.value}
        >
          <InputNumber
            placeholder={`${option.min} to ${option.max}`}
            max={option.max}
            min={option.min}
            style={{ width: "100%" }}
            onChange={(value) => handleChange(value, option.value)}
          />
        </Form.Item>
      ))}
    </>
  );
};

export default TCMItems;
