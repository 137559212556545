import React, { useContext, useState } from "react";
import { Button, Divider, Form, Input, message, Typography } from "antd";
import inputStyles from "../../../styles/Custom/Input.module.css";
import btnStyles from "../../../styles/Custom/Button.module.css";
import textStyles from "../../../styles/Custom/Typography.module.css";
import { mobileOtpLoginApi } from "../../../services/auth.services";
import { setItem } from "../../../helpers/localStorage";
import { USER } from "../../../constants/defaultKeys";
import { UserContext } from "../../../context/UserContext";
import { useNavigate } from "react-router-dom";

function MobileLogin({ orgID }) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { setUserDetails } = useContext(UserContext);

  const onFinish = async (values) => {
    setLoading(true);
    try {
      const resp = await mobileOtpLoginApi(values);
      const data = resp?.data;
      if (resp) {
        setItem(USER, JSON.stringify(data));
        navigate(
          orgID
            ? `/otp/${resp?.data?.token}?org=${orgID}`
            : `/otp/${resp?.data?.token}`
        );
      }
    } catch (error) {
      console.log(error);
      message.error(error?.response?.data?.message || "Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {" "}
      <Typography
        className={`text-center `}
        style={{ paddingTop: "12px", paddingBottom: "32px" }}
      >
        <br />
        <Typography
          className={`text-center ${textStyles.h5}`}
          // style={{ marginTop: "24px" }}
        >
          Login to your account.
        </Typography>
        <br />
        Please enter your mobile number
        <br />
      </Typography>{" "}
      <Form
        autoComplete="off"
        onFinish={onFinish}
        layout="vertical"
        // requiredMark={false}
        style={{ width: "100%" }}
      >
        <Form.Item
          label="Mobile Number"
          name="mobile_number"
          rules={[
            { required: true, message: "Please enter mobile number" },
            {
              pattern: /^[0-9]{10}$/,
              message: "Please enter a valid 10-digit phone number",
            },
          ]}
        >
          <Input
            maxLength="10"
            placeholder="Mobile Number"
            addonBefore={"+91"}
            className={inputStyles.mdAddonInput}
            style={{ width: "100%" }}
          />
        </Form.Item>
        <br />
        <Form.Item style={{ width: "100%", margin: "auto" }}>
          <Button
            loading={loading}
            style={{ width: "100%" }}
            type="primary"
            htmlType="submit"
            className={btnStyles.mdBtn}
          >
            Send Otp
          </Button>
        </Form.Item>
        <Divider>Or</Divider>
        <Typography className="text-center" style={{ paddingTop: "12px" }}>
          Login with{" "}
          <span
            style={{ color: "#7F56D9", cursor: "pointer" }}
            onClick={() => navigate("/login?type=email")}
          >
            Email{" "}
          </span>
          /{" "}
          <span
            style={{ color: "#7F56D9", cursor: "pointer" }}
            onClick={() => navigate("/login")}
          >
            Mobile Password
          </span>
        </Typography>
      </Form>
    </>
  );
}

export default MobileLogin;
