import React, { useContext, useEffect } from "react";
import { Col, Collapse, Row } from "antd";
import SkinForm from "./SkinForm/SkinForm";
import CardioForm from "./CardioForm/CardioForm";
import AbdomenForm from "./AbdomenForm/AbdomenForm";
import RespiratoryForm from "./RespiratoryForm/RespiratoryForm";
import MusculoSkeletalForm from "./MusculoSkeletalForm/MusculoSkeletalForm";
import CNSForm from "./CNSForm/CNSForm";
import "./SystemicExaminationForm.css";
import { DoctorInitialContext } from "../../HistoryForm/DoctorHistoryFormProvider";
const { Panel } = Collapse;

const SystemicExaminationForm = ({
  form,
  openWounds,
  setOpenWounds,
  pressureSore,
  setPressureSore,
}) => {
  const { formData } = useContext(DoctorInitialContext);

  useEffect(() => {
    if (formData?.systemic_examination) {
      form.setFieldsValue({
        ...formData?.systemic_examination,
        pressure_sore: formData?.systemic_examination?.pressure_sore,
        open_wounds: formData?.systemic_examination?.open_wounds,
      });
    }
  }, [formData]);

  return (
    <Row gutter={[16, 16]}>
      <Col span={12}>
        <Collapse defaultActiveKey={["1"]}>
          <Panel header="Skin" key="1">
            <SkinForm
              pressureSore={pressureSore}
              setPressureSore={setPressureSore}
              openWounds={openWounds}
              setOpenWounds={setOpenWounds}
            />
          </Panel>
        </Collapse>
        <Collapse defaultActiveKey={["3"]}>
          <Panel header="Respiratory System" key="3">
            <RespiratoryForm context={DoctorInitialContext} />
          </Panel>
        </Collapse>
        <Collapse defaultActiveKey={["5"]}>
          <Panel header="Musculo Skeletal System" key="5">
            <MusculoSkeletalForm />
          </Panel>
        </Collapse>
      </Col>
      <Col span={12}>
        <Collapse defaultActiveKey={["2"]}>
          <Panel header="Cardio Vascular System" key="2">
            <CardioForm context={DoctorInitialContext} />
          </Panel>
        </Collapse>
        <Collapse defaultActiveKey={["4"]}>
          <Panel header="Abdomen" key="4">
            <AbdomenForm />
          </Panel>
        </Collapse>
        <Collapse defaultActiveKey={["6"]}>
          <Panel header="Central Nervous System" key="6">
            <CNSForm />
          </Panel>
        </Collapse>
      </Col>
    </Row>
  );
};

export default SystemicExaminationForm;
