import { Descriptions, Image, Space } from "antd";
import React from "react";
import {
  getLabelTagInput,
  getLabelTagsWithDescription,
} from "../constructTextFromValues";
import { speechCheckboxOptions } from "../../Options/functionalAsessmentOptions";
import diagnosisIcon from "../../../../assets/doctorIcons/diagnosis.svg";

const SpeechSwallowFunctionalDiagnosisSummary = ({ formData }) => {
  const constructFunctionalDiagnosisSummaryItems = () => {
    const functionalDiagnosisSummaryItems = [];
    const diagnosis = formData?.functional_diagnosis || {};
    const pushFunctionalItem = (key, label, children) => {
      functionalDiagnosisSummaryItems.push({ key, label, children });
    };
    if (diagnosis) {
      pushFunctionalItem(
        "1",
        "Functional Diagnosis",
        <>
          {getLabelTagsWithDescription(speechCheckboxOptions, diagnosis)}
          {getLabelTagInput("Comments", diagnosis?.comments)}
        </>
      );
    }
    return functionalDiagnosisSummaryItems;
  };
  const functionalDiagnosisSummaryItems =
    constructFunctionalDiagnosisSummaryItems();
  return (
    <>
      <Descriptions
        bordered
        column={1}
        title={
          <span style={{ fontWeight: "500", marginLeft: ".2rem" }}>
            <Space>
              <Image src={diagnosisIcon} width={18} preview={false} />
              <span style={{ fontSize: "16px", fontWeight: "normal" }}>
                Functional Diagnosis
              </span>
            </Space>
          </span>
        }
        items={functionalDiagnosisSummaryItems}
        style={{ margin: "0", border: "none" }}
        className="initial-insp-desc"
      />
    </>
  );
};

export default SpeechSwallowFunctionalDiagnosisSummary;
