import React from "react";
import { Table } from "antd";
import dayjs from "dayjs";
import { formatToINR } from "../../../../helpers/utility";

const PatientPackageTable = ({ data }) => {
  const tableData = data && [
    ...data?.package?.package_services,
    ...data?.package?.package_investigations,
  ];

  const columns = [
    {
      title: "Item",
      dataIndex: "name",
      key: "name",
      render: (_, record) =>
        record?.service?.name || record?.investigation?.name,
    },
    {
      title: "Type",
      render: (_, record) =>
        record?.investigation?.name
          ? "Investigation"
          : record?.service?.name
          ? "Service"
          : record?.room_type
          ? "Room Type"
          : "-",
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      render: (value) => formatToINR(value),
    },
  ];
  return (
    <div>
      <Table
        dataSource={tableData}
        columns={columns}
        pagination={false}
        size="small"
      />
    </div>
  );
};

export default PatientPackageTable;
