import React from "react";
import { Form, Input, Select, InputNumber } from "antd";
import inputStyles from "../../../styles/Custom/Input.module.css";
import { genderKeys } from "../receptionKeys";
import { validateConfig } from "../../../config/validate.config";
import PatientMobileSearch from "../PatientMobileSearch";

const PatientDetailsForm = ({
  searchedText,
  setSearchedText,
  selectedId,
  setSelectedId,
  editData,
  form,
}) => {
  return (
    <>
      <PatientMobileSearch
        searchedText={searchedText}
        setSearchedText={setSearchedText}
        setSelectedId={setSelectedId}
        selectedId={selectedId}
        id={editData?.patient || editData?.id}
        form={form}
      />
      <Form.Item
        label="First Name"
        rules={validateConfig.isRequired("Patient Name")}
        name="first_name"
      >
        <Input
          className={inputStyles.mdInput}
          style={{ width: "300px" }}
          placeholder="Enter First name"
        />
      </Form.Item>
      <Form.Item
        label="Last Name"
        rules={validateConfig.isRequired("Patient Name")}
        name="last_name"
      >
        <Input
          className={inputStyles.mdInput}
          style={{ width: "300px" }}
          placeholder="Enter Last name"
        />
      </Form.Item>
      <Form.Item
        label="Age"
        name={"age"}
        rules={validateConfig.isRequired("Age")}
      >
        <InputNumber
          placeholder="Enter age"
          className={inputStyles.mdNumberInput}
          style={{ width: "300px" }}
        />
      </Form.Item>
      <Form.Item
        label="Gender"
        name={"gender"}
        rules={[{ required: true, message: "Please select gender" }]}
      >
        <Select
          allowClear
          placeholder="Gender"
          className={inputStyles.mdSelect}
          style={{ width: "300px" }}
          options={genderKeys}
        />
      </Form.Item>
      <Form.Item
        label="Email"
        name={"email"}
        rules={[{ type: "email", message: "Please enter a valid email" }]}
      >
        <Input
          placeholder="Enter email"
          className={inputStyles.mdInput}
          style={{ width: "300px" }}
        />
      </Form.Item>
      <Form.Item label="Address" name={"address"}>
        <Input.TextArea
          placeholder="Address"
          className={inputStyles.mdInput}
          style={{ width: "300px" }}
        />
      </Form.Item>
    </>
  );
};

export default PatientDetailsForm;
