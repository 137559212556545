import { Descriptions, Space } from "antd";
import React from "react";
import { getPatientConditionTags } from "../constructTextFromValues";
import { speechOptions } from "../../Options/speechSwallowOptions";
import { yesNoOptions } from "../../Options/commonOptions";
import { FileSearchOutlined } from "@ant-design/icons";

const SpeechSwallowGeneralExaminationSummary = ({ formData }) => {
  const constructExaminationSummaryItems = () => {
    const examinationSummaryItems = [];
    const pushExaminationItem = (key, label, children) => {
      examinationSummaryItems.push({ key, label, children });
    };
    if (formData?.general_examination) {
      pushExaminationItem(
        "1",
        "General Examination",
        getPatientConditionTags(speechOptions, yesNoOptions, formData)
      );
    }
    return examinationSummaryItems;
  };
  const examinationSummaryItems = constructExaminationSummaryItems();
  return (
    <>
      <Descriptions
        bordered
        column={1}
        title={
          <Space>
            <FileSearchOutlined style={{ color: "#7F56D9" }} />
            <span style={{ fontWeight: 500 }}>General Examination</span>
          </Space>
        }
        items={examinationSummaryItems}
        style={{ margin: "0", border: "none", textAlign: "left" }}
        className="initial-insp-desc"
      />
    </>
  );
};

export default SpeechSwallowGeneralExaminationSummary;
