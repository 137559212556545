import React, { useEffect, useState } from "react";
import {
  Form,
  List,
  InputNumber,
  Select,
  Button,
  message,
  Checkbox,
  DatePicker,
} from "antd";
import inputStyles from "../../../../styles/Custom/Input.module.css";
import {
  paymentKeys,
  printBill,
} from "../../../../components/Receptionist/receptionKeys";
import btnStyles from "../../../../styles/Custom/Button.module.css";
import {
  addPaymentApi,
  generateIpBillApi,
} from "../../../../services/receptionist.services";
import useQuery from "../../../../hooks/useQuery";
import { formatToINR } from "../../../../helpers/utility";
import dayjs from "dayjs";

const FinalSettlement = ({ billData, refreshData }) => {
  const params = useQuery();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [partialPayment, setPartialPayment] = useState(false);
  const [partialPaymentAmount, setPartialPaymentAmount] = useState(0);
  const [finalAmount, setFinalAmount] = useState(0); // State for final amount

  const activePatient = billData?.data?.admission?.is_active;

  const onFinish = (values) => {
    const formData = {
      amount: values?.amount,
      discount: values?.discount || 0,
      mode: values?.mode,
      discharge_date: values?.discharge_date,
    };

    setLoading(true);
    activePatient
      ? generateIpBillApi(params?.patient, formData)
          .then((res) => {
            message.success(res.data.message);
            printBill(
              res?.data?.data?.id,
              res?.data?.data?.payments?.[0]?.id,
              partialPayment
            );
            form.resetFields();
            refreshData();
            setLoading(false);
          })
          .catch((err) => {
            message.error(err.response.data.message);
            setLoading(false);
          })
      : addPaymentApi(billData?.data?.bill?.id, formData)
          .then((res) => {
            message.success(res.data.message);
            form.resetFields();
            refreshData();
            printBill(
              res?.data?.data?.bill,
              res?.data?.data?.id,
              partialPayment
            );
            setLoading(false);
          })
          .catch((err) => {
            message.error(err.response.data.message);
            setLoading(false);
          });
  };

  const onValuesChange = (changedValues, allValues) => {
    const { discount, amount } = { ...changedValues, ...allValues };

    // Get the due amount from billData
    const dueAmount = billData?.data?.payment_details?.total_due_amount;

    // If partial payment is selected, use the entered amount, else calculate based on due amount
    let calculatedFinalAmount;
    if (partialPayment) {
      calculatedFinalAmount = amount || 0; // Use the entered partial payment amount
    } else {
      // Calculate the final amount based on discount
      calculatedFinalAmount = dueAmount - (discount || 0);
    }

    // Set the final amount state
    setFinalAmount(calculatedFinalAmount);

    // Update the amount field in the form (ensure it's not negative)
    form.setFieldsValue({
      amount: calculatedFinalAmount < 0 ? 0 : calculatedFinalAmount,
    });
  };

  useEffect(() => {
    if (billData?.data?.payment_details?.total_due_amount) {
      form.setFieldsValue({
        amount:
          billData?.data?.payment_details?.total_due_amount?.toFixed(2) > 0
            ? billData?.data?.payment_details?.total_due_amount?.toFixed(2)
            : 0,
      });
      setPartialPaymentAmount(
        billData?.data?.payment_details?.total_due_amount?.toFixed(2)
      );
      setFinalAmount(
        billData?.data?.payment_details?.total_due_amount?.toFixed(2) > 0
          ? billData?.data?.payment_details?.total_due_amount?.toFixed(2)
          : 0
      ); // Initialize final amount
    }
  }, [billData]);

  return (
    <>
      <Form
        // layout="vertical"
        onFinish={onFinish}
        form={form}
        onValuesChange={onValuesChange}
        initialValues={{
          discharge_date: dayjs().startOf("minute"),
        }}
        disabled={billData?.data?.payment_details?.total_due_amount <= 0}
      >
        <Form.Item
          label="Discharge date and time"
          name="discharge_date"
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 12, offset: 6 }}
        >
          <DatePicker
            defaultValue={dayjs(dayjs().format("YYYY-MM-DD HH:mm"))}
            showTime={{ format: "HH:mm" }}
            format="YYYY-MM-DD HH:mm"
            className={inputStyles.mdDateInput}
            style={{ width: "220px" }}
          />
        </Form.Item>
        <List split={false}>
          <List.Item>
            <List.Item.Meta title="Total Charges" />
            <div>
              {formatToINR(
                billData?.data?.payment_details?.total_amount?.toFixed(2)
              )}
              {/* /- */}
            </div>
          </List.Item>
          <List.Item>
            <List.Item.Meta title="Payments" />
            <div>
              {formatToINR(
                billData?.data?.payment_details?.total_paid_amount?.toFixed(2)
              )}
              {/* /- */}
            </div>
          </List.Item>

          <List.Item>
            <List.Item.Meta title="Discount (In rupees)" />
            <Form.Item
              name="discount"
              rules={[
                {
                  validator: (_, value) => {
                    if (value && Number(value) > Number(partialPaymentAmount)) {
                      return Promise.reject(
                        "Discount cannot be greater than amount"
                      );
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <InputNumber
                onChange={() => {
                  form.setFields([
                    {
                      name: "amount",
                      errors: [],
                    },
                  ]);

                  form.validateFields(["amount"]);
                }}
                style={{ width: "300px" }}
                min={0}
                className={inputStyles.mdNumberInput}
              />
            </Form.Item>
          </List.Item>
          <List.Item>
            <List.Item.Meta title="Amount to be paid" />
            <Form.Item
              name="amount"
              rules={[
                {
                  validator: (_, value) => {
                    const discount = form.getFieldValue("discount");
                    if (
                      value &&
                      Number(value) >
                        Number(
                          billData?.data?.payment_details?.total_due_amount
                        )
                    ) {
                      return Promise.reject(
                        "Amount cannot be greater than total amount"
                      );
                    } else if (
                      value &&
                      Number(value) >
                        Number(
                          billData?.data?.payment_details?.total_due_amount -
                            discount
                        )
                    ) {
                      return Promise.reject(
                        "Amount cannot be greater than total amount"
                      );
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <InputNumber
                prefix="Rs"
                disabled={!partialPayment}
                style={{ width: "300px" }}
                className={inputStyles.mdNumberInput}
              />
            </Form.Item>
          </List.Item>

          <Form.Item name="partial_payment">
            <Checkbox
              checked={partialPayment}
              onChange={(e) => setPartialPayment(e.target.checked)}
            />{" "}
            Partial Payment
          </Form.Item>

          <List.Item>
            <List.Item.Meta title="Payment Mode" />
            <Form.Item
              name="mode"
              rules={[
                { required: true, message: "Please select a payment mode" },
              ]}
            >
              <Select
                style={{ width: "300px" }}
                options={paymentKeys}
                placeholder="Select Payment Mode"
                className={inputStyles.mdSelect}
              />
            </Form.Item>
          </List.Item>
        </List>
        {billData?.data?.payment_details?.total_due_amount !== 0 && (
          <div style={{ textAlign: "center" }}>
            <Button
              loading={loading}
              type="primary"
              htmlType="submit"
              className={btnStyles.lgBtn}
            >
              {partialPayment ? "Generate Receipt" : "Generate Bill"}
            </Button>
          </div>
        )}
      </Form>
    </>
  );
};

export default FinalSettlement;
