import { Col, message, Row } from "antd";
import React, { useContext, useEffect } from "react";
import PatientAssessmentReviewSummary from "../../DailyReviewSummaries/PatientAssessment/PatientAssessmentReviewSummary";
import { RespiratoryReviewContext } from "./RespiratoryTherapistReviewProvider";
import { getRespiratoryReviewAPI } from "../../../../services/respiratory.services";
import DrClinicalImpressionSummary from "../../InspectionSummaries/ClinicalImpressionSummary/DrClinicalImpressionSummary";
import RespiratoryTreatmentPlanSummary from "../../InspectionSummaries/TreatmentPlan/RespiratoryTreatmentPlanSummary";
import { checkEmpty } from "../../../../helpers/utility";

const RespiratoryTherapistReviewSummary = ({ editId, dailyRefresh }) => {
  const { formData, setFormData } = useContext(RespiratoryReviewContext);
  const openEdit = async () => {
    if (editId) {
      try {
        const response = await getRespiratoryReviewAPI(editId);
        setFormData(response.data.data);
      } catch (error) {
        console.log(`Error: ${error}`);
        message.error("Unable to Fetch Data");
      }
    }
  };
  useEffect(() => {
    openEdit();
  }, [editId, dailyRefresh]);
  return (
    <>
      <Row className="summaries" gutter={[16, 16]}>
        <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
          {checkEmpty(formData?.clinical_diagnosis) && (
            <DrClinicalImpressionSummary context={RespiratoryReviewContext} />
          )}
          {checkEmpty(formData?.patient_assessment) && (
            <PatientAssessmentReviewSummary formData={formData} />
          )}
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
          {checkEmpty(formData?.treatment_plan) && (
            <RespiratoryTreatmentPlanSummary
              context={RespiratoryReviewContext}
            />
          )}
        </Col>
      </Row>
    </>
  );
};

export default RespiratoryTherapistReviewSummary;
