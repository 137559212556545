import React, { useEffect, useState } from "react";
import { Empty, Form, Tag } from "antd";
import { getSlotsApi } from "../../../services/receptionist.services";
import tagStyles from "../../../styles/Custom/Tag.module.css";
import dayjs from "dayjs";

const Slot = ({
  formValues,
  selectedPractitioner,
  selectedSlot,
  setSelectedSlot,
  setSelectedEndSlot,
  editData,
}) => {
  const [slots, setSlots] = useState([]);
  console.log(selectedPractitioner, "sele");
  useEffect(() => {
    if (selectedPractitioner) {
      getSlotsApi(
        `doctor_id=${selectedPractitioner}&selected_date=${dayjs(
          formValues?.appointment_date || editData?.appointment_date
        ).format("YYYY-MM-DD")}`
      )
        .then((res) => {
          setSlots(res?.data?.slots);
        })
        .catch((err) => {
          console.log(err);
          setSlots([]);
        });
    } else {
      setSlots(null);
    }
  }, [
    selectedPractitioner,
    formValues?.appointment_date,
    editData?.appointment_date,
  ]);

  const editedAppointmentTime = editData?.appointment_time
    .split(":")
    .slice(0, 2)
    .join(":");

  const handleChange = (tag) => {
    if (
      tag.status === "scheduled" &&
      editedAppointmentTime !== tag.appointment_time
    )
      return;

    const isSelected = tag.appointment_time === selectedSlot;
    setSelectedSlot(isSelected ? null : tag.appointment_time);
    setSelectedEndSlot(isSelected ? null : tag.appointment_end_time);
  };
  const getTagStyle = (tag) => {
    const isScheduled = tag.status === "scheduled";
    const isEdited = editedAppointmentTime === tag.appointment_time;

    return {
      backgroundColor: isScheduled && !isEdited ? "#d9d9d9" : "",
      color: isScheduled && !editedAppointmentTime ? "#a0a0a0" : "",
      pointerEvents: isScheduled && !editedAppointmentTime ? "none" : "",
      border: !isScheduled || isEdited ? "1px solid green" : "",
      margin: "4px",
    };
  };
  return (
    <>
      {slots?.length === 0 ? (
        <Empty description="No slots available for selected date" />
      ) : slots ? (
        <Form.Item label="Choose Slot" name="appointment_time">
          {slots?.map((tag, index) => (
            <Tag.CheckableTag
              className={tagStyles.mdTag}
              key={index}
              checked={selectedSlot === tag?.appointment_time}
              onChange={() => handleChange(tag)}
              style={getTagStyle(tag)}
            >
              {tag.appointment_time}
            </Tag.CheckableTag>
          ))}
        </Form.Item>
      ) : null}
    </>
  );
};

export default Slot;
