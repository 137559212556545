import React, { useContext } from "react";
import { PsychologistInitialContext } from "./PsychologistInitialAssessmentProvider";
import PsychologistPatientDemographicSummary from "../../InspectionSummaries/PatientDemographics/PsychologistPatientDemographicSummary";
import DrClinicalImpressionSummary from "../../InspectionSummaries/ClinicalImpressionSummary/DrClinicalImpressionSummary";
import { Col, Row } from "antd";
import PsychologistPreviousCounsellingSummary from "../../InspectionSummaries/PreviousCounselling/PsychologistPreviousCounsellingSummary";
import PsychologistSymptomsSummary from "../../InspectionSummaries/Symptoms/PsychologistSymptomsSummary";
import PsychologyDiagnosisSummary from "../../InspectionSummaries/PsychologyDiagnosis/PsychologyDiagnosisSummary";
import PsychologistTherapySummary from "../../InspectionSummaries/Therapy/PsychologistTherapySummary";
import { checkEmpty } from "../../../../helpers/utility";

const PsychologistInitialInspectionSummary = () => {
  const { formData, setFormData } = useContext(PsychologistInitialContext);

  return (
    <Row className="summaries" gutter={[16, 16]}>
      <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
        {checkEmpty(formData?.patient_demographics) && (
          <PsychologistPatientDemographicSummary formData={formData} />
        )}
        {checkEmpty(formData?.previous_counselling) && (
          <PsychologistPreviousCounsellingSummary formData={formData} />
        )}
        {checkEmpty(formData?.psychology_diagnosis) && (
          <PsychologyDiagnosisSummary
            formData={formData}
            context={PsychologistInitialContext}
          />
        )}
      </Col>
      <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
        {checkEmpty(formData?.symptoms) && (
          <PsychologistSymptomsSummary formData={formData} />
        )}

        {checkEmpty(formData?.therapies_suggested) && (
          <PsychologistTherapySummary
            formData={formData}
            context={PsychologistInitialContext}
          />
        )}
      </Col>
    </Row>
  );
};

export default PsychologistInitialInspectionSummary;
