import { Card, Descriptions } from "antd";
import React from "react";

const SummaryMedicines = ({ data }) => {
  return (
    <Card title="Current Medicines"  style={{ marginTop: "10px"}} styles={{header:{borderBottom:"none"},body:{paddingTop:"0px"}}}>
      <Descriptions >
        {data?.map((medicines) => (
          <Descriptions.Item>
            <span style={{padding:"5px"}} >
              {medicines?.name}
            </span>
          </Descriptions.Item>
        ))}
      
      </Descriptions>
    </Card>
  );
};

export default SummaryMedicines;
