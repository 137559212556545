import React, { useState, useRef } from "react";
import {
  Space,
  Table,
  Tag,
  Input,
  Button,
  Typography,
  message,
  Popconfirm,
} from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  ExportOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import InvestigationModal from "./InvestigationModal";
import InvestigationsUploadModal from "./InvestigationsUploadModal";
import { statusColors } from "../../../helpers/medicineKeys";
import { deletePatientatientInvestigationsAPI } from "../../../services/patient.services";
import InvestigationGallery from "./InvestigationGallery/InvestigationGallery";
import { isTablet } from "react-device-detect";
import dayjs from "dayjs";
import { Header } from "antd/es/layout/layout";
const { Paragraph, Text } = Typography;

const InvestigationTable = ({ data, refreshPage, id, patientData }) => {
  const [editData, setEditData] = useState(null);
  const [uploadModal, setUploadModal] = useState(false);
  const searchInput = useRef(null);
  const [open, setOpen] = useState(false);
  const [galleryModal, setGalleryModal] = useState(false);
  const handleSearch = (confirm) => {
    confirm();
  };
  const handleReset = (clearFilters) => {
    clearFilters();
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(confirm)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(confirm)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => {
              clearFilters && handleReset(clearFilters);
              confirm({
                closeDropdown: true,
              });
            }}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
          fontSize: "20px",
        }}
      />
    ),
    onFilter: (value, record) =>
      (record.name &&
        record.name.toString().toLowerCase().includes(value.toLowerCase())) ||
      (record.investigation_name &&
        record.investigation_name
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())),
    onFilterDropdownVisibleChange: (visible) => {},
  });
  const deleteInvestigation = (id) => {
    deletePatientatientInvestigationsAPI(id)
      .then((res) => {
        message.success(res?.data?.message);
        refreshPage();
      })
      .catch((err) => message.error(err?.response?.data?.message));
  };
  const columns = [
    {
      title: "Investigation",
      render: (record) => record?.name || record?.investigation_name,
      ...getColumnSearchProps("name"),
      width: "20%",
      
    },
    {
      title: "Report Delivered On",
      render: (value, record) =>
        record?.investigation_date || record?.delivery_on
          ? dayjs(record?.investigation_date || record?.delivery_on)?.format(
              "MMM DD"
            )
          : "-",
      width: "15%",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: "20%",
      render: (value, record) => {
        return (
          <>
            {" "}
            <Tag color={statusColors?.[value]}>{value?.replace("_", " ")}</Tag>
           
          </>
        );
      },
    },
    {
      title: "Notes",
      dataIndex: "comments",
      key: "comments",
      width: "15%",
      render: (value) =>
        value ? (
          <Paragraph
            ellipsis={{
              rows: 2,
              expandable: true,
              symbol: "more",
            }}
            style={{ width: isTablet ? 100 : 250 }}
          >
            {value}
          </Paragraph>
        ) : (
          "-"
        ),
    },

    {
      title: "Action",
      width: "10%",
      render: (record) => {
        return (
          <Space size="large">
          {
            record?.status === "Report_Delivered" &&(
              <ExportOutlined
              style={{ color: "#45AFFB", fontSize: "18px" }}
              onClick={() => {
                setGalleryModal(true);
                setEditData(record);
              }}
            />
            )
          }
            {record?.is_initial ? (
              <Button size="small">
                <EditOutlined
                  style={{ color: "#7f56d9" }}
                  onClick={() => {
                    setUploadModal(true);
                    setEditData(record);
                  }}
                />
              </Button>
            ) : (
              <Button size="small">
                <EditOutlined
                  style={{ color: "#7f56d9" }}
                  onClick={() => {
                    setOpen(true);
                    setEditData(record);
                  }}
                />
              </Button>
            )}
            {record?.status !== "Report_Delivered" && (
              <Popconfirm
                title="Sure to delete"
                onConfirm={() => deleteInvestigation(record?.id)}
              >
                {" "}
                <Button size="small">
                  <DeleteOutlined style={{ color: "red", cursor: "pointer" }} />
                </Button>
              </Popconfirm>
            ) }
          </Space>
        );
      },
    },
  ];
  return (
    <>
      <Table
        columns={columns}
        dataSource={data}
        pagination={false}
        bordered
        scroll={{ y: "calc(100vh - 200px)", x: 0 }}
      />
      {open && (
        <InvestigationModal
          refreshPage={refreshPage}
          open={open}
          setOpen={setOpen}
          editData={editData}
        />
      )}
      {uploadModal && (
        <InvestigationsUploadModal
          open={uploadModal}
          setOpen={setUploadModal}
          editData={editData}
          refreshPage={refreshPage}
        />
      )}
      {galleryModal && (
        <InvestigationGallery
          open={galleryModal}
          setOpen={setGalleryModal}
          editData={editData}
          id={id}
          patientData={patientData}
          // refreshPage={refreshPage}
        />
      )}
    </>
  );
};

export default InvestigationTable;
