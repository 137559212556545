import React from "react";
import { Col, Form, Input, Select } from "antd";
import inputStyles from "../../../styles/Custom/Input.module.css";
import { payerOptions } from "../receptionKeys";
const PayerDetails = () => {
  return (
    <>
      <Col span={8}>
        <Form.Item label="Payer" name="payer">
          <Select
            allowClear
            placeholder="Payer"
            className={inputStyles.mdSelect}
            style={{ width: "300px" }}
            options={payerOptions}
          />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item
          label="Insurance Corporate Name"
          name="insurance_corporate_name"
        >
          <Input
            placeholder="Insurance Corporate Name"
            className={inputStyles.mdInput}
            style={{ width: "80%" }}
          />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item label="Insurance Corporate ID" name="insurance_corporate_id">
          <Input
            placeholder="Insurance Corporate ID"
            className={inputStyles.mdInput}
            style={{ width: "80%" }}
          />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item label="Notes" name="message">
          <Input.TextArea
            placeholder="Notes"
            className={inputStyles.mdInput}
            style={{ width: "80%" }}
          />
        </Form.Item>
      </Col>
    </>
  );
};

export default PayerDetails;
