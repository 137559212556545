import React, { useContext, useEffect, useState } from "react";
import { Form, Input, Radio } from "antd";
import { DoctorInitialContext } from "../DoctorHistoryFormProvider";
import { yesNoOptions } from "../../../Options/commonOptions";
const PastSurgicalHistory = ({ context }) => {
  const { formData } = useContext(context);
  const [pastSurgicalHistory, setPastSurgicalHistory] = useState(
    formData?.history?.surgical_history === "yes" ? true : false
  );

  useEffect(() => {
    setPastSurgicalHistory(
      formData?.history?.surgical_history === "yes" ? true : false
    );
  }, [formData]);
  const handleSurgicalHistoryChange = (e) => {
    setPastSurgicalHistory(e.target.value === "yes");
  };
  return (
    <>
      <Form.Item
        label="Past Surgical History"
        name="surgical_history"
        onChange={handleSurgicalHistoryChange}
      >
        <Radio.Group options={yesNoOptions} />
      </Form.Item>
      {pastSurgicalHistory && (
        <Form.Item name="surgical_history_details">
          <Input placeholder="Details" style={{ width: "50%" }} />
        </Form.Item>
      )}
    </>
  );
};

export default PastSurgicalHistory;
