import React, { useContext, useState, useEffect } from "react";
import { Form, Radio } from "antd";
import PainSelection from "../../PhysioScales/VAScaleComponents/PainSelection";

const PainScale = ({ context }) => {
  const { formData } = useContext(context);
  const [painSelection, setPainSelection] = useState(
    formData?.general_examination?.pain_scale === "yes"
      ? "yes"
      : formData?.examination?.pain_scale === "yes"
      ? "yes"
      : null
  );
  useEffect(() => {
    setPainSelection(
      formData?.general_examination?.pain_scale === "yes"
        ? "yes"
        : formData?.examination?.pain_scale === "yes"
        ? "yes"
        : null
    );
  }, [formData]);
  const painOptions = [
    { label: "No", value: "no" },
    { label: "Yes", value: "yes" },
  ];
  return (
    <>
      <Form.Item label="Pain Scale" name="pain_scale">
        <Radio.Group
          options={painOptions}
          onChange={(e) => setPainSelection(e.target.value)}
        />
      </Form.Item>
      {painSelection === "yes" && <PainSelection />}
      <br />
    </>
  );
};

export default PainScale;
