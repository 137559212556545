import React, { useEffect, useState } from "react";
import { Card, Button, Row, Col, Tag, Divider, Skeleton } from "antd";
import useQuery from "../../../../hooks/useQuery";
import { viewIpBedApi } from "../../../../services/receptionist.services";
import { CalendarOutlined } from "@ant-design/icons";
import { formatToINR } from "../../../../helpers/utility";
import dayjs from "dayjs";

const ViewBeds = ({ setEditBed, setSwitchBed, bedId, patientData }) => {
  const params = useQuery();
  const [bedsData, setBedsData] = useState([]);
  const admission = patientData?.admission?.is_active;
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    viewIpBedApi(params?.patient)
      .then((res) => {
        setBedsData(res?.data?.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, [params?.patient]);

  const renderCardAction = (record) => {
    const startDate = dayjs(record.created_at);

    const currentTime = dayjs();

    const timeDifference = currentTime.diff(startDate, "minute");

    if (record?.bed?.id === bedId && !record?.end_date) {
      if (timeDifference > 1) {
        return (
          <Button onClick={() => setSwitchBed(true)} type="primary" ghost>
            Switch Bed
          </Button>
        );
      } else {
        return (
          <Button onClick={() => setEditBed(true)} type="primary" ghost>
            Edit Bed
          </Button>
        );
      }
    }
    return null;
  };

  return (
    <Row gutter={[16, 16]}>
      {loading ? (
        <Skeleton active />
      ) : (
        <>
          {" "}
          {bedsData?.map((record) => (
            <Col key={record?.bed?.id} span={24}>
              <Card bordered={false}>
                <Row>
                  <Col span={4}>
                    <div>
                      <strong>Bed No</strong>
                      <div>
                        <Tag color="blue">{record?.bed?.bed_no}</Tag>
                      </div>
                    </div>
                  </Col>
                  <Col span={4}>
                    <div>
                      <strong>Room No</strong>
                      <div>
                        <Tag color="red">{record?.bed?.room_no}</Tag>
                      </div>
                    </div>
                  </Col>
                  <Col span={4}>
                    <div>
                      <strong>Room Type</strong>
                      <div>
                        <Tag color="cyan">{record?.bed?.room_type}</Tag>
                      </div>
                    </div>
                  </Col>
                  <Col span={4}>
                    <div>
                      <strong>Cash Price</strong>
                      <div> {formatToINR(record?.bed?.cash_price)}</div>
                    </div>
                  </Col>
                  <Col span={4}>
                    <div>
                      <strong>Insurance Price</strong>
                      <div>{formatToINR(record?.bed?.insurance_price)}</div>
                    </div>
                  </Col>
                </Row>
                <Divider />
                <Row>
                  <Col span={4}>
                    <strong>Start Date</strong>
                    <div>
                      <Tag icon={<CalendarOutlined />} color="default">
                        {dayjs(record?.start_date).format(
                          "DD MMM, YYYY hh:mm A"
                        )}
                      </Tag>
                    </div>
                  </Col>

                  <Col span={4}>
                    <strong>End Date</strong>
                    <div>
                      <Tag icon={<CalendarOutlined />} color="default">
                        {record?.end_date
                          ? dayjs(record?.end_date).format(
                              "DD MMM, YYYY hh:mm A"
                            )
                          : "------"}
                      </Tag>
                    </div>
                  </Col>

                  {admission && (
                    <Col span={8} style={{ textAlign: "right" }}>
                      {renderCardAction(record)}
                    </Col>
                  )}
                </Row>
              </Card>
            </Col>
          ))}
        </>
      )}
    </Row>
  );
};

export default ViewBeds;
