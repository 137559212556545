import React, { useState, useRef } from "react";
import {
  Upload,
  message,
  Button,
  Modal,
  Select,
  Form,
  AutoComplete,
  Row,
  Col,
  Typography,
} from "antd";
import btnStyles from "../../../styles/Custom/Button.module.css";
import { getUploadUrlApi } from "../../../services/common.services";
import { convertToFormData } from "../../../helpers/utility";
import { uploadPatientImagesApi } from "../../../services/patient.services";
import useQuery from "../../../hooks/useQuery";
import { CameraOutlined, DeleteOutlined } from "@ant-design/icons";
import { getFileIcon } from "../../../helpers/getFileIcon";
import axios from "axios";
import Compressor from "compressorjs";

const { Option } = Select;
const { Dragger } = Upload;

const PatientGalleryUpload = ({
  refresh,
  isModalVisible,
  setIsModalVisible,
  labels,
}) => {
  const [form] = Form.useForm();
  const formRef = useRef(null);
  const [uploadFileLoading, setUploadFileLoading] = useState(false);
  const [uploadFile, setUploadFile] = useState([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const params = useQuery();

  const handleRemoveFile = (fileToRemove) => {
    const filteredFiles = uploadFile.filter(
      (file) => file.path !== fileToRemove.path
    );
    setUploadFile(filteredFiles);
  };

  const uploadProps = {
    beforeUpload: (file) => {
      return new Promise((resolve, reject) => {
        let file_ext = file.name.split(".").slice(-1)[0];
        const validImageTypes = ["png", "jpg", "jpeg", "svg"];
        const validVideoTypes = ["mp4", "mov"];
        const validPdfTypes = ["pdf"];

        const fileType =
          validImageTypes.includes(file_ext) ||
          validVideoTypes.includes(file_ext) ||
          validPdfTypes.includes(file_ext);

        if (!fileType) {
          message.error(`${file.name} is not a valid file type`);
          return reject(Upload.LIST_IGNORE);
        }

        if (validImageTypes.includes(file_ext)) {
          new Compressor(file, {
            convertSize: 1000000,
            quality: 0.8,
            success(compressedFile) {
              resolve(compressedFile);
            },
            error(err) {
              console.error("Compression failed:", err.message);
              reject(err);
            },
          });
        } else {
          resolve(file);
        }
      });
    },
    async onChange(info) {
      if (info.file.status === "done") {
        setUploadFileLoading(true);
        try {
          const res = await getUploadUrlApi(`path_key=${info.file.name}`);
          const formData = convertToFormData(res, info);
          await axios({
            method: "POST",
            url: res?.data?.url,
            data: formData,
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });
          setUploadFile((prevState) => [
            ...prevState,
            { path: res?.data?.fields?.key, type: info.file.type },
          ]);
        } catch (err) {
          message.error(err?.response?.data?.message);
        } finally {
          setUploadFileLoading(false);
        }
      }
    },
  };

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const onFinish = async (val) => {
    const formData = {
      category: Number(selectedCategoryId),
      paths: uploadFile,
      ip_admission: params?.patient,
    };

    uploadPatientImagesApi(formData)
      .then((response) => {
        message.success(response.data.message);
        refresh();
        setIsModalVisible(false);
      })
      .catch((error) => {
        message.error(error.response.data.message);
      });
  };

  const handleLabelSelect = (value, option) => {
    setSelectedCategoryId(option.key);
  };

  return (
    <>
      <Modal
        width={450}
        title="Upload Gallery"
        onCancel={() => setIsModalVisible(false)}
        open={isModalVisible}
        footer={
          <div className="flex-end" style={{ gap: "24px", marginTop: "20px" }}>
            <Form.Item>
              <Button
                type="primary"
                ghost
                style={{ fontWeight: "500" }}
                className={btnStyles.mdBtn}
                onClick={() => setIsModalVisible(false)}
              >
                Cancel
              </Button>
            </Form.Item>
            <Form.Item>
              <Button
                disabled={uploadFileLoading}
                htmlType="submit"
                type="primary"
                style={{ fontWeight: "500" }}
                className={btnStyles.mdBtn}
                onClick={() => {
                  if (formRef.current) {
                    formRef.current.submit();
                  }
                }}
              >
                Save
              </Button>
            </Form.Item>
          </div>
        }
      >
        <Form layout="vertical" form={form} ref={formRef} onFinish={onFinish}>
          <Form.Item
            label="Select Label"
            name="label"
            rules={[
              {
                required: !selectedCategoryId && true,
                message: "Please choose Label",
              },
            ]}
          >
            <AutoComplete
              filterOption={(inputValue, option) =>
                option?.value
                  .toUpperCase()
                  .indexOf(inputValue.toUpperCase()) !== -1
              }
              placeholder="Search Labels..."
              onSelect={handleLabelSelect}
            >
              {labels?.map((label) => (
                <Option key={label?.id} value={label?.name}>
                  <div
                    style={{
                      width: "100%",
                      height: "30px",
                      padding: "4px",
                      borderRadius: "3px",
                      background: label?.color,
                    }}
                  >
                    {label?.name}
                  </div>
                </Option>
              ))}
            </AutoComplete>
          </Form.Item>
          <Form.Item
            label="Upload Gallery"
            name="upload"
            rules={[
              {
                required: uploadFile?.length === 0 ? true : false,
                message: "Please upload files",
              },
            ]}
          >
            <Dragger
              style={{ width: "400px" }}
              accept=".png,.jpg,.jpeg,.svg,.mp4,.mov,.avi,.pdf"
              {...uploadProps}
              multiple
              customRequest={dummyRequest}
              showUploadList={false}
            >
              <p className="ant-upload-drag-icon">
                <CameraOutlined />
              </p>
              <p className="ant-upload-text">
                <span style={{ color: "#406AFF" }}>Click to Capture </span> or
                upload
              </p>
              <p className="ant-upload-hint">
                PDF, SVG, JPEG, JPG, PNG, MP4, MOV, AVI
              </p>
            </Dragger>
            <Row gutter={16} style={{ padding: "5px" }}>
              {uploadFile.map((item, index) => (
                <Col className="inv-upload-file" span={6} key={index}>
                  <span style={{ display: "flex" }}>
                    {getFileIcon(item)}
                    <Button
                      className="inv-upload-del-btn"
                      type="link"
                      icon={<DeleteOutlined />}
                      onClick={() => handleRemoveFile(item)}
                    />
                  </span>
                  <Typography.Text ellipsis>
                    {item.path.replace("curengo/", "")}
                  </Typography.Text>
                </Col>
              ))}
            </Row>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default PatientGalleryUpload;
