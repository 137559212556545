import React, { createContext, useState } from "react";
export const PatientTypeContext = createContext("");
const PatientTypeContextProvider = ({ children }) => {
  const [patientType, setPatientType] = useState(
    new URLSearchParams(document?.location?.search)?.get("patient-type") ||
      "inPatients"
  );
  return (
    <PatientTypeContext.Provider value={{ patientType, setPatientType }}>
      {children}
    </PatientTypeContext.Provider>
  );
};

export default PatientTypeContextProvider;
