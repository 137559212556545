import React, { useRef, useState } from "react";
import {
  Drawer,
  Form,
  Space,
  Input,
  Button,
  Row,
  Col,
  Typography,
  message,
  Checkbox,
  InputNumber,
} from "antd";
import { PlusOutlined, CloseOutlined, DeleteOutlined } from "@ant-design/icons";
import btnStyles from "../../../styles/Custom/Button.module.css";
import inputStyles from "../../../styles/Custom/Input.module.css";
import numStyles from "../../../styles/Custom/Dashboard/Dashboard.module.css";
import checkboxStyles from "../../../styles/Custom/Checkbox.module.css";
import { createMedicationApi } from "../../../services/medication.services";

function MedicationForm({ open, setOpen, refreshPage }) {
  const formRef = useRef(null);
  const [form] = Form.useForm();
  const [btnLoading, setBtnLoading] = useState(false);

  const onFinish = (values) => {
    setBtnLoading(true);
    createMedicationApi(values?.users)
      .then((res) => {
        message.success(res?.data?.message);
        setOpen(false);
        refreshPage();
        setBtnLoading(false);
      })
      .catch((err) => {
        message.error(err?.response?.data?.message);
        console.log(err);
        setBtnLoading(false);
      });
  };
  const onClose = () => {
    setOpen(false);
  };

  return (
    <Drawer
      open={open}
      width={"70%"}
      title={
        <Row>
          <Col span={12} className="flex-start">
            <Typography
              style={{
                marginLeft: "12px",
                fontWeight: "400",
                fontSize: "20px",
              }}
            >
              Add Medicine
            </Typography>
          </Col>
          <Col span={12} className="flex-end">
            <CloseOutlined onClick={onClose} />
          </Col>
        </Row>
      }
      footer={
        <div style={{ textAlign: "right" }}>
          <Form.Item>
            <Button
              loading={btnLoading}
              className={btnStyles.lgBtn}
              type="primary"
              htmlType="submit"
              onClick={() => {
                if (formRef.current) {
                  formRef.current.submit();
                }
              }}
            >
              Submit
            </Button>
          </Form.Item>
        </div>
      }
      onClose={onClose}
      closeIcon={null}
    >
      <Form
        ref={formRef}
        form={form}
        name="dynamic_form_nest_item"
        onFinish={onFinish}
        autoComplete="off"
        layout="vertical"
        // requiredMark={false}
        initialValues={{
          users: [{}],
        }}
      >
        <Form.List name="users">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }) => (
                <Space key={key} style={{ display: "flex", marginBottom: 8 }}>
                  <Form.Item
                    {...restField}
                    name={[name, "name"]}
                    rules={[{ required: true, message: "Enter medicine name" }]}
                  >
                    <Input
                      placeholder="Enter medicine name"
                      className={inputStyles.mdInput}
                      style={{ width: "200px" }}
                    />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, "drug1_name"]}
                    // rules={[{ required: true, message: "Enter drug 1 name" }]}
                  >
                    <Input
                      placeholder="Enter drug 1 name"
                      className={inputStyles.mdInput}
                      style={{ width: "200px" }}
                    />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, "drug2_name"]}
                    // rules={[{ required: true, message: "Enter drug 2 name" }]}
                  >
                    <Input
                      placeholder="Enter drug 2 name"
                      className={inputStyles.mdInput}
                      style={{ width: "200px" }}
                    />
                  </Form.Item>
                  {/* <Form.Item
                    {...restField}
                    name={[name, "description"]}
                    // rules={[{ required: true, message: "Enter description" }]}
                  >
                    <Input.TextArea
                      placeholder="Enter description"
                      className={inputStyles.mdInput}
                      style={{ width: "200px" }}
                      rows={2}
                    />
                  </Form.Item> */}
                  {/* <Form.Item
                    name={[name, "price"]}
                    // rules={[{ required: true, message: "Enter price" }]}
                  >
                    <InputNumber
                      placeholder="Price"
                      addonBefore="Rs"
                      className={`${numStyles.mdNumberInput}`}
                    />
                  </Form.Item> */}
                  <Form.Item
                    name={[name, "is_antiboitic"]}
                    valuePropName="checked"
                    initialValue={false}
                  >
                    <Checkbox className={checkboxStyles.mdCheck}>
                      Antibiotic
                    </Checkbox>
                  </Form.Item>

                  {fields?.length > 1 && (
                    <DeleteOutlined onClick={() => remove(name)} />
                  )}
                </Space>
              ))}

              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add({ name: "", is_antiboitic: false })}
                  block
                  icon={<PlusOutlined />}
                >
                  Medicine
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      </Form>
    </Drawer>
  );
}

export default MedicationForm;
