import React from "react";
import InitialInspection from "../IpEMR/Doctor/InitialInspection/InitialInspection";
import DailyReview from "../IpEMR/Doctor/DailyReview/DailyReview";
import OrderSheet from "../IpEMR/Doctor/OrderSheet/OrderSheet";
import DoctorHistoryFormProvider from "../IpEMR/InspectionForms/HistoryForm/DoctorHistoryFormProvider";
import DailyReviewFormProvider from "../IpEMR/Doctor/DailyReview/DailyReviewFormProvider";
import Landing from "../IpEMR/Doctor/Landing/Landing";
import MedicationHistoryIP from "./MedicationsIp/MedicationHistoryIP";
import Monitoring from "./Monitoring/Monitoring";

const DoctorIP = ({ patientData, params, refreshPage }) => {
  return (
    <>
      {params?.type === "ia" ? (
        <DoctorHistoryFormProvider>
          <InitialInspection
            refreshPage={refreshPage}
            editId={patientData?.doctor_intial_assessment}
            patientData={patientData}
          />
        </DoctorHistoryFormProvider>
      ) : params?.type === "review" ? (
        <DailyReviewFormProvider>
          <DailyReview
            reviewData={patientData?.doctor_daily_review}
            refreshPage={refreshPage}
            iAssessment={patientData?.doctor_intial_assessment}
            patientData={patientData}
          />
        </DailyReviewFormProvider>
      ) : params?.type === "order-sheet" ? (
        <OrderSheet />
      ) : params?.type === "medicine" ? (
        <MedicationHistoryIP patientData={patientData} />
      ) : params?.type === "monitoring" ? (
        <Monitoring />
      ) : (
        params?.type === "landing" && <Landing />
      )}
    </>
  );
};

export default DoctorIP;
