import React from "react";
import { getLabelTagInput, getLabelTagYesNO } from "../constructTextFromValues";
import { Descriptions, Image, Space } from "antd";
import previousCounsellingIcon from "../../../../assets/doctorIcons/previousCounselling.svg";

const PsychologistPreviousCounsellingSummary = ({ formData }) => {
  const constructCounsellingSummaryItems = () => {
    const counsellingSummaryItems = [];
    if (formData?.previous_counselling?.previous_counselling) {
      counsellingSummaryItems.push({
        key: "1",
        label: "Previous Counselling",
        children: (
          <>
            {getLabelTagYesNO(
              formData?.previous_counselling?.previous_counselling
            )}
            {getLabelTagInput(
              "Details",
              formData?.previous_counselling?.description
            )}
          </>
        ),
      });
    }
    return counsellingSummaryItems;
  };
  const counsellingSummaryItems = constructCounsellingSummaryItems();
  return (
    <>
      <Descriptions
        bordered
        column={1}
        title={
          <span style={{ fontWeight: "500", marginLeft: ".2rem" }}>
            <Space>
              <Image src={previousCounsellingIcon} width={18} preview={false} />
              <span style={{ fontSize: "16px", fontWeight: "normal" }}>
                Previous Counselling/ Psychotherapy
              </span>
            </Space>
          </span>
        }
        items={counsellingSummaryItems}
        style={{ margin: "0", border: "none" }}
        className="initial-insp-desc"
      />
    </>
  );
};

export default PsychologistPreviousCounsellingSummary;
