import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Button,
  Table,
  Input,
  TimePicker,
  Form,
  Space,
  message,
} from "antd";
import { timeAgo } from "../../../../../helpers/utility";
import {
  CloseCircleOutlined,
  DeleteOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import dayjs from "dayjs";
import {
  nurseDailyRoutineApi,
  updateDailyRoutineApi,
} from "../../../../../services/nurse.services";
import useQuery from "../../../../../hooks/useQuery";

const format = "hh:mm A";

const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  form,
  selectOptions,
  ...restProps
}) => {
  useEffect(() => {
    if (editable) {
      form.setFieldsValue({
        [dataIndex]:
          dataIndex === "process_time" || dataIndex === "action_time"
            ? record[dataIndex]
              ? dayjs(record[dataIndex])
              : null
            : record[dataIndex],
      });
    }
  }, [editable, record, dataIndex, form]);

  const inputNode =
    dataIndex === "remarks" ? (
      <Input.TextArea />
    ) : (
      (dataIndex === "process_time" || dataIndex === "action_time") && (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[
            {
              required: true,
              message: "Time Done is required!",
            },
          ]}
        >
          <TimePicker format={format} needConfirm={false} />
        </Form.Item>
      )
    );

  return (
    <td {...restProps}>
      {editable ? (
        <Form.Item name={dataIndex} style={{ margin: 0 }}>
          {inputNode}
        </Form.Item>
      ) : dataIndex === "process_time" || dataIndex === "action_time" ? (
        record[dataIndex] ? (
          dayjs(record[dataIndex]).format(format)
        ) : null
      ) : (
        children
      )}
    </td>
  );
};

const DailyRoutineFormTable = ({ nurseData, type, getData }) => {
  const params = useQuery();
  const [form] = Form.useForm();
  const [dataSource, setDataSource] = useState([]);
  const [editingKey, setEditingKey] = useState("");

  useEffect(() => {
    if (nurseData) {
      const updatedData = nurseData?.map((item, index) => ({
        ...item,
        key: index.toString(),
      }));
      setDataSource(updatedData);
    }
  }, [nurseData]);

  const isEditing = (record) => record.key === editingKey;

  const handleAdd = () => {
    const newData = {
      key: (dataSource.length + 1).toString(),
      action_time: new Date().toISOString(),
      scheduleDisplay: timeAgo(new Date()),
      isEditing: true,
    };
    setDataSource([...dataSource, newData]);
    setEditingKey(newData.key);
    form.resetFields();
  };

  const handleDelete = (key) => {
    const newData = dataSource.filter((item) => item.key !== key);
    setDataSource(newData);
  };

  const handleSave = async (record) => {
    try {
      const row = await form.validateFields();
      // Check if process_time is empty
      if (!row.process_time) {
        throw new Error("Time Done is required!");
      }
      const updatedRecord = { ...row, daily_routine_type: type };
      if (record?.id) {
        const res = await updateDailyRoutineApi(record?.id, updatedRecord);
        await getData();
        message.success(res?.data?.message);
        setEditingKey("");
      } else {
        const res = await nurseDailyRoutineApi(params?.patient, updatedRecord);
        await getData();
        message.success(res?.data?.message);
        setEditingKey("");
      }
    } catch (err) {
      message.error(err.message);
      console.error(err?.response?.data?.message?.name[0]);
    }
  };

  const columns = [
    // {
    //   title: "Schedule",
    //   dataIndex: "action_time",
    //   key: "action_time",
    //   editable: true,
    // },

    {
      title: "Time Done",
      dataIndex: "process_time",
      key: "process_time",
      editable: true,
    },
    { title: "Remarks", dataIndex: "remarks", key: "remarks", editable: true },
    {
      title: "Updated By",
      dataIndex: "updated_by",
      key: "updated_by",
    },
    {
      title: "Action",
      render: (_, record) => {
        const editable = isEditing(record);
        return (
          <Space>
            <Button
              onClick={() => {
                if (editable) {
                  handleSave(record);
                } else {
                  setEditingKey(record.key);
                  form.setFieldsValue({
                    ...record,
                    process_time: record.process_time
                      ? dayjs(record.process_time)
                      : null,
                    action_time: record.action_time
                      ? dayjs(record.action_time)
                      : null,
                  });
                }
              }}
            >
              {editable ? "Save" : "Edit"}
            </Button>
            {record?.scheduleDisplay && (
              <Button
                icon={<DeleteOutlined style={{ color: "red" }} />}
                onClick={() => handleDelete(record.key)}
              />
            )}
            {editable && !record?.scheduleDisplay && (
              <CloseCircleOutlined onClick={() => setEditingKey("")} />
            )}
          </Space>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => ({
    ...col,
    onCell: (record) => ({
      record,
      editable: col.editable && isEditing(record),
      dataIndex: col.dataIndex,
      title: col.title,
      selectOptions: col.selectOptions,
      form,
    }),
  }));

  return (
    <Form form={form} component={false}>
      <Row>
        <Col span={24} style={{ textAlign: "right", marginBottom: 10 }}>
          <Button
            className="inv-btn"
            type="primary"
            onClick={handleAdd}
            style={{ fontWeight: 500 }}
          >
            <PlusOutlined /> SOS
          </Button>
        </Col>
        <Col span={24}>
          <Table
            pagination={false}
            columns={mergedColumns}
            dataSource={dataSource}
            components={{
              body: {
                cell: EditableCell,
              },
            }}
          />
        </Col>
      </Row>
    </Form>
  );
};

export default DailyRoutineFormTable;
