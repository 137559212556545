import React from "react";
import {
  getLabelTag,
  getLabelTagInput,
  getLabelTagYesNO,
} from "../constructTextFromValues";
import { builtOptions } from "../../Options/genExamOptions";
import { Descriptions, Image, Space } from "antd";
import SearchIcon from "../../../../assets/images/nurseIcons/search.svg";

const PhysiotherapistGeneralExaminationSummary = ({ formData }) => {
  const constructGeneralExaminationSummaryOptions = () => {
    const generalExaminationSummaryItems = [];
    const examination = formData?.general_examination || {};
    const pushGeneralItem = (key, label, children) => {
      generalExaminationSummaryItems.push({ key, label, children });
    };
    if (examination?.built) {
      pushGeneralItem(
        "1",
        "Built",
        <>{getLabelTag(builtOptions, examination?.built)}</>
      );
    }
    if (
      examination?.temperature ||
      examination?.pulse_rate ||
      examination?.respiratory_rate ||
      examination?.systolic_bp ||
      examination?.diastolic_bp ||
      examination?.spo2
    ) {
      pushGeneralItem(
        "22",
        "Vitals at the Time of Admission",
        <>
          {getLabelTagInput("Temperature", examination?.temperature)}
          {getLabelTagInput("Pulse Rate", examination?.pulse_rate)}
          {getLabelTagInput("Respiratory Rate", examination?.respiratory_rate)}
          {getLabelTagInput("Systolic BP", examination?.systolic_bp)}
          {getLabelTagInput("Diastolic BP", examination?.diastolic_bp)}
          {getLabelTagInput("SPO2", examination?.spo2)}
        </>
      );
    }
    if (
      examination?.gcs?.eye_opening_response ||
      examination?.gcs?.verbal_response ||
      examination?.gcs?.motor_response
    ) {
      pushGeneralItem(
        "6",
        "Glasgow Coma Scale",
        <>
          {getLabelTagInput(
            "Eye Opening Response",
            examination?.gcs?.eye_opening_response
          )}
          {getLabelTagInput(
            "Verbal Response",
            examination?.gcs?.verbal_response
          )}
          {getLabelTagInput("Motor Response", examination?.gcs?.motor_response)}
        </>
      );
    }
    if (examination?.pain_scale) {
      pushGeneralItem(
        "23",
        "Pain Scale",
        <>
          {getLabelTagYesNO(examination?.pain)}
          {getLabelTagInput("Pain level", examination?.pain)}
        </>
      );
    }
    return generalExaminationSummaryItems;
  };
  const generalExaminationSummaryItems =
    constructGeneralExaminationSummaryOptions();
  return (
    <div>
      <Descriptions
        bordered
        column={1}
        title={
          <Space>
            <Image src={SearchIcon} preview={false} />
            <span style={{ fontWeight: 500 }}>General Examination</span>
          </Space>
        }
        items={generalExaminationSummaryItems}
        style={{ margin: "0", border: "none" }}
        className="initial-insp-desc"
      />
    </div>
  );
};

export default PhysiotherapistGeneralExaminationSummary;
