import React, { useContext, useEffect, useState } from "react";
import { Row, Col, Button } from "antd";
import BedCards from "./BedCards";
import BedFilterCard from "./BedFilterCard";
import { getIpBedsListApi } from "../../../../services/receptionist.services";
import { getCookie } from "../../../../helpers/localStorage";
import { FacilityID } from "../../../../constants/defaultKeys";
import { UserContext } from "../../../../context/UserContext";
import ViewBeds from "./ViewBeds";

const BedManagement = ({ patientData, refreshPage }) => {
  const { userDetails } = useContext(UserContext);
  const [buildingId, setBuildingId] = useState(null);
  const [roomId, setRoomId] = useState(null);
  const [beds, setBeds] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [editBed, setEditBed] = useState(false);
  const [switchBed, setSwitchBed] = useState(false);

  const refreshBedData = () => setRefresh((prev) => !prev);

  useEffect(() => {
    if (buildingId || roomId) {
      const params = roomId
        ? `building=${buildingId}&room_type=${roomId}`
        : `building=${buildingId}`;
      getIpBedsListApi(
        Number(getCookie(FacilityID) || userDetails?.currFacility),
        params
      )
        .then((res) => setBeds(res?.data))
        .catch((err) => {
          console.log(err);
        });
    }
  }, [buildingId, roomId, refresh]);

  return (
    <Row gutter={[12, 12]}>
      {!patientData?.admission?.bed?.id || editBed || switchBed ? (
        <>
          <Col span={20}>
            <BedFilterCard
              buildingId={buildingId}
              setBuildingId={setBuildingId}
              roomId={roomId}
              setRoomId={setRoomId}
            />
          </Col>
          {patientData?.admission?.bed?.id && (
            <Col span={4} style={{ textAlign: "right" }}>
              <Button
                type="primary"
                ghost
                onClick={() => {
                  setEditBed(false);
                  setSwitchBed(false);
                }}
              >
                Cancel
              </Button>
            </Col>
          )}

          <Col span={24}>
            <BedCards
              beds={beds?.data}
              patientData={patientData}
              refreshPage={refreshPage}
              refreshBedData={refreshBedData}
              editBed={editBed}
              setEditBed={setEditBed}
              switchBed={switchBed}
              setSwitchBed={setSwitchBed}
            />
          </Col>
        </>
      ) : (
        <Col span={24}>
          <ViewBeds
            editBed={editBed}
            setEditBed={setEditBed}
            switchBed={switchBed}
            setSwitchBed={setSwitchBed}
            patientData={patientData}
            bedId={patientData?.admission?.bed?.id}
          />
        </Col>
      )}
    </Row>
  );
};

export default BedManagement;
