import React, { useContext, useEffect, useState } from "react";
import { Layout, Image, Select, Space, Segmented } from "antd";
import UserSettings from "../CustomLayout/UserSettings";
import { UserContext } from "../../../context/UserContext";
import { getCookie, setCookie } from "../../../helpers/localStorage";
import { useLocation, useNavigate } from "react-router-dom";
import { MenuOutlined } from "@ant-design/icons";
import DocSideMenu from "./DocSidemenu";
import { FacilityID } from "../../../constants/defaultKeys";
import Search from "../CustomLayout/Search";
import { PatientTypeContext } from "./PatientTypeContextProvider";

const { Option } = Select;
const { Header, Footer, Content } = Layout;

const DoctorLayout = ({
  children,
  footer,
  headerExtra = true,
  showPatientSegment = false,
  header,
}) => {
  const location = useLocation();
  const url = new URL(window?.location?.href);
  const isHome = location?.pathname?.endsWith("/home");
  const navigate = useNavigate();
  const { userDetails, setUserDetails } = useContext(UserContext);
  const [menuOpen, setMenuOpen] = useState(false);
  const [selectedFacility, setSelectedFacility] = useState(
    Number(getCookie(FacilityID)) ||
      userDetails?.activeOrg?.facilities?.[0]?.facility_id
  );

  const role = userDetails?.activeOrg?.default_role
    ?.toLowerCase()
    .replaceAll("_", "-");
  const handleFacilityChange = (value) => {
    navigate(`${document?.location.pathname}?${url.searchParams.toString()}`, {
      replace: true,
      // This prevents adding a new entry in the history stack
    });
    setSelectedFacility(value);
    setCookie(
      FacilityID,
      value,
      `.${process.env.REACT_APP_PUBLIC_DOMAIN}`,
      "/"
    );
    setUserDetails((prev) => ({ ...prev, currFacility: value }));
  };
  const { patientType, setPatientType } = useContext(PatientTypeContext);
  const patientTypeOptions = [
    { label: "In Patients", value: "inPatients" },
    { label: "Out Patients", value: "outPatients" },
  ];
  const onPatientTypeChange = (value) => {
    url?.searchParams?.set("patient-type", value);

    url?.searchParams?.set(
      "status",
      value === "inPatients" ? "current" : "today"
    );
    navigate(`${document?.location.pathname}?${url.searchParams.toString()}`, {
      replace: true,
      // This prevents adding a new entry in the history stack
    });
  };

  useEffect(() => {
    const params = new URLSearchParams(document?.location?.search);
    const status = params?.get("patient-type");
    {
      userDetails?.activeOrg?.default_role !== "Nurse" &&
        setPatientType(status);
    }
  }, [url]);

  return (
    <Layout className={"layoutContent"} style={{ minHeight: "100vh" }}>
      {header && (
        <Header className={`headerStyle ${"headerStylesWithoutSide"}`}>
          <div className="flex-between" style={{ height: "64px" }}>
            <div className="tabWebDisplay" style={{ width: "100%" }}>
              <Space>
                {userDetails?.activeOrg?.default_role !== "Nurse" && (
                  <MenuOutlined
                    style={{ fontSize: "18px" }}
                    onClick={() => setMenuOpen(true)}
                  />
                )}
                {userDetails?.activeOrg?.org_logo ? (
                  <Image
                    preview={false}
                    src={userDetails?.activeOrg?.org_logo}
                    alt={"Logo"}
                    style={{
                      width: "30px",
                      marginBottom: "10px",
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      userDetails?.activeOrg?.default_role === "Nurse"
                        ? navigate(`/${role}/home`)
                        : navigate(
                            `/${role}/home?patient-type=inPatients&status=current`
                          )
                    }
                  />
                ) : (
                  userDetails?.activeOrg?.org_name
                )}
                {header}
                {showPatientSegment && isHome && (
                  <Segmented
                    options={patientTypeOptions}
                    value={patientType}
                    onChange={onPatientTypeChange}
                  />
                )}
              </Space>
            </div>
            {headerExtra && (
              <div className="flex-end" style={{ gap: "24px" }}>
                <Search selectedFacility={selectedFacility} />

                <Select
                  defaultValue={selectedFacility}
                  onChange={handleFacilityChange}
                  style={{ width: "200px" }}
                >
                  {userDetails?.activeOrg?.facilities?.map((facility) => (
                    <Option
                      value={facility?.facility_id}
                      key={facility?.facility_id}
                    >
                      {facility?.facility}
                    </Option>
                  ))}
                </Select>

                <UserSettings />
              </div>
            )}
          </div>
        </Header>
      )}

      <Content
        className={header ? "contentStyle" : "contentNoHeaderStyle"}
        style={{
          paddingBottom: footer ? "132px" : "32px",
        }}
      >
        {children}
      </Content>
      {footer && (
        <Footer className={`footerStyle ${"footerStyleWithOutSide"}`}>
          {footer}
        </Footer>
      )}

      {menuOpen && <DocSideMenu open={menuOpen} setOpen={setMenuOpen} />}
    </Layout>
  );
};

export default DoctorLayout;
