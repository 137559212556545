import React, { useState } from "react";
import { Form, Button, Modal, message, Input } from "antd";
import { TwitterPicker } from "react-color";
import { addCategoryApi } from "../../../services/patient.services";

const AddLabelModal = ({
  isModalVisible,
  setIsModalVisible,
  refreshLabelsPage,
}) => {
  const [form] = Form.useForm();
  const [selectedLabel, setSelectedLabel] = useState("#fff");
  const [loading, setLoading] = useState(false);

  const onFinish = (values) => {
    setLoading(true);
    const payload = {
      name: values?.name,
      color: values?.color?.hex || "#ffffff",
    };
    addCategoryApi(payload)
      ?.then((res) => {
        setIsModalVisible(false);
        message.success(res?.data?.message);
        refreshLabelsPage();
      })
      .catch((err) => message.error(err?.response?.data?.message));

    setLoading(false);
  };
  return (
    <div>
      <Modal
        width={350}
        footer={null}
        title="Labels"
        onCancel={() => setIsModalVisible(false)}
        open={isModalVisible}
      >
        <Form
          name="label"
          requiredMark={false}
          onFinish={onFinish}
          layout="vertical"
          form={form}
        >
          <Form.Item
            label="Name"
            name="name"
            rules={[
              {
                validator: (_, value, callback) => {
                  if (value && value !== "") {
                    let trimVal = value.trim();
                    if (trimVal !== "") {
                      if (trimVal.length < 3) {
                        callback("Label should be at least 3 characters long");
                      } else if (trimVal.length > 20) {
                        callback("Label cannot exceed 20 characters");
                      } else {
                        callback();
                      }
                    } else {
                      callback("Label cannot be empty or contain only spaces");
                    }
                  } else {
                    callback("Please enter your label name");
                  }
                },
              },
            ]}
          >
            <Input placeholder="Enter Label Name" />
          </Form.Item>
          <Form.Item
            label="Choose Color"
            name="color"
            rules={[
              {
                required: true,
                message: "Choose your label color !",
              },
            ]}
          >
            <TwitterPicker
              className="label-color-picker"
              color={selectedLabel}
            />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={loading}>
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default AddLabelModal;
