import React, { useState, useRef, useEffect, useContext } from "react";
import { Typography, Row, Col, Form, message, Tag, Space } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import PatientOpSearch from "../PatientOpSearch";
import OpBillingFormField from "./OpBillingFormField";
import SummaryBillCards from "./SummaryBillCards";
import useQuery from "../../../hooks/useQuery";
import {
  addBillingApi,
  addPaymentApi,
  getBillDetailsApi,
  getPatientDetailsApi,
} from "../../../services/receptionist.services";
import typographyStyles from "../../../styles/Custom/Typography.module.css";
import { getCookie } from "../../../helpers/localStorage";
import { FacilityID } from "../../../constants/defaultKeys";
import { UserContext } from "../../../context/UserContext";
import { printBill } from "../receptionKeys";

const BillingPage = () => {
  const navigate = useNavigate();
  const { userDetails } = useContext(UserContext);
  const params = useQuery();
  const [form] = Form.useForm();
  const formRef = useRef(null);
  const [searchedText, setSearchedText] = useState("");
  const [selectedId, setSelectedId] = useState(null);
  const [totalPrice, setTotalPrice] = useState(0);
  const [formValues, setFormValues] = useState(null);
  const [finalDiscountedPrice, setFinalDiscountedPrice] = useState(totalPrice);
  const [partialPayment, setPartialPayment] = useState(false);
  const [partialPaymentAmount, setPartialPaymentAmount] = useState(0);
  const [paymentData, setPaymentData] = useState(null);
  const [patientData, setPatientData] = useState(null);
  const [printButton, setPrintButton] = useState(false);
  const [isSaving, setIsSaving] = useState(false); // Loading for Save button
  const [isPrinting, setIsPrinting] = useState(false); // Loading for Print Bill button
  const [amtPayable, setAmtPayable] = useState(paymentData?.due_amount);
  const [doubleDiscount, setDoubleDiscount] = useState(false);

  useEffect(() => {
    if (!params?.id) {
      if (!partialPayment) {
        form.setFieldsValue({
          payment: {
            amount: finalDiscountedPrice < 0 ? 0 : finalDiscountedPrice,
          },
        });
        setPartialPaymentAmount(finalDiscountedPrice);
      }
    }
  }, [finalDiscountedPrice, partialPayment]);

  useEffect(() => {
    if (selectedId || Number(params?.patient_id)) {
      getPatientDetailsApi(selectedId || Number(params?.patient_id))
        .then((res) => {
          form?.setFieldsValue({
            ...res?.data,

            mobile: res?.data?.mobile,
          });
          setPatientData(res?.data);

          // setFacilityId(res?.data?.facility);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [selectedId || params?.patient_id]);

  useEffect(() => {
    if (params?.id) {
      getBillDetailsApi(params?.id)
        .then((res) => {
          form?.setFieldsValue({
            ...res?.data?.data,
            payment: { amount: res?.data?.data?.due_amount?.toFixed(2) },
            total_discount: res?.data?.data?.total_discount?.toFixed(2),
          });
          setPaymentData(res?.data?.data);
          setSelectedId(res?.data?.data?.op_admission);
          setTotalPrice(Number(res?.data?.data?.gross_amount));
          setFinalDiscountedPrice(
            res?.data?.data?.gross_amount - res?.data?.data?.total_discount
          );
          setAmtPayable(res?.data?.data?.due_amount);
          setPartialPaymentAmount(res?.data?.data?.due_amount);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [params?.id]);

  const onSubmit = (values) => {
    const submitAmount = partialPayment
      ? values?.payment?.amount || finalDiscountedPrice
      : finalDiscountedPrice;

    const formData = params?.id
      ? {
          amount: partialPayment
            ? Number(partialPaymentAmount)
            : values?.is_total_percent
            ? paymentData?.due_amount -
                Number((values?.discount * totalPrice) / 100) ||
              Number(partialPaymentAmount)
            : paymentData?.due_amount - values?.discount ||
              Number(partialPaymentAmount),
          discount: values?.is_total_percent
            ? Number((values?.discount * totalPrice) / 100)
            : values?.discount || 0,
          mode: values?.payment?.mode,
        }
      : {
          gross_amount: totalPrice,
          total_amount: Number(finalDiscountedPrice),
          total_discount: values?.is_percent
            ? Number((values?.total_discount * totalPrice) / 100)
            : Number(values?.total_discount) || 0,
          service_packages: values?.service_packages?.map((item) => ({
            is_percent: item?.is_percent,
            type: item?.type,
            price: item?.price,
            package: item?.package,
            investigation: item?.investigation,
            service: item?.service,
            discount: item?.is_percent
              ? Number((item?.discount * item?.price) / 100)
              : item?.discount || 0,
          })),

          payment: {
            ...values?.payment,
            amount: submitAmount,
            discount: 0,
          },
          facility: Number(getCookie(FacilityID) || userDetails?.currFacility),
        };

    if (printButton) {
      setIsPrinting(true);
    } else {
      setIsSaving(true);
    }

    const apiCall = params?.id
      ? addPaymentApi(params?.id, formData) // Call Payment API
      : addBillingApi(selectedId || params?.patient_id, formData); // Call Billing API

    apiCall
      .then((res) => {
        message.success(res.data.message);
        // After save is successful, check if print button was clicked
        if (printButton) {
          if (params?.id) {
            printBill(
              res?.data?.data?.bill,
              res?.data?.data?.id,
              partialPayment,
              navigate
            );
          }
          // For billing API response
          else {
            printBill(
              res?.data?.data?.id,
              res?.data?.data?.payments?.[0]?.id,
              partialPayment,
              navigate
            );
          }
        } else {
          navigate("/receptionist/billing");
        }
      })
      .catch((err) => {
        message.error(err.response.data.message);
      })
      .finally(() => {
        // Stop loading for both buttons
        setIsSaving(false);
        setIsPrinting(false);
      });
  };

  const calculateTotalPrice = (values) => {
    let total = 0;

    // Ensure we are recalculating from scratch
    if (!params?.id) {
      if (values?.service_packages && values?.service_packages.length > 0) {
        values?.service_packages?.forEach((service) => {
          const price = service?.price || 0;
          const discount = service?.discount || 0;

          total += service?.is_percent
            ? price - (discount * price) / 100
            : price - discount;
        });
      } else {
        // Set total to 0 when there are no services
        total = 0;
      }

      total = parseFloat(total.toFixed(2));

      setTotalPrice(total);

      if (values?.total_discount) {
        const totalPrice = values?.is_percent
          ? total - (total * values?.total_discount) / 100
          : total - values?.total_discount;

        setFinalDiscountedPrice(parseFloat(totalPrice.toFixed(2)));

        if (!partialPayment) {
          form.setFieldsValue({
            payment: {
              amount: totalPrice < 0 ? 0 : parseFloat(totalPrice.toFixed(2)),
            },
          });
        }
      } else {
        setFinalDiscountedPrice(parseFloat(total.toFixed(2)));
        if (!partialPayment) {
          form.setFieldsValue({
            payment: { amount: total },
          });
        }
      }
    } else {
      setAmtPayable(
        values?.is_total_percent
          ? paymentData?.due_amount -
              Number(((values?.discount * totalPrice) / 100).toFixed(2)) ||
              paymentData?.due_amount
          : paymentData?.due_amount - values?.discount ||
              paymentData?.due_amount
      );
    }

    return total;
  };

  const onValuesChange = (changedValues, allValues) => {
    const totalPrice = calculateTotalPrice({ ...changedValues, ...allValues });
    setFormValues({ ...changedValues, ...allValues });
    form.setFieldsValue({ total_amount: totalPrice });

    if (changedValues?.payment?.amount && partialPayment) {
      setPartialPaymentAmount(changedValues?.payment?.amount);
    }
  };

  return (
    <>
      <Form
        disabled={params?.view}
        layout="vertical"
        form={form}
        ref={formRef}
        initialValues={{
          service_packages: [{ is_percent: false }],
          is_total_percent: false,
          is_percent: false,
        }}
        onFinish={onSubmit}
        onValuesChange={onValuesChange}
      >
        <Row
          gutter={[12, 4]}
          style={{
            position: "fixed", // Fixes the Row at the top
            top: "0", // Aligns it to the top
            width: "100%", // Ensures it spans the full width
            zIndex: "1000", // Keeps it above other elements
            backgroundColor: "white",
            padding: "6px 16px 2px",
            boxShadow: "0 2px 4px -4px #222",
          }}
        >
          <Col xs={8} sm={6} md={6} lg={4} xl={4}>
            <Typography className={typographyStyles.subtext1}>
              <LeftOutlined onClick={() => navigate(-1)} /> Create Bill
            </Typography>
          </Col>

          <Col xs={16} sm={14} md={12} lg={12} xl={12}>
            <Space>
              <PatientOpSearch
                searchedText={searchedText}
                setSearchedText={setSearchedText}
                selectedId={selectedId}
                setSelectedId={setSelectedId}
                form={form}
                id={params?.id || params?.patient_id}
              />

              {patientData && (
                <>
                  <Typography
                    style={{
                      color: "#101828",
                      fontSize: "16px",
                      fontWeight: 600,
                      marginLeft: "20px",
                    }}
                  >
                    {patientData?.first_name} {patientData?.last_name}{" "}
                  </Typography>
                  <Tag>
                    {patientData?.age} {patientData?.gender}
                  </Tag>
                </>
              )}
            </Space>
          </Col>
        </Row>

        <Row gutter={[12, 4]} style={{ padding: "72px 24px 24px" }}>
          <Col span={16}>
            <OpBillingFormField
              form={form}
              totalPrice={totalPrice}
              setTotalPrice={setTotalPrice}
              finalDiscountedPrice={finalDiscountedPrice}
              setFinalDiscountedPrice={setFinalDiscountedPrice}
              id={params?.id}
              paymentData={paymentData}
            />
          </Col>
          <Col span={8}>
            <SummaryBillCards
              form={form}
              totalPrice={totalPrice}
              setTotalPrice={setTotalPrice}
              finalDiscountedPrice={finalDiscountedPrice}
              partialPayment={partialPayment}
              setPartialPayment={setPartialPayment}
              id={params?.id}
              isSaving={isSaving}
              isPrinting={isPrinting}
              paymentData={paymentData}
              partialPaymentAmount={partialPaymentAmount}
              setPrintButton={setPrintButton}
              amtPayable={amtPayable}
              doubleDiscount={doubleDiscount}
              setDoubleDiscount={setDoubleDiscount}
            />
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default BillingPage;
