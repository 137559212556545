import { Descriptions, Image, Space } from "antd";
import React from "react";
import { getLabelTag } from "../constructTextFromValues";
import {
  hourlyOptions,
  onceTwiceOptions,
  twiceThriceOptions,
} from "../../Options/physioOptions";
import treatmentPlanIcon from "../../../../assets/doctorIcons/treatmentPlans.svg";

const PhysioTreatmentPlanSummary = ({ formData }) => {
  const constructTreatmentSummaryItems = () => {
    const treatmentSummaryItems = [];
    const plan = formData?.treatment_plan;
    const pushPlanItem = (key, label, children) => {
      treatmentSummaryItems.push({ key, label, children });
    };
    if (plan?.ambulation_schedule) {
      pushPlanItem(
        "1",
        "Ambulation Schedule",
        getLabelTag(twiceThriceOptions, plan?.ambulation_schedule)
      );
    }
    if (plan?.recreation_schedule) {
      pushPlanItem(
        "2",
        "Recreation Schedule",
        getLabelTag(onceTwiceOptions, plan?.recreation_schedule)
      );
    }
    if (plan?.position_change_schedule) {
      pushPlanItem(
        "3",
        "Position Change Schedule",
        getLabelTag(hourlyOptions, plan?.position_change_schedule)
      );
    }
    if (plan?.physio_schedule) {
      pushPlanItem(
        "4",
        "Physiotherapy Schedule",
        getLabelTag(onceTwiceOptions, plan?.physio_schedule)
      );
    }
    return treatmentSummaryItems;
  };
  const treatmentSummaryItems = constructTreatmentSummaryItems();
  return (
    <div>
      <Descriptions
        bordered
        column={1}
        title={
          <span style={{ fontWeight: "500", marginLeft: ".2rem" }}>
            <Space>
              <Image src={treatmentPlanIcon} width={"18px"} preview={false} />
              <span>Treatment Plan</span>
            </Space>
          </span>
        }
        items={treatmentSummaryItems}
        style={{ margin: "0", border: "none" }}
        className="initial-insp-desc"
      />
    </div>
  );
};

export default PhysioTreatmentPlanSummary;
