import ProjectRoutes from "./routes/Routes";
import * as Sentry from "@sentry/react";
import "./App.css";

function App() {
  function FallbackComponent() {
    return <div>An error has occurred</div>;
  }

  return (
    <Sentry.ErrorBoundary fallback={<FallbackComponent />}>
      <ProjectRoutes />
    </Sentry.ErrorBoundary>
  );
}

export default App;
