import React, { useState } from "react";
import { Button, Col, Descriptions, Form, Radio, Row } from "antd";

import CareItemForm from "./CareItemForm";
import { gradeOptions, grbsOptions } from "../../Options/careItemsOptions";
import { PlusOutlined } from "@ant-design/icons";

const formItems = (
  <Form.Item name="grbs_monitoring" label="GRBS Monitoring"  rules={[{ required: true, message: "Please select an option" }]}>
  <Radio.Group options={grbsOptions} />
</Form.Item>
);

const items = (item) => [
  {
    key: "grbs_monitoring",
    label: "GRBS Monitoring",
    children:
      grbsOptions?.find((option) => option.value === item?.grbs_monitoring)
        ?.label || "-",
  },
];

function GRBS({ formData, getData }) {
  console.log(grbsOptions)
  const [formState, setFormState] = useState({
    editingIndex: null,
    adding: false,
  });

  return (
    <Row gutter={[12, 12]}>
       {(formData === undefined || formData?.isallstopstate) && (
        <>
          {formData !== undefined && !formState?.adding ? (
            <Col span={24} style={{ textAlign: "right" ,padding:"0 10%"}}>
              <Button
                 type="primary"
                 ghost
                onClick={() =>
                  setFormState((prev) => ({ ...prev, adding: true }))
                }
              ><PlusOutlined />
                Add Another
              </Button>
            </Col>
          ) : (
            <CareItemForm
              getData={getData}
              formItems={formItems}
              formState={formState}
              formType="grbs_monitoring"
              setFormState={setFormState}
            />
          )}
        </>
      )}
      {formData?.data?.slice().reverse().map((item, index) => (
        <CareItemForm
          key={index}
          formData={item}
          getData={getData}
          formState={formState}
          formType="grbs_monitoring"
          setFormState={setFormState}
          formItems={
            item === undefined || formState?.editingIndex === item?.id ? (
              formItems
            ) : (
              <Descriptions
                bordered
                column={1}
                items={items(item?.data)}
                className="descriptions-custom-column-width"
              />
            )
          }
        />
      ))}
     
    </Row>
  );
}

export default GRBS;
