import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../../context/UserContext";
import useQuery from "../../../../hooks/useQuery";
import { getPatientDietItemsApi } from "../../../../services/nutritionist.services";
import DietView from "./DietView";
import NurtritionistDietChart from "./NurtritionistDietChart";
import NoDataPage from "../../../NoDataPages/NoDataPage";
import dietImage from "../../../../assets/images/nurseIcons/diet.svg";
import LoadingIndicator from "../../../Loaders/LoadingIndicator";
import { Col, Row } from "antd";
const NutritionistDiet = () => {
  const { userDetails } = useContext(UserContext);
  const params = useQuery();
  const [dietData, setDietData] = useState();
  const [refresh, setRefresh] = useState(false);
const [loading,setLoading] = useState(true)
  const role = userDetails?.activeOrg?.default_role
    .toLowerCase()
    ?.replaceAll("_", "-");

  const refreshDietData = () => {
    setRefresh(!refresh);
  };

  useEffect(() => {
    getPatientDietItemsApi(params?.patient)
      .then((res) => {
        setDietData(res?.data);
        setLoading(false)
      })
      .catch((err) => {
        console.log(err);
        setLoading(false)
      });
  }, [refresh]);

  return (
    <>
    {loading ? (
      <LoadingIndicator message={"Loading diet data, please wait..."} />
    ) : (
      <>
        {dietData && Object.keys(dietData).length !== 0 ? (
          <DietView dietData={dietData} refresh={refreshDietData} />
        ) : role === "nutritionist" ? (
          <NurtritionistDietChart refresh={refreshDietData} />
        ) : (
          <Row
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            minHeight: "80vh",
          }}
        >
          <Col span={24} style={{ textAlign: "center" }}>
          <NoDataPage
            title="No Diet Found"
            subtitle="It looks like there are no diets at the moment."
            image={dietImage}
          />
          </Col>
          </Row>
        )}
      </>
    )}
  </>
);
};

export default NutritionistDiet;
