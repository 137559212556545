import React, { useContext } from "react";
import { psychologistTherapyOptions } from "../../Options/psychologistOptions";
import { Descriptions, Image, Space } from "antd";
import { getLabelTagsWithDescription } from "../constructTextFromValues";
import therapiesSuggestedIcon from "../../../../assets/doctorIcons/therapiesSuggested.svg";

const PsychologistTherapySummary = ({ context }) => {
  const { formData } = useContext(context);
  const constructTherapySummaryItems = () => {
    const therapySummaryItems = [];
    if (formData?.therapies_suggested) {
      therapySummaryItems.push({
        key: "1",
        label: "Therapy",
        children: (
          <>
            {getLabelTagsWithDescription(
              psychologistTherapyOptions,
              formData?.therapies_suggested
            )}
          </>
        ),
      });
    }
    return therapySummaryItems;
  };
  const therapySummaryItems = constructTherapySummaryItems();
  return (
    <>
      <Descriptions
        bordered
        column={1}
        title={
          <span style={{ fontWeight: "500", marginLeft: ".2rem" }}>
            <Space>
              <Image src={therapiesSuggestedIcon} width={18} preview={false} />
              <span style={{ fontSize: "16px", fontWeight: "normal" }}>
                Therapies Suggested
              </span>
            </Space>
          </span>
        }
        items={therapySummaryItems}
        style={{ margin: "0", border: "none" }}
        className="initial-insp-desc"
      />
    </>
  );
};

export default PsychologistTherapySummary;
