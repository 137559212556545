import { Descriptions, Image, Space } from "antd";
import React, { useContext } from "react";
import { getLabelTagsWithDescription } from "../constructTextFromValues";
import { psychologistDiagnosisOptions } from "../../Options/psychologistOptions";
import psychologyDiagnosisIcon from "../../../../assets/doctorIcons/psychologyDiagnosis.svg";

const PsychologyDiagnosisSummary = ({ context }) => {
  const { formData } = useContext(context);
  const constructDiagnosisSummaryItems = () => {
    const diagnosisSummaryItems = [];
    if (formData?.psychology_diagnosis) {
      diagnosisSummaryItems.push({
        key: "1",
        label: "Psychology Diagnosis",
        children: (
          <>
            {getLabelTagsWithDescription(
              psychologistDiagnosisOptions,
              formData?.psychology_diagnosis
            )}
          </>
        ),
      });
    }
    return diagnosisSummaryItems;
  };
  const diagnosisSummaryItems = constructDiagnosisSummaryItems();
  return (
    <>
      <Descriptions
        bordered
        column={1}
        title={
          <span style={{ fontWeight: "500", marginLeft: ".2rem" }}>
            <Space>
              <Image src={psychologyDiagnosisIcon} width={18} preview={false} />
              <span style={{ fontSize: "16px", fontWeight: "normal" }}>
                Psychology Diagnosis
              </span>
            </Space>
          </span>
        }
        items={diagnosisSummaryItems}
        style={{ margin: "0", border: "none" }}
        className="initial-insp-desc"
      />
    </>
  );
};

export default PsychologyDiagnosisSummary;
