import { Col, Collapse, Row } from "antd";
import React, { useContext, useEffect } from "react";
import SkinForm from "./SkinForm/SkinForm";
import CardioForm from "./CardioForm/CardioForm";
import RespiratoryForm from "./RespiratoryForm/RespiratoryForm";
import MusculoSkeletalForm from "./MusculoSkeletalForm/MusculoSkeletalForm";
import CNSForm from "./CNSForm/CNSForm";
import { PhysioInitialContext } from "../../../Physiotherapist/PhysiotherapistInitialInspection/PhysiotherapistInitialAssessmentProvider";
const { Panel } = Collapse;

const PhysioSystemicExaminationForm = ({ form }) => {
  const { formData } = useContext(PhysioInitialContext);
  useEffect(() => {
    form.setFieldsValue(formData?.systemic_examination);
  }, [formData]);
  return (
    <Row gutter={[16, 16]}>
      <Col span={12}>
        <Collapse defaultActiveKey={["1"]}>
          <Panel header="Skin" key="1">
            <SkinForm context={PhysioInitialContext} />
          </Panel>
        </Collapse>
        <Collapse defaultActiveKey={["3"]}>
          <Panel header="Respiratory System" key="3">
            <RespiratoryForm context={PhysioInitialContext} />
          </Panel>
        </Collapse>
        <Collapse defaultActiveKey={["5"]}>
          <Panel header="Central Nervous System" key="5">
            <CNSForm context={PhysioInitialContext} />
          </Panel>
        </Collapse>
      </Col>
      <Col span={12}>
        <Collapse defaultActiveKey={["2"]}>
          <Panel header="Cardio Vascular System" key="2">
            <CardioForm context={PhysioInitialContext} />
          </Panel>
        </Collapse>
        <Collapse defaultActiveKey={["4"]}>
          <Panel header="Musculo Skeletal System" key="4">
            <MusculoSkeletalForm context={PhysioInitialContext} />
          </Panel>
        </Collapse>
      </Col>
    </Row>
  );
};

export default PhysioSystemicExaminationForm;
