import { Fragment, forwardRef, useState } from "react";
import { Button, Col, Descriptions, Form, InputNumber, Radio, Row } from "antd";

import CareItemForm from "../CareItemForm";
import { respiratorySupportOptions } from "../../../Options/careItemsOptions";
import { PlusOutlined } from "@ant-design/icons";

function RespiratorySupport(props, ref) {
  const {
    getData,
    formData,
    iaAction,
    formState,
    isIaCareItem,
    setFormState,
    updateIACareItemRecord,
  } = props;
  const [supportType, setSupportType] = useState("");

  const handleSupportTypeChange = (value) => {
    setSupportType(value);
  };

  const getDynamicLabel = (type) => {
    if (type === "cpap_support") return "PS above PEEP";
    return "TV"; // Default label
  };

  const formItems = (
    <Fragment>
      <Form.Item
        name="respiratory_support_type"
        rules={[
          { required: true, message: "Please select a respiratory support" },
        ]}
        label="Respiratory Support"
      >
        <Radio.Group
          options={respiratorySupportOptions.respiratorySupport}
          onChange={(e) => handleSupportTypeChange(e.target.value)}
        />
      </Form.Item>

      <>
        {[
          "oxygen_nasal_prongs",
          "thermo_ventilator",
          "oxygen_hudson_mask",
          "oxygen_hi_flow_mask",
          "oxygen_rebreathing_mask",
        ].includes(supportType) && (
          <Form.Item
            name={`respiratory_support_type_${supportType}`}
            rules={[{ required: true, message: "Please enter a value" }]}
          >
            <InputNumber addonAfter="Lts/Min" />
          </Form.Item>
        )}

        {supportType === "bipap_support" && (
          <>
            <Form.Item
              label="EPAP"
              name="bipap_support_epap"
              rules={[{ required: true, message: "Please enter a value" }]}
            >
              <InputNumber addonAfter="Cm of H2O" />
            </Form.Item>
            <Form.Item
              label="IPAP"
              name="bipap_support_ipap"
              rules={[{ required: true, message: "Please enter a value" }]}
            >
              <InputNumber addonAfter="Cm of H2O" />
            </Form.Item>
            <Form.Item
              label="RR"
              name="bipap_support_rr"
              rules={[{ required: true, message: "Please enter a value" }]}
            >
              <InputNumber addonAfter="/min" />
            </Form.Item>
            <Form.Item
              label="FiO2"
              name="bipap_support_fio2"
              rules={[{ required: true, message: "Please enter a value" }]}
            >
              <InputNumber addonAfter="%" />
            </Form.Item>
          </>
        )}
        {supportType === "mechanical_ventilation" && (
          <Form.Item
            label="Mode"
            rules={[{ required: true, message: "Please select a mode" }]}
            name={`${supportType}_mode`}
          >
            <Radio.Group
              options={respiratorySupportOptions.mechanicalVentilation}
            />
          </Form.Item>
        )}
        {/* Add similar blocks for "cpap_support" and "mechanical_ventilation" */}
        {["cpap_support", "mechanical_ventilation"].includes(supportType) && (
          <>
            <Form.Item
              label="PEEP"
              name={`${supportType}_peep`}
              rules={[{ required: true, message: "Please enter a value" }]}
            >
              <InputNumber addonAfter="Cm of H2O" />
            </Form.Item>
            <Form.Item
              label={getDynamicLabel(supportType)}
              name={`${supportType}_ps_peep`} /**Add the label to the name */
              rules={[{ required: true, message: "Please enter a value" }]}
            >
              <InputNumber addonAfter="Cm of H2O" />
            </Form.Item>
            <Form.Item
              label="RR"
              name={`${supportType}_rr`}
              rules={[{ required: true, message: "Please enter a value" }]}
            >
              <InputNumber addonAfter="/min" />
            </Form.Item>
            <Form.Item
              label="FiO2"
              name={`${supportType}_fio2`}
              rules={[{ required: true, message: "Please enter a value" }]}
            >
              <InputNumber addonAfter="%" />
            </Form.Item>
          </>
        )}
      </>
    </Fragment>
  );

  const respiratoryCareItems = (item) => [
    {
      key: "respiratory_support_type",
      label: "Respiratory Support",
      children:
        respiratorySupportOptions.respiratorySupport?.find(
          (option) => option.value === item?.respiratory_support_type
        )?.label || "-",
    },
  ];

  return (
    <Row gutter={[12, 12]}>
      {(!formData || formData?.isallstopstate) && (
        <>
          {formData !== undefined && !formState?.adding ? (
            <Col
              span={24}
              style={{
                textAlign: "right",
                marginTop: "-10px",
                padding: "0 10%",
              }}
            >
              <Button
                type="primary"
                className="add-another-btn"
                onClick={() =>
                  setFormState((prev) => ({
                    ...prev,
                    adding: "respiratory_care_respiratory_support",
                  }))
                }
              >
                <PlusOutlined /> Add Another
              </Button>
            </Col>
          ) : (
            (formState?.adding === "respiratory_care_respiratory_support" ||
              !formData) && (
              <CareItemForm
                getData={getData}
                formItems={formItems}
                formState={formState}
                setFormState={setFormState}
                formType="respiratory_care_respiratory_support"
              />
            )
          )}
        </>
      )}
      {formData?.data?.map((item, index) => (
        <CareItemForm
          ref={ref}
          key={index}
          formData={item}
          getData={getData}
          iaAction={iaAction}
          formState={formState}
          isIaCareItem={isIaCareItem}
          setFormState={setFormState}
          formType="respiratory_care_respiratory_support"
          updateIACareItemRecord={updateIACareItemRecord}
          formItems={
            isIaCareItem ||
            item === undefined ||
            formState?.editingIndex === item?.id ? (
              formItems
            ) : (
              <Descriptions
                className="descriptions-custom-column-width"
                bordered
                column={1}
                items={respiratoryCareItems(item?.data)}
              />
            )
          }
        />
      ))}
    </Row>
  );
}

export default forwardRef(RespiratorySupport);
