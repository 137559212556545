import React, { useContext, useState } from "react";
import { Form, Input, Col, Select, DatePicker } from "antd";
import inputStyles from "../../../styles/Custom/Input.module.css";
import { contactRelationOptions } from "../receptionKeys";
import dayjs from "dayjs";
import PractitionerSearch from "../Appointments/PractitionerSearch";
import { UserContext } from "../../../context/UserContext";
import { getCookie } from "../../../helpers/localStorage";
import { FacilityID } from "../../../constants/defaultKeys";

const MoreDetails = ({ selectedPractitioner, setSelectedPractitioner }) => {
  const { userDetails } = useContext(UserContext);
  const [practitionerText, setPractitionerText] = useState("");

  const facilityId = Number(getCookie(FacilityID)) || userDetails?.currFacility;

  return (
    <>
      {/* <Col span={8}>
        <Form.Item
          label="IP Admission Date"
          name="admission_date"
          rules={[
            { required: true, message: "Please select an admission date" },
          ]}
        >
          <DatePicker
            defaultValue={dayjs()}
            className={inputStyles?.mdDateInput}
            style={{ width: "300px" }}
            allowClear={false}
          />
        </Form.Item>
      </Col> */}
      <Col span={8}>
        <Form.Item label="Emergency Contact Name" name="emergency_contact_name">
          <Input className={inputStyles.mdInput} style={{ width: "80%" }} />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item
          label="Emergency Contact No"
          name="emergency_contact_no"
          rules={[
            { required: true, message: "Please enter mobile number" },
            {
              pattern: /^[0-9]{10}$/,
              message: "Please enter a valid 10-digit phone number",
            },
          ]}
        >
          <Input
            className={inputStyles.mdAddonInput}
            style={{ width: "80%" }}
            maxLength="10"
            placeholder="Mobile Number"
            addonBefore={"+91"}
          />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item
          label="Emergency Contact Relation"
          name="emergency_contact_relation"
        >
          <Select
            options={contactRelationOptions}
            style={{ width: "300px" }}
            className={inputStyles.mdSelect}
          />
        </Form.Item>
      </Col>
      <Col span={8}>
        <PractitionerSearch
          type="Consultant Name"
          selectedPractitioner={selectedPractitioner}
          setSelectedPractitioner={setSelectedPractitioner}
          practitionerText={practitionerText}
          setPractitionerText={setPractitionerText}
          facilityId={facilityId}
        />
      </Col>
      <Col span={8}>
        <Form.Item label="Primary Hospital Name" name="primary_hospital_name">
          <Input className={inputStyles.mdInput} style={{ width: "80%" }} />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item
          label="Primary Consultant Name"
          name="primary_consultant_name"
        >
          <Input
            placeholder="Primary Consultant Name"
            className={inputStyles.mdInput}
            style={{ width: "80%" }}
          />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item
          label="Primary Consultant Mobile"
          name="primary_consultant_contact"
          rules={[
            {
              pattern: /^[0-9]{10}$/,
              message: "Please enter a valid 10-digit phone number",
            },
          ]}
        >
          <Input
            className={inputStyles.mdAddonInput}
            style={{ width: "80%" }}
            maxLength="10"
            placeholder="Mobile Number"
            addonBefore={"+91"}
          />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item
          label="Primary Hospital Address"
          name="primary_hospital_address"
        >
          <Input.TextArea
            placeholder="Primary Hospital Address"
            className={inputStyles.mdInput}
            style={{ width: "80%" }}
          />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item
          label="Primary Consultant Designation"
          name="primary_consultant_designation"
        >
          <Input
            placeholder="Designation"
            className={inputStyles.mdInput}
            style={{ width: "80%" }}
          />
        </Form.Item>
      </Col>
    </>
  );
};

export default MoreDetails;
