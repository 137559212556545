export const customLightTheme = {
  token: {
    colorPrimary: "#7F56D9",
    colorBorder: "#D0D5DD",
    colorText: "#101828",
    fontFamily: "Roboto",
    colorErrorHover: "#B42318",
    screenXXLMin: 1700,
    screenXXL: 1700,
    screenXLMax: 1699,
  },
  components: {
    Timeline: {
      colorPrimary: "#ED783E",
      dotBg: "#ED783E",
      itemPaddingBottom: "108px",
    },
    Button: {
      colorPrimaryHover: "#6941C6",
      colorPrimaryActive: "#7F56D9",
      colorBorder: "#D0D5DD",
      colorText: "#344054",
      colorError: "#D92D20",
      colorErrorHover: "#B42318",
      colorErrorActive: "#D92D20",
      colorErrorBorderHover: "#FDA29B",
      colorLink: "#475467",
      colorLinkHover: "#344054",
      colorLinkActive: "#475467",
    },
    Progress: {
      defaultColor: "#7F56D9",
    },

    Input: {
      colorborder: "#D0D5DD",
      colorPrimaryHover: "#7F56D9",
      colorError: "#FDA29B",
      colorErrorBorderHover: "#FDA29B",
    },

    Form: {
      colorError: "#F04438",
      fontSize: 14,
      lineHeight: "20px",
    },
    Radio: {
      buttonSolidCheckedActiveBg: "#fff",
      buttonSolidCheckedColor: "#fff",
    },
    Tag: {
      defaultBg: "#F9FAFB",
      defaultColor: "#344054",
      colorBorder: "#EAECF0",
    },
    Menu: {
      itemSelectedBg: "#1D2939",
      itemSelectedColor: "#fff",
    },
  },
};
