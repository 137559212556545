import React, { useContext, useEffect } from "react";
import { Divider } from "antd";
import Built from "./GeneralExaminationComponents/Built";
import RespiratoryDistress from "./GeneralExaminationComponents/RespiratoryDistress";
import RespiratorySupport from "./GeneralExaminationComponents/RespiratorySupport";
import Tracheostomy from "./GeneralExaminationComponents/Tracheostomy";
import Hydration from "./GeneralExaminationComponents/Hydration";
import LevelOfConsciousness from "./GeneralExaminationComponents/LevelOfConsciousness";
import Pupils from "./GeneralExaminationComponents/Pupils";
import Pallor from "./GeneralExaminationComponents/Pallor";
import Icterus from "./GeneralExaminationComponents/Icterus";
import Cyanosis from "./GeneralExaminationComponents/Cyanosis";
import Edema from "./GeneralExaminationComponents/Edema";
import Ostomies from "./GeneralExaminationComponents/Ostomies";
import Urobag from "./GeneralExaminationComponents/Urobag";
import PainScale from "./GeneralExaminationComponents/PainScale";
import VitalsAdmission from "./GeneralExaminationComponents/VitalsAdmission";
import ExternalSplints from "./GeneralExaminationComponents/ExternalSplints";
import UrineExamination from "./GeneralExaminationComponents/UrineExamination";
import Urine24hr from "./GeneralExaminationComponents/Urine24hr";
import UrinaryCatheter from "./GeneralExaminationComponents/UrinaryCatheter";
import FeedingTube from "./GeneralExaminationComponents/FeedingTube";
import IVAccess from "./GeneralExaminationComponents/IVAccess";
import Drain from "./GeneralExaminationComponents/Drain";
import { DoctorInitialContext } from "../../HistoryForm/DoctorHistoryFormProvider";
import dayjs from "dayjs";
import Gcs from "./GeneralExaminationComponents/Gcs";
import PhysioGcs from "./GeneralExaminationComponents/PhysioGcs";

const GeneralExaminationForm = ({
  drainData,
  handleDrainChange,
  form,
  setDrainData,
}) => {
  const { formData } = useContext(DoctorInitialContext);

  useEffect(() => {
    if (formData?.general_examination) {
      form.setFieldsValue({
        ...formData?.general_examination,
        started_on:
          formData?.general_examination?.started_on &&
          dayjs(formData?.general_examination?.started_on),
        urobag_datepicker:
          formData?.general_examination?.urobag_datepicker &&
          dayjs(formData?.general_examination?.urobag_datepicker),
        drain: formData?.general_examination?.drain,
        feedingtube_start_date:
          formData?.general_examination?.feedingtube_start_date &&
          dayjs(formData?.general_examination?.feedingtube_start_date),
      });
      setDrainData(formData?.general_examination?.drain);
    }
  }, [formData]);

  return (
    <>
      <Built />
      <Divider />

      <RespiratoryDistress />
      <Divider />

      <RespiratorySupport />
      <Divider />

      <Tracheostomy />
      <Divider />

      <LevelOfConsciousness context={DoctorInitialContext} />
      <Divider />

      <PhysioGcs formData={formData} />
      <Divider />

      <Pupils context={DoctorInitialContext} />
      <Divider />

      <Hydration />
      <Divider />
      <Pallor />
      <Divider />

      <Icterus />
      <Divider />

      <Cyanosis />
      <Divider />

      <Edema context={DoctorInitialContext} />
      <Divider />

      <Ostomies context={DoctorInitialContext} />
      <Divider />

      <IVAccess context={DoctorInitialContext} />
      <Divider />

      <FeedingTube context={DoctorInitialContext} />
      <Divider />

      <Drain drainData={drainData} setDrainData={setDrainData} />
      <Divider />

      <Urobag />
      <Divider />

      <UrinaryCatheter context={DoctorInitialContext} />
      <Divider />

      <Urine24hr />
      <Divider />

      <UrineExamination />
      <Divider />

      <ExternalSplints />
      <Divider />

      <VitalsAdmission />
      <Divider />

      <PainScale context={DoctorInitialContext} />
    </>
  );
};

export default GeneralExaminationForm;
