import React, { useContext, useState } from "react";
import {
  Row,
  Col,
  Card,
  Tag,
  Image,
  Descriptions,
  Typography,
  Space,
} from "antd";
import morningIcon from "../../../../assets/images/nutritionistIcons/morning.svg";
import afternoonIcon from "../../../../assets/images/nutritionistIcons/afternoon.svg";
import nightIcon from "../../../../assets/images/nutritionistIcons/night.svg";
import { customTag } from "./nutritionistKeys";
import { EditOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import DietEditChart from "./EditChart/DietEditChart";
import { UserContext } from "../../../../context/UserContext";

const DietView = ({ dietData, refresh }) => {
  const { userDetails } = useContext(UserContext);
  const [editState, setEditState] = useState(false);

  const role = userDetails?.activeOrg?.default_role
    .toLowerCase()
    ?.replaceAll("_", "-");

  const renderDietDetails = (mealData) => {
    return mealData?.map((day) => (
      <>
        <Descriptions bordered labelStyle={{ width: "20%" }}>
          <Descriptions.Item
            label={dayjs(String(day?.time), "HH").format("hh A")}
          >
            {day?.items?.map((item, index) => (
              <Tag key={index} color="blue" style={{ marginBottom: 8 }}>
                {item?.diet__name}
              </Tag>
            ))}
            <br />
            {day?.items[0]?.comments && (
              <Tag color="purple">{day?.items[0]?.comments}</Tag>
            )}
          </Descriptions.Item>
        </Descriptions>
      </>
    ));
  };

  const customSection = (icon, title, mealData) => (
    <Descriptions
      title={
        <Row type="flex" align={"middle"}>
          <Col span={3}>{icon}</Col>
          <Col span={4}>
            <Typography>{title}</Typography>
          </Col>
          {/* {mealData &&
              mapToCustomTag(calculateDietTotals(mealData), "orange")} */}
        </Row>
      }
      bordered
      labelStyle={{ width: "20%" }}
    >
      {mealData?.map((day) => (
        <Descriptions.Item
          key={day?.time} // Add key for better rendering
          label={dayjs(String(day?.time), "HH").format("hh A")}
          span={3}
        >
          {day?.items?.map((item, index) => (
            <Tag key={index} color="blue" style={{ marginBottom: 8 }}>
              {item?.diet__name}
            </Tag>
          ))}
          <br />
          {day?.items[0]?.comments && (
            <Tag color="purple">{day?.items[0]?.comments}</Tag>
          )}
        </Descriptions.Item>
      ))}
    </Descriptions>
  );

  const calculateDietTotals = (data) => {
    return data?.reduce(
      (totals, timeSlot) => {
        timeSlot?.items?.forEach((item) => {
          totals.proteins += item.diet__protien || 0;
          totals.calories += item.diet__calories || 0;
          totals.sodium += item.diet__sodium || 0;
          totals.potassium += item.diet__potassium || 0;
          totals.carbohydrate += item.diet__carbohydrates || 0;
        });

        return totals;
      },
      { proteins: 0, calories: 0, sodium: 0, potassium: 0, carbohydrate: 0 }
    );
  };
  const mapToCustomTag = (data, color) => {
    return Object.entries(data).map(([key, value]) =>
      customTag(key, value, color)
    );
  };
  const overallData = [
    ...(dietData?.morning || []),
    ...(dietData?.afternoon || []),
    ...(dietData?.evening || []),
  ];

  return (
    <>
      {editState ? (
        <DietEditChart
          dietEditData={dietData}
          refresh={refresh}
          setEditState={setEditState}
        />
      ) : (
        <Card
          title={
            <>
              <Row>
                {/* <Col span={16}>Diet Items</Col> */}
                {role === "nutritionist" && (
                <Col span={24} style={{ textAlign: "right" }}>
                
                    <div className="flex-end">
                      <EditOutlined
                        onClick={() => setEditState(true)}
                        style={{ fontSize: "20px", color:"#7F56D9",marginTop:"5px" }}
                      /> 
                    </div>
                  
                </Col>
                )}
                <Col span={24}>
                  {mapToCustomTag(
                    calculateDietTotals([...overallData]),
                    "purple"
                  )}
                </Col>
              </Row>
            </>
          }
        >
          <Row gutter={[12, 12]}>
            {dietData?.morning && (
              <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                {customSection(
                  <Image
                    style={{ width: "44px" }}
                    src={morningIcon}
                    preview={false}
                  />,
                  "Morning",
                  dietData?.morning
                )}
              </Col>
            )}
            {dietData?.afternoon && (
              <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                {customSection(
                  <Image
                    style={{ width: "42px" }}
                    src={afternoonIcon}
                    preview={false}
                  />,
                  "Afternoon",
                  dietData?.afternoon
                )}
              </Col>
            )}
            {dietData?.evening && (
              <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                {customSection(
                  <Image
                    style={{ width: "32px" }}
                    src={nightIcon}
                    preview={false}
                  />,
                  "Night",
                  dietData?.evening
                )}
              </Col>
            )}
          </Row>
        </Card>
      )}
    </>
  );
};

export default DietView;
