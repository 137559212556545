import { Descriptions, Image, Space } from "antd";
import React from "react";
import {
  getLabelTag,
  getLabelTagInput,
  getTherapyLabelTags,
} from "../constructTextFromValues";
import {
  cardioTherapyOptions,
  daysUptoFifteenOptions,
  orthoTherapyOptions,
  sportsTherapyOptions,
  weeksStartingFourOptions,
  weeksStartingTwoOptions,
} from "../../Options/physioOptions";
import therapyIcon from "../../../../assets/doctorIcons/therapy.svg";

const PhysioTherapySummary = ({ formData }) => {
  const constructTherapySummaryItems = () => {
    const therapySummaryItems = [];
    const therapy = formData?.therapy || {};
    const pushTherapyItem = (key, label, children) => {
      therapySummaryItems.push({ key, label, children });
    };
    if (therapy?.cardio) {
      pushTherapyItem(
        "1",
        "Cardio",
        <>
          {getTherapyLabelTags(cardioTherapyOptions, therapy?.cardio_details)}
        </>
      );
    }
    if (therapy?.ortho) {
      pushTherapyItem(
        "2",
        "Ortho",
        <>
          {getTherapyLabelTags(orthoTherapyOptions, therapy?.ortho_details)}
          {getLabelTagInput(
            "Postural Correction",
            therapy?.ortho_details?.postural_correction
          )}
        </>
      );
    }
    if (therapy?.sports) {
      pushTherapyItem(
        "3",
        "Sports",
        <>
          {getTherapyLabelTags(sportsTherapyOptions, therapy?.sports_details)}
          {getLabelTagInput(
            "Postural Correction",
            therapy?.sports_details?.postural_correction
          )}
        </>
      );
    }
    if (therapy?.neuro) {
      pushTherapyItem(
        "4",
        "Neuro",
        <>
          {getLabelTagInput(
            "Stroke Rehab",
            getLabelTag(
              weeksStartingFourOptions,
              therapy?.neuro_details?.stroke_rehab
            )
          )}
          {getLabelTagInput(
            "Brustromme Approach",
            therapy?.neuro_details?.brustromme_approach
          )}
          {getLabelTagInput(
            "MRP Approach",
            therapy?.neuro_details?.mrp_approach
          )}
          {getLabelTagInput(
            "NDT Approach",
            therapy?.neuro_details?.ndt_approach
          )}
          {getLabelTagInput(
            "CIMT Approach",
            therapy?.neuro_details?.cimt_approach
          )}
          {getLabelTagInput(
            "VOJTA Approach",
            therapy?.neuro_details?.vojta_approach
          )}
          {getLabelTagInput(
            "PNF Approach",
            therapy?.neuro_details?.pnf_approach
          )}
          {getLabelTagInput(
            "Electic Approach",
            therapy?.neuro_details?.electic_approach
          )}
          {getLabelTagInput(
            "ROODS Approach",
            therapy?.neuro_details?.roods_approach
          )}
          {getLabelTagInput(
            "Speech Therapy",
            therapy?.neuro_details?.speech_therapy
          )}
          {getLabelTagInput(
            "Electical Muscle Stimulation",
            therapy?.neuro_details?.electrical_muscle_stimulation
          )}
          {getLabelTagInput(
            "Mile Stone Training",
            therapy?.neuro_details?.mile_stone_training
          )}
          {getLabelTagInput(
            "Cognitive Rehab",
            therapy?.neuro_details?.cognitive_rehab
          )}
          {getLabelTagInput(
            "Neuro Conditioning Training",
            therapy?.neuro_details?.neuro_conditioning_training
          )}
          {getLabelTagInput(
            "Sensory Integration",
            therapy?.neuro_details?.sensory_integration
          )}
          {getLabelTagInput(
            "ADL Training",
            therapy?.neuro_details?.adl_training
          )}
          {getLabelTagInput(
            "Wheel Chair Training",
            therapy?.neuro_details?.wheel_chair_training
          )}
          {getLabelTagInput(
            "Ultrasound",
            getLabelTag(
              daysUptoFifteenOptions,
              therapy?.neuro_details?.ultrasound
            )
          )}
          {getLabelTagInput(
            "Splinting/ Bracing",
            getLabelTag(
              weeksStartingTwoOptions,
              therapy?.neuro_details?.splinting
            )
          )}
          {getLabelTagInput(
            "Postural Correction",
            therapy?.neuro_details?.postural_correction
          )}
          {getLabelTagInput("Robotics", therapy?.neuro_details?.robotics)}
          {getLabelTagInput(
            "Gait Training",
            therapy?.neuro_details?.gait_training
          )}
          {getLabelTagInput("Description", therapy?.neuro_details?.description)}
        </>
      );
    }
    return therapySummaryItems;
  };
  const therapySummaryItems = constructTherapySummaryItems();
  return (
    <div>
      <Descriptions
        bordered
        column={1}
        title={
          <span style={{ fontWeight: "500", marginLeft: ".2rem" }}>
            <Space>
              <Image src={therapyIcon} width={"18px"} preview={false} />
              <span>Therapy</span>
            </Space>
          </span>
        }
        items={therapySummaryItems}
        style={{ margin: "0", border: "none" }}
        className="initial-insp-desc"
      />
    </div>
  );
};

export default PhysioTherapySummary;
