import React, { Fragment, forwardRef, useState } from "react";
import { Button, Col, Descriptions, Form, Input, Radio, Row } from "antd";

import CareItemForm from "./CareItemForm";
import { feedingTubeOptions } from "../../Options/careItemsOptions";
import { PlusOutlined } from "@ant-design/icons";

const formItems = (
  <Form.Item label="Feeding Tube" name="feeding_tube_type" rules={[{ required: true, message: "Please select a feeding tube" }]}>
    <Radio.Group options={feedingTubeOptions} />
  </Form.Item>
);

const reviewFormItems = (
  <Fragment>
    <Form.Item label="Position" name="position">
      <Radio.Group
        options={[
          { label: "Intact", value: "intact" },
          { label: "Displaced", value: "displaced" },
        ]}
      />
    </Form.Item>
    <Form.Item label="Tube Aspiration" name="tube_aspiration">
      <Radio.Group
        options={[
          { label: "None", value: "none" },
          { label: "Less than 20ml", value: "less_than_20ml" },
          { label: "More than 20ml", value: "more_than_20ml" },
        ]}
      />
    </Form.Item>
    <Form.Item label="Notes" name="notes">
      <Input />
    </Form.Item>
  </Fragment>
);

const items = (item) => [
  {
    key: "feeding_tube_type",
    label: "Feeding Tube",
    children:
      feedingTubeOptions?.find(
        (option) => option.value === item?.feeding_tube_type
      )?.label || "-",
  },
];

function FeedingTube(
  { getData, formData, iaAction, isIaCareItem, updateIACareItemRecord },
  ref
) {
  const [formState, setFormState] = useState({
    editingIndex: null,
    adding: false,
  });

  return (
    <Row gutter={[12, 12]}>
      {(formData === undefined || formData?.isallstopstate) && (
        <>
          {formData !== undefined && !formState?.adding ? (
            <Col
              span={24}
              style={{
                textAlign: "right",
                marginTop: "-10px",
                padding: "0 10%",
              }}
            >
              <Button
                type="primary"
                className="add-another-btn"
                onClick={() =>
                  setFormState((prev) => ({ ...prev, adding: true }))
                }
              >
                <PlusOutlined /> Add Another
              </Button>
            </Col>
          ) : (
            <CareItemForm
              getData={getData}
              formItems={formItems}
              formState={formState}
              formType="feeding_tube"
              setFormState={setFormState}
            />
          )}
        </>
      )}
      {formData?.data?.map((item, index) => (
        <CareItemForm
          ref={ref}
          key={index}
          formData={item}
          getData={getData}
          iaAction={iaAction}
          formState={formState}
          formType="feeding_tube"
          isIaCareItem={isIaCareItem}
          setFormState={setFormState}
          reviewFormItems={reviewFormItems}
          updateIACareItemRecord={updateIACareItemRecord}
          formItems={
            item === undefined ||
            isIaCareItem ||
            formState?.editingIndex === item?.id ? (
              formItems
            ) : (
              <Descriptions
                className="descriptions-custom-column-width"
                column={1}
                bordered
                items={items(item?.data)}
              />
            )
          }
        />
      ))}
    </Row>
  );
}

export default forwardRef(FeedingTube);
