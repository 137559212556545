import React, { useContext, useEffect, useState } from "react";
import { FacilityID } from "../../../constants/defaultKeys";
import { UserContext } from "../../../context/UserContext";
import { getCookie } from "../../../helpers/localStorage";
import { getInPatientInvestigationsApi } from "../../../services/receptionist.services";
import InvestigationTable from "./InvestigationTable";
import { debounce } from "../../../helpers/utility";
import { Input } from "antd";

const Investigations = () => {
  const { userDetails } = useContext(UserContext);
  const [data, setData] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [searchQuery, setSearchQuery] = useState(null);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);

  const refreshPage = () => {
    setRefresh((prev) => !prev);
  };
  useEffect(() => {
    let params = {
      page: page,
    };
    if (searchQuery) {
      params.search = searchQuery;
    }
    const queryString = new URLSearchParams(params);
    setLoading(true);
    getInPatientInvestigationsApi(
      getCookie(FacilityID) || userDetails?.currFacility,
      queryString
    )
      .then((res) => {
        setData(res?.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, [
    refresh,
    searchQuery,
    page,
    Number(getCookie(FacilityID)) || userDetails?.currFacility,
  ]);

  const onContactSearch = (value) => {
    setSearchQuery(value?.target?.value);
  };
  return (
    <>
      <Input.Search
        style={{ width: "30%", marginBottom: "10px" }}
        size="large"
        placeholder="Search for investigation"
        onChange={debounce(onContactSearch, 300)}
      ></Input.Search>
      <InvestigationTable
        loading={loading}
        data={data}
        refreshPage={refreshPage}
        page={page}
        setPage={setPage}
      />
    </>
  );
};

export default Investigations;
