import { Form, InputNumber, Radio, Typography } from "antd";
import React from "react";
import { yesNoOptions } from "../../../Options/commonOptions";
import {
  catheterOptions,
  incontinenceOptions,
  urineOptions,
} from "../../../Options/functionalAsessmentOptions";

const BladderNeeds = () => {
  return (
    <>
      <Typography>Bladder Needs</Typography>
      <div className="subsection-card">
        <Form.Item
          label="Indicates Need to Void"
          name={["bladder_needs", "indicates_need_to_void"]}
        >
          <Radio.Group options={yesNoOptions} />
        </Form.Item>
        <Form.Item
          label="Incontinence"
          name={["bladder_needs", "incontinence"]}
        >
          <Radio.Group options={incontinenceOptions} />
        </Form.Item>
        <Form.Item label="Catheter" name={["bladder_needs", "catheter"]}>
          <Radio.Group options={catheterOptions} />
        </Form.Item>
        <Form.Item label="Since" name={["bladder_needs", "catheter_days"]}>
          <InputNumber addonAfter="Days" style={{ width: "15%" }} />
        </Form.Item>
        <Form.Item label="Color Urine" name={["bladder_needs", "color_urine"]}>
          <Radio.Group options={urineOptions} />
        </Form.Item>
        <Form.Item label="Volume" name={["bladder_needs", "volume"]}>
          <InputNumber addonAfter="ml" />
        </Form.Item>
      </div>
    </>
  );
};

export default BladderNeeds;
