import React from "react";
import { Button, Image, message, Table, Tag } from "antd";
import tagStyles from "../../../styles/Custom/Tag.module.css";
import dayjs from "dayjs";
import { formatToINR } from "../../../helpers/utility";
import { shareBillApi } from "../../../services/receptionist.services";
import exportIcon from "../../../assets/receptionistIcons/exportIcon.svg";

const RecordPayments = ({ paymentData, bill_id, type }) => {
  const printBill = async (id, payment_id) => {
    const submitParams = `type=payment&payment_id=${payment_id}&share=download`;

    try {
      const resp = await shareBillApi(id, submitParams);
      const s3Link = resp?.data?.data; // Assuming the S3 link is in res.data.data
      if (s3Link) {
        window.open(s3Link, "_blank"); // Open the link in a new tab
      } else {
        message.error("Failed to retrieve download link.");
      }
    } catch (error) {
      console.error(error);
      message.error(error?.response?.data?.message);
    }
  };
  const columns = [
    {
      title: "Date",
      dataIndex: "created_at",
      key: "created_at",
      render: (value) => dayjs(value).format("MMM,DD"),
      width: "20%",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      width: "20%",
      render: (value) => value && formatToINR(value),
    },
    {
      title: "Mode",
      dataIndex: "mode",
      key: "mode",
      width: "20%",
      render: (value) => (
        <Tag color="purple" className={tagStyles.mdRndTag}>
          {value}
        </Tag>
      ),
    },
    // ...(type
    //   ? [
    {
      title: "Action",
      render: (_, record) => (
        <>
          <Button onClick={() => printBill(bill_id, record?.id)}>
            <Image
              src={exportIcon}
              alt="export"
              preview={false}
              style={{ cursor: "pointer", width: "22px" }}
            />
          </Button>
        </>
      ),
    },
    //   ]
    // : []),
  ];
  return (
    <>
      <Table
        dataSource={paymentData}
        title={() => "Payments"}
        columns={columns}
        pagination={false}
      />
    </>
  );
};

export default RecordPayments;
