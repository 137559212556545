import React, { useContext } from "react";
import { UserContext } from "../../context/UserContext";
import { PatientTypeContext } from "../Layout/DoctorLayout/PatientTypeContextProvider";
import InPatientsCard from "./InPatientsCard";
import OutPatientsCard from "./OutPatientsCard";

const PatientCards = () => {
  const { userDetails } = useContext(UserContext);
  const role = userDetails?.activeOrg?.default_role
    .toLowerCase()
    .replaceAll("_", "-");

  const { patientType } = useContext(PatientTypeContext);
  return (
    <>
      {patientType === "outPatients" ? (
        <OutPatientsCard userDetails={userDetails} role={role} />
      ) : (
        <InPatientsCard userDetails={userDetails} role={role} />
      )}
    </>
  );
};

export default PatientCards;
