import React from "react";
import { Spin, Row, Col, Typography } from "antd";

const LoadingIndicator = ({ message }) => (
  <Row
    justify="center"
    align="middle"
    style={{ marginTop: "200px", height: "100%" }}
  >
    <Col style={{ textAlign: "center" }}>
      <Spin />
      {message && (
        <Typography.Text
          style={{
            marginTop: "10px",
            fontSize: "12px",
            color: "#a6a6a6",
            display: "block",
          }}
        >
          {message}
        </Typography.Text>
      )}
    </Col>
  </Row>
);

export default LoadingIndicator;
