import { Form, Input, InputNumber, Typography } from "antd";
import React, { useContext, useEffect, useState } from "react";

const Bmi = ({ context }) => {
  const { formData } = useContext(context);
  const [states, setStates] = useState({ height: null, weight: null });

  useEffect(() => {
    setStates({
      height:
        formData?.clinical_evaluation?.bmi?.height / 100 ||
        formData?.functional_diagnosis?.bmi?.height / 100 || // assuming it's in cm
        null,
      weight:
        formData?.clinical_evaluation?.bmi?.weight ||
        formData?.functional_diagnosis?.bmi?.weight ||
        null,
    });
  }, [formData]);

  const calculateBmi = () => {
    const { height, weight } = states;
    if (!height || !weight) return null;

    const bmiValue = (weight / (height * height)).toFixed(2);

    let status = "Underweight";
    if (bmiValue >= 30) {
      status = "Obese";
    } else if (bmiValue >= 25) {
      status = "Overweight";
    } else if (bmiValue >= 18.5) {
      status = "Healthy Weight";
    }

    return `${bmiValue} (${status})`;
  };

  return (
    <>
      <Typography>
        BMI: {states?.height && states?.weight ? calculateBmi() : "N/A"}
      </Typography>
      <div className="subsection_card">
        <Form.Item label="Height" name={["bmi", "height"]}>
          <InputNumber
            addonAfter="cm"
            onChange={(value) => {
              setStates((prev) => ({ ...prev, height: value / 100 })); // convert cm to meters
            }}
          />
        </Form.Item>
        <Form.Item label="Weight" name={["bmi", "weight"]}>
          <InputNumber
            addonAfter="kg"
            onChange={(value) => {
              setStates((prev) => ({ ...prev, weight: value }));
            }}
          />
        </Form.Item>
        <Form.Item name={["bmi", "value"]} hidden>
          <Input value={calculateBmi() || ""} hidden />
        </Form.Item>
      </div>
    </>
  );
};

export default Bmi;
