import React from "react";
import RespiratoryTherapistInitialAssessmentProvider from "../IpEMR/RespiratoryTherapist/InitialInspection/RespiratoryTherapistInitialAssessmentProvider";
import RespiratoryTherapistInitialInspection from "../IpEMR/RespiratoryTherapist/InitialInspection/RespiratoryTherapistInitialInspection";
import RespiratoryTherapistReviewProvider from "../IpEMR/RespiratoryTherapist/Review/RespiratoryTherapistReviewProvider";
import RespiratoryTherapistReview from "../IpEMR/RespiratoryTherapist/Review/RespiratoryTherapistReview";

const RespiratoryTherapistIP = ({ patientData, params, refreshPage }) => {
  return (
    <>
      {params?.type === "ia" ? (
        <RespiratoryTherapistInitialAssessmentProvider>
          <RespiratoryTherapistInitialInspection
            refreshPage={refreshPage}
            editId={patientData?.respiratory_initial_assessment}
            patientData={patientData}
          />
        </RespiratoryTherapistInitialAssessmentProvider>
      ) : params?.type === "review" ? (
        <RespiratoryTherapistReviewProvider>
          <RespiratoryTherapistReview
            reviewData={patientData?.respiratory_review}
            refreshPage={refreshPage}
            iAssessment={patientData?.respiratory_initial_assessment}
            patientData={patientData}
          />
        </RespiratoryTherapistReviewProvider>
      ) : null}
    </>
  );
};

export default RespiratoryTherapistIP;
