import React from "react";
import {
  getLabelTagInput,
  getLabelTagsArray,
} from "../constructTextFromValues";
import { Descriptions, Image, Space } from "antd";
import { goalsOptions } from "../../Options/goalsOptions";
import goalsIcon from "../../../../assets/doctorIcons/goals.svg";

const NutritionistGoalsSummary = ({ formData }) => {
  const constructGoalsSummaryItems = () => {
    const goalsSummaryItems = [];
    const pushGoalsItem = (key, label, children) => {
      goalsSummaryItems.push({ key, label, children });
    };
    const goals = formData?.goals || {};
    if (goals?.goals) {
      pushGoalsItem(
        "1",
        "Goals",
        <>
          {getLabelTagsArray(goalsOptions, goals?.goals)}
          {getLabelTagInput("Other Details", goals?.others_descriptions)}
        </>
      );
    }
    return goalsSummaryItems;
  };
  const goalsSummaryItems = constructGoalsSummaryItems();
  return (
    <div>
      <Descriptions
        bordered
        column={1}
        title={
          <span style={{ fontWeight: "500", marginLeft: ".2rem" }}>
            <Space>
              <Image src={goalsIcon} width={18} preview={false} />
              <span style={{ fontSize: "16px", fontWeight: "normal" }}>
                Goals
              </span>
            </Space>
          </span>
        }
        items={goalsSummaryItems}
        style={{ margin: "0", border: "none" }}
        className="initial-insp-desc"
      />
    </div>
  );
};

export default NutritionistGoalsSummary;
