import React, { useRef, useState, useEffect } from "react";
import {
  Drawer,
  Form,
  Space,
  Input,
  Button,
  Row,
  Col,
  Select,
  Typography,
  message,
  InputNumber,
} from "antd";
import { CloseOutlined, SettingOutlined } from "@ant-design/icons";
import btnStyles from "../../../styles/Custom/Button.module.css";
import inputStyles from "../../../styles/Custom/Input.module.css";
import { validateConfig } from "../../../config/validate.config";
import FacilityInvestigationsModal from "./FacilityInvestigationsModal";
import { getFacilityListApi } from "../../../services/facilities.services";
import {
  createInvestigationsApi,
  getDepartmentsApi,
  updateInvestigationsApi,
} from "../../../services/investigation.services";

const { Option } = Select;
const InvestigationForm = ({ open, setOpen, refreshPage, editListData }) => {
  const formRef = useRef(null);
  const [form] = Form.useForm();
  const [btnLoading, setBtnLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [facilities, setFacilities] = useState(null);
  const [formValues, setFormValues] = useState(null);
  const [departments, setDepartments] = useState(null);
  const [facilityPrice, setFacilityPrice] = useState(null);

  const priceTagList = [
    { name: "With tax", id: true },
    { name: "Without tax", id: false },
  ];
  useEffect(() => {
    getFacilityListApi()
      .then((res) => {
        setFacilities(res?.data);
      })
      .catch((err) => {
        console.log(err);
      });
    getDepartmentsApi()
      .then((res) => setDepartments(res?.data))
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    if (editListData) {
      form.setFieldsValue({
        ...editListData,
        department: editListData?.department?.id,
      });
    }
  }, []);

  const onFinish = (values) => {
    setBtnLoading(true);

    const formData = {
      ...values,
      facility_investigations: facilityPrice
        ? facilityPrice
        : editListData?.id
        ? editListData?.facility_investigations
        : facilities?.map((facility) => ({
            is_available: true,
            facility: facility?.id,
            cash_withtax: false,
            insurance_withtax: false,
            cash_price: values?.cash_price,
            insurance_price: values?.insurance_price,
          })),
    };
    if (editListData) {
      updateInvestigationsApi(editListData?.id, formData)
        .then((res) => {
          message?.success(res?.data?.message);
          setOpen(false);
          refreshPage();
          setBtnLoading(false);
        })
        .catch((err) => {
          message.error(err?.response?.data?.message);
          setBtnLoading(false);
        });
    } else {
      createInvestigationsApi(formData)
        .then((res) => {
          message.success(res?.data?.message);
          setOpen(false);
          refreshPage();
          setBtnLoading(false);
        })
        .catch((err) => {
          message.error(err?.response?.data?.message || "Something went wrong");
          setBtnLoading(false);
        });
    }
  };
  const onClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Drawer
        maskClosable={false}
        open={open}
        width={"30%"}
        title={
          <Row>
            <Col span={12} className="flex-start">
              <Typography
                style={{
                  marginLeft: "12px",
                  fontWeight: "400",
                  fontSize: "20px",
                }}
              >
                {editListData ? "Edit Investigation" : " Add Investigation"}
              </Typography>
            </Col>
            <Col span={12} className="flex-end">
              <CloseOutlined onClick={onClose} />
            </Col>
          </Row>
        }
        footer={
          <div style={{ textAlign: "right" }}>
            <Space>
              {facilities?.length > 1 && (
                <Form.Item>
                  <Button
                    loading={btnLoading}
                    className={btnStyles.lgBtn}
                    type="primary"
                    htmlType="submit"
                    ghost
                    onClick={() => {
                      setVisible(true);
                    }}
                  >
                    <SettingOutlined /> Facilitywise Availability and Pricing
                  </Button>
                </Form.Item>
              )}
              <Form.Item>
                <Button
                  loading={btnLoading}
                  className={btnStyles.lgBtn}
                  type="primary"
                  htmlType="submit"
                  onClick={() => {
                    if (formRef.current) {
                      formRef.current.submit();
                    }
                  }}
                >
                  Submit
                </Button>
              </Form.Item>
            </Space>
          </div>
        }
        onClose={onClose}
        closeIcon={null}
      >
        <Row gutter={[12, 12]}>
          <Col span={24}>
            <Form
              ref={formRef}
              form={form}
              onFinish={onFinish}
              // style={{ maxWidth: 600 }}
              autoComplete="off"
              layout="vertical"
              // requiredMark={false}
              onValuesChange={(allValues, changedValues) =>
                setFormValues({ ...allValues, ...changedValues })
              }
              initialValues={{ cash_withtax: false, insurance_withtax: false }}
            >
              <Form.Item
                label="Investigation Name"
                name="name"
                rules={validateConfig.isRequired("Investigation Name")}
              >
                <Input
                  placeholder="Enter investigation name"
                  className={inputStyles.mdInput}
                  style={{ width: "300px" }}
                />
              </Form.Item>
              <Form.Item label="Description" name="description">
                <Input.TextArea
                  className={inputStyles.mdInput}
                  style={{ width: "300px" }}
                  rows={2}
                />
              </Form.Item>
              <Form.Item
                label="Department"
                name="department"
                rules={validateConfig.isRequired("Department")}
              >
                <Select
                  style={{ width: "300px" }}
                  placeholder="Choose Department"
                  className={inputStyles.mdSelect}
                  optionFilterProp="children"
                >
                  {departments?.map((dept) => (
                    <Option value={dept?.id} key={dept?.id}>
                      {dept?.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Space.Compact>
                <Form.Item
                  label="Cash Price"
                  name="cash_price"
                  rules={validateConfig.isRequired("Price")}
                >
                  <InputNumber
                    style={{ width: "150px" }}
                    placeholder="Price"
                    prefix="Rs"
                    className={`${inputStyles.mdNumberInput}`}
                  />
                </Form.Item>
                <Form.Item label=" " name="cash_withtax">
                  <Select
                    className={inputStyles.mdSelect}
                    defaultValue={false}
                    style={{ width: "150px" }}
                  >
                    {priceTagList?.map((data) => {
                      return (
                        <Select.Option key={data?.id} value={data?.id}>
                          {data?.name}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Space.Compact>
              <Space.Compact>
                <Form.Item
                  label="Insurance Price"
                  name="insurance_price"
                  rules={validateConfig.isRequired("Price")}
                >
                  <InputNumber
                    style={{ width: "150px" }}
                    placeholder="Price"
                    prefix="Rs"
                    className={`${inputStyles.mdNumberInput}`}
                  />
                </Form.Item>
                <Form.Item label=" " name="insurance_withtax">
                  <Select
                    className={inputStyles.mdSelect}
                    defaultValue={false}
                    style={{ width: "150px" }}
                  >
                    {priceTagList?.map((data) => {
                      return (
                        <Select.Option key={data?.id} value={data?.id}>
                          {data?.name}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Space.Compact>
              <Form.Item
                label="GST%"
                name="tax"
                // rules={validateConfig.isRequired("Price")}
                initialValue={0}
              >
                <InputNumber
                  placeholder="Enter GST%"
                  style={{ width: "200px" }}
                  className={`${inputStyles.mdNumberInput}`}
                />
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Drawer>
      {visible && (
        <FacilityInvestigationsModal
          visible={visible}
          setVisible={setVisible}
          facilities={facilities}
          formValues={formValues}
          facilityPrice={facilityPrice}
          setFacilityPrice={setFacilityPrice}
          editListData={editListData}
        />
      )}
    </>
  );
};

export default InvestigationForm;
