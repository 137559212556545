import { Form, Radio, Typography } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { notAssessableAndAssessableOptions } from "../../../../Options/commonOptions";
import TCMItems from "../../PhysioScales/TCMScaleComponents/TCMItems";

const TCMAssessment = ({ context }) => {
  const { formData } = useContext(context);
  const [assessable, setAssessable] = useState(
    formData?.systemic_examination?.tcms?.status === "assessable"
  );
  const [states, setStates] = useState({});
  const handleChange = (value, key) => {
    setStates((prev) => ({ ...prev, [key]: value }));
  };
  useEffect(() => {
    setAssessable(
      formData?.systemic_examination?.tcms?.status === "assessable"
    );
    setStates(
      Object?.fromEntries(
        Object?.entries(formData?.systemic_examination?.tcms || {})?.filter(
          ([key]) =>
            [
              "selective_movement_control",
              "sitting_balance",
              "sitting_to_standing",
            ]?.includes(key)
        )
      ) || {}
    );
  }, [formData]);
  const score = Object.values(states).reduce((acc, cur) => acc + cur, 0);
  return (
    <>
      <Typography>Trunk Control Management Scale</Typography>
      <Typography>Score: {score}</Typography>
      <Form.Item name={["tcms", "status"]}>
        <Radio.Group
          options={notAssessableAndAssessableOptions}
          onChange={(e) => setAssessable(e.target.value === "assessable")}
        />
      </Form.Item>
      {assessable && (
        <div className="subsection-card">
          <TCMItems handleChange={handleChange} nameKey={"tcms"} />
        </div>
      )}
    </>
  );
};

export default TCMAssessment;
