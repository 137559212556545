import React, { useState } from "react";
import {
  Form,
  Input,
  DatePicker,
  Upload,
  message,
  Button,
  Row,
  Col,
  Typography,
} from "antd";
import inputStyles from "../../../styles/Custom/Input.module.css";
import { getUploadUrlApi } from "../../../services/common.services";
import { convertToFormData } from "../../../helpers/utility";
import { CameraOutlined, DeleteOutlined } from "@ant-design/icons";
import axios from "axios";
import dayjs from "dayjs";
import { getFileIcon } from "../../../helpers/getFileIcon";
import "./InvestigationsIp.css";

const { Dragger } = Upload;

const InitialInvestigationForm = ({
  editData,
  setUploadFile,
  setUploadFileLoading,
  uploadFile,
}) => {
  const [uploading, setUploading] = useState(false);

  function disabledDate(current) {
    return current && current > dayjs().endOf("day");
  }

  const handleRemoveFile = (fileToRemove) => {
    const filteredFiles = uploadFile.filter(
      (file) => file.path !== fileToRemove.path
    );
    setUploadFile(filteredFiles);
  };

  const uploadProps = {
    beforeUpload: (file) => {
      let file_ext = file.name.split(".").slice(-1)[0];
      const fileType =
        file_ext === "png" ||
        file_ext === "jpg" ||
        file_ext === "jpeg" ||
        file_ext === "svg" ||
        file.type === "application/pdf";

      if (!fileType) {
        message.error(`${file.name} is not a valid file`);
      }
      return fileType ? true : Upload.LIST_IGNORE;
    },
    async onChange(info) {
      if (info.file.status === "done") {
        setUploadFileLoading(true);
        try {
          const res = await getUploadUrlApi(`path_key=${info.file.name}`);
          const formData = convertToFormData(res, info);
          await axios({
            method: "POST",
            url: res?.data?.url,
            data: formData,
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });
          setUploadFile((prevState) => [
            ...prevState,
            { path: res?.data?.fields?.key, type: info.file.type },
          ]);
        } catch (err) {
          message.error(err?.response?.data?.message);
        } finally {
          setUploadFileLoading(false);
        }
      }
    },
  };

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  return (
    <>
      <Form.Item
        label="Investigation Name"
        name="name"
        rules={[{ required: true, message: "Please enter investigation name" }]}
      >
        <Input className={inputStyles.mdInput} style={{ width: "100%" }} />
      </Form.Item>
      <Form.Item
        label="Upload Initial Investigations"
        name="upload"
        rules={[
          {
            required: uploadFile?.length === 0 && true,
            message: "Please upload investigations",
          },
        ]}
      >
        <Dragger
          style={{ width: "100%" }}
          accept=".png,.jpg,.jpeg,.svg,application/pdf"
          {...uploadProps}
          multiple
          customRequest={dummyRequest}
          showUploadList={false}
        >
          <p className="ant-upload-drag-icon">
            <CameraOutlined />
          </p>
          <p className="ant-upload-text">
            <span style={{ color: "#406AFF" }}>Click to Capture </span> or
            upload
          </p>
          <p className="ant-upload-hint">PDF, SVG, JPEG, JPG, PNG</p>
        </Dragger>

        <Row gutter={16} style={{ padding: "5px" }}>
          {uploadFile.map((item, index) => (
            <>
              <Col className="inv-upload-file" span={6} key={index}>
                <span style={{ display: "flex" }}>
                  {getFileIcon(item)}
                  <Button
                    className="inv-upload-del-btn"
                    type="link"
                    icon={<DeleteOutlined />}
                    onClick={() => handleRemoveFile(item)}
                  />
                </span>

                <Typography.Text ellipsis>
                  {item.path.replace("curengo/", "")}
                </Typography.Text>
              </Col>
            </>
          ))}
        </Row>
      </Form.Item>
      <Form.Item
        label="Date"
        name="date"
        rules={[{ required: true, message: "Please enter investigation date" }]}
      >
        <DatePicker
          className={inputStyles.mdDateInput}
          disabledDate={disabledDate}
        />
      </Form.Item>
    </>
  );
};

export default InitialInvestigationForm;
