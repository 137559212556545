import React, { useEffect, useState } from "react";
import { Row, Col, Space, Button, Tabs } from "antd";
import PatientGalleryUpload from "./PatientGalleryUpload";
import {
  getCategoriesListApi,
  getPatientUploadsApi,
} from "../../../services/patient.services";
import useQuery from "../../../hooks/useQuery";
import PatientImageView from "./PatientImageView";
import LoadingIndicator from "../../Loaders/LoadingIndicator";
import { UploadOutlined } from "@ant-design/icons";
import AddLabelModal from "./AddLabelModal";

const PatientGallery = () => {
  const [fileList, setFileList] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(true);
  const [lableModal, setLableModal] = useState(false);
  const [categories, setCategories] = useState([]);
  const [refreshLabels, setRefreshLabels] = useState(false);
  const [galleryModal, setGalleryModal] = useState(false);
  const [activeKey, seActiveKey] = useState(0);
  const params = useQuery();

  const refreshPage = () => setRefresh((prev) => !prev);
  const refreshLabelsPage = () => setRefreshLabels((prev) => !prev);

  useEffect(() => {
    const queryFilters =
      activeKey === 0
        ? `ip_admission_id=${Number(params?.patient)}`
        : `ip_admission_id=${Number(params?.patient)}&category_id=${activeKey}`;

    getPatientUploadsApi(queryFilters)
      .then((res) => setFileList(res?.data))
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  }, [refresh, activeKey]);

  useEffect(() => {
    getCategoriesListApi()
      .then((res) => setCategories(res?.data?.data))
      .catch((err) => console.log(err));
  }, [refreshLabels]);

  return (
    <Row gutter={[12, 12]} className="patient-gallery">
      <Col span={24} style={{ textAlign: "right" }}>
        <Space>
          {categories?.length <= 0 && (
            <Button type="primary" ghost onClick={() => setLableModal(true)}>
              Add Label
            </Button>
          )}
          {categories?.length > 0 && (
            <Button
              className="inv-btn"
              type="primary"
              onClick={() => setGalleryModal(true)}
            >
              <UploadOutlined />
              Upload
            </Button>
          )}
        </Space>
      </Col>
      <Row gutter={0}>
        {categories?.length > 0 && (
          <Col span={24}>
            <Tabs
              type="editable-card"
              onChange={(value) => seActiveKey(value)}
              onEdit={() => setLableModal(true)}
              items={[
                { label: "All", key: 0, closable: false },
                ...categories?.map((item) => ({
                  label: item?.name,
                  key: item?.id,
                  closable: false,
                })),
              ]}
              style={{
                background: "white",
                display: "inline-block",
                padding: "5px",
                borderTopRightRadius: "10px",
              }}
            />
          </Col>
        )}

        <Col span={24}>
          {loading ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "1500px",
              }}
            >
              <LoadingIndicator
                message={"Loading patient gallery data, please wait ..."}
              />
            </div>
          ) : (
            <PatientImageView data={fileList} loading={loading} />
          )}
        </Col>
      </Row>
      {lableModal && (
        <AddLabelModal
          refresh={refreshPage}
          isModalVisible={lableModal}
          setIsModalVisible={setLableModal}
          labels={categories}
          refreshLabelsPage={refreshLabelsPage}
        />
      )}
      {galleryModal && (
        <PatientGalleryUpload
          refresh={refreshPage}
          isModalVisible={galleryModal}
          setIsModalVisible={setGalleryModal}
          labels={categories}
        />
      )}
    </Row>
  );
};
export default PatientGallery;
