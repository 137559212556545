import { Descriptions } from "antd";
import React from "react";
import {
  presentIllnessOptions,
  pastMedicalOptions,
  chiefComplaintsOptions,
  medicalTreatmentOptions,
  surgicalTreatmentOptions,
  maritalStatusOptions,
  educationalOptions,
  smokingOptions,
  alcoholOptions,
  informantOptions,
  functionalOptions,
  knownAllergiesOptions,
  relationOptions,
} from "../../Options/historyOptions";
import {
  getPastMedicalHistorySummary,
  getLabelTagsWithDescription,
  getLabelTag,
  getLabelTagsArray,
  getLabelTagInput,
  getLabelTagYesNO,
} from "../constructTextFromValues";
import { HistoryOutlined } from "@ant-design/icons";
import "../../summary.css";

const DoctorHistorySummary = ({ formData }) => {
  // const { formData } = useContext(DoctorInitialContext);

  const historySummaryItems = [];

  const pushHistoryItem = (key, label, children) => {
    historySummaryItems.push({ key, label, children });
  };

  const constructHistorySummaryItems = () => {
    const history = formData?.history || {};

    if (
      history.informant ||
      history.relation_with_patient ||
      history.informant_name
    ) {
      pushHistoryItem(
        "1",
        "Informant",
        <>
          {getLabelTag(informantOptions, history.informant)}
          {getLabelTag(relationOptions, history.relation_with_patient)}
          {getLabelTagInput("Name", history.informant_name)}
        </>
      );
    }

    if (history.chief_complaints || history.others_chief_complaint) {
      pushHistoryItem(
        "2",
        "Chief Complaints",
        <>
          {getLabelTagsArray(
            chiefComplaintsOptions,
            history.chief_complaints,
            history
          )}
          {getLabelTagInput("Other Details", history.others_chief_complaint)}
        </>
      );
    }

    if (
      getLabelTagsWithDescription(presentIllnessOptions, history)?.length > 0
    ) {
      pushHistoryItem(
        "3",
        "Present Illness",
        getLabelTagsWithDescription(presentIllnessOptions, history)
      );
    }

    if (
      getLabelTagsWithDescription(
        medicalTreatmentOptions,
        history?.treatment_history
      )?.length > 0
    ) {
      pushHistoryItem(
        "4",
        "Medical Treatment History",
        getLabelTagsWithDescription(
          medicalTreatmentOptions,
          history?.treatment_history
        )
      );
    }

    if (
      getLabelTagsWithDescription(
        surgicalTreatmentOptions,
        history?.treatment_history
      )?.length > 0
    ) {
      pushHistoryItem(
        "5",
        "Surgical Treatment History",
        getLabelTagsWithDescription(
          surgicalTreatmentOptions,
          history?.treatment_history
        )
      );
    }

    if (getPastMedicalHistorySummary(pastMedicalOptions, history)?.length > 0) {
      pushHistoryItem(
        "6",
        "Past Medical History",
        getPastMedicalHistorySummary(pastMedicalOptions, history)
      );
    }

    if (history.surgical_history || history.surgical_history_details) {
      pushHistoryItem(
        "7",
        "Past Surgical History",
        <>
          {getLabelTagYesNO(history.surgical_history)}
          {getLabelTagInput("Details", history.surgical_history_details)}
        </>
      );
    }

    if (
      history.marital_status ||
      history.education ||
      history.smoking ||
      history.alcohol
    ) {
      pushHistoryItem(
        "8",
        "Personal History",
        <>
          {getLabelTag(maritalStatusOptions, history.marital_status)}
          {getLabelTag(educationalOptions, history.education)}
          {getLabelTag(smokingOptions, history.smoking)}
          {getLabelTag(alcoholOptions, history.alcohol)}
        </>
      );
    }

    if (
      getLabelTagsWithDescription(knownAllergiesOptions, history)?.length > 0
    ) {
      pushHistoryItem(
        "9",
        "Known Allergies",
        getLabelTagsWithDescription(knownAllergiesOptions, history)
      );
    }

    if (history.functional_capacity) {
      pushHistoryItem(
        "10",
        "Previous Functional Capacity",
        getLabelTag(functionalOptions, history.functional_capacity)
      );
    }

    return historySummaryItems;
  };

  return (
    <div>
      <Descriptions
        bordered
        column={1}
        title={
          <span style={{fontWeight:"normal"}}>
            <HistoryOutlined style={{color:"#7F56D9",fontWeight:"bold"}}/> History
          </span>
        }
        items={constructHistorySummaryItems()}
        className="initial-insp-desc"
        
      />
    </div>
  );
};

export default DoctorHistorySummary;
