import React, { lazy, Suspense } from "react";
import { Tabs } from "antd";
import { PhysioReviewContext } from "../../../Physiotherapist/PhysiotherapistReview/PhysioReviewFormProvider";
import "./PhysioScales.css";

const { TabPane } = Tabs;
const GCScale = lazy(() => import("./GCScale"));
const NIHSScale = lazy(() => import("./NIHSScale"));
const MOCAScale = lazy(() => import("./MOCAScale"));
const RASScale = lazy(() => import("./RASScale"));
const ASIAScale = lazy(() => import("./ASIAScale"));
const VAScale = lazy(() => import("./VAScale"));
const MAScale = lazy(() => import("./MAScale"));
const MMTScale = lazy(() => import("./MMTScale"));
const ROMScale = lazy(() => import("./ROMScale"));
const VCGScale = lazy(() => import("./VCGScale"));
const RPEScale = lazy(() => import("./RPEScale"));
const Reflexes = lazy(() => import("./Reflexes"));
const PeakFlowMeterTest = lazy(() => import("./PeakFlowMeterTest"));
const SixMinuteWalkTest = lazy(() => import("./SixMinuteWalkTest"));
const Coordination = lazy(() => import("./Coordination"));
const TCMScale = lazy(() => import("./TCMScale"));
const FingerGripTest = lazy(() => import("./FingerGripTest"));
const BarthelIndex = lazy(() => import("./BarthelIndex"));

const PhysioScales = ({
  admission,
  onScalesSubmit,
  scalesId,
  setScalesId,
  scaleTab,
  setScaleTab,
  setActiveTab,
  setVisible,
}) => {
  return (
    <div className="physio-scales">
      <Tabs
        activeKey={scaleTab}
        type="card"
        tabPosition="top"
        animated={{ tabPane: true }}
        onChange={(key) => {
          setScaleTab(key);
        }}
      >
        <TabPane tab="GCS" key="1">
          <Suspense fallback={<div>Loading GCS...</div>}>
            <GCScale
              admission={admission}
              setActiveTab={setActiveTab}
              onScalesSubmit={onScalesSubmit}
              scalesId={scalesId}
              setScalesId={setScalesId}
              scaleTab={scaleTab}
              setScaleTab={setScaleTab}
              setVisible={setVisible}
            />
          </Suspense>
        </TabPane>
        <TabPane tab="NIHSS" key="2">
          <Suspense fallback={<div>Loading NIHSS...</div>}>
            <NIHSScale
              onScalesSubmit={onScalesSubmit}
              admission={admission}
              setActiveTab={setActiveTab}
              scalesId={scalesId}
              setScalesId={setScalesId}
              setScaleTab={setScaleTab}
              setVisible={setVisible}
            />
          </Suspense>
        </TabPane>
        <TabPane tab="MOCA" key="3">
          <Suspense fallback={<div>Loading MOCA...</div>}>
            <MOCAScale
              admission={admission}
              setActiveTab={setActiveTab}
              onScalesSubmit={onScalesSubmit}
              setScalesId={setScalesId}
              scalesId={scalesId}
              setScaleTab={setScaleTab}
              setVisible={setVisible}
            />
          </Suspense>
        </TabPane>
        <TabPane tab="RAS" key="4">
          <Suspense fallback={<div>Loading RAS...</div>}>
            <RASScale
              admission={admission}
              setActiveTab={setActiveTab}
              onScalesSubmit={onScalesSubmit}
              scalesId={scalesId}
              setScalesId={setScalesId}
              setScaleTab={setScaleTab}
              setVisible={setVisible}
            />
          </Suspense>
        </TabPane>
        <TabPane tab="ASIA" key="5">
          <Suspense fallback={<div>Loading ASIA...</div>}>
            <ASIAScale
              admission={admission}
              setActiveTab={setActiveTab}
              onScalesSubmit={onScalesSubmit}
              setScalesId={setScalesId}
              scalesId={scalesId}
              setScaleTab={setScaleTab}
              setVisible={setVisible}
            />
          </Suspense>
        </TabPane>
        <TabPane tab="VAS" key="6">
          <Suspense fallback={<div>Loading VAS...</div>}>
            <VAScale
              admission={admission}
              setActiveTab={setActiveTab}
              onScalesSubmit={onScalesSubmit}
              setScalesId={setScalesId}
              scalesId={scalesId}
              setScaleTab={setScaleTab}
              setVisible={setVisible}
            />
          </Suspense>
        </TabPane>
        <TabPane tab="MAS" key="7">
          <Suspense fallback={<div>Loading MAS...</div>}>
            <MAScale
              admission={admission}
              setActiveTab={setActiveTab}
              onScalesSubmit={onScalesSubmit}
              setScalesId={setScalesId}
              scalesId={scalesId}
              setScaleTab={setScaleTab}
              setVisible={setVisible}
            />
          </Suspense>
        </TabPane>
        <TabPane tab="MMT" key="8">
          <Suspense fallback={<div>Loading MMT...</div>}>
            <MMTScale
              admission={admission}
              setActiveTab={setActiveTab}
              onScalesSubmit={onScalesSubmit}
              setScalesId={setScalesId}
              scalesId={scalesId}
              setScaleTab={setScaleTab}
              setVisible={setVisible}
            />
          </Suspense>
        </TabPane>
        <TabPane tab="ROM" key="9">
          <Suspense fallback={<div>Loading ROM...</div>}>
            <ROMScale
              admission={admission}
              setActiveTab={setActiveTab}
              onScalesSubmit={onScalesSubmit}
              setScalesId={setScalesId}
              scalesId={scalesId}
              setScaleTab={setScaleTab}
              setVisible={setVisible}
            />
          </Suspense>
        </TabPane>
        <TabPane tab="VCG" key="10">
          <Suspense fallback={<div>Loading VCG...</div>}>
            <VCGScale
              admission={admission}
              setActiveTab={setActiveTab}
              onScalesSubmit={onScalesSubmit}
              setScalesId={setScalesId}
              scalesId={scalesId}
              setScaleTab={setScaleTab}
              setVisible={setVisible}
            />
          </Suspense>
        </TabPane>
        <TabPane tab="RPE" key="11">
          <Suspense fallback={<div>Loading RPE...</div>}>
            <RPEScale
              admission={admission}
              setActiveTab={setActiveTab}
              onScalesSubmit={onScalesSubmit}
              setScalesId={setScalesId}
              scalesId={scalesId}
              setScaleTab={setScaleTab}
              setVisible={setVisible}
            />
          </Suspense>
        </TabPane>
        <TabPane tab="Reflexes" key="12">
          <Suspense fallback={<div>Loading Reflexes...</div>}>
            <Reflexes
              admission={admission}
              setActiveTab={setActiveTab}
              onScalesSubmit={onScalesSubmit}
              setScalesId={setScalesId}
              scalesId={scalesId}
              setScaleTab={setScaleTab}
              setVisible={setVisible}
            />
          </Suspense>
        </TabPane>
        <TabPane tab="Peak Flow Meter Test" key="13">
          <Suspense fallback={<div>Loading Peak Flow Meter Test...</div>}>
            <PeakFlowMeterTest
              admission={admission}
              setActiveTab={setActiveTab}
              onScalesSubmit={onScalesSubmit}
              setScalesId={setScalesId}
              scalesId={scalesId}
              setScaleTab={setScaleTab}
              setVisible={setVisible}
            />
          </Suspense>
        </TabPane>
        <TabPane tab="Six Minute Walk Test" key="14">
          <Suspense fallback={<div>Loading Six Minute Walk Test...</div>}>
            <SixMinuteWalkTest
              admission={admission}
              setActiveTab={setActiveTab}
              onScalesSubmit={onScalesSubmit}
              setScalesId={setScalesId}
              scalesId={scalesId}
              setScaleTab={setScaleTab}
              setVisible={setVisible}
            />
          </Suspense>
        </TabPane>
        <TabPane tab="Coordination" key="15">
          <Suspense fallback={<div>Loading Coordination...</div>}>
            <Coordination
              admission={admission}
              setActiveTab={setActiveTab}
              onScalesSubmit={onScalesSubmit}
              setScalesId={setScalesId}
              scalesId={scalesId}
              setScaleTab={setScaleTab}
              setVisible={setVisible}
            />
          </Suspense>
        </TabPane>
        <TabPane tab="TCM" key="16">
          <Suspense fallback={<div>Loading TCM...</div>}>
            <TCMScale
              context={PhysioReviewContext}
              admission={admission}
              setActiveTab={setActiveTab}
              onScalesSubmit={onScalesSubmit}
              setScalesId={setScalesId}
              scalesId={scalesId}
              setScaleTab={setScaleTab}
              setVisible={setVisible}
            />
          </Suspense>
        </TabPane>
        <TabPane tab="Finger Grip Test" key="17">
          <Suspense fallback={<div>Loading Finger Grip Test...</div>}>
            <FingerGripTest
              admission={admission}
              setActiveTab={setActiveTab}
              onScalesSubmit={onScalesSubmit}
              setScalesId={setScalesId}
              scalesId={scalesId}
              setScaleTab={setScaleTab}
              setVisible={setVisible}
            />
          </Suspense>
        </TabPane>
        <TabPane tab="Barthel Index" key="18">
          <Suspense fallback={<div>Loading Barthel Index...</div>}>
            <BarthelIndex
              admission={admission}
              setActiveTab={setActiveTab}
              onScalesSubmit={onScalesSubmit}
              setScalesId={setScalesId}
              scalesId={scalesId}
              setScaleTab={setScaleTab}
              setVisible={setVisible}
            />
          </Suspense>
        </TabPane>
      </Tabs>
    </div>
  );
};

export default PhysioScales;
