import { useState } from "react";
import { Card, Col, Row, Statistic, Typography } from "antd";
import {
  AreaChart,
  Area,
  Tooltip,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";

const CommonGraph = ({ title, data, lineColor }) => {
  const [displayValue, setDisplayVaue] = useState(
    data?.[data?.length - 1]?.value
  );

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      setDisplayVaue(payload[0]?.payload?.value);
    } else {
      setDisplayVaue(data?.[data?.length - 1]?.value);
    }
    return null;
  };
  return (
    <Card
      size="small"
      className="tiny-graph-card"
      style={{height:"100%"}}
      title={
        <Row>
          <Col span={12}>
            <Typography>{title}</Typography>
          </Col>
          <Col span={12} style={{ textAlign: "right" }}>
            <Statistic
              value={displayValue}
              valueStyle={{
                color: "#52C41A",
                fontWeight: 600,
                fontSize: "24px",
              }}
            />
          </Col>
        </Row>
      }
    >
      <ResponsiveContainer width="100%" height={100} className="line-chart">
        <AreaChart data={data}>
          <XAxis dataKey="name" hide={true} />
          <YAxis domain={["dataMin", "dataMax"]} hide={true} />
          <Area
            type="monotone"
            dataKey="value"
            stroke={lineColor}
            dot={false}
            fill={lineColor}
            fillOpacity={0.2}
            strokeWidth={2}
          />
          <Tooltip content={<CustomTooltip />} cursor={false} />
        </AreaChart>
      </ResponsiveContainer>
    </Card>
  );
};
export default CommonGraph;
