import React, { useState, useRef, useEffect } from "react";
import {
  Form,
  Button,
  Typography,
  Col,
  Row,
  Modal,
  InputNumber,
  message,
  Divider,
  TimePicker,
} from "antd";
import btnStyles from "../../../../styles/Custom/Button.module.css";
import { CloseOutlined } from "@ant-design/icons";
import { vitalValidations } from "../../../../helpers/medicineKeys";
import {
  addVitalsMonitoringApi,
  updateNurseVitalsMonitoringApi,
} from "../../../../services/nurse.services";
import useQuery from "../../../../hooks/useQuery";
import dayjs from "dayjs";
const format = "hh A";

const VitalsModal = ({ open, setOpen, editData, refresh, date }) => {
  const params = useQuery();
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const formRef = useRef(null);
  console.log(editData);
  useEffect(() => {
    if (editData?.id) {
      form.setFieldsValue({
        ...editData,
        action_time: dayjs(editData?.action_time),
      });
    }
  }, []);
  const onFinish = (values) => {
    const selectedTime = values?.action_time
      ? dayjs(values?.action_time).format("HH:mm")
      : null;
    const combinedDateTime = selectedTime
      ? dayjs(date)
          .set("hour", dayjs(values?.action_time).hour())
          .set("minute", dayjs(values?.action_time).minute())
          .toISOString()
      : editData?.action_time;

    const updatedValues = {
      ...values,
      action_time: combinedDateTime,
    };
    if (editData?.id) {
      updateNurseVitalsMonitoringApi(editData?.id, updatedValues)
        ?.then((res) => {
          message.success(res?.data?.message);
          setOpen(false);
          refresh();
        })
        .catch((err) => {
          message?.error(err?.response?.data?.message);
        });
    } else {
      addVitalsMonitoringApi(params?.patient, updatedValues)
        ?.then((res) => {
          message.success(res?.data?.message);
          setOpen(false);
          refresh();
        })
        .catch((err) => {
          message?.error(err?.response?.data?.message);
        });
    }
  };

  const formItems = [
    { name: "Temperature", value: "temperature" },
    { name: "Pulse Rate", value: "pulse" },
    { name: "Respiratory Rate", value: "respiration" },
    { name: "SPO2", value: "oxygen_saturation" },
    { name: "Systolic Pressure", value: "systolic_pressure" },
    { name: "Diastolic Pressure", value: "diastolic_pressure" },
  ];
  return (
    <div>
      <Modal
        open={open}
        onCancel={() => setOpen(false)}
        title={
          <Row>
            <Col span={12} className="flex-start">
              <Typography
                style={{
                  marginLeft: "12px",
                  fontWeight: "400",
                  fontSize: "20px",
                }}
              >
                {editData?.id ? "Edit Vitals" : "Add Vitals"}
              </Typography>
            </Col>
            <Col span={12} className="flex-end">
              <CloseOutlined
                onClick={() => {
                  setOpen(false);
                }}
              />
            </Col>
          </Row>
        }
        closeIcon={null}
        footer={
          <>
            <Divider style={{ margin: "16px 0" }} />
            <div
              className="flex-end"
              style={{ gap: "24px", justifyItems: "center" }}
            >
              <Form.Item>
                <Button
                  type="primary"
                  ghost
                  className={`${btnStyles.mdBtn} inv-btn`}
                  onClick={() => setOpen(false)}
                >
                  Cancel
                </Button>
              </Form.Item>
              <Form.Item>
                <Button
                  loading={loading}
                  htmlType="submit"
                  type="primary"
                  className={`${btnStyles.mdBtn} inv-btn`}
                  onClick={() => {
                    if (formRef.current) {
                      formRef.current.submit();
                    }
                  }}
                >
                  Save
                </Button>
              </Form.Item>
            </div>
          </>
        }
      >
        <Divider style={{ margin: "16px 0" }} />
        <Form layout="vertical" onFinish={onFinish} form={form} ref={formRef}>
          <Form.Item
            label="Time"
            name="action_time"
            rules={[{ required: true, message: "Please Select time" }]}
          >
            <TimePicker format={format} needConfirm={false} />
          </Form.Item>
          <Row gutter={16} style={{ justifyContent: "center" }}>
            {formItems?.map((item) => (
              <Col xs={24} sm={24} md={12} lg={8}>
                <Form.Item
                  label={item?.name}
                  name={item?.value}
                  rules={[
                    {
                      type: "number",
                      min: vitalValidations[item?.value]?.minimum,
                      message: `Out of Range (${
                        vitalValidations[item?.value]?.minimum
                      }-${vitalValidations[item?.value]?.maximum})`,
                    },
                    {
                      type: "number",
                      max: vitalValidations[item?.value]?.maximum,
                      message: `Out of Range (${
                        vitalValidations[item?.value]?.minimum
                      }-${vitalValidations[item?.value]?.maximum})`,
                    },
                  ]}
                >
                  <InputNumber
                    placeholder={`${vitalValidations[item?.value]?.minimum}-${
                      vitalValidations[item?.value]?.maximum
                    }`}
                  />
                </Form.Item>
              </Col>
            ))}
          </Row>
        </Form>
      </Modal>
    </div>
  );
};

export default VitalsModal;
