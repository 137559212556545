import React, { useEffect, useState } from "react";
import { Form, Select, Table, Input, Space } from "antd";
import { getDietApi } from "../../../../services/diet.services";
import inputStyles from "../../../../styles/Custom/Input.module.css";

const DietTable = ({ data, onChange, mapToCustomTag, overAllCalc }) => {
  const [tableData, setTableData] = useState(data);
  const [items, setItems] = useState([]);

  useEffect(() => {
    getDietApi(`patination=no_pagination`)
      .then((res) => setItems(res?.data?.data))
      .catch((err) => console.log(err));
  }, []);

  // Handle changes to selected items in the table
  const handleItemChange = (selectedItems, record) => {
    const totalValues = selectedItems.reduce(
      (totals, itemName) => {
        const item = items.find((dietItem) => dietItem.id === itemName);
        if (item) {
          totals.proteins += item.protien || 0;
          totals.calories += item.calories || 0;
          totals.sodium += item.sodium || 0;
          totals.potassium += item.potassium || 0;
          totals.carbohydrate += item.carbohydrates || 0;
        }
        return totals;
      },
      { proteins: 0, calories: 0, sodium: 0, potassium: 0, carbohydrate: 0 }
    );

    const updatedData = tableData.map((data) =>
      data.time === record.time
        ? { ...data, items: selectedItems, ...totalValues, modified: true }
        : data
    );

    setTableData(updatedData);
    onChange(updatedData); // Notify parent component of the change
  };

  const columns = [
    {
      title: "Time",
      dataIndex: "time",
      key: "time",
      width: 80,
      render: (text) => (
        <span>
          {text >= 12
            ? `${text > 12 ? text - 12 : text}:00 PM`
            : `${text}:00 AM`}
        </span>
      ),
    },
    {
      title: "Items",
      dataIndex: "items",
      key: "items",
      width: 300,
      render: (text, record) => (
        <Form.Item name={[record.time, "items"]}>
          <Select
            className={inputStyles.mdSelect}
            style={{ width: "100%" }}
            mode="multiple"
            onChange={(selectedItems) =>
              handleItemChange(selectedItems, record)
            }
          >
            {items.map((item) => (
              <Select.Option key={item.id} value={item.id}>
                {item.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      ),
    },
    { title: "Proteins", dataIndex: "proteins", key: "proteins", width: 80 },
    { title: "Calories", dataIndex: "calories", key: "calories", width: 80 },
    { title: "Sodium", dataIndex: "sodium", key: "sodium", width: 80 },
    { title: "Potassium", dataIndex: "potassium", key: "potassium", width: 80 },
    {
      title: "Carbohydrate",
      dataIndex: "carbohydrate",
      key: "carbohydrate",
      width: 80,
    },
    {
      title: "Comment",
      dataIndex: "comment",
      key: "comment",
      width: 80,
      render: (_, record) => (
        <Form.Item name={[record.time, "comments"]}>
          <Input.TextArea className={inputStyles.mdInput} rows={1} />
        </Form.Item>
      ),
    },
  ];

  return (
    <Table
      title={() => (
        <>
          <Space>Diet Plan {mapToCustomTag(overAllCalc, "purple")}</Space>
        </>
      )}
      dataSource={tableData}
      columns={columns}
      pagination={false}
      rowKey="time"
      style={{ background: "#fff" }}
      scroll={{ y: "calc(100vh - 330px)", x: "400px" }}
    />
  );
};

export default DietTable;
