import React, { useState, useRef, useContext, useEffect } from "react";
import {
  Modal,
  Form,
  message,
  Button,
  Drawer,
  Row,
  Col,
  Typography,
} from "antd";
import btnStyles from "../../../styles/Custom/Button.module.css";
import styles from "../../../styles/Custom/Dashboard/Dashboard.module.css";
import { getCookie } from "../../../helpers/localStorage";
import { FacilityID } from "../../../constants/defaultKeys";
import { UserContext } from "../../../context/UserContext";
import {
  patientInvestigationsOrderAPI,
  patientInvestigationsOrderUpdateAPI,
} from "../../../services/patient.services";
import dayjs from "dayjs";
import InitialInvestigationForm from "./InitialInvestigationForm";
import { CloseOutlined } from "@ant-design/icons";

const InvestigationsUploadModal = ({
  open,
  setOpen,
  refreshPage,
  editData,
  id,
}) => {
  const { userDetails } = useContext(UserContext);
  const [form] = Form.useForm();
  const formRef = useRef(null);
  const [uploadFile, setUploadFile] = useState([]);
  const [uploadFileLoading, setUploadFileLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (editData?.id) {
      form.setFieldsValue({
        ...editData,
        date: dayjs(editData?.investigation_date),
      });
      setUploadFile(editData?.report_path);
    }
  }, [editData?.id]);

  const onFinish = (values) => {
    setLoading(true);
    const formData = {
      facility: Number(getCookie(FacilityID)) || userDetails?.currFacility,
      patient: Number(id),
      ip_admission: Number(id),
      name: values?.name,
      investigation_date: dayjs(values?.date).format("YYYY-MM-DD"),
      report_path: uploadFile,
      is_initial: true,
    };
    const apiCall = editData?.id
      ? patientInvestigationsOrderUpdateAPI(editData?.id, formData)
      : patientInvestigationsOrderAPI(formData);
    apiCall
      .then((res) => {
        message.success(res?.data?.message);
        setLoading(false);
        setOpen(false);
        refreshPage();
      })
      .catch((err) => {
        message.error(err?.response?.data?.message);
        setLoading(false);
      });
  };

  return (
    <Modal
      title={
        <Row>
          <Col span={20}>
            <Typography>
              {editData?.id
                ? "Edit Initial Investigation"
                : "Add Initial Investigation"}
            </Typography>
          </Col>
          <Col span={4} className="flex-end">
            <CloseOutlined onClick={() => setOpen(false)} />
          </Col>
        </Row>
      }
      open={open}
      onCancel={() => setOpen(false)}
      closeIcon={null}
      width={"40%"}
      style={{ top: "8px" }}
      footer={
        <div className="flex-end" style={{ gap: "24px" }}>
          <Form.Item>
            <Button
              type="primary"
              ghost
              className={btnStyles.mdBtn}
              onClick={() => setOpen(false)}
            >
              Cancel
            </Button>
          </Form.Item>
          <Form.Item>
            <Button
              loading={loading}
              disabled={uploadFileLoading}
              htmlType="submit"
              type="primary"
              className={btnStyles.mdBtn}
              onClick={() => {
                if (formRef.current) {
                  formRef.current.submit();
                }
              }}
            >
              Upload
            </Button>
          </Form.Item>
        </div>
      }
    >
      <Form layout="vertical" form={form} ref={formRef} onFinish={onFinish}>
        <InitialInvestigationForm
          setUploadFile={setUploadFile}
          setUploadFileLoading={setUploadFileLoading}
          editData={editData}
          uploadFile={uploadFile}
        />
      </Form>
    </Modal>
  );
};

export default InvestigationsUploadModal;
