import { Button, Col, Drawer, Row, Tabs, Typography } from "antd";
import React, { useContext, useEffect, useState } from "react";
import PsychologistInitialInspectionSummary from "./PsychologistInitialInspectionSummary";
import { CloseOutlined } from "@ant-design/icons";
import PsychologistInitialInspectionForm from "./PsychologistInitialInspectionForm";
import ClosePopup from "../../Doctor/InitialInspection/ClosePopup";
import { getPhysioInspectionAPI } from "../../../../services/physio.services";
import { PsychologistInitialContext } from "./PsychologistInitialAssessmentProvider";
import btnStyles from "../../../../styles/Custom/Button.module.css";
import { getPsychologistInspectionAPI } from "../../../../services/psychologist.services";
import { getLabel } from "../../InspectionSummaries/constructTextFromValues";
import NoDataPage from "../../../NoDataPages/NoDataPage";
import assessmentIcon from "../../../../assets/images/nurseIcons/assessment.svg";

const PsychologistInitialInspection = ({
  patientData,
  editId,
  refreshPage,
}) => {
  const [visible, setVisible] = useState(false);
  const { formData, setFormData } = useContext(PsychologistInitialContext);
  const [initialRefresh, setInitialRefresh] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [addModal, setAddModal] = useState(false);
  const [activeTab, setActiveTab] = useState("1");
  const [isDirty, setIsDirty] = useState(false);

  const setInitialAssessmentRefresh = () => {
    setInitialRefresh((prev) => !prev);
  };

  const openEdit = async () => {
    if (editId) {
      try {
        const response = await getPsychologistInspectionAPI(editId);
        setFormData(response.data.data);
      } catch (error) {
        console.log(`Error: ${error}`);
      }
    }
  };

  useEffect(() => {
    openEdit();
  }, [editId, initialRefresh]);
  console.log("patient", patientData);
  return (
    <>
      {editId ? (
        <Row gutter={[12, 12]}>
          <Col span={24} style={{ textAlign: "right" }}>
            <Button
              type="primary"
              onClick={() => {
                setVisible(true);
                setAddModal(false);
              }}
              className={`${btnStyles.lgBtn} inv-btn`}
            >
              Edit
            </Button>
          </Col>
          <Col span={24} style={{ textAlign: "center" }}>
            <PsychologistInitialInspectionSummary editId={editId} />
          </Col>
        </Row>
      ) : (
        <Row
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            minHeight: "80vh",
          }}
        >
          <Col span={24} style={{ textAlign: "center" }}>
            <NoDataPage
              title={"Initial Assessment Not Yet Submitted "}
              subtitle={"Kindly, upload an initial assessment here"}
              image={assessmentIcon}
            />
            <Button
              type="primary"
              onClick={() => {
                setVisible(true);
                setAddModal(true);
              }}
              className={`${btnStyles.lgBtn} inv-btn`}
            >
              Initial Assessment Forms
            </Button>
          </Col>
        </Row>
      )}
      {visible && (
        <Drawer
          open={visible}
          width="100%"
          footer={null}
          onClose={() => setVisible(false)}
          closeIcon={null}
          keyboard={false}
        >
          <PsychologistInitialInspectionForm
            visible={visible}
            setVisible={setVisible}
            refreshPage={refreshPage}
            initialRefresh={setInitialAssessmentRefresh}
            editId={editId}
            patientData={patientData}
            setConfirmModal={setConfirmModal}
          />

          {confirmModal && (
            <ClosePopup
              open={confirmModal}
              setOpen={setConfirmModal}
              setVisible={setVisible}
            />
          )}
        </Drawer>
      )}
    </>
  );
};

export default PsychologistInitialInspection;
