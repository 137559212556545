import React from "react";
import { useLocation } from "react-router-dom";
import DoctorLayout from "../Layout/DoctorLayout";
import PatientsIP from "../PatientsIP/PatientsIP";
import NurseCards from "./NurseCards";
import PatientTypeContextProvider from "../Layout/DoctorLayout/PatientTypeContextProvider";

const NurseView = () => {
  const location = useLocation();
  const params = Object.fromEntries(new URLSearchParams(location?.search));

  return (
    <PatientTypeContextProvider>
      {params?.patient ? (
        <DoctorLayout>
          <PatientsIP />
        </DoctorLayout>
      ) : (
        <DoctorLayout header={true}>
          <NurseCards />
        </DoctorLayout>
      )}
    </PatientTypeContextProvider>
  );
};

export default NurseView;
