import React, { useContext, useEffect, useState } from "react";
import {
  eatingAssessmentOptions,
  eatingAssessmentRangeOptions,
} from "../../../Options/speechSwallowOptions";
import { Form, Input, Radio, Typography } from "antd";
import { SpeechReviewContext } from "../../../SpeechAndSwallowTherapist/Review/SpeechSwallowReviewFormProvider";

const EatingAssessmentTool = ({ form }) => {
  const { formData } = useContext(SpeechReviewContext);
  const [states, setStates] = useState({});

  const handleChange = (key, value) => {
    setStates((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const calculateScore = () => {
    const score = Object.values(states).reduce((acc, cur) => acc + cur, 0);
    form.setFieldsValue({ eating_assessment: { score: score } });
    return score;
  };

  useEffect(() => {
    if (formData?.scales?.eating_assessment) {
      const filteredEntries = Object.entries(
        formData.scales.eating_assessment
      ).filter(([key]) => key !== "score");
      setStates(Object.fromEntries(filteredEntries));
    }
  }, [formData]);

  const score = calculateScore();

  return (
    <>
      <Typography.Title level={5}>
        Eating Assessment Tool (EAT-10): {score}
      </Typography.Title>
      <Form.Item
        name={["eating_assessment", "score"]}
        hidden
        initialValue={score}
      >
        <Input value={score} />
      </Form.Item>
      {eatingAssessmentOptions.map((option) => (
        <Form.Item
          label={option.label}
          name={["eating_assessment", option.value]}
          key={option.value}
        >
          <Radio.Group
            options={eatingAssessmentRangeOptions}
            onChange={(e) => handleChange(option.value, e.target.value)}
          />
        </Form.Item>
      ))}
    </>
  );
};

export default EatingAssessmentTool;
