import React, { useRef, useState } from "react";
import { Form, Button, message } from "antd";
import DietTable from "./DietTable";
import {
  afternoonKeys,
  customTag,
  eveningKeys,
  morningKeys,
  transformData,
} from "./nutritionistKeys";
import { addPatientDietItemsApi } from "../../../../services/nutritionist.services";
import btnStyles from "../../../../styles/Custom/Button.module.css";
import useQuery from "../../../../hooks/useQuery";

const NurtritionistDietChart = ({ refresh }) => {
  const params = useQuery();
  const [form] = Form.useForm();
  const formRef = useRef(null);
  const [dietData, setDietData] = useState([]);

  // Handle changes to the diet table
  const handleTableChange = (updatedData) => {
    setDietData(updatedData);
  };

  // Submit the form data
  const onFinish = (values) => {
    addPatientDietItemsApi(params?.patient, transformData(values))
      .then((res) => {
        message.success(res.data.message);
        refresh();
      })
      .catch((err) => message.error(err.response.data.message));
  };

  // Calculate totals for a given diet data
  const calculateDietTotals = (data) => {
    return data.reduce(
      (totals, item) => {
        totals.proteins += item.proteins || 0;
        totals.calories += item.calories || 0;
        totals.sodium += item.sodium || 0;
        totals.potassium += item.potassium || 0;
        totals.carbohydrate += item.carbohydrate || 0;
        return totals;
      },
      { proteins: 0, calories: 0, sodium: 0, potassium: 0, carbohydrate: 0 }
    );
  };

  // Map diet data to custom tags
  const mapToCustomTag = (data, color) => {
    return Object.entries(data).map(([key, value]) =>
      customTag(key, value, color)
    );
  };

  const overAllCalc = calculateDietTotals(dietData);

  return (
    <Form onFinish={onFinish} form={form} ref={formRef}>
      <DietTable
        mapToCustomTag={mapToCustomTag}
        overAllCalc={overAllCalc}
        data={[...morningKeys, ...afternoonKeys, ...eveningKeys]}
        onChange={handleTableChange}
      />

      <Form.Item style={{ textAlign: "right", marginTop: "10px" }}>
        <Button type="primary" htmlType="submit" className={btnStyles.mdBtn}>
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default NurtritionistDietChart;
