import React, { useContext, useEffect, useState } from "react";
import CommonLayout from "../Layout/CustomLayout";
import DoctorLayout from "../Layout/DoctorLayout/index";
import SideMenu from "../Layout/CustomLayout/SideMenu";
import OrgTabs from "./OrgTabs";
import textStyles from "../../styles/Custom/Typography.module.css";
import styles from "../../styles/Custom/Dashboard/Dashboard.module.css";
import inputStyles from "../../styles/Custom/Input.module.css";
import btnStyles from "../../styles/Custom/Button.module.css";
import {
  Typography,
  Card,
  Form,
  Input,
  Upload,
  Space,
  Button,
  Image,
  Avatar,
  message,
  Spin,
} from "antd";
import { UserContext } from "../../context/UserContext";
import { colors, convertToFormData, orgColors } from "../../helpers/utility";
import { updateOrgDetailsApi } from "../../services/org.services";
import {
  downloadUrlApi,
  getUploadUrlApi,
} from "../../services/common.services";
import axios from "axios";
import Circle from "@uiw/react-color-circle";
import { userMetaDataApi } from "../../services/auth.services";
import { getCookie, setItem } from "../../helpers/localStorage";
import PatientTypeContextProvider from "../Layout/DoctorLayout/PatientTypeContextProvider";

const OrgDetails = ({ sideContent }) => {
  const { userDetails, setUserDetails } = useContext(UserContext);
  const [displayLogo, setDisplayLogo] = useState(null);
  const [updateDetails, setUpdateDetails] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const orgLogo = userDetails?.activeOrg?.org_logo;
  const numericUserId = Number(userDetails?.activeOrg?.org_id);
  const colorIndex = numericUserId % 24;
  const selectedColor = colors[colorIndex];
  const [hex, setHex] = useState(userDetails?.activeOrg?.color);

  const isOrgAdminOrOwner =
    userDetails?.activeOrg?.default_role === "Organization_Owner" ||
    userDetails?.activeOrg?.default_role === "Organization_Admin";

  const getUserData = async () => {
    try {
      let resp = await userMetaDataApi();
      const orgId = getCookie("organisation_id");
      const profile = orgId
        ? resp?.data?.organizations?.filter((org) => org?.org_id == orgId)?.[0]
        : {};
      setUserDetails({
        ...resp?.data,
        logo: profile?.org_logo ? `${profile?.org_logo}` : null,
        activeOrg: orgId
          ? resp?.data?.organizations?.filter(
              (org) => org?.org_id == orgId
            )?.[0]
          : {},
      });
    } catch (error) {
      console.log(error);
    }
  };
  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };
  const updateProfile = async (logo) => {
    const payload = { user: userDetails?.id, logo: logo };
    try {
      const resp = await updateOrgDetailsApi(payload);
      if (resp) {
        message.success(resp?.data?.message || "Details updated");
        getUserData();

        if (logo) {
          downloadPic(logo);
        }
      }
    } catch (error) {
      message.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again"
      );
    }
  };
  async function uploadProfilePicFunc(info) {
    setLoading(true);
    if (info.file.status === "done") {
      try {
        const res = await getUploadUrlApi(
          `path_key=${info?.file?.name}&used_for=logo`
        );
        const formData = convertToFormData(res, info);
        await axios({
          method: "POST",
          url: res?.data?.url,
          data: formData,
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        if (res?.data?.fields?.key) {
          updateProfile(res?.data?.fields?.key);
        }
      } catch (err) {
        console.log(err, "err");
        message.error(err?.response?.data?.message);
      } finally {
        setLoading(false);
      }
    }
  }
  const downloadPic = (profile) => {
    downloadUrlApi(`path_key=${profile}&used_for=logo`)
      .then((res) => {
        setDisplayLogo(res?.data);
        setItem(
          "org_logo",
          JSON.stringify({
            path: res?.data,
            key: profile,
          })
        );
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    // downloadPic(orgLogo);
    setDisplayLogo(userDetails?.activeOrg?.org_logo);
  }, []);
  const onFinish = async (values) => {
    let payload = {
      ...values,
      user: userDetails?.id,
      color: hex,
    };
    try {
      const resp = await updateOrgDetailsApi(payload);
      if (resp) {
        setUpdateDetails(false);
        message.success(resp?.data?.message || "Details updated");
        getUserData();
        setHex(hex);
      }
    } catch (error) {
      console.log(error);
      message.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again"
      );
    }
  };
  const commonOrg = () => {
    return (
      <Card
        style={{ width: "50%", margin: "auto" }}
        className={`${styles.personalDetailsCard} centeredCard`}
      >
        <Form
          layout="vertical"
          // requiredMark={false}
          form={form}
          initialValues={{
            name: userDetails?.activeOrg?.org_name,
          }}
          onValuesChange={() => setUpdateDetails(true)}
          onFinish={onFinish}
        >
          <Form.Item name="name" label="Organisation Name">
            <Input
              disabled={!isOrgAdminOrOwner}
              placeholder="Enter organisation name"
              style={{ width: "100%" }}
              className={inputStyles.mdInput}
            />
          </Form.Item>
          <Form.Item label="Logo">
            <Space>
              {orgLogo || displayLogo ? (
                loading ? (
                  <Spin />
                ) : (
                  <Image
                    preview={false}
                    src={displayLogo}
                    alt={"Profile Pic"}
                    width={130}
                    height={130}
                    style={{
                      height: "130px",
                      width: "130px",
                      // borderRadius: "100%",
                    }}
                  />
                )
              ) : (
                <Avatar
                  size={130}
                  style={{
                    fontSize: "70px",
                    background: selectedColor,
                  }}
                >
                  {userDetails?.activeOrg?.org_name?.slice(0, 1).toUpperCase()}
                </Avatar>
              )}
              <Image />
              {isOrgAdminOrOwner && (
                <Upload
                  onChange={(info) => uploadProfilePicFunc(info)}
                  customRequest={dummyRequest}
                  showUploadList={false}
                  accept="image/*"
                >
                  <Button type="primary">Upload Logo</Button>
                </Upload>
              )}
            </Space>
          </Form.Item>
          {isOrgAdminOrOwner && (
            <Form.Item label="Choose Color" name="color">
              <Circle
                colors={orgColors}
                color={hex}
                pointProps={{
                  style: {
                    marginRight: 20,
                  },
                }}
                onChange={(color) => {
                  setHex(color.hex);
                }}
              />
            </Form.Item>
          )}
        </Form>
      </Card>
    );
  };
  return (
    <PatientTypeContextProvider>
      {userDetails?.activeOrg?.default_role === "Organization_Owner" ||
      userDetails?.activeOrg?.default_role === "Organization_Admin" ||
      userDetails?.activeOrg?.default_role === "Facility_Admin" ||
      userDetails?.activeOrg?.default_role === "Receptionist" ? (
        <CommonLayout
          sideContent={sideContent}
          header={
            <div
              style={{
                gap: "5%",
                width: "100%",
              }}
              className="flex-start"
            >
              <Typography className={textStyles.h6}>
                Organisation Profile
              </Typography>
              <OrgTabs />
            </div>
          }
          footer={
            updateDetails && (
              <div className="flex-end" style={{ gap: "24px" }}>
                <Button
                  className={btnStyles.mdBtn}
                  onClick={() => setUpdateDetails(false)}
                >
                  Cancel
                </Button>

                <Button
                  type="primary"
                  onClick={() => form.submit()}
                  className={btnStyles.mdBtn}
                >
                  Save
                </Button>
              </div>
            )
          }
        >
          {commonOrg()}
        </CommonLayout>
      ) : (
        <DoctorLayout
          sideContent={<SideMenu />}
          header={
            <div
              style={{
                gap: "5%",
                width: "900px",
              }}
              className="flex-start"
            >
              <Typography className={textStyles.h6}>
                Organisation Profile
              </Typography>
              <OrgTabs />
            </div>
          }
          footer={
            updateDetails && (
              <div className="flex-end" style={{ gap: "24px" }}>
                <Button
                  className={btnStyles.mdBtn}
                  onClick={() => setUpdateDetails(false)}
                >
                  Cancel
                </Button>

                <Button
                  type="primary"
                  onClick={() => form.submit()}
                  className={btnStyles.mdBtn}
                >
                  Save
                </Button>
              </div>
            )
          }
        >
          {commonOrg()}
        </DoctorLayout>
      )}
    </PatientTypeContextProvider>
  );
};

export default OrgDetails;
