import React, { useContext, useEffect, useState } from "react";
import CommonLayout from "../Layout/CustomLayout";
import DoctorLayout from "../Layout/DoctorLayout/index";
import SideMenu from "../Layout/CustomLayout/SideMenu";
import OrgTabs from "./OrgTabs";
import textStyles from "../../styles/Custom/Typography.module.css";
import { Typography, Card, Button, Input, Form, message } from "antd";
import styles from "../../styles/Custom/Dashboard/Dashboard.module.css";
import inputStyles from "../../styles/Custom/Input.module.css";
import btnStyles from "../../styles/Custom/Button.module.css";
import { UserContext } from "../../context/UserContext";
import { updateOrgDetailsApi } from "../../services/org.services";
import PatientTypeContextProvider from "../Layout/DoctorLayout/PatientTypeContextProvider";

const OrgContact = ({ data, refreshPage, sideContent }) => {
  const { userDetails, setUserDetails } = useContext(UserContext);
  const [details, setDetails] = useState(false);
  const [form] = Form.useForm();
  const onFinish = (values) => {
    updateOrgDetailsApi(values)
      .then((res) => {
        refreshPage();
        message.success(res?.data?.message);
      })
      .catch((err) => {
        message.error(err?.response?.data?.message);
      });
  };
  const isOrgAdminOrOwner =
    userDetails?.activeOrg?.default_role === "Organization_Owner" ||
    userDetails?.activeOrg?.default_role === "Organization_Admin";
  useEffect(() => {
    if (data) {
      form.setFieldsValue(data);
    }
  }, [data]);

  const commonOrg = () => {
    return (
      <Card
        style={{ width: "50%", margin: "auto" }}
        className={`${styles.personalDetailsCard} centeredCard`}
      >
        <Form
          onFinish={onFinish}
          onFinishFailed={(values) => console.log(values)}
          onValuesChange={() => setDetails(true)}
          style={{ marginTop: "24px" }}
          layout="vertical"
          form={form}
          // requiredMark={false}
        >
          <Form.Item name="address" label="Location">
            <Input
              disabled={!isOrgAdminOrOwner}
              placeholder="Enter location"
              style={{ width: "100%" }}
              className={inputStyles.mdInput}
            />
          </Form.Item>
          <Form.Item name="email" label="Email">
            <Input
              disabled={!isOrgAdminOrOwner}
              placeholder="Enter email"
              style={{ width: "100%" }}
              className={inputStyles.mdInput}
            />
          </Form.Item>
          <Form.Item
            name="phone"
            label="Contact number"
            rules={[
              {
                pattern: /^[0-9]{10}$/,
                message: "Please enter a valid 10-digit phone number",
              },
            ]}
          >
            <Input
              disabled={!isOrgAdminOrOwner}
              maxLength="10"
              addonBefore={"+91"}
              placeholder="Enter contact number"
              style={{ width: "100%" }}
              className={inputStyles.mdAddonInput}
            />
          </Form.Item>
        </Form>
      </Card>
    );
  };

  return (
    <PatientTypeContextProvider>
      {userDetails?.activeOrg?.default_role === "Organization_Owner" ||
      userDetails?.activeOrg?.default_role === "Organization_Admin" ||
      userDetails?.activeOrg?.default_role === "Facility_Admin" ||
      userDetails?.activeOrg?.default_role === "Receptionist" ? (
        <CommonLayout
          sideContent={sideContent}
          header={
            <div
              style={{
                gap: "5%",
                width: "100%",
              }}
              className="flex-start"
            >
              <Typography className={textStyles.h6}>
                {" "}
                Organisation Profile
              </Typography>
              <OrgTabs />
            </div>
          }
          footer={
            details && (
              <div className="flex-end" style={{ gap: "24px" }}>
                <Button
                  className={btnStyles.mdBtn}
                  onClick={() => setDetails(false)}
                >
                  Cancel
                </Button>

                <Button
                  type="primary"
                  onClick={() => form.submit()}
                  className={btnStyles.mdBtn}
                >
                  Save
                </Button>
              </div>
            )
          }
        >
          {commonOrg()}
        </CommonLayout>
      ) : (
        <DoctorLayout
          sideContent={<SideMenu />}
          header={
            <div
              style={{
                gap: "5%",
                width: "900px",
              }}
              className="flex-start"
            >
              <Typography className={textStyles.h6}>
                {" "}
                Organisation Profile
              </Typography>
              <OrgTabs />
            </div>
          }
          footer={
            details && (
              <div className="flex-end" style={{ gap: "24px" }}>
                <Button
                  className={btnStyles.mdBtn}
                  onClick={() => setDetails(false)}
                >
                  Cancel
                </Button>

                <Button
                  type="primary"
                  onClick={() => form.submit()}
                  className={btnStyles.mdBtn}
                >
                  Save
                </Button>
              </div>
            )
          }
        >
          {commonOrg()}
        </DoctorLayout>
      )}
    </PatientTypeContextProvider>
  );
};

export default OrgContact;
