import React, { useEffect, useState } from "react";
import {
  Checkbox,
  Form,
  Input,
  InputNumber,
  message,
  Pagination,
  Table,
  Tag,
  Typography,
} from "antd";
import inputStyles from "../../../styles/Custom/Input.module.css";
import tagStyles from "../../../styles/Custom/Tag.module.css";
import { updateMedicationApi } from "../../../services/medication.services";
import checkboxStyles from "../../../styles/Custom/Checkbox.module.css";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode =
    inputType === "number" ? (
      <InputNumber className={inputStyles.mdNumberInput} />
    ) : inputType === "check" ? (
      <Checkbox className={checkboxStyles.mdCheck}>Antibiotic</Checkbox>
    ) : (
      <Input className={inputStyles?.mdInput} />
    );

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          valuePropName={inputType === "check" ? "checked" : "value"}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: record?.key === "name" && true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const MedicationTable = ({
  loading,
  data,
  setData,
  page,
  setPage,
  refreshPage,
  userDetails,
}) => {
  const [form] = Form.useForm();

  const [editingKey, setEditingKey] = useState("");

  const isEditing = (record) => record?.id === editingKey?.id;

  const edit = (record) => {
    form.setFieldsValue({
      name: "",
      protien: "",
      calories: "",
      ...record,
    });
    setEditingKey(record);
  };
  const cancel = () => {
    setEditingKey("");
  };
  const save = async (record) => {
    try {
      const updatedValues = await form.validateFields();
      const newData = [...data?.data];
      const index = newData.findIndex((item) => record.id === item.id);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, { ...item, ...updatedValues });
        setData(newData);
        const res = await updateMedicationApi(record.id, updatedValues);
        refreshPage();
        message.success(res?.data?.message);
        setEditingKey("");
      } else {
        message.error("Row not found");
      }
    } catch (err) {
      console.error("Error:", err);
      message.error("Error updating medication");
    }
  };
  const columns = [
    {
      title: "Medicine Name",
      dataIndex: "name",
      editable: true,
      width: "15%",
      sorter: (a, b) => a?.name.localeCompare(b?.name),
      render: (value, record) =>
        record?.is_antiboitic ? (
          <Tag className={tagStyles.mdRndTag} color="magenta">
            {value}
          </Tag>
        ) : (
          value
        ),
    },
    {
      title: "Drug 1 Name",
      dataIndex: "drug1_name",
      editable: true,
      width: "10%",
      render: (value) => (value ? value : "-"),
      sorter: (a, b) => a?.drug1_name.localeCompare(b?.drug1_name),
    },
    {
      title: "Drug 2 Name",
      dataIndex: "drug2_name",
      editable: true,
      width: "10%",
      render: (value) => (value ? value : "-"),
      sorter: (a, b) => a?.drug2_name.localeCompare(b?.drug2_name),
    },
    // {
    //   title: "Description",
    //   dataIndex: "description",
    //   editable: true,
    //   width: "20%",
    //   render: (value) =>
    //     value ? (
    //       <Paragraph
    //         ellipsis={{
    //           rows: 2,
    //           expandable: true,
    //           symbol: "more",
    //         }}
    //         style={{ width: 320 }}
    //       >
    //         {value}
    //       </Paragraph>
    //     ) : (
    //       "-"
    //     ),
    // },
    // {
    //   title: "Price",
    //   dataIndex: "price",
    //   editable: true,
    //   width: "5%",
    // },
    {
      title: "Is antibiotic",
      dataIndex: "is_antiboitic",
      editable: true,
      width: "10%",
      render: (value) =>
        value ? (
          <CheckCircleOutlined style={{ color: "green", fontSize: "18px" }} />
        ) : (
          <CloseCircleOutlined style={{ color: "red", fontSize: "18px" }} />
        ),
    },
    ...(userDetails?.activeOrg?.default_role === "Facility_Admin"
      ? []
      : [
          {
            title: "Action",
            dataIndex: "operation",
            width: "5%",
            render: (_, record) => {
              const editable = isEditing(record);
              return editable ? (
                <span>
                  <Typography.Link
                    onClick={() => save(record)}
                    style={{
                      marginRight: 8,
                    }}
                  >
                    Save
                  </Typography.Link>
                  <Typography.Link
                    onClick={cancel}
                    style={{
                      marginRight: 8,
                    }}
                  >
                    Cancel
                  </Typography.Link>
                </span>
              ) : (
                <Typography.Link
                  disabled={editingKey !== ""}
                  onClick={() => edit(record)}
                >
                  Edit
                </Typography.Link>
              );
            },
          },
        ]),
  ];
  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType:
          col.dataIndex === "price"
            ? "number"
            : col.dataIndex === "is_antiboitic"
            ? "check"
            : "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });
  return (
    <>
      <Form form={form} component={false}>
        <Table
          loading={loading}
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          bordered
          dataSource={data?.data}
          columns={mergedColumns}
          rowClassName="editable-row"
          pagination={false}
          scroll={{ y: "calc(100vh - 300px)", x: 0 }}
        />
      </Form>
      {data?.total_count > 100 && (
        <div className="flex-center" style={{ paddingTop: "10px" }}>
          <Pagination
            current={page}
            pageSize={100}
            total={data?.total_count}
            onChange={(e) => setPage(e)}
            showSizeChanger={false}
          />
        </div>
      )}
    </>
  );
};
export default MedicationTable;
