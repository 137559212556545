import React, { useState, useCallback } from "react";
import { AutoComplete, Form, Input, Row, Col, Tag } from "antd";
import { capitalizeFirstLetter, debounce } from "../../helpers/utility";
import { opSearchPatientsApi } from "../../services/receptionist.services";

const setSearchOptions = (data) => {
  const searchOptions = data?.map((options, index) => {
    return {
      key: options?.id,
      value: options?.id,
      label: (
        <Row>
          <Col span={10}>
            <span>{options?.mobile}</span>
            <Tag color="blue">
              {capitalizeFirstLetter(options?.first_name) +
                " " +
                capitalizeFirstLetter(options?.last_name)}
            </Tag>
          </Col>
        </Row>
      ),
    };
  });

  return searchOptions;
};

const PatientMobileSearch = ({
  searchedText,
  setSearchedText,
  setSelectedId,
  id,
  form,
  appointment = false,
}) => {
  const [data, setData] = useState(null);

  const handleSearch = (searchedValue) => {
    opSearchPatientsApi(`mobile=${searchedValue}`)
      .then((res) => {
        setData(setSearchOptions(res?.data?.data));
      })
      .catch((err) => console.log(err));
  };

  const optimizedSearch = useCallback(debounce(handleSearch, 300), []);

  const handleSelect = (value, option) => {
    setSearchedText(value);
    setSelectedId(option?.key);
  };

  return (
    <>
      <Form.Item
        name={appointment ? "patient_mobile" : "mobile"}
        // label="Enter Mobile Number"
        rules={[
          { required: true, message: "Please select or enter mobile number" },
        ]}
      >
        <AutoComplete
          maxLength={10}
          disabled={id}
          options={data}
          className="search"
          onSearch={optimizedSearch}
          onSelect={handleSelect}
          onChange={(value) => {
            setSearchedText(value);
            optimizedSearch(value);
            setSelectedId(null);
          }}
          value={searchedText}
          style={{ width: "300px", marginBottom: "10px" }}
        >
          <Input
            disabled={id}
            size="large"
            addonBefore="+91"
            placeholder="Enter Mobile Number"
            style={{ width: "300px" }}
          />
        </AutoComplete>
      </Form.Item>
    </>
  );
};

export default PatientMobileSearch;
