import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AutoComplete, Col, Row, Tag, Typography } from "antd";

import { debounce } from "../../../helpers/utility";
import { patientSearchApi } from "../../../services/search.service";
import { UserContext } from "../../../context/UserContext";
import { SearchOutlined } from "@ant-design/icons";

const searchResult = (data) =>
  data?.map((item, idx) => {
    return {
      value: item?.id,
      label: (
        <Row key={idx} gutter={[8, 8]}>
          <Col span={14}>
            <Typography.Paragraph ellipsis style={{ marginBottom: "0px" }}>
              {item?.patient_name}
            </Typography.Paragraph>
          </Col>
          <Col span={5}>
            <Tag>IP ID: {item?.ip_id}</Tag>
          </Col>
          <Col span={5}>
            <Tag color={item?.is_active ? "blue" : "cyan"}>
              {item?.is_active ? "Active" : "Discharged"}
            </Tag>
          </Col>
        </Row>
      ),
    };
  });

function Search({ selectedFacility }) {
  const navigate = useNavigate();
  const [value, setValue] = useState("");
  const [options, setOptions] = useState([]);
  const { userDetails } = useContext(UserContext);
  const role = userDetails?.activeOrg?.default_role
    .toLowerCase()
    .replaceAll("_", "-");
  async function handleSearch(value) {
    if (value?.length >= 1)
      try {
        const response = await patientSearchApi({
          search: value,
          facility: selectedFacility,
        });
        setOptions(value ? searchResult(response?.data) : []);
      } catch (error) {
        console.log(error);
      }
  }

  function onSelect(value) {
    navigate(`/${role}/home?patient=${value}&type=landing`);
    setValue("");
  }

  return (
    <AutoComplete
      suffixIcon={<SearchOutlined />}
      value={value}
      options={options}
      onSelect={onSelect}
      onChange={setValue}
      style={{ width: "600px" }}
      onSearch={debounce(handleSearch, 300)}
      placeholder="Patient Name or Phone Number or Bed No or IP Id"
    />
  );
}

export default Search;
