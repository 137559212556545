import React, { useContext, useState, useEffect } from "react";
import { Form, Radio, Input } from "antd";
import { noYesOptions } from "../../../../Options/commonOptions";
import { DoctorInitialContext } from "../../../HistoryForm/DoctorHistoryFormProvider";

const ExternalSplints = () => {
  const { formData } = useContext(DoctorInitialContext);
  const [selectedSplints, setSelectedSplints] = useState(
    formData?.general_examination?.external_splints === "yes" ? "yes" : "no"
  );
  useEffect(() => {
    setSelectedSplints(
      formData?.general_examination?.external_splints === "yes" ? "yes" : "no"
    );
  }, [formData]);
  return (
    <>
      <Form.Item label="External Splints" name="external_splints">
        <Radio.Group
          options={noYesOptions}
          onChange={(e) => setSelectedSplints(e.target.value)}
          value={selectedSplints}
        />
      </Form.Item>
      {selectedSplints === "yes" && (
        <Form.Item name="splints_details">
          <Input style={{ width: "50%" }} />
        </Form.Item>
      )}
    </>
  );
};

export default ExternalSplints;
