import React, { useEffect, useState } from "react";
import { Button, Form, Typography, message, Input } from "antd";
import "./PhysioScales.css";
import {
  barthelIndexAPI,
  getBarthelIndexAPI,
  updateBarthelIndexAPI,
} from "../../../../../services/physio.services";
import BarthelIndexItems from "./BarthelIndexComponents/BarthelIndexItems";
import BarthelIndexPdf from "../../../../../assets/scalesInfo/BarthelIndexInfo.pdf";
import ScalesInfoViewer from "./ScalesInfoViewer";

const scaleInfo = {
  name: "Barthel Index",
  type: "pdf",
  url: BarthelIndexPdf,
};

const BarthelIndex = ({
  admission,
  onScalesSubmit,
  scalesId,
  setScalesId,
  setActiveTab,
  setVisible,
}) => {
  const [form] = Form.useForm();
  const [states, setStates] = useState({});
  const [openInfo, setOpenInfo] = useState(false);

  const aggregate = Object.values(states).reduce(
    (acc, curr) => acc + (curr || 0),
    0
  );

  const interpretation =
    aggregate < 20
      ? "Totally Dependent"
      : aggregate < 40
      ? "Very Dependent"
      : aggregate < 60
      ? "Partially Dependent"
      : aggregate < 80
      ? "Minimally Dependent"
      : "Independent";

  const handleSubmitWithAction = async (values, action) => {
    const finalData = {
      ...values,
      score: aggregate,
      interpretation: interpretation,
      ip_admission: admission,
    };
    try {
      if (scalesId?.barthel_index) {
        await updateBarthelIndexAPI(scalesId?.barthel_index, finalData);
      } else {
        const response = await barthelIndexAPI(finalData);
        setScalesId((prev) => {
          const newScalesId = { ...prev, barthel_index: response?.data?.id };
          onScalesSubmit(newScalesId);
          return newScalesId;
        });
      }
      // message.success("Form filled successfully");
      if (action === "continue") {
        setActiveTab("3");
      } else if (action === "close") {
        setVisible(false);
      }
    } catch (error) {
      console.log(`Error :${error}`);
      message.error("Failed to load data");
    }
  };

  const handleView = async () => {
    if (scalesId?.barthel_index) {
      getBarthelIndexAPI(admission, scalesId?.barthel_index)
        .then((response) => {
          form.setFieldsValue(response.data);
          setStates(
            Object.fromEntries(
              Object.entries(response.data).filter(([key]) =>
                [
                  "feeding",
                  "bathing",
                  "grooming",
                  "dressing",
                  "bowels",
                  "bladder",
                  "toilet_use",
                  "transfers",
                  "mobility",
                  "stairs",
                ].includes(key)
              )
            )
          );
        })
        .catch((error) => {
          console.error(`Error: ${error}`);
        });
    }
  };

  useEffect(() => {
    handleView();
  }, [admission]);

  const handleChange = (category, value) => {
    setStates((prev) => ({ ...prev, [category]: value }));
  };

  return (
    <>
      <div className="cardless-scale">
        <Button
          className="scale-info"
          onClick={() => {
            setOpenInfo(true);
          }}
        >
          Info
        </Button>
        <Typography.Text className="score-counter">
          {aggregate}
          <br />
          —
          <br />
          100
          <div className="score-string">{interpretation}</div>
        </Typography.Text>
        <Form
          id="barthel-index"
          onFinish={(values) => handleSubmitWithAction(values, "continue")}
          form={form}
        >
          <BarthelIndexItems handleChange={handleChange} states={states} />

          <Form.Item className="scales-comments-formitem" name="comments">
            <Input.TextArea rows={4} placeholder="Comments" />
          </Form.Item>
        </Form>
      </div>
      <div className="scale-footer">
        <Button
          size="large"
          style={{ marginRight: "8px" }}
          onClick={() =>
            form
              .validateFields()
              .then((values) => handleSubmitWithAction(values, "close"))
          }
        >
          Close
        </Button>

        <Button size="large" type="primary" onClick={() => form.submit()}>
          Continue
        </Button>
        {openInfo && (
          <ScalesInfoViewer
            data={scaleInfo}
            open={openInfo}
            setOpen={setOpenInfo}
          />
        )}
      </div>
    </>
  );
};

export default BarthelIndex;
