import { Col, Row, Typography } from "antd";
import React, { useContext, useEffect, useState } from "react";
import CheckListFBYRadioItems from "../../../../../blocks/FormItems/CheckListFBYRadioItems";
import {
  dailyActivityDependenceOptions,
  dailyActivityOptions,
} from "../../../Options/functionalAsessmentOptions";

const DailyActivities = ({ context }) => {
  const { formData } = useContext(context);
  const [checkboxes, setCheckBoxes] = useState([]);
  useEffect(() => {
    const initialCheckboxes = dailyActivityOptions.reduce((acc, checkbox) => {
      acc[checkbox.value] =
        formData?.functional_status?.daily_activities?.[checkbox.value] ||
        false;
      return acc;
    }, {});
    setCheckBoxes(initialCheckboxes);
  }, [formData]);
  return (
    <>
      <Typography>Daily Activities</Typography>
      <div className="subsection-card">
        <CheckListFBYRadioItems
          checkBoxOptions={dailyActivityOptions}
          radioOptions={dailyActivityDependenceOptions}
          checkboxes={checkboxes}
          setCheckboxes={setCheckBoxes}
          keyChain={"daily_activities"}
        />
      </div>
    </>
  );
};

export default DailyActivities;
