import React from "react";
import { Tag } from "antd";
import dayjs from "dayjs";
import { fractureTypeOptions } from "../Options/systemicExamOptions";
import "./InspectionSummaries.css";

export const getLabelTagYesNO = (value) => {
  return value === "no" ? (
    <Tag bordered={false} color="red">
      {"No"}
    </Tag>
  ) : null;
};

export const getLabel = (label) => {
  return (
    <Tag bordered={false} className="break-line-tag">
      {label}
    </Tag>
  );
};

export const getLabelTagInput = (label, value, after = null) => {
  return value || value === 0 ? (
    <Tag bordered={false} className="break-line-tag" >
      {label}: {value} {after ? after : ""}
    </Tag>
  ) : null;
};

const formatString = (str) => {
  return str
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

export const getGussSummary = (data = {}) => {
  const tags = [];
  for (const [key, value] of Object.entries(data)) {
    const formattedKey = formatString(key);
    if (typeof value === "string") {
      tags.push(getLabelTagInput(formattedKey, formatString(value)));
    } else if (typeof value === "object") {
      for (const [subKey, subValue] of Object.entries(value)) {
        const formattedSubKey = formatString(subKey);
        tags.push(
          getLabelTagInput(`${formattedKey} - ${formattedSubKey}`, subValue)
        );
      }
    }
  }
  return tags;
};

export const getLabelTagTable = (array = []) => {
  return array.map((row, index) => {
    let tagContent = Object.entries(row)
      .filter(([key, value]) => key !== "key" && key !== "action")
      .map(([key, value]) => {
        const formattedKey = formatString(key);
        const formattedValue =
          typeof value === "string" ? formatString(value) : value;
        return `${formattedKey}: ${formattedValue}`;
      })
      .join(", ");
    return (
      <Tag bordered={false} className="break-line-tag" key={index}>
        {tagContent}
      </Tag>
    );
  });
};
//Used for radio boxes
export const getLabelTag = (options = [], value = "") => {
  const label = options.find((option) => option.value === value)?.label || "";
  return label ? (
    <Tag bordered={false} key={value} className="break-line-tag">
      {label}
    </Tag>
  ) : null;
};
// Used for Multiselect checkboxes
export const getLabelTagsArray = (options = [], valuesArray = []) => {
  const enteredOptions = options
    .filter(
      (option) =>
        valuesArray?.includes(option.value) && option.value !== "others"
    )
    .map((option) => option.label);

  return enteredOptions?.length === 0
    ? null
    : enteredOptions?.map((label, index) => (
        <Tag bordered={false} key={index} className="break-line-tag">
          {label}
        </Tag>
      ));
};

//Used for CheckListFBYInput items
export const getLabelTagsWithDescription = (options = [], formData = {}) => {
  const enteredOptions = options.reduce((acc, option) => {
    if (formData?.[option.value]) {
      if (formData?.[option.value + "_description"]) {
        acc.push(
          `${option.label}: ${formData?.[option.value + "_description"]}`
        );
      } else {
        acc.push(option.label);
      }
    }
    return acc;
  }, []);
  return enteredOptions?.length === 0
    ? null
    : enteredOptions?.map((label, index) => (
        <Tag bordered={false} className="break-line-tag" key={index}>
          {label}
        </Tag>
      ));
};

export const getPastMedicalHistorySummary = (options = [], formData = {}) => {
  const enteredOptions = options.reduce((acc, option) => {
    let labelCreated = "";
    if (formData?.[option.value]) {
      labelCreated = option.label;
      if (formData?.[option.value + "_yrs"]) {
        labelCreated =
          labelCreated + ` since ${formData?.[option.value + "_yrs"]} yrs`;
      }
      if (formData?.[option.value + "_control"]) {
        labelCreated =
          labelCreated + ` : ${formData?.[option.value + "_control"]}`;
      }
    }

    labelCreated && acc.push(labelCreated);

    return acc;
  }, []);
  return enteredOptions?.length === 0
    ? null
    : enteredOptions.map((label, index) => (
        <Tag bordered={false} className="break-line-tag" key={index}>
          {label}
        </Tag>
      ));
};

export const getEdemaSummary = (options = [], formData = {}) => {
  const enteredOptions = options.reduce((acc, option) => {
    let labelCreated = "";
    if (formData?.[option.value]) {
      labelCreated = option.label;
      if (formData?.[option.value + "_pitting"]) {
        labelCreated =
          labelCreated +
          ` Pitting:${formData?.[option.value + "_pitting_description"]}`;
      }
      if (formData?.[option.value + "_non_pitting"]) {
        labelCreated =
          labelCreated +
          ` Non Pitting:${
            formData?.[option.value + "_non_pitting_description"]
          }`;
      }
      if (formData?.[option.value + "_text"]) {
        labelCreated =
          labelCreated + ` : ${formData?.[option.value + "_text"]}`;
      }
    }
    labelCreated && acc.push(labelCreated);
    return acc;
  }, []);
  return enteredOptions?.length === 0
    ? null
    : enteredOptions?.map((label, index) => (
        <Tag bordered={false} className="break-line-tag" key={index}>
          {label}
        </Tag>
      ));
};

function getFractureType(value) {
  for (const group of fractureTypeOptions) {
    for (const option of group.options) {
      if (option.value === value) {
        return option.label;
      }
    }
  }
  return null;
}

export const getFractureSummary = (options = [], formData = {}) => {
  const enteredOptions = options.reduce((acc, option) => {
    let labelCreated = "";
    //  labelCreated = option.label;
    if (formData?.[option.value]) {
      labelCreated = option.label;
      if (formData?.[option.value + "_right_closed"]) {
        labelCreated = labelCreated + " Right-Closed";
        if (formData?.[option.value + "_right_closed_type"]) {
          labelCreated =
            labelCreated +
            ":" +
            getFractureType(formData?.[option.value + "_right_closed_type"]);
        }
      }
      if (formData?.[option.value + "_right_open"]) {
        labelCreated = labelCreated + " Right-Open";
        if (formData?.[option.value + "_right_open_type"]) {
          labelCreated =
            labelCreated +
            ":" +
            getFractureType(formData?.[option.value + "_right_open_type"]);
        }
      }
      if (formData?.[option.value + "_left_closed"]) {
        labelCreated = labelCreated + " Left-Closed";
        if (formData?.[option.value + "_left_closed_type"]) {
          labelCreated =
            labelCreated +
            ":" +
            getFractureType(formData?.[option.value + "_left_closed_type"]);
        }
      }
      if (formData?.[option.value + "_left_open"]) {
        labelCreated = labelCreated + " Left-Open";
        if (formData?.[option.value + "_left_open_type"]) {
          labelCreated =
            labelCreated +
            ":" +
            getFractureType(formData?.[option.value + "_left_open_type"]);
        }
      }
    }

    labelCreated && acc.push(labelCreated);
    return acc;
  }, []);
  return enteredOptions?.length === 0
    ? null
    : enteredOptions?.map((label, index) => (
        <Tag bordered={false} className="break-line-tag" key={index}>
          {label}
        </Tag>
      ));
};

// export const getTracheostomySummary = (formData = {}) => {
//   let labelCreated = "";
//   if (formData?.tracheostomy_type === "yes") {
//     labelCreated = formData?general_examination.tracheostomy_type;
//     if (formData?.["started_on"]) {
//       labelCreated =
//         labelCreated +
//         ` started ${dayjs(formData?.["number_of_days"]).format("YYYY-MM-DD")}`;
//     }
//     if (formData?.["secretion"]) {
//       labelCreated = labelCreated + ` : ${formData?.["secretion"]}`;
//     }
//   }

//   return <Tag key={"tracheostomy"}>{labelCreated}</Tag>;
// };

// Used for FormItems CheckListFBYSelect
export const getLabelTagsWithSelectItems = (
  checkOptions = [],
  selectOptions = [],
  formData = {}
) => {
  const enteredOptions = checkOptions.reduce((acc, option) => {
    if (formData?.[option.value]) {
      if (formData?.[option.value + "_type"]) {
        const selectedValue = formData?.[option.value + "_type"];
        const selectedOption = selectOptions.find(
          (option) => option.value === selectedValue
        );
        const selectedLabel = selectedOption
          ? selectedOption.label
          : selectedValue;
        acc.push(`${option.label}: ${selectedLabel}`);
      } else {
        acc.push(option.label);
      }
    }
    return acc;
  }, []);
  return enteredOptions?.length === 0
    ? null
    : enteredOptions?.length === 0
    ? null
    : enteredOptions?.map((label, index) => (
        <Tag bordered={false} className="break-line-tag" key={index}>
          {label}
        </Tag>
      ));
};
// This block to be used when a list of checkboxes is to be displayed with radio options for each checkbox
// The radio options are displayed only when the checkbox is checked
// Here is the sample data object to be used.
//  const jointPainLocationOptions = [
//   { label: "Elbow", value: "elbow" },
//   { label: "Wrist", value: "wrist" },
//   { label: "Shoulder", value: "shoulder" },
//   { label: "Hip", value: "hip" },
//   { label: "Knee", value: "knee" },
//   { label: "Ankle", value: "ankle" },
//   { label: "Small joints of hand", value: "small_joints_of_hand" },
//   { label: "Small Joints of Foot", value: "small_joints_of_foot" },
//   { label: "Others", value: "others" },
// ];

//  const jointPainSeverityOptions = [
//   {
//     label: "Left",
//     name_key: "left",
//     options: [
//       { label: "Tenderness", value: "tenderness" },
//       { label: "Swelling", value: "swelling" },
//       { label: "Decreased ROM", value: "decreased_rom" },
//     ],
//   },
//   {
//     label: "Right",
//     name_key: "right",
//     options: [
//       { label: "Tenderness", value: "tenderness" },
//       { label: "Swelling", value: "swelling" },
//       { label: "Decreased ROM", value: "decreased_rom" },
//     ],
//   },
// ];

//Used for CheckListFBYCheckGroup items

export const getLabelTagsWithCheckGroup = (
  checkBoxOptions = [],
  checkBoxGroupOptions = [],
  formData = {}
) => {
  const enteredOptions = checkBoxOptions.reduce((acc, option) => {
    if (formData?.[option.value]) {
      const groupDetails = checkBoxGroupOptions.reduce(
        (details, checkBoxGroupOption) => {
          const selectedValues =
            formData?.[`${option.value}_${checkBoxGroupOption.name_key}`];
          if (Array.isArray(selectedValues) && selectedValues.length > 0) {
            const selectedLabels = selectedValues.map((selectedValue) => {
              const selectedOption = checkBoxGroupOption.options.find(
                (opt) => opt.value === selectedValue
              );
              return selectedOption ? selectedOption.label : selectedValue;
            });
            details.push(
              `${checkBoxGroupOption.label} => ${selectedLabels.join(", ")}`
            );
          }
          return details;
        },
        []
      );

      if (groupDetails.length > 0) {
        acc.push(`${option.label}: ${groupDetails.join(" : ")}`);
      } else {
        acc.push(option.label);
      }
    }
    return acc;
  }, []);

  return enteredOptions?.length === 0
    ? null
    : enteredOptions?.map((label, index) => (
        <Tag bordered={false} className="break-line-tag" key={index}>
          {label}
        </Tag>
      ));
};

// Used for CheckListFBYRadioItems. Remember the formitem name should be the same as the name_key in the radioOptions
export const getLabelTagsWithRadioOptions = (
  checkBoxOptions = [],
  radioOptions = [],
  formData = {}
) => {
  const enteredOptions = checkBoxOptions.reduce((acc, option) => {
    if (formData?.[option?.value]) {
      const groupLabels = radioOptions.reduce((labels, radioOptionGroup) => {
        if (formData?.[`${option.value}_${radioOptionGroup.name_key}`]) {
          const selectedValue =
            formData?.[`${option.value}_${radioOptionGroup.name_key}`];
          const selectedOption = radioOptionGroup?.options?.find(
            (opt) => opt.value === selectedValue
          );

          if (selectedOption) {
            labels.push(selectedOption.label);
          }
        }
        return labels;
      }, []);

      if (groupLabels.length > 0) {
        acc.push(`${option.label}: ${groupLabels.join(", ")}`);
      } else {
        acc.push(option.label);
      }
    }
    return acc;
  }, []);

  return enteredOptions?.length === 0
    ? null
    : enteredOptions?.map((label, index) => (
        <Tag bordered={false} className="break-line-tag" key={index}>
          {label}
        </Tag>
      ));
};

// <Tag color="magenta">magenta</Tag>
//       <Tag color="red">red</Tag>
//       <Tag color="volcano">volcano</Tag>
//       <Tag color="orange">orange</Tag>
//       <Tag color="gold">gold</Tag>
//       <Tag color="lime">lime</Tag>
//       <Tag color="green">green</Tag>
//       <Tag color="cyan">cyan</Tag>
//       <Tag color="blue">blue</Tag>
//       <Tag color="geekblue">geekblue</Tag>
//       <Tag className="break-line-tag">purple</Tag>

export const getLabelScalesTags = (options = [], values = {}) => {
  return options
    .filter((option) => values[option.value] !== undefined)
    .map((option, index) => (
      <Tag bordered={false} className="break-line-tag" key={index}>
        {option.label}: {values[option.value]}
      </Tag>
    ));
};

export const getDepressionScaleTags = (options = [], values = {}) => {
  const getValue = (value) => {
    if (typeof value !== "string") return value;
    return value.startsWith("1") ? 1 : value.startsWith("2") ? 2 : 3;
  };

  return options
    .filter((option) => values[option.value] !== undefined)
    .map((option, index) => {
      const value = getValue(values[option.value]);
      return (
        <Tag bordered={false} key={index} className="break-line-tag">
          {option.label}: {value}
        </Tag>
      );
    });
};

export const getTherapyLabelTags = (therapyOptions, formData = {}) => {
  const selectedOptions = therapyOptions.reduce((acc, therapy) => {
    const selectedOption = formData[therapy.name_key];
    if (selectedOption) {
      const selectedLabel = therapy.options.find(
        (option) => option.value === selectedOption
      )?.label;
      if (selectedLabel) {
        acc.push(`${therapy.label}: ${selectedLabel}`);
      }
    }
    return acc;
  }, []);
  return selectedOptions?.length === 0
    ? null
    : selectedOptions?.map((label, index) => (
        <Tag bordered={false} key={index} className="break-line-tag">
          {label}
        </Tag>
      ));
};

export const getPatientConditionTags = (
  speechOptions = [],
  yesNoOptions = [],
  formData = {}
) => {
  const enteredOptions = speechOptions.reduce((acc, option) => {
    if (formData?.general_examination?.[option.value]) {
      const status = formData?.general_examination?.[`${option.value}_status`];
      const comments =
        formData?.general_examination?.[`${option.value}_comments`];

      const statusLabel =
        yesNoOptions.find((opt) => opt.value === status)?.label || " ";

      const label = `${option.label}: ${statusLabel}${
        comments ? `, Comments: ${comments}` : ""
      }`;

      acc.push(label);
    }
    return acc;
  }, []);
  console.log("entered options:", enteredOptions);
  return enteredOptions.length === 0
    ? null
    : enteredOptions.map((label, index) => (
        <Tag bordered={false} key={index} className="break-line-tag">
          {label}
        </Tag>
      ));
};
