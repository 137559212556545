import tabletImg from "../assets/medicineImages/tablet.svg";
import capsuleImg from "../assets/medicineImages/Capsule.svg";
import syrupImg from "../assets/medicineImages/syrup.svg";
import suspensionImg from "../assets/medicineImages/suspension.svg";
import injectionImg from "../assets/medicineImages/injection.svg";
import creamImg from "../assets/medicineImages/cream.svg";
import ointmentImg from "../assets/medicineImages/ointment.svg";
import gelImg from "../assets/medicineImages/gel.svg";
import lotionImg from "../assets/medicineImages/lotion.svg";
import DropsImg from "../assets/medicineImages/Drops.svg";
import inhalerImg from "../assets/medicineImages/inhaler.svg";
import nebulizerImg from "../assets/medicineImages/nebulizer.svg";
import suppositoryImg from "../assets/medicineImages/suppository.svg";
import PatchImg from "../assets/medicineImages/Patch.svg";
import { Image, Progress, Tag } from "antd";
import { capitalizeFirstLetter } from "./utility";
import dayjs from "dayjs";

export const medicineIcons = {
  Tablet: tabletImg,
  Capsule: capsuleImg,
  Syrup: syrupImg,
  Suspension: suspensionImg,
  Injection: injectionImg,
  Cream: creamImg,
  Ointment: ointmentImg,
  Gel: gelImg,
  Lotion: lotionImg,
  Drops: DropsImg,
  Inhaler: inhalerImg,
  Nebulizer: nebulizerImg,
  Suppository: suppositoryImg,
  Patch: PatchImg,
};
export const medicineFormTypes = [
  { label: "Tablet", value: "Tablet" },
  { label: "Capsule", value: "Capsule" },
  { label: "Syrup", value: "Syrup" },
  { label: "Suspension", value: "Suspension" },
  { label: "Injection", value: "Injection" },
  { label: "Cream", value: "Cream" },
  { label: "Ointment", value: "Ointment" },
  { label: "Gel", value: "Gel" },
  { label: "Lotion", value: "Lotion" },
  { label: "Drops", value: "Drops" },
  { label: "Inhaler", value: "Inhaler" },
  { label: "Nebulizer", value: "Nebulizer" },
  { label: "Suppository", value: "Suppository" },
  { label: "Patch", value: "Patch" },
];
export const medicineRoutes = [
  { label: "PO", value: "PO" },
  { label: "RT", value: "RT" },
  { label: "SL", value: "SL" },
  { label: "IV", value: "IV" },
  { label: "IM", value: "IM" },
  { label: "SC", value: "SC" },
  { label: "PR", value: "PR" },
  { label: "PV", value: "PV" },
  { label: "TOPICAL", value: "TOPICAL" },
  { label: "OCULAR", value: "OCULAR" },
  { label: "OTIC", value: "OTIC" },
  { label: "NASAL", value: "NASAL" },
  { label: "PEG", value: "PEG" },
  { label: "Oral", value: "Oral" },
  { label: "IV Infusion", value: "IV Infusion" },
  { label: "IV Push", value: "IV Push" },
  { label: "IV Piggyback", value: "IV Piggyback" },
  { label: "IV Bolus", value: "IV Bolus" },
  { label: "IV Drip", value: "IV Drip" },
];
export const medicineFrequency = [
  { label: "OD", value: "Once_Daily" },
  { label: "BD", value: "Twice_Daily" },
  { label: "TDS", value: "Thrice_Daily" },
  { label: "QID", value: "Four_Times_Daily" },
  { label: "HS", value: "At_Bed_Time" },
  { label: "SOS", value: "If_Required" },
  // { label: "As Needed", value: "As_Needed" },
  { label: "Stat", value: "Immediately" },
  // { label: "Every X Hours", value: "Every_X_Hours" },
  // { label: "Every Other Day", value: "Every_Other_Day" },
  // { label: "Weekly", value: "Weekly" },
  // { label: "Monthly", value: "Monthly" },
];
export const frequencyDisplay = {
  Once_Daily: "OD",
  Twice_Daily: "BD",
  Thrice_Daily: "TDS",
  Four_Times_Daily: "QID",
  At_Bed_Time: "HS",
  If_Required: "SOS",
  Immediately: "Immediately",
};
export const unitTags = [
  { name: "mg", id: "mg" },
  { name: "ml", id: "ml" },
  { name: "gms", id: "gms" },
  { name: "mcg", id: "mcg" },
  { name: "IU", id: "IU" },
  { name: "number", id: "number" },
];
export const StatusOptions = [
  // { name: "Ordered", value: "Ordered" },
  { name: "Informed Lab", value: "Informed_Lab" },
  { name: "Sample Collected", value: "Sample_Collected" },
  // { name: "Report Ready", value: "Report_Ready" },
  { name: "Report Delivered", value: "Report_Delivered" },
];
export const statusColors = {
  Ordered: "blue",
  Informed_Lab: "purple",
  Sample_Collected: "magenta",
  Report_Ready: "cyan",
  Report_Delivered: "green",
};
export const initialTabs = [
  {
    key: "1",
    label: "History",
  },
  {
    key: "2",
    label: "General Examination",
  },
  {
    key: "3",
    label: "Systemic Examination",
  },
  {
    key: "4",
    label: "Functional Examination",
  },
  {
    key: "5",
    label: "Clinical Impression/Diagnosis",
  },
];
export const dosageTiming = [
  { title: "Empty Stomach", value: "empty_stomach" },
  { title: "After Meals", value: "after_food" },
  { title: "Before Meals", value: "before_food" },
];
export const vitalValidations = {
  temperature: {
    minimum: 95,
    maximum: 106,
  },
  pulse: {
    minimum: 40,
    maximum: 160,
  },
  respiration: {
    minimum: 6,
    maximum: 60,
  },
  oxygen_saturation: {
    minimum: 75,
    maximum: 100,
  },
  systolic_pressure: {
    minimum: 60,
    maximum: 200,
  },
  diastolic_pressure: {
    minimum: 40,
    maximum: 140,
  },
};
export const grbsValidations = {
  grbs: {
    minimum: 60,
    maximum: 500,
  },
};
export const careStatus = [
  { label: "Done", value: "done" },
  { label: "Withheld", value: "withheld" },
  { label: "Not Done", value: "not_done" },
];
export const medicineActionStatus = [
  // { label: "Done", value: "done" },
  { label: "Done with delay", value: "done_with_delay" },
  { label: "Withheld", value: "withheld" },
  { label: "Not Done", value: "not_done" },
];

export const customTag = (action) => {
  return (
    <Tag
      color={
        action?.action === "not_done"
          ? "red"
          : action?.action === "withheld"
          ? "orange"
          : action?.action === "done_with_delay"
          ? "cyan"
          : "green"
      }
    >
      {action?.action === "done_with_delay"
        ? "Delayed"
        : capitalizeFirstLetter(action?.action?.replaceAll("_", " "))}
      {action?.action === "done_with_delay" &&
        action?.process_time &&
        ` - ${dayjs(action?.process_time)?.format("hh:mm A")}`}
    </Tag>
  );
};
export const dietProgressBar = (value) => {
  return (
    <Progress
      style={{ width: "90%" }}
      percent={
        value === "nothing"
          ? 0
          : value === "one_quarter"
          ? 25
          : value === "half"
          ? 50
          : value === "three_quarters"
          ? 75
          : 100
      }
      size="small"
    />
  );
};
