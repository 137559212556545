import React from "react";
import { Card, Col, Image, Row, Typography } from "antd";
import NoDataPage from "../../NoDataPages/NoDataPage";
import GalleryIcon from "../../../assets/images/nurseIcons/gallery.svg";
import ReactPlayer from "react-player";
import GridPdfViewer from "../InvestigationsIp/InvestigationGallery/GridPdfViewer";
import "./PatientImageView.css";
import dayjs from "dayjs";

const { Meta } = Card;
const PatientImageView = ({ data }) => {
  const fileData = [
    ...new Set(
      data?.flatMap((imgs) =>
        imgs.paths.map((action) => {
          return { ...action, date: imgs?.uploaded_at };
        })
      )
    ),
  ];

  const handlePdfClick = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  return (
    <div
      style={{
        overflowX: "hidden",
        overflowY: fileData.length > 0 ? "auto" : "hidden",
        height: fileData.length > 0 ? "calc(80vh)" : "calc(80vh)",
      }}
      className="gallery-card-container"
    >
      {data?.length > 0 ? (
        <div
          className="gallery-img-container"
          style={{ overflowX: "hidden", display: "flex" }}
        >
          <Row gutter={[16, 16]} style={{ width: "1500px", height: "64vh" }}>
            {" "}
            {fileData?.map((item, index) => (
              <Col
                key={index}
                xs={12}
                sm={6}
                md={4}
                lg={4}
                xl={4}
                xxl={2}
                style={{ minWidth: "270px", margin: "10px" }}
              >
                <div className="gallery-img-card" hoverable>
                  {item?.type?.includes("video") ? (
                    <ReactPlayer
                      url={item?.url}
                      height="120px"
                      width="100%"
                      controls={true}
                      style={{ marginInline: "auto" }}
                    />
                  ) : item?.type?.includes("image") ? (
                    <Image
                      style={{ width: "auto", height: "120px" }}
                      alt={item?.path?.replace("curengo/", "") || "Image"}
                      src={item?.url}
                    />
                  ) : (
                    <div onClick={() => handlePdfClick(item?.url)}>
                      <GridPdfViewer
                        className="img-card"
                        data={[item]}
                        pageNumber={1}
                        height="120px"
                        width="200px"
                      />
                    </div>
                  )}

                  <Meta
                    title={
                      <div className="gallery-meta-title">
                        <Typography.Text
                          ellipsis
                          style={{
                            whiteSpace: "normal",
                            wordBreak: "break-word",
                          }}
                        >
                          {item?.path?.replace("curengo/", "")}
                        </Typography.Text>
                      </div>
                    }
                    description={
                      <div className="gallery-meta-desc">
                        {item?.date
                          ? dayjs(item.date).format("DD MMM")
                          : "Unknown Date"}
                      </div>
                    }
                  />
                </div>
              </Col>
            ))}
          </Row>
        </div>
      ) : (
        <Row
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            minHeight: "70vh",
            backgroundColor: "white",
            width: "1500px",
          }}
        >
          <Col span={24} style={{ textAlign: "center" }}>
            <NoDataPage
              title={"Gallery Empty"}
              subtitle={"No images to be shown at the moment!"}
              image={GalleryIcon}
            />
          </Col>
        </Row>
      )}
    </div>
  );
};

export default PatientImageView;
