import React from "react";
import { Input } from "antd";
import { debounce } from "../../../helpers/utility";
import AllPatientTable from "./AllPatientTable";

const AllPatient = ({
  data,
  refresh,
  setPage,
  setSearchQuery,
  page,
  loading,
}) => {
  const onContactSearch = (value) => {
    setSearchQuery(value?.target?.value);
  };
  return (
    <>
      <Input.Search
        style={{ width: "30%", marginBottom: "10px" }}
        size="large"
        placeholder="Search for patient"
        onChange={debounce(onContactSearch, 300)}
      ></Input.Search>
      <AllPatientTable
        data={data}
        refresh={refresh}
        setPage={setPage}
        page={page}
        loading={loading}
      />
    </>
  );
};

export default AllPatient;
