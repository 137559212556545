import { PlusOutlined } from "@ant-design/icons";
import { Button, Form, Modal, Popconfirm, Select, Table, Tag } from "antd";
import React, { useEffect, useState } from "react";
import { openWoundAreaOptions } from "../../../../Options/systemicExamOptions";

const woundTypeOptions = [
  { value: "abrasion", label: "Abrasion" },
  { value: "laceration", label: "Laceration" },
  { value: "puncture", label: "Puncture" },
];

const infectionStatusOptions = [
  { value: "infected", label: "Infected" },
  { value: "non_infected", label: "Non-Infected" },
];

const OpenWounds = ({ openWounds, setOpenWounds }) => {
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [area, setArea] = useState("");

  const handleAddRow = () => {
    setVisible(true);
  };

  const handleOk = () => {
    form
      .validateFields()

      .then((values) => {
        const newData = [...openWounds, { ...values, key: openWounds.length }];
        setOpenWounds(newData);
        form.resetFields();
        setVisible(false);
        setArea("");
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const handleCancel = () => {
    form.resetFields();
    setVisible(false);
    setArea("");
  };

  const handleAreaChange = (value) => {
    setArea(value);
  };

  useEffect(() => {
    form.setFieldsValue({ ...openWounds });
  }, [openWounds]);

  const columns = [
    {
      title: "Area",
      dataIndex: "area",
      key: "area",
      render: (text) =>
        openWoundAreaOptions.find((option) => option.value === text)?.label,
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      render: (text) =>
        woundTypeOptions.find((option) => option.value === text)?.label,
    },
    {
      title: "Infection Status",
      dataIndex: "infection_status",
      key: "infection_status",
      render: (text) =>
        infectionStatusOptions.find((option) => option.value === text)?.label,
    },
  ];

  return (
    <div>
      <Form.Item label="Open Wounds">
        <Button
          onClick={handleAddRow}
          type="primary"
          style={{ marginBottom: 16 }}
        >
          <PlusOutlined /> Add Row
        </Button>
        <Table columns={columns} dataSource={openWounds} pagination={false} />
        <Modal
          open={visible}
          onOk={handleOk}
          onCancel={handleCancel}
          okText="Add Row"
          cancelText="Close"
        >
          <Form form={form} layout="vertical">
            <Form.Item
              name="area"
              label="Area"
              rules={[{ required: true, message: "Please select an area!" }]}
            >
              <Select
                options={openWoundAreaOptions}
                placeholder="Select Area"
                onChange={handleAreaChange}
              />
            </Form.Item>
            {area && (
              <>
                <Form.Item
                  name="type"
                  label="Type"
                  rules={[{ required: true, message: "Please select a type!" }]}
                >
                  <Select
                    options={woundTypeOptions}
                    placeholder="Select Type"
                  />
                </Form.Item>
                <Form.Item
                  name="infection_status"
                  label="Infection Status"
                  rules={[
                    {
                      required: true,
                      message: "Please select an infection status!",
                    },
                  ]}
                >
                  <Select
                    options={infectionStatusOptions}
                    placeholder="Select Infection Status"
                  />
                </Form.Item>
              </>
            )}
          </Form>
        </Modal>
      </Form.Item>
    </div>
  );
};

export default OpenWounds;
