import { Typography } from "antd";
import React, { useEffect, useState } from "react";
import Gcs from "./Gcs";

const PhysioGcs = ({ formData }) => {
  const [states, setStates] = useState({});
  const handleChange = (key, val) => {
    setStates((prev) => ({
      ...prev,
      [key]: val,
    }));
  };
  const aggregate = Object.values(states).reduce((acc, cur) => acc + cur, 0);
  const interpretation =
    aggregate < 9
      ? "Severe Brain Injury"
      : aggregate < 13
      ? "Moderate Brain Injury"
      : aggregate < 15
      ? "Mild Brain Injury"
      : "Normal";

  useEffect(() => {
    setStates(formData?.general_examination?.gcs || {});
  }, [formData]);
  return (
    <>
      <Typography.Title level={5}>GCS Score:{aggregate}</Typography.Title>
      <Typography.Title level={5}>
        Interpretation:{interpretation}
      </Typography.Title>
      <Gcs initial={true} handleChange={handleChange} />
    </>
  );
};

export default PhysioGcs;
