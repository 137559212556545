import React, { useContext } from "react";
import { Menu, Row, Col, Space, Tag, Typography, theme, Image } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import { getuserRoleTabs } from "./getuserRoleTabs";
import bedImg from "../../assets/images/patientIcons/bed.svg";
import { isDesktop } from "react-device-detect";
import { UserContext } from "../../context/UserContext";
import { useParams } from "react-router-dom";

const { useToken } = theme;
const PatientMenu = ({
  current,
  onClick,
  userRole,
  params,
  navigate,
  patientData,
}) => {
  const query = useParams();
  // const params = new URLSearchParams(document?.location?.search);
  const url = new URL(window?.location?.href);
  const { userDetails } = useContext(UserContext);
  const details = patientData?.admission;
  const { token } = useToken();
  const role = userDetails?.activeOrg?.default_role
    .toLowerCase()
    .replaceAll("_", "-");

  const navigateToHome = () => {
    // params?.delete("patient");
    // params?.delete("type");
    // delete params?.patient;
    // delete params?.type;
    // console.log(params);
    url?.searchParams?.delete("patient");
    url?.searchParams?.delete("type");
    navigate(`${window?.location.pathname}?${url.searchParams.toString()}`, {
      // replace: true,
      // This prevents adding a new entry in the history stack
    });
  };
  console.log(query?.tab);
  return (
    <Row
      gutter={[12, 12]}
      style={{
        position: "fixed", // Fixes the Row at the top
        top: "0", // Aligns it to the top
        width: "100%", // Ensures it spans the full width
        zIndex: "1000", // Keeps it above other elements
        backgroundColor: "white",
        padding: "6px 16px 2px",
        boxShadow: "0 2px 4px -4px #222",
      }}
    >
      <Col xs={24} sm={24} md={24} lg={6} xl={6}>
        <Space>
          <LeftOutlined
            onClick={() =>
              role === "receptionist"
                ? navigate(
                    `/${role}/${
                      query?.tab === "ip"
                        ? "in-patients"
                        : query?.tab === "bill"
                        ? "billing"
                        : query?.tab
                    }`
                  )
                : // navigate(`/${role}/home`)
                  navigateToHome()
            }
            style={{ fontSize: "18px", padding: "0px 10px", marginTop: "15px" }}
          />
          <Typography
            style={{ color: "#101828", fontSize: "16px", fontWeight: 600 }}
          >
            {`${details?.patient?.first_name || " "} ${
              details?.patient?.last_name || " "
            }`}
          </Typography>
          {details?.bed?.bed_no && (
            <Tag color="orange">
              <Image src={bedImg} preview={false} />{" "}
              {details?.bed?.bed_no || ""}
            </Tag>
          )}
        </Space>
        <br />
        <Space style={{ marginLeft: "48px" }}>
          {(details?.patient?.age || details?.patient?.gender) && (
            <Tag>
              {details?.patient?.age} {details?.patient?.gender}
            </Tag>
          )}
          {details?.ip_id && <Tag>IP ID: {details?.ip_id}</Tag>}
        </Space>
      </Col>
      <Col xs={24} sm={24} md={24} lg={18} xl={18} xxl={18} >
        <Menu
          className="patientMenu"
          style={{ justifyContent:  "flex-end" }}
          onClick={onClick}
          selectedKeys={[params?.type || current]}
          mode="horizontal"
          items={getuserRoleTabs(userRole, params?.type, token)}
        />
      </Col>
    </Row>
  );
};

export default PatientMenu;
