import React, { useContext, useEffect, useState } from "react";
import { message, Modal, Select } from "antd";
import { FacilityID } from "../../../../constants/defaultKeys";
import { UserContext } from "../../../../context/UserContext";
import { getCookie } from "../../../../helpers/localStorage";
import {
  getPractitionerListApi,
  updateIpServicesApi,
} from "../../../../services/receptionist.services";

const PractitionerModal = ({ open, setOpen, editData, refresh }) => {
  const { userDetails } = useContext(UserContext);
  const [data, setData] = useState([]);
  const [selectedId, setSelectedId] = useState(null);

  useEffect(() => {
    getPractitionerListApi(
      Number(getCookie(FacilityID)) || userDetails?.currFacility
    )
      .then((res) => {
        setData(res?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const onFinish = () => {
    if (selectedId) {
      updateIpServicesApi(editData?.id, {
        performed_by: selectedId,
        status: "Done",
      })
        .then((res) => {
          message.success(res?.data?.message);
          setOpen(false);
          refresh();
        })
        .catch((err) => {
          message.error(err?.response?.data?.message);
        });
    } else {
      message.error("Please select a practitioner");
    }
  };
  return (
    <>
      <Modal open={open} onCancel={() => setOpen(false)} onOk={onFinish}>
        <Select
          style={{ width: "300px" }}
          placeholder="Choose Practitioner"
          onChange={(value) => setSelectedId(value)}
        >
          {data?.data?.map((item) => (
            <Select.Option key={item?.user_id} value={item?.user_id}>
              {item?.first_name} {item?.last_name}
            </Select.Option>
          ))}
        </Select>
      </Modal>
    </>
  );
};

export default PractitionerModal;
