import React from "react";
import { Table } from "antd";
import { formatToINR } from "../../../helpers/utility";

const ServicePackageTable = ({ paymentData }) => {
  const tableData = paymentData?.service_packages;
  const columns = [
    {
      title: "Service / Package / Investigation",
      dataIndex: "name",
      key: "name",
      // render: (value, record) => record?.package?.name || value,
    },

    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      render: (value, record) => formatToINR(value),
    },
    {
      title: "Discount",
      dataIndex: "discount",
      key: "discount",
      // render: (value, record) => record?.package?.total_discount || value,
    },
  ];
  return (
    <div>
      <Table dataSource={tableData} columns={columns} pagination={false} />
    </div>
  );
};

export default ServicePackageTable;
