import { Col, Form, Modal, Radio, Row, Typography } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { notAssessableAndAssessableOptions } from "../../../Options/commonOptions";
import BarthelIndex from "../../Examination/PhysioScales/BarthelIndex";
import BarthelIndexItems from "../../Examination/PhysioScales/BarthelIndexComponents/BarthelIndexItems";

const PhysioDailyActivities = ({ context }) => {
  const { formData } = useContext(context);
  const [states, setStates] = useState({});
  const [showDailyActivites, setShowDailyActivites] = useState(
    formData?.functional_status?.daily_activities === "assessable"
  );
  useEffect(() => {
    setShowDailyActivites(
      formData?.functional_status?.daily_activities === "assessable"
    );
    setStates(
      Object.fromEntries(
        Object.entries(formData?.functional_status?.barthel_index || {}).filter(
          ([key]) =>
            [
              "feeding",
              "bathing",
              "grooming",
              "dressing",
              "bowels",
              "bladder",
              "toilet_use",
              "transfers",
              "mobility",
              "stairs",
            ].includes(key)
        )
      )
    );
  }, [formData]);

  const handleChange = (category, value) => {
    setStates((prev) => ({ ...prev, [category]: value }));
  };

  const score = Object.values(states).reduce((acc, cur) => acc + cur, 0);

  return (
    <>
      <Form.Item label="Daily Activities" name="daily_activities">
        <Radio.Group
          options={notAssessableAndAssessableOptions}
          onChange={(e) =>
            setShowDailyActivites(e.target.value === "assessable")
          }
        />
      </Form.Item>
      {showDailyActivites && (
        <>
          <Typography>Score: {score}</Typography>
          <BarthelIndexItems
            states={states}
            handleChange={handleChange}
            nameKey={"barthel_index"}
          />
        </>
      )}
    </>
  );
};

export default PhysioDailyActivities;
