import React, { useState, useCallback, useMemo } from "react";
import IndirectSwallowingTest from "./SpeechSwallowComponents/IndirectSwallowingTest";
import { Divider, Form, Input, Typography } from "antd";
import DirectSwallowingTestSS from "./SpeechSwallowComponents/DirectSwallowingTestSS";
import DirectSwallowingTestLiquid from "./SpeechSwallowComponents/DirectSwallowingTestLiquid";
import DirectSwallowingTestSolid from "./SpeechSwallowComponents/DirectSwallowingTestSolid";

const GUSS = ({ context, form }) => {
  const [gussStates, setGussStates] = useState({});

  const handleGussStates = useCallback((key, value) => {
    setGussStates((prev) => ({
      ...prev,
      [key]: value,
    }));
  }, []);

  const calculateScore = useMemo(() => {
    const score = Object.values(gussStates).reduce((acc, cur) => acc + cur, 0);
    // console.log("calc guss", score);
    form.setFieldsValue({ guss_score: score });
    return score;
  }, [gussStates]);

  console.log("guss states", gussStates);

  return (
    <>
      <Typography.Title level={5}>GUSS: {calculateScore}</Typography.Title>
      <Form.Item name="guss_score" hidden>
        <Input value={calculateScore} />
      </Form.Item>
      <IndirectSwallowingTest
        handleGussStates={handleGussStates}
        context={context}
        form={form}
      />
      <Divider />
      <DirectSwallowingTestSS
        handleGussStates={handleGussStates}
        context={context}
        form={form}
      />
      <Divider />
      <DirectSwallowingTestLiquid
        handleGussStates={handleGussStates}
        context={context}
        form={form}
      />
      <Divider />
      <DirectSwallowingTestSolid
        handleGussStates={handleGussStates}
        context={context}
        form={form}
      />
    </>
  );
};

export default GUSS;
