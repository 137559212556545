import React, { useContext, useEffect } from "react";
import GUSS from "../Examination/SpeechSwallowExaminationForm/GUSS";
import { Divider } from "antd";
import MASA from "../Examination/SpeechSwallowExaminationForm/MASA";
import { SpeechInitialContext } from "../../SpeechAndSwallowTherapist/InitialInspection/SpeechSwallowInitialAssessmentProvider";

const SpeechSwallowScales = ({ form }) => {
  const { formData } = useContext(SpeechInitialContext);
  useEffect(() => {
    form.setFieldsValue(formData?.scales);
  }, [formData]);
  return (
    <>
      <GUSS context={SpeechInitialContext} form={form} />
      <Divider />
      <MASA context={SpeechInitialContext} />
    </>
  );
};

export default SpeechSwallowScales;
