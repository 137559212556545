export const setItem = (key, value, isObject = false) => {
  const data = isObject ? JSON.stringify(value) : value;
  localStorage.setItem(key, data);
};

export const getItem = (key, isObject = false) => {
  const value = localStorage.getItem(key);
  const data = isObject ? JSON.parse(value) : value;
  return data;
};

export const clear = () => {
  localStorage.clear();
};
export const removeItem = (key) => {
  localStorage.removeItem(key);
};

export function getCookie(cookieName) {
  const cookies = document.cookie.split("; ");
  for (const cookie of cookies) {
    const [name, value] = cookie.split("=");
    if (name === cookieName) {
      return decodeURIComponent(value);
    }
  }
  return null; // Return null if the cookie is not found
}
export function clearAllCookies() {
  const cookies = document.cookie.split("; ");
  const mainDomainUrl = process.env.REACT_APP_PUBLIC_DOMAIN_BASE_URL;
  const mainDomain = extractDomainFromUrl(mainDomainUrl);
  for (const cookie of cookies) {
    const [name, _] = cookie.split("=");
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC;domain=.${process.env.REACT_APP_PUBLIC_DOMAIN}; path=/`;
  }
}

export function extractDomainFromUrl(url) {
  // Use a regular expression to match and extract the domain
  const match = url.match(
    /^(?:https?:\/\/)?(?:[^@/\n]+@)?(?:www\.)?([^:/\n]+)/i
  );

  // Check if there was a match and return the domain
  if (match) {
    return match[1];
  } else {
    return null; // Return null if the URL is invalid or doesn't contain a domain
  }
}

export function setCookie(cookieName, cookieValue, domain, path) {
  document.cookie = `${cookieName}=${cookieValue}; domain=${domain}; path=${path}`;
}
export function deleteCookie(name, domain) {
  const currentDomain = domain ? domain : window.location.hostname;
  if (document.cookie.includes(name + "=")) {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC;domain=.${currentDomain}; path=/`;
    console.log("Cookie with name " + name + " has been removed.");
  } else {
    console.log("Cookie with name " + name + " does not exist.");
  }
}
