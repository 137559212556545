import React, { useState } from "react";
import {
  Button,
  Card,
  List,
  Space,
  Row,
  Col,
  Typography,
  Tag,
  Divider,
} from "antd";
import { medicineFrequency } from "../../../helpers/medicineKeys";
import StatMedicineModal from "./StatMedicineModal";
import buttonStyle from "../../../styles/Custom/Button.module.css";
import dayjs from "dayjs";
import { ClockCircleOutlined } from "@ant-design/icons";

const { Paragraph } = Typography;
const StatMedicines = ({ statData, refreshPage }) => {
  const [open, setOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const handleOpenModal = (item) => {
    setSelectedItem(item);
    setOpen(true);
  };

  const handleCloseModal = () => {
    setSelectedItem(null);
    setOpen(false);
  };

  return (
    <>
      <span>Stat / SOS Medicines</span>
      <br />
      <Row
        gutter={[24, 0]}
        justify="start"
        style={{
          padding: "10px 50px",
        }}
      >
        {statData.map((item, index) => (
          <>
            <Col span={7}>
              <Row>
                {(item?.frequency === "Immediately" &&
                  item?.actions?.[0]?.processed === true) ||
                item?.actions?.[0]?.is_stopped ? (
                  <span style={{ fontWeight: "lighter", fontSize: "12px" }}>
                    {item?.name} -{" "}
                    {
                      medicineFrequency?.find(
                        (med) => med?.value === item?.frequency
                      )?.label
                    }
                  </span>
                ) : (
                  <Button
                    type="primary"
                    ghost
                    style={{
                      borderColor:
                        item?.frequency === "Immediately" &&
                        item?.actions?.[0]?.processed !== true &&
                        "#FED7D7",
                      color:
                        item?.frequency === "Immediately" &&
                        item?.actions?.[0]?.processed !== true &&
                        "#C64141",
                      fontWeight: "lighter",
                      fontSize: "12px",
                    }}
                    className={`${buttonStyle.mdBtn} inv-btn`}
                    disabled={
                      item?.frequency === "Immediately" &&
                      item?.actions?.[0]?.processed === true
                    }
                    onClick={() => handleOpenModal(item)}
                  >
                    {item?.name} -{" "}
                    {
                      medicineFrequency?.find(
                        (med) => med?.value === item?.frequency
                      )?.label
                    }
                  </Button>
                )}
                {item?.actions?.[0]?.is_stopped && (
                  <Tag
                    color="red"
                    style={{
                      height: "20px",
                      marginLeft: "8px",
                    }}
                  >
                    Stopped
                  </Tag>
                )}
              </Row>

              {item?.frequency === "Immediately" &&
                item?.actions?.[0]?.processed === true && (
                  <Row gutter={8} style={{ marginTop: "2px" }}>
                    <Col>
                      <Tag
                        color={
                          item?.frequency === "Immediately" &&
                          item?.actions?.[0]?.processed === true
                            ? "gray"
                            : "orange"
                        }
                        style={{ padding: "0px 3px", fontSize: "9px" }}
                      >
                        <Space size={3}>
                          <ClockCircleOutlined />
                          {item?.actions?.[0]?.process_time &&
                            dayjs(item?.actions?.[0]?.process_time)?.format(
                              "hh:mm A"
                            )}
                        </Space>
                      </Tag>
                    </Col>

                    <Col>
                      <Paragraph
                        ellipsis={{
                          rows: 2,
                          expandable: true,
                          symbol: "more",
                        }}
                        style={{
                          width: 300,
                          fontSize: "12px",
                          color: "#8c8c8c",
                        }}
                      >
                        {item?.actions?.[0]?.remarks}
                      </Paragraph>
                    </Col>
                  </Row>
                )}

              {item?.frequency === "If_Required" &&
                item?.actions?.[0]?.processed === true &&
                item?.actions?.[0]?.nurse_data?.map((nurseItem, nurseIndex) => (
                  <Row
                    gutter={8}
                    style={{ marginTop: "5px", marginBottom: "-10px" }}
                    key={nurseIndex}
                  >
                    <Col>
                      <Tag
                        color="purple"
                        style={{ padding: "0px 3px", fontSize: "9px" }}
                      >
                        <Space size={3}>
                          <ClockCircleOutlined />
                          {dayjs(nurseItem?.process_time)?.format("hh:mm A")}
                        </Space>
                      </Tag>
                    </Col>

                    <Col>
                      <Paragraph
                        ellipsis={{
                          rows: 2,
                          expandable: true,
                          symbol: "more",
                        }}
                        style={{
                          width: 300,
                          fontSize: "12px",
                          color: "#8c8c8c",
                        }}
                      >
                        {nurseItem?.remarks}
                      </Paragraph>
                    </Col>
                  </Row>
                ))}

              {open && selectedItem?.name === item?.name && (
                <StatMedicineModal
                  open={open}
                  setOpen={handleCloseModal}
                  frequency={selectedItem?.frequency}
                  actions={selectedItem?.actions}
                  refreshPage={refreshPage}
                />
              )}
            </Col>{" "}
            {(index + 1) % 3 !== 0 && index < statData.length - 1 && (
              <Col>
                <Divider
                  style={{ borderColor: "#D0D5DD", height: "50px" }}
                  type="vertical"
                />
              </Col>
            )}
          </>
        ))}
      </Row>
    </>
  );
};

export default StatMedicines;
