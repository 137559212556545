import React from "react";
import {
  getLabelTag,
  getLabelTagInput,
  getLabelTagYesNO,
  getLabelTagsArray,
  getLabelTagsWithDescription,
  getLabelTagsWithRadioOptions,
} from "../constructTextFromValues";
import {
  cannulaOptions,
  consiousnessOptions,
  depthOfBreathingOptions,
  drainNumberOptions,
  feedingTubeTypeOptions,
  foleysCatheterTypeOptions,
  moreConsiousnessOptions,
  moreOstomiesOptions,
  ostomiesOptions,
  oxygenSupportOptions,
  pulseVolumeOptions,
  respiratoryDistressOptions,
  tracheostomyConditionOptions,
  urinaryCatheterTypeOptions,
} from "../../Options/genExamOptions";
import { regularIrregularOptions } from "../../Options/commonOptions";
import {
  limbExtremityOptions,
  nursePressureSoreGradeOptions,
  pressureSoreAreaOptions,
  pressureSoreGradeOptions,
  softTissueInjuryAreaOptions,
} from "../../Options/systemicExamOptions";
import { Descriptions, Image, Space } from "antd";
import SearchIcon from "../../../../assets/images/nurseIcons/search.svg";

const NurseExaminationSummary = ({ formData }) => {
  const constructExaminationSummaryItems = () => {
    const examinationSummaryItems = [];
    const examination = formData?.examination || {};
    const pushExaminationItem = (key, label, children) => {
      examinationSummaryItems.push({ key, label, children });
    };

    const getIVAccessSummary = () => {
      return (
        <>
          {getLabelTagInput(
            "Peripheral Cannula",
            getLabelTagYesNO(examination?.peripheral_cannula)
          )}
          {getLabelTagInput(
            examination?.cannula_site1,
            getLabelTagsArray(cannulaOptions, examination?.cannula_type1)
              ?.length === 0
              ? null
              : getLabelTagsArray(cannulaOptions, examination?.cannula_type1)
          )}
          {getLabelTagInput(
            examination?.cannula_site2,
            getLabelTagsArray(cannulaOptions, examination?.cannula_type2)
              ?.length === 0
              ? null
              : getLabelTagsArray(cannulaOptions, examination?.cannula_type2)
          )}
          {getLabelTagInput(
            "Central Line",
            getLabelTagYesNO(examination?.central_line)
          )}
          {getLabelTagInput(
            "Central Line Cumulative Days",
            examination?.central_line_days
          )}
        </>
      );
    };

    if (examination?.level_of_consciousness) {
      pushExaminationItem(
        "1",
        "Level of Consciousness",
        <>
          {getLabelTag(
            consiousnessOptions,
            examination?.level_of_consciousness
          )}
          {getLabelTag(
            moreConsiousnessOptions,
            examination?.level_of_consciousness_options
          )}
        </>
      );
    }

    if (
      examination?.temperature ||
      examination?.pulse_rate ||
      examination?.respiratory_rate ||
      examination?.systolic_bp ||
      examination?.diastolic_bp ||
      examination?.spo2
    ) {
      pushExaminationItem(
        "2",
        "Vitals at the Time of Admission",
        <>
          {getLabelTagInput("Temperature", examination?.temperature)}
          {getLabelTagInput("Pulse Rate", examination?.pulse_rate)}
          {getLabelTagInput("Respiratory Rate", examination?.respiratory_rate)}
          {getLabelTagInput("Systolic BP", examination?.systolic_bp)}
          {getLabelTagInput("Diastolic BP", examination?.diastolic_bp)}
          {getLabelTagInput("SPO2", examination?.spo2)}
        </>
      );
    }

    if (examination?.pain_scale) {
      pushExaminationItem(
        "3",
        "Pain Scale",
        <>
          {getLabelTagYesNO(examination?.pain_scale)}
          {getLabelTagInput("Pain level", examination?.pain)}
        </>
      );
    }

    if (
      examination?.depth_of_breathing ||
      examination?.respiratory_distress ||
      examination?.oxygen_support ||
      examination?.tracheostomy
    ) {
      pushExaminationItem(
        "4",
        "Respiratory Condition",
        <>
          {getLabelTagInput(
            "Depth of Breathing",
            getLabelTag(
              depthOfBreathingOptions,
              examination?.depth_of_breathing
            )
          )}
          {getLabelTagInput(
            "Respiratory Distress",
            getLabelTag(
              respiratoryDistressOptions,
              examination?.respiratory_distress
            )
          )}
          {getLabelTagInput(
            "Oxygen Support",
            getLabelTag(oxygenSupportOptions, examination?.oxygen_support)
          )}
          {getLabelTagInput(
            "Tracheostomy",
            getLabelTagYesNO(examination?.tracheostomy)
              ? getLabelTagYesNO(examination?.tracheostomy)
              : getLabelTag(
                  tracheostomyConditionOptions,
                  examination?.tracheostomy_condition
                )
          )}
        </>
      );
    }

    if (
      examination?.pulse_details?.pulse_regular ||
      examination?.pulse_details?.pulse_volume
    ) {
      pushExaminationItem(
        "5",
        "Pulse",
        <>
          {getLabelTag(
            regularIrregularOptions,
            examination?.pulse_details?.pulse_regular
          )}
          {getLabelTag(
            pulseVolumeOptions,
            examination?.pulse_details?.pulse_volume
          )}
        </>
      );
    }

    if (examination?.pressure_sore_details?.pressure_sores) {
      pushExaminationItem(
        "6",
        "Pressure Sores",
        <>
          {getLabelTagYesNO(examination?.pressure_sore_details?.pressure_sores)}
          {getLabelTagsWithRadioOptions(
            pressureSoreAreaOptions,
            nursePressureSoreGradeOptions,
            examination?.pressure_sore_details
          )}
        </>
      );
    }

    if (
      getLabelTagsWithDescription(
        softTissueInjuryAreaOptions,
        examination?.soft_tissue_injury
      )
    ) {
      pushExaminationItem(
        "7",
        "Soft Tissue Injury",
        getLabelTagsWithDescription(
          softTissueInjuryAreaOptions,
          examination?.soft_tissue_injury
        )
      );
    }

    if (examination?.peripheral_cannula || examination?.central_line) {
      pushExaminationItem("8", "IV Access", getIVAccessSummary());
    }

    if (examination?.feeding_tube) {
      pushExaminationItem(
        "9",
        "Feeding Tube",
        <>
          {getLabelTagYesNO(examination?.feeding_tube)}
          {getLabelTag(feedingTubeTypeOptions, examination?.feeding_tube_type)}
          {getLabelTagInput("Position", examination?.feedingtube_position)}
          {getLabelTagInput("No of Days", examination?.feedingtube_no_days)}
          {getLabelTagInput(
            "Tube Aspiration",
            examination?.feedingtube_aspirations
          )}
        </>
      );
    }

    if (getLabelTagsWithDescription(drainNumberOptions, examination?.drain)) {
      pushExaminationItem(
        "10",
        "Drain",
        getLabelTagsWithDescription(drainNumberOptions, examination?.drain)
      );
    }

    if (examination?.urinary_catheter) {
      pushExaminationItem(
        "11",
        "Urinary Catherter",
        <>
          {getLabelTagYesNO(examination?.urinary_catheter)}
          {getLabelTag(
            urinaryCatheterTypeOptions,
            examination?.urinary_catheter_type
          )}
          {examination?.urinary_catheter_type === "foleys_catheter"
            ? getLabelTag(
                foleysCatheterTypeOptions,
                examination?.foleys_catheter_type
              )
            : ""}
          {getLabelTagInput("Days on Catheter", examination?.days_on_catheter)}
        </>
      );
    }
    if (
      getLabelTagsWithRadioOptions(
        ostomiesOptions,
        moreOstomiesOptions,
        examination
      ) ||
      examination?.ostomies_others
    ) {
      pushExaminationItem(
        "12",
        "Ostomies",

        <>
          {getLabelTagsWithRadioOptions(
            ostomiesOptions,
            moreOstomiesOptions,
            examination
          )}
          {getLabelTagInput("Other Details", examination?.ostomies_others)}
        </>
      );
    }

    if (examination?.paralysis?.status) {
      pushExaminationItem(
        "13",
        "Paralysis",
        <>
          {getLabelTagYesNO(examination?.paralysis?.status)}
          {getLabelTagsWithDescription(
            limbExtremityOptions,
            examination?.paralysis
          )}
        </>
      );
    }

    if (examination?.fractures?.status) {
      pushExaminationItem(
        "14",
        "Fractures",
        <>
          {getLabelTagYesNO(examination?.fractures?.status)}
          {getLabelTagsWithDescription(
            limbExtremityOptions,
            examination?.fractures
          )}
        </>
      );
    }
    return examinationSummaryItems;
  };

  const examinationSummaryItems = constructExaminationSummaryItems();
  return (
    <>
      {examinationSummaryItems?.length !== 0 && (
        <Descriptions
          bordered
          column={1}
          title={
            <span style={{ fontWeight: "500", marginLeft: ".2rem" }}>
              <Space>
                <Image src={SearchIcon} preview={false} />
                <span>Examination</span>
              </Space>
            </span>
          }
          items={examinationSummaryItems}
          contentStyle={{ padding: "4px 4px" }}
          className="initial-insp-desc"
        />
      )}
    </>
  );
};

export default NurseExaminationSummary;
