import React, { useState, useContext, useRef, useEffect } from "react";
import {
  Form,
  Input,
  Button,
  message,
  Select,
  Upload,
  DatePicker,
  Typography,
  Col,
  Row,
  Modal,
} from "antd";
import inputStyles from "../../../styles/Custom/Input.module.css";
import btnStyles from "../../../styles/Custom/Button.module.css";
import { UserContext } from "../../../context/UserContext";
import {
  patientInvestigationsOrderAPI,
  patientInvestigationsOrderUpdateAPI,
} from "../../../services/patient.services";
import { getCookie } from "../../../helpers/localStorage";
import {
  CameraOutlined,
  CloseOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { FacilityID } from "../../../constants/defaultKeys";
import { StatusOptions } from "../../../helpers/medicineKeys";
import InvestigationSearch from "./InvestigationSearch";
import { getUploadUrlApi } from "../../../services/common.services";
import { convertToFormData } from "../../../helpers/utility";
import axios from "axios";
import dayjs from "dayjs";

const { Option } = Select;
const { Dragger } = Upload;
const InvestigationModal = ({ open, setOpen, refreshPage, editData, id }) => {
  const { userDetails } = useContext(UserContext);
  const [form] = Form.useForm();
  const formRef = useRef(null);
  const [searchedText, setSearchedText] = useState("");
  const [selectedId, setSelectedId] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [loading, setLoading] = useState(false);
  const [uploadFile, setUploadFile] = useState([]);
  const [uploadFileLoading, setUploadFileLoading] = useState(false);

  function disabledDate(current) {
    return current && current > dayjs().endOf("day");
  }

  useEffect(() => {
    if (editData) {
      form.setFieldsValue({
        ...editData,
        investigation: editData?.investigation_name || editData?.name,
        delivery_on: editData?.delivery_on
          ? dayjs(editData?.delivery_on)
          : null,
      });
      setSelectedId(editData?.investigation);
      setSelectedStatus(editData?.status);
      setUploadFile(editData?.report_path || []);
    }
  }, [editData]);

  const uploadProps = {
    beforeUpload: (file) => {
      let file_ext = file.name.split(".").slice(-1)[0];
      const fileType =
        file_ext === "png" ||
        file_ext === "jpg" ||
        file_ext === "jpeg" ||
        file_ext === "svg" ||
        file.type === "application/pdf";

      if (!fileType) {
        message.error(`${file.name} is not a valid file`);
      }
      return fileType ? true : Upload.LIST_IGNORE;
    },
    async onChange(info) {
      if (info.file.status === "done") {
        setUploadFileLoading(true);
        try {
          const res = await getUploadUrlApi(`path_key=${info.file.name}`);
          const formData = convertToFormData(res, info);
          await axios({
            method: "POST",
            url: res?.data?.url,
            data: formData,
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });
          setUploadFile((prevState) =>
            prevState
              ? [
                  ...prevState,
                  { path: res?.data?.fields?.key, type: info.file.type },
                ]
              : [{ path: res?.data?.fields?.key, type: info.file.type }]
          );
        } catch (err) {
          message.error(err?.response?.data?.message);
        } finally {
          setUploadFileLoading(false);
        }
      }
    },
  };

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const onFinish = (values) => {
    setLoading(true);
    const formData = {
      facility: Number(getCookie(FacilityID)) || userDetails?.currFacility,
      investigation: selectedId,
      patient: Number(id),
      ip_admission: Number(id),
      ordered_by: userDetails?.id,
      comments: values?.comments,
      status: values?.status,
      report_path: uploadFile,
      delivery_on: values?.delivery_on,
    };

    if (selectedId) {
      const apiCall = editData?.id
        ? patientInvestigationsOrderUpdateAPI(editData?.id, formData)
        : patientInvestigationsOrderAPI(formData);
      apiCall
        .then((res) => {
          message.success(res?.data?.message);
          setLoading(false);
          setOpen(false);
          refreshPage();
        })
        .catch((err) => {
          message.error(err?.response?.data?.message);
          setLoading(false);
        });
    } else {
      message.error("Please select a valid Investigation ID");
      console.log("Please select a valid Investigation ID");
      setLoading(false);
    }
  };

  const handleRemoveFile = (fileToRemove) => {
    const filteredFiles = uploadFile.filter(
      (file) => file.path !== fileToRemove.path
    );
    setUploadFile(filteredFiles);
  };

  return (
    <>
      <Modal
        title={
          <Row>
            <Col span={20} className="flex-start">
              <Typography
                style={{
                  marginLeft: "12px",
                  fontWeight: "400",
                  fontSize: "18px",
                }}
              >
                {editData?.id ? "Edit Investigation" : "Request Investigation"}
              </Typography>
            </Col>
            <Col span={4} className="flex-end">
              <CloseOutlined
                onClick={() => {
                  setOpen(false);
                }}
              />
            </Col>
          </Row>
        }
        closeIcon={null}
        width={"30%"}
        style={{ top: "8px" }}
        open={open}
        footer={
          <div className="flex-end" style={{ gap: "24px", marginTop: "20px" }}>
            <Form.Item>
              <Button
                type="primary"
                ghost
                style={{ fontWeight: "500" }}
                className={btnStyles.mdBtn}
                onClick={() => setOpen(false)}
              >
                Cancel
              </Button>
            </Form.Item>
            <Form.Item>
              <Button
                loading={loading}
                htmlType="submit"
                type="primary"
                style={{ fontWeight: "500" }}
                className={btnStyles.mdBtn}
                onClick={() => {
                  if (formRef.current) {
                    formRef.current.submit();
                  }
                }}
              >
                Save
              </Button>
            </Form.Item>
          </div>
        }
        onCancel={() => setOpen(false)}
      >
        <Form
          layout="vertical"
          onFinish={onFinish}
          form={form}
          ref={formRef}
          style={{ marginTop: "20px" }}
        >
          <InvestigationSearch
            searchedText={searchedText}
            setSearchedText={setSearchedText}
            setSelectedId={setSelectedId}
          />
          {(searchedText || selectedId) && (
            <Form.Item
              label="Add Notes"
              name="comments"
              style={{ marginTop: "20px" }}
            >
              <Input.TextArea
                disabled={["Nurse", "Receptionist"].includes(
                  userDetails?.activeOrg?.default_role
                )}
                rows={1}
                className={inputStyles.mdInput}
                style={{ width: "500px" }}
              />
            </Form.Item>
          )}
          {editData?.id && (
            <Form.Item label="Status" name="status">
              <Select onChange={(value) => setSelectedStatus(value)}>
                {StatusOptions?.map((item) => (
                  <Option key={item?.value} value={item?.value}>
                    {item?.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          )}
          {selectedStatus === "Report_Delivered" && (
            <>
              <Form.Item
                label="Delivered Date"
                name="delivery_on"
                rules={[
                  {
                    required: true,
                    message: "Please enter report delivered date",
                  },
                ]}
              >
                <DatePicker
                  className={inputStyles.mdDateInput}
                  disabledDate={disabledDate}
                />
              </Form.Item>
              <Form.Item
                label="Upload Investigations"
                name="upload"
                rules={[
                  {
                    required: !uploadFile?.length > 0 && true,
                    message: "Please upload investigations",
                  },
                ]}
              >
                <Dragger
                  style={{ width: "300px" }}
                  accept=".png,.jpg,.jpeg,.svg,application/pdf"
                  {...uploadProps}
                  multiple
                  showUploadList={{
                    showRemoveIcon: false,
                  }}
                  customRequest={dummyRequest}
                >
                  <p className="ant-upload-drag-icon">
                    <CameraOutlined />
                  </p>

                  <p className="ant-upload-text">
                    <span style={{ color: "#406AFF" }}>Click to Capture </span>{" "}
                    or upload
                  </p>
                  <p className="ant-upload-hint">PDF, SVG, JPEG, JPG, PNG</p>
                </Dragger>
                {uploadFile.map((item, index) => (
                  <div key={index}>
                    {item.path.replace("curengo/", "")}
                    <Button
                      type="link"
                      icon={<DeleteOutlined />}
                      onClick={() => handleRemoveFile(item)}
                      style={{ marginLeft: "8px" }}
                    />
                  </div>
                ))}
              </Form.Item>
            </>
          )}
        </Form>
      </Modal>
    </>
  );
};

export default InvestigationModal;
