import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../context/UserContext";
import { viewDoctorAppointmentsApi } from "../../../services/doctor.services";
import { Radio, Carousel, Row, Col } from "antd";
import MyAppointmentsTable from "./MyAppointmentsTable";
import dayjs from "dayjs";

const AppointmentsView = () => {
  const { userDetails } = useContext(UserContext);
  const [data, setData] = useState(null);
  const [selectedDate, setSelectedDate] = useState(
    dayjs().format("YYYY-MM-DD")
  ); // Default to today's date

  useEffect(() => {
    viewDoctorAppointmentsApi(userDetails?.id, `date=${selectedDate}`)
      .then((res) => {
        setData(res?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [selectedDate, userDetails?.id]);

  const generateTwoWeeksOptions = () => {
    const options = [];
    const today = dayjs();

    // Generate options for the next 14 days
    for (let i = 0; i < 14; i++) {
      const date = today.add(i, "day");
      const label =
        i === 0
          ? `Today\n${date.format("DD MMM, YY")}`
          : `${date.format("dddd")} \n ${date.format("DD MMM, YY")}`;

      options.push({
        label,
        value: date.format("YYYY-MM-DD"),
      });
    }
    return options;
  };

  const handleDateChange = (e) => {
    setSelectedDate(e.target.value);
  };

  const options = generateTwoWeeksOptions();

  return (
    <Row gutter={[12, 12]}>
      <Col span={24}>
        <Carousel
          dots={false}
          slidesToShow={7}
          responsive={[
            {
              breakpoint: 992,
              settings: {
                slidesToShow: 4,
              },
            },
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 4,
              },
            },
            {
              breakpoint: 576,
              settings: {
                slidesToShow: 1,
              },
            },
            {
              breakpoint: 0,
              settings: {
                slidesToShow: 1,
              },
            },
          ]}
          slidesToScroll={1}
          arrows
          infinite={false}
        >
          {options.map((option) => (
            <div key={option.value}>
              <Radio.Group
                onChange={handleDateChange}
                value={selectedDate}
                optionType="button"
                buttonStyle="solid"
                size="large"
                style={{ alignItems: "center", justifyContent: "center" }}
              >
                <Radio.Button
                  key={option.value}
                  value={option.value}
                  style={{
                    whiteSpace: "break-spaces",
                    height: "auto",
                    padding: "0px 40px",
                    margin: "0px 0px 0px 30px",
                    textAlign: "center",
                    // width: "200px",
                  }}
                >
                  {option.label}
                </Radio.Button>
              </Radio.Group>
            </div>
          ))}
        </Carousel>
      </Col>
      <Col span={24}>
        <MyAppointmentsTable data={data} />
      </Col>
    </Row>
  );
};

export default AppointmentsView;
