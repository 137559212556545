import { Card, Col, Empty, Row, Typography } from "antd";
import React, { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import LoadingIndicator from "../../../Loaders/LoadingIndicator";

function GridPdfViewer({ data, width = null, height = null, pageNumber }) {
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;
  const [loadErrors, setLoadErrors] = useState([]);

  return (
    <>
      {data?.map((item, index) => (
        <Card
          key={index}
          style={{ cursor: "pointer", width: "250px", height: "170px" }}
          bodyStyle={{ padding: 0 }}
        >
          <Row
            style={{
              overflow: "hidden",
              height: height || "650px",
              width: "100%",
              alignItems: "center",
              justifyContent: "center",
              padding: "2px 2px",
              paddingBottom: "0",
              backgroundColor: "#f3f5f8",
            }}
          >
            <Col span={24}>
              <Document
                file={item?.pdf || item?.url}
                loading={
                  <LoadingIndicator
                    message={"Loading your document, please wait..."}
                  />
                }
                onLoadError={() => {
                  const newLoadErrors = [...loadErrors];
                  newLoadErrors[index] = true;
                  setLoadErrors(newLoadErrors);
                }}
                error={
                  loadErrors[index] ? (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                      }}
                    >
                      <Empty description="Cannot load pdf file" />
                    </div>
                  ) : (
                    "Failed to load PDF"
                  )
                }
              >
                <Page
                  noData="Unable to read file"
                  pageNumber={pageNumber}
                  renderAnnotationLayer={false}
                  renderTextLayer={false}
                  size="A4"
                  scale={0.5}
                />
              </Document>
            </Col>
          </Row>

          <Typography.Text
            className="meta-title"
            style={{
              padding: "15px",
              backgroundColor: "#f3f5f8",
              fontSize: "13px",
            }}
          >
            {item?.path}
          </Typography.Text>
        </Card>
      ))}
    </>
  );
}

export default GridPdfViewer;
