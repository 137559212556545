import React, { useContext, useEffect } from "react";
import NeurologicalSystem from "./NeurologicalSystem";
import Respiratory from "./Respiratory";
import CardioVascular from "./CardioVascular";
import Orthopedic from "./Orthopedic";
import Trauma from "./Trauma";
import Malignancy from "./Malignancy";
import { Col, Divider, Row } from "antd";
import { getDoctorInspectionAPI } from "../../../../services/doctor.services";

const ClinicalImpressionForm = ({
  form = {},
  context,
  editId,
  patientData,
}) => {
  const { formData } = useContext(context);

  useEffect(() => {
    const SetDiagnosisData = async () => {
      if (editId) {
        form.setFieldsValue(formData?.clinical_diagnosis);
      } else {
        try {
          const response = await getDoctorInspectionAPI(
            patientData?.doctor_intial_assessment
          );
          form.setFieldsValue(response?.data?.data?.clinical_diagnosis);
        } catch (error) {
          console.log(`Error:`, error);
        }
      }
    };
    SetDiagnosisData();
  }, [editId]);

  return (
    <>
      <Row>
        <Col span={24}>
          <NeurologicalSystem context={context} />
        </Col>
      </Row>
      <Divider />

      <Row>
        <Col span={12}>
          <Respiratory context={context} />
        </Col>
        <Col span={12}>
          <CardioVascular context={context} />
        </Col>
      </Row>
      <Divider />

      <Row>
        <Col span={12}>
        <Orthopedic />
        </Col>
        <Col span={12}>
        <Trauma context={context} />
        </Col>
      </Row>
      
      <Divider />
      <Malignancy />
    </>
  );
};

export default ClinicalImpressionForm;
