import React from "react";
import {
  getLabelScalesTags,
  getLabelTag,
  getLabelTagInput,
} from "../constructTextFromValues";
import {
  barthelIndexOptions,
  genderOptions,
  minsWalkedOptions,
  moreSubjectiveOptions,
  reasonOptions,
  rpeOptions,
  subjectiveOptions,
} from "../../Options/functionalAsessmentOptions";
import {
  normalAbnormalOptions,
  notAssessableAndAssessableOptions,
} from "../../Options/commonOptions";
import { Descriptions, Image, Space, Tag } from "antd";
import FunctionalStatus from "../../../../assets/images/nurseIcons/functionalstatus.svg";

const PhysioFunctionalStatusSummary = ({ formData }) => {
  const constructFunctionalStatusSummaryItems = () => {
    const functionalStatusSummaryItems = [];
    const status = formData?.functional_status || {};
    const pushFunctionalItem = (key, label, children) => {
      functionalStatusSummaryItems.push({ key, label, children });
    };

    if (status?.subjective_assessment?.vision_status) {
      pushFunctionalItem(
        "1",
        "Vision",
        <>
          {getLabelTag(
            subjectiveOptions,
            status?.subjective_assessment?.vision_status
          )}
          {getLabelTag(
            moreSubjectiveOptions,
            status?.subjective_assessment?.vision_affected_status
          )}
        </>
      );
    }
    if (status?.subjective_assessment?.hearing_status) {
      pushFunctionalItem(
        "2",
        "Hearing",
        <>
          {getLabelTag(
            subjectiveOptions,
            status?.subjective_assessment?.hearing_status
          )}
          {getLabelTag(
            moreSubjectiveOptions,
            status?.subjective_assessment?.hearing_affected_status
          )}
        </>
      );
    }
    if (status?.daily_activities) {
      pushFunctionalItem(
        "3",
        "Daily Activities",
        <>
          {getLabelTag(
            notAssessableAndAssessableOptions,
            status?.daily_activities
          )}
          {getLabelScalesTags(barthelIndexOptions, status?.barthel_index)}
        </>
      );
    }
    if (status?.endurance) {
      pushFunctionalItem(
        "4",
        "Endurance",
        <>
          {getLabelTag(
            notAssessableAndAssessableOptions,
            status?.endurance?.status
          )}
          {getLabelTagInput(
            "Rating of perceived exertion",
            status?.endurance?.exertion
          )}
          {getLabelTagInput("Age", status?.endurance?.smwt?.age)}
          {getLabelTagInput(
            "Gender",
            getLabelTag(genderOptions, status?.endurance?.smwt?.gender)
          )}
          {getLabelTagInput("Height", status?.endurance?.smwt?.height)}
          {getLabelTagInput("Weight", status?.endurance?.smwt?.weight)}
          {getLabelTagInput(
            "Predicted Distance",
            status?.endurance?.smwt?.predicted_distance,
            "mtrs"
          )}
          <Tag bordered={false} className="break-line-tag" key={"pre-vitals"}>
            Pre Vitals:{" "}
            {getLabelTagInput(
              "Heart Rate",
              status?.endurance?.smwt?.heart_rate,
              "beats/min"
            )}
            {getLabelTagInput("SBP", status?.endurance?.smwt?.sbp, "mmHg")}
            {getLabelTagInput("DBP", status?.endurance?.smwt?.dbp, "mmHg")}
            {getLabelTagInput("SP02", status?.endurance?.smwt?.spo2, "%")}
            {getLabelTagInput("RR", status?.endurance?.smwt?.rr, "breaths/min")}
            {getLabelTagInput(
              "RPE",
              getLabelTag(rpeOptions, status?.endurance?.smwt?.rpe)
            )}
          </Tag>
          <Tag bordered={false} className="break-line-tag" key={"post-vitals"}>
            Post Vitals:{" "}
            {getLabelTagInput(
              "Heart Rate",
              status?.endurance?.smwt?.post_vital_heart_rate,
              "beats/min"
            )}
            {getLabelTagInput(
              "SBP",
              status?.endurance?.smwt?.post_vital_sbp,
              "mmHg"
            )}
            {getLabelTagInput(
              "DBP",
              status?.endurance?.smwt?.post_vital_dbp,
              "mmHg"
            )}
            {getLabelTagInput(
              "SP02",
              status?.endurance?.smwt?.post_vital_spo2,
              "%"
            )}
            {getLabelTagInput(
              "RR",
              status?.endurance?.smwt?.post_vital_rr,
              "breaths/min"
            )}
            {getLabelTagInput(
              "RPE",
              getLabelTag(rpeOptions, status?.endurance?.smwt?.post_vital_rpe)
            )}
          </Tag>
          {getLabelTagInput("No of Laps", status?.endurance?.smwt?.no_of_laps)}
          {getLabelTagInput(
            "Distance covered",
            status?.endurance?.smwt?.distance_covered,
            "mtrs"
          )}
          {getLabelTagInput(
            "Final Lap",
            status?.endurance?.smwt?.final_lap_in_meters,
            "mtrs"
          )}
          {getLabelTagInput(
            "Mins Walked",
            getLabelTag(minsWalkedOptions, status?.endurance?.smwt?.mins_walked)
          )}
          {getLabelTagInput(
            "Reason for Stopping 6MWT",
            getLabelTag(
              reasonOptions,
              status?.endurance?.smwt?.reason_for_immediate_stopping
            )
          )}
          {getLabelTagInput("Comments", status?.endurance?.smwt?.comments)}
        </>
      );
    }
    if (status?.speech_and_swallow_assessment) {
      pushFunctionalItem(
        "5",
        "Speech and Swallow Assessment",
        getLabelTag(
          normalAbnormalOptions,
          status?.speech_and_swallow_assessment
        )
      );
    }
    return functionalStatusSummaryItems;
  };
  const functionalStatusSummaryItems = constructFunctionalStatusSummaryItems();
  return (
    <div>
      <Descriptions
        bordered
        column={1}
        title={
          <span style={{ fontWeight: "500", marginLeft: ".2rem" }}>
            <Space>
              <Image src={FunctionalStatus} preview={false} />
              <span>Functional Status Assessment</span>
            </Space>
          </span>
        }
        items={functionalStatusSummaryItems}
        style={{ margin: "0", border: "none" }}
        className="initial-insp-desc"
      />
    </div>
  );
};

export default PhysioFunctionalStatusSummary;
