import React, { useState, useEffect, useContext } from "react";
import { Row, Col, Button, Typography, Empty, Tabs } from "antd";
import btnStyles from "../../../styles/Custom/Button.module.css";
import { PlusOutlined } from "@ant-design/icons";
import MedicationTable from "./MedicationTable";
import MedicationForm from "./MedicationForm";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getMedicationApi } from "../../../services/medication.services";
import CustomLayout from "../../Layout/CustomLayout";
import typoStyles from "../../../styles/Custom/Typography.module.css";
import SideMenu from "../../Layout/CustomLayout/SideMenu";
import GlobalMedicationTable from "./GlobalMedicationTable";
import { UserContext } from "../../../context/UserContext";

const options = [
  { label: "Medicine List From Curengo", key: "medicine_list" },
  { label: "In-house Medicines", key: "additional_medicines" },
];
const Medication = () => {
  const { userDetails } = useContext(UserContext);
  const location = useLocation();
  const navigate = useNavigate();
  const query = useParams();
  const params = Object.fromEntries(new URLSearchParams(location?.search));
  const [isVisible, setIsVisible] = useState(false);
  const [medicineData, setMedicineData] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);

  const refreshPage = () => {
    setRefresh((prev) => !prev);
  };

  useEffect(() => {
    setLoading(true);
    getMedicationApi(`page=${page}`)
      .then((res) => {
        setMedicineData(res?.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, [refresh, page]);

  const onChange = (value) => {
    // setActiveSegment(value);
    navigate(`/dashboard/${query?.tab}?medicine=${value}`);
  };

  return (
    <CustomLayout
      header={
        <div
          style={{
            gap: "5%",
            width: "100%",
          }}
          className="flex-start"
        >
          <Typography className={typoStyles.h6}>Medicines</Typography>
          <Tabs
            items={options}
            onChange={onChange}
            defaultActiveKey={params?.medicine}
            style={{ width: "80%", paddingTop: "16px" }}
          />
        </div>
      }
      sideContent={<SideMenu />}
    >
      {params?.medicine === "additional_medicines" ? (
        <>
          {medicineData?.data?.length < 0 ? (
            <Row className="facilities">
              {userDetails?.activeOrg?.default_role === "Facility_Admin" ? (
                <Empty />
              ) : (
                <Col span={24} style={{ textAlign: "center" }}>
                  <Button
                    type="primary"
                    onClick={() => setIsVisible(true)}
                    className={btnStyles.lgBtn}
                  >
                    <PlusOutlined /> Medicines
                  </Button>
                </Col>
              )}
            </Row>
          ) : (
            <Row gutter={[12, 12]}>
              {userDetails?.activeOrg?.default_role !== "Facility_Admin" && (
                <Col span={24} style={{ textAlign: "right" }}>
                  <Button
                    type="primary"
                    onClick={() => setIsVisible(true)}
                    className={btnStyles.lgBtn}
                  >
                    <PlusOutlined /> Medicines
                  </Button>
                </Col>
              )}

              <Col span={24}>
                <MedicationTable
                  data={medicineData}
                  setData={setMedicineData}
                  refreshPage={refreshPage}
                  loading={loading}
                  page={page}
                  setPage={setPage}
                  userDetails={userDetails}
                />
              </Col>
            </Row>
          )}
        </>
      ) : (
        <GlobalMedicationTable />
      )}

      {isVisible && (
        <MedicationForm
          open={isVisible}
          setOpen={setIsVisible}
          refreshPage={refreshPage}
        />
      )}
    </CustomLayout>
  );
};

export default Medication;
