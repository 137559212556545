import React, { useContext, useEffect, useState } from "react";
import { Form, Checkbox, Input } from "antd";
import { traumaOptions } from "../../Options/clinicalImpressionOptions";

const Trauma = ({ context }) => {
  const { formData } = useContext(context);
  const [showTraumaOthers, setShowTraumaOthers] = useState(
    formData?.clinical_diagnosis?.trauma_diagnosis?.includes("others")
  );

  const handleTraumaChange = (selectedOptions) => {
    setShowTraumaOthers(selectedOptions?.includes("others"));
  };

  useEffect(() => {
    setShowTraumaOthers(
      formData?.clinical_diagnosis?.trauma_diagnosis?.includes("others")
    );
  }, [formData]);

  return (
    <>
      <Form.Item name="trauma_diagnosis" label="Trauma Diagnosis">
        <Checkbox.Group options={traumaOptions} onChange={handleTraumaChange} />
      </Form.Item>
      {showTraumaOthers && (
        <Form.Item
          name="trauma_others"
          label="Others"
          rules={[{ required: true, message: "Please enter the details" }]}
        >
          <Input type="text" placeholder="Please specify" style={{width:"50%"}} />
        </Form.Item>
      )}
    </>
  );
};

export default Trauma;
