import React, { useState, useEffect } from "react";
import { Button, message, Popconfirm, Space, Table, Tag } from "antd";
import {
  deleteIpServicesApi,
  getIpServicesListApi,
} from "../../../../services/receptionist.services";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import {
  capitalizeFirstLetter,
  formatToINR,
} from "../../../../helpers/utility";
import useQuery from "../../../../hooks/useQuery";
import dayjs from "dayjs";
import PractitionerModal from "./PractitionerModal";

const AssignedServicesTable = ({ patientData }) => {
  const params = useQuery();
  const [services, setServices] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false);
  const [editData, setEditData] = useState(null);
  const [open, setOpen] = useState(false);
  const admission = patientData?.admission?.is_active;

  const refreshServices = () => {
    setRefresh((prev) => !prev);
  };

  useEffect(() => {
    setLoading(true);
    getIpServicesListApi(`${params?.patient}`)
      .then((res) => {
        setServices(res?.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, [refresh]);

  const onDelete = (patient_service_id) => {
    deleteIpServicesApi(patient_service_id)
      .then((res) => {
        message.success(res?.data?.message);
        refreshServices();
      })
      .catch((err) => {
        message.error(err?.response?.data?.message);
      });
  };

  const columns = [
    {
      title: "Service Name",
      dataIndex: "name",
      key: "name",
    },

    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      render: (value) => formatToINR(value),
    },
    {
      title: "Date",
      dataIndex: "from_date",
      key: "from_date",
      render: (value) => {
        return dayjs(value).format("DD-MM-YYYY");
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (value) => {
        return (
          <Tag color={value === "Ordered" ? "blue" : "green"}>{value}</Tag>
        );
      },
    },
    {
      title: "Updated By",
      dataIndex: "ordered_by_name",
      key: "ordered_by_name",
      render: (value, record) => {
        return record?.performed_by ? capitalizeFirstLetter(value) : "-";
      },
    },
    {
      title: "Performed By",
      dataIndex: "performed_by_name",
      key: "performed_by_name",
      render: (value) => {
        return capitalizeFirstLetter(value);
      },
    },

    ...(admission
      ? [
          {
            title: "Action",
            dataIndex: "action",
            key: "action",
            render: (_, record) => (
              <>
                {record?.status === "Ordered" && (
                  <Space>
                    <Popconfirm
                      title="Sure to delete?"
                      onConfirm={() => {
                        onDelete(record.id);
                      }}
                    >
                      <Button>
                        <DeleteOutlined />
                      </Button>
                    </Popconfirm>
                    <Button
                      onClick={() => {
                        setOpen(true);
                        setEditData(record);
                      }}
                    >
                      <EditOutlined />
                    </Button>
                  </Space>
                )}
              </>
            ),
          },
        ]
      : []),
  ];

  return (
    <>
      <Table
        dataSource={services}
        columns={columns}
        pagination={false}
        loading={loading}
        style={{ marginTop: "15px" }}
      />
      {open && (
        <PractitionerModal
          open={open}
          setOpen={setOpen}
          editData={editData}
          refresh={refreshServices}
        />
      )}
    </>
  );
};

export default AssignedServicesTable;
