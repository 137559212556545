import { Modal, Timeline } from "antd";
import { useEffect, useState } from "react";
import { capitalizeFirstLetter } from "../../../../helpers/utility";
import { getNurseReviewDataApi } from "../../../../services/nurse.services";
import NoDataPage from "../../../NoDataPages/NoDataPage";
import ProgressNotesIcon from "../../../../assets/images/nurseIcons/notes.svg";
import dayjs from "dayjs";
import LoadingIndicator from "../../../Loaders/LoadingIndicator";

function NurseData({ nurseModalStatus, setNurseModalStatus, data, id }) {
  const [reviewData, setReviewData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (id) {
      getNurseReviewDataApi(id)
        .then((res) => {
          setReviewData(res?.data);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => setLoading(false));
    }
  }, [id]);
  const filterData = reviewData?.data?.filter(
    (item) => item?.processed === true
  );
  const timeLineData =
  filterData?.map((item) => ({
    children: `Start Time: ${
      dayjs(item?.process_time).format("DD MMM hh:mm A") === "Invalid Date"
        ? "Not added"
        : dayjs(item?.process_time).format("DD MMM hh:mm A")
    }${
      dayjs(item?.end_time).format("DD MMM hh:mm A") === "Invalid Date"
        ? ""
        : `, End Time: ${dayjs(item?.end_time).format("DD MMM hh:mm A")}`
    }, Processed By: ${item?.processed_by_name}${
      item?.remarks ? `, Remarks: ${item.remarks}` : ""
    }, Status: ${capitalizeFirstLetter(item?.action)}`,
  })) || [];


  return (
    <Modal
      footer={null}
      title="Nurse Data"
      styles={{ header: { marginBottom: "5px" } }}
      open={nurseModalStatus}
      onCancel={() => setNurseModalStatus(false)}
    >
      {loading ? (
        <LoadingIndicator />
      ) : timeLineData?.length > 0 ? (
        <Timeline mode="left" items={timeLineData} />
      ) : (
        <NoDataPage
          title={"No Data Available"}
          subtitle={
            "Please check back later or ensure all relevant data has been recorded."
          }
          image={ProgressNotesIcon}
        />
      )}
    </Modal>
  );
}

export default NurseData;
