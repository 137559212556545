import React, { useContext, useState, useCallback } from "react";
import { AutoComplete, Form, Input, Row, Col, Tag } from "antd";
import { getInvestigationsSearchApi } from "../../../services/investigation.services";
import { getCookie } from "../../../helpers/localStorage";
import { FacilityID } from "../../../constants/defaultKeys";
import { UserContext } from "../../../context/UserContext";
import { SearchOutlined } from "@ant-design/icons";
import inputStyles from "../../../styles/Custom/Input.module.css";
import { debounce } from "../../../helpers/utility";

const setSearchOptions = (data) => {
  const searchOptions = data.map((options) => {
    return {
      value: options?.name,
      key: options?.investigation_id,
      price: options?.cash_price,
      name: options?.name,
      label: (
        <Row>
          <Col span={10}>
            <span>{options?.name}</span>
          </Col>
        </Row>
      ),
    };
  });

  return searchOptions;
};
const BillingInvSearch = ({
  form,
  name,
  setTotalPrice,
  type,
  setFinalDiscountedPrice,
  id,
}) => {
  const [searchedText, setSearchedText] = useState(null);
  const { userDetails } = useContext(UserContext);
  const [data, setData] = useState(null);
  const [previousPrice, setPreviousPrice] = useState(0);
  const handleSearch = (searchedValue) => {
    getInvestigationsSearchApi(
      `${searchedValue.toLowerCase()}&facility_id=${
        getCookie(FacilityID) || userDetails?.currFacility
      }`
    )
      .then((res) => {
        setData(setSearchOptions(res?.data));
      })
      .catch((err) => console.log(err));
  };

  const optimizedSearch = useCallback(debounce(handleSearch, 300), []);

  const handleSelect = (value, option) => {
    const currentPrice = option.price;

    // Subtract the previous price from the total
    setTotalPrice((prevTotal) => prevTotal - previousPrice + currentPrice);
    setFinalDiscountedPrice &&
      setFinalDiscountedPrice(
        (prevTotal) => prevTotal - previousPrice + currentPrice
      );

    // Update the form with the selected service
    form.setFieldsValue({
      service_packages: {
        [name]: {
          price: option.price,
          investigation: option.key,
          discount: 0,
        },
      },
    });

    // Store the current price as the new previous price
    setPreviousPrice(currentPrice);
  };
  return (
    <Form.Item
      name={[name, "name"]}
      // label="Search Investigation"
      rules={[
        {
          required: true,
          message: "Please select investigation",
        },
      ]}
    >
      <AutoComplete
        options={data && data}
        className="search"
        onSearch={optimizedSearch}
        onSelect={handleSelect}
        onChange={(value) => {
          setSearchedText(value);
          optimizedSearch(value);
        }}
        value={searchedText}
      >
        <Input
          size="large"
          prefix={<SearchOutlined />}
          placeholder="Search Investigation"
          className={inputStyles.mdInput}
          style={{ width: "180px" }}
        />
      </AutoComplete>
    </Form.Item>
  );
};

export default BillingInvSearch;
