import React, { useContext } from "react";
import { NurseInitialContext } from "../../InspectionForms/HistoryForm/NurseInitialAssessmentFormProvider";
import { Col, Row } from "antd";
import NurseHistorySummary from "../../InspectionSummaries/HistorySummary/NurseHistorySummary";
import NurseFunctionalAssessmentSummary from "../../InspectionSummaries/FunctionalExaminationSummary/NurseFunctionalAssessmentSummary";
import NurseExaminationSummary from "../../InspectionSummaries/GeneralExaminationSummary/NurseExaminationSummary";
import NurseSpecialCareRequirementSummary from "../../InspectionSummaries/NurseSpecialCareRequirementSummary/NurseSpecialCareRequirementSummary";
import { checkEmpty } from "../../../../helpers/utility";

const NurseInitialInspectionSummary = () => {
  const { formData } = useContext(NurseInitialContext);
  return (
    <>
      <Row className="summaries" gutter={[16, 16]}>
        <Col span={12}>
          <Col
            className="insp-card"
            // xs={24}
            // sm={24}
            // md={24}
            // lg={12}
            // xl={12}
            // xxl={12}
            style={{ paddingTop: 0 }}
          >
            {checkEmpty(formData?.history) && (
              <NurseHistorySummary formData={formData} />
            )}
          </Col>

          <Col
            className="insp-card"
            // xs={24}
            // sm={24}
            // md={24}
            // lg={12}
            // xl={12}
            // xxl={12}
            style={{ paddingTop: 0 }}
          >
            {checkEmpty(formData?.functional_status) && (
              <NurseFunctionalAssessmentSummary formData={formData} />
            )}
          </Col>
        </Col>

        <Col span={12}>
          <Col
            className="insp-card"
            // xs={24}
            // sm={24}
            // md={24}
            // lg={12}
            // xl={12}
            // xxl={12}
            style={{ paddingTop: 0 }}
          >
            {checkEmpty(formData?.examination) && (
              <NurseExaminationSummary formData={formData} />
            )}
          </Col>

          <Col
            className="insp-card"
            // xs={24}
            // sm={24}
            // md={24}
            // lg={12}
            // xl={12}
            // xxl={12}
            style={{ paddingTop: 0 }}
          >
            {checkEmpty(formData?.special_care) && (
              <NurseSpecialCareRequirementSummary formData={formData} />
            )}
          </Col>
        </Col>
      </Row>
    </>
  );
};

export default NurseInitialInspectionSummary;
