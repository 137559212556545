import React, { useState } from "react";
import {
  Button,
  message,
  Space,
  Table,
  Tag,
  Typography,
  Modal,
  Input,
  Tooltip,
  Col,
  Row,
} from "antd";
import {
  CheckOutlined,
  CloseCircleOutlined,
  CloseOutlined,
  EditOutlined,
  InfoCircleOutlined,
  PauseOutlined,
} from "@ant-design/icons";
import StatMedicines from "./StatMedicines";
import { updateNurseCommonData } from "../../../services/nurse.services";
import { capitalizeFirstLetter } from "../../../helpers/utility";
import dayjs from "dayjs";

const ScheduledMedicine = ({
  medData,
  statData,
  sortedTimeSlots,
  refreshPage,
  date,
  curDate,
  loading,
}) => {
  const [editing, setEditing] = useState({});
  const [inputValue, setInputValue] = useState("");
  const [visibleModal, setVisibleModal] = useState(false);
  const [currentAction, setCurrentAction] = useState({});

  console.log("statData", statData);
  console.log("medData", medData);

  const handleSubmit = (item, action) => {
    const updatedMedicine = { action: action?.action };
    updateNurseCommonData(item.id, {
      ...updatedMedicine,
      remarks: inputValue,
    })
      .then((res) => {
        message.success(res.data.message);
        refreshPage();
        setVisibleModal(false);
        setEditing({});
      })
      .catch((err) => {
        message.error(err.response.data.message);
      });
  };

  const handleEditClick = (id, hour) => {
    setEditing({
      [id]: {
        [hour]: true,
      },
    });
  };

  const handleModalConfirm = () => {
    handleSubmit(currentAction?.medicine, currentAction?.action);
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const showModal = (medicine, action) => {
    setCurrentAction({ medicine: medicine, action: action });
    setInputValue(medicine?.remarks || "");
    setVisibleModal(true);
  };

  const handleCancelModal = () => {
    setVisibleModal(false);
    handleSubmit(currentAction?.medicine, currentAction?.action);
  };

  const commonButtons = (action) => {
    return (
      <>
        <Button
          size="small"
          onClick={() => showModal(action, { action: "done" })}
        >
          <CheckOutlined />
        </Button>
        <Button
          size="small"
          onClick={() => showModal(action, { action: "withheld" })}
        >
          <PauseOutlined />
        </Button>
        <Button
          size="small"
          onClick={() => showModal(action, { action: "not_done" })}
        >
          <CloseOutlined />
        </Button>
      </>
    );
  };

  console.log(sortedTimeSlots, "sprrr");

  const columns = [
    {
      title: "Medicine Name",
      dataIndex: "name",
      key: "name",
      width: 250,
      fixed: "left",
      padding: "0px 10px ",
      render: (text) => (
        <span
          style={{
            fontWeight: "200",
            padding: "0 10px",
            display: "inline-block",
          }}
        >
          {text}
        </span>
      ),
    },
    ...sortedTimeSlots?.map((slot) => ({
      title: ` ${dayjs(String(slot), "HH").format("hh A")}`,
      dataIndex: slot,
      key: slot,
      width: 160,
      align: "center",

      render: (_, record) => {
        const action = record?.actions?.find(
          (act) => act?.hour === slot && act?.is_stopped === false
        );

        const isEditing = editing[action?.id]?.[slot];

        return (
          <>
            {isEditing ? (
              <Space>
                {commonButtons(action)}
                <CloseCircleOutlined
                  style={{ color: "red" }}
                  onClick={() => setEditing({})}
                />
              </Space>
            ) : (
              <>
                <Row gutter={16}>
                  <Col span={24}>
                    {action?.processed === false ? (
                      <Space>{commonButtons(action)}</Space>
                    ) : action?.processed === true ? (
                      <Space>
                        <Tag
                          color={
                            action?.action === "not_done"
                              ? "red"
                              : action?.action === "withheld"
                              ? "orange"
                              : "green"
                          }
                        >
                          {capitalizeFirstLetter(
                            action?.action?.replace("_", " ")
                          )}
                        </Tag>

                        {date === curDate && (
                          <Button size="small">
                            <EditOutlined
                              onClick={() => handleEditClick(action?.id, slot)}
                            />
                          </Button>
                        )}
                        <Tooltip
                          title={
                            <>
                              <Typography style={{ color: "#fff" }}>
                                Updated By : {action?.updated_by}
                              </Typography>
                              <Typography style={{ color: "#fff" }}>
                                Updated At :{" "}
                                {dayjs(action?.updated_time)?.format("hh:mm A")}
                              </Typography>
                            </>
                          }
                        >
                          <InfoCircleOutlined style={{ color: "blue" }} />
                        </Tooltip>
                      </Space>
                    ) : null}
                  </Col>
                  <Col span={24}>
                    <Typography style={{ fontSize: "10px" }}>
                      {action?.remarks}
                    </Typography>
                  </Col>
                </Row>
              </>
            )}
          </>
        );
      },
    })),
  ];

  return (
    <div>
      <Table
        size="small"
        // className="scheduled-medicine-table"
        dataSource={medData}
        columns={columns}
        pagination={false}
        bordered
        scroll={{ y: "calc(100vh - 300px)", x: "400px" }}
        // title={() => "Scheduled Medicines"}
        loading={loading}
        footer={() => (
          <div>
            {statData.length > 0 && (
              <div>
                <StatMedicines statData={statData} refreshPage={refreshPage} />
              </div>
            )}
          </div>
        )}
      />

      <Modal
        title="Enter Reason"
        visible={visibleModal}
        onCancel={() => setVisibleModal(false)}
        footer={
          <Space>
            {/* <Button type="primary" ghost onClick={handleModalConfirm}>
              Submit without Remarks
            </Button> */}
            <Button type="primary" onClick={handleCancelModal}>
              Submit
            </Button>
          </Space>
        }
      >
        <Input
          value={inputValue}
          onChange={handleInputChange}
          placeholder="Enter reason for action"
        />
      </Modal>
    </div>
  );
};

export default ScheduledMedicine;
