import React, { useContext, useEffect, useState } from "react";
import { RespiratoryInitialContext } from "./RespiratoryTherapistInitialAssessmentProvider";
import { getRespiratoryInspectionAPI } from "../../../../services/respiratory.services";
import { Button, Col, Drawer, Row } from "antd";
import RespiratoryTherapistInspectionSummary from "./RespiratoryTherapistInspectionSummary";
import RespiratoryTherapistInitialInspectionForm from "./RespiratoryTherapistInitialInspectionForm";
import ClosePopup from "../../Doctor/InitialInspection/ClosePopup";
import btnStyles from "../../../../styles/Custom/Button.module.css";
import NoDataPage from "../../../NoDataPages/NoDataPage";
import assessmentIcon from "../../../../assets/images/nurseIcons/assessment.svg";

const RespiratoryTherapistInitialInspection = ({
  refreshPage,
  editId,
  patientData,
}) => {
  const [visible, setVisible] = useState(false);
  const { formData, setFormData } = useContext(RespiratoryInitialContext);
  const [initialRefresh, setInitialRefresh] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [addModal, setAddModal] = useState(false);

  const setInitialAssessmentRefresh = () => {
    setInitialRefresh((prev) => !prev);
  };

  const openEdit = async () => {
    if (editId) {
      try {
        const response = await getRespiratoryInspectionAPI(editId);
        setFormData(response.data.data);
      } catch (error) {
        console.log(`Error: ${error}`);
      }
    }
  };
  useEffect(() => {
    openEdit();
  }, [editId, initialRefresh]);
  return (
    <>
      {editId ? (
        <Row gutter={[12, 12]}>
          <Col span={24} style={{ textAlign: "right" }}>
            <Button
              type="primary"
              onClick={() => {
                setVisible(true);
                setAddModal(false);
              }}
              className={`${btnStyles.lgBtn} inv-btn `}
            >
              Edit
            </Button>
          </Col>
          <Col span={24} style={{ textAlign: "center" }}>
            <RespiratoryTherapistInspectionSummary editId={editId} />
          </Col>
        </Row>
      ) : (
        <Row
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            minHeight: "80vh",
          }}
        >
          <Col span={24} style={{ textAlign: "center" }}>
            <NoDataPage
              title={"No assessment has been done"}
              subtitle={
                "Kindly, fill the following form to continue initial assessment"
              }
              image={assessmentIcon}
            />
            <Button
              type="primary"
              onClick={() => {
                setVisible(true);
                setAddModal(true);
              }}
              className={`${btnStyles.lgBtn} inv-btn `}
            >
              Initial Assessment Form
            </Button>
          </Col>
        </Row>
      )}
      {visible && (
        <Drawer
          open={visible}
          width="100%"
          footer={null}
          onClose={() => setVisible(false)}
          closeIcon={null}
          keyboard={false}
        >
          <RespiratoryTherapistInitialInspectionForm
            visible={visible}
            setVisible={setVisible}
            refreshPage={refreshPage}
            initialRefresh={setInitialAssessmentRefresh}
            editId={editId}
            patientData={patientData}
            setConfirmModal={setConfirmModal}
          />

          {confirmModal && (
            <ClosePopup
              open={confirmModal}
              setOpen={setConfirmModal}
              setVisible={setVisible}
            />
          )}
        </Drawer>
      )}
    </>
  );
};

export default RespiratoryTherapistInitialInspection;
