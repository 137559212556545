import React, { useState } from "react";
import { Col, Menu, Row, Image, Button } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import investigation from "../../../assets/images/menuImages/investigation.svg";
import patients from "../../../assets/images/menuImages/patients.svg";
import users from "../../../assets/images/menuImages/users.svg";
import activeUserIcon from "../../../assets/images/menuImages/activeUserIcon.svg";
import patientsActive from "../../../assets/images/menuImages/patientsActive.svg";
import investigationActive from "../../../assets/images/menuImages/investigationActive.svg";
import appointmentActive from "../../../assets/images/menuImages/appointmentActive.svg";
import appointment from "../../../assets/images/menuImages/appointment.svg";
import billingopIcon from "../../../assets/receptionistIcons/billingopIcon.svg";
import activeBill from "../../../assets/receptionistIcons/activeBill.svg";
import { PlusOutlined } from "@ant-design/icons";
import buttonstyles from "../../../styles/Custom/Button.module.css";
import AddPatientModal from "../../Receptionist/AddPatientModal";

const ReceptionistSideMenu = ({ refresh, collapsed }) => {
  const router = useParams();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }

  const menuImages = (icon) => {
    return (
      <span
        role="img"
        aria-label="user"
        class="anticon anticon-user ant-menu-item-icon"
      >
        <Image
          src={icon}
          preview={false}
          style={{ height: "20px", width: "auto", marginBottom: "8px" }}
        />
      </span>
    );
  };
  const items = [
    getItem(
      "All Patients",
      "home",
      router?.tab === "home" ? menuImages(activeUserIcon) : menuImages(users)
    ),
    getItem(
      "In Patients",
      "in-patients",
      router?.tab === "in-patients"
        ? menuImages(patientsActive)
        : menuImages(patients)
    ),
    getItem(
      "Investigations",
      "investigation",
      router?.tab === "investigation"
        ? menuImages(investigationActive)
        : menuImages(investigation)
    ),

    getItem(
      "Appointments",
      "appointments",
      router?.tab === "appointments"
        ? menuImages(appointmentActive)
        : menuImages(appointment)
    ),
    getItem(
      "Billing",
      "billing",
      router?.tab === "billing"
        ? menuImages(activeBill)
        : menuImages(billingopIcon)
    ),
  ];
  const onClick = (e) => {
    let path;

    switch (e.key) {
      case "users":
        path = `/receptionist/${e.key}`;
        break;
      case "medication":
        path = `/receptionist/${e.key}`;
        break;
      case "services":
        path = `/receptionist/${e.key}`;
        break;
      default:
        path = `/receptionist/${e.key}`;
    }

    navigate(path);
  };
  return (
    <Row gutter={[24, 24]}>
      <Col span={24}>
        <Button
          style={{ width: !collapsed && "90%", margin: "12px" }}
          className={buttonstyles.lgBtn}
          type="primary"
          onClick={() => setOpen(true)}
        >
          {collapsed ? (
            <PlusOutlined />
          ) : (
            <>
              <PlusOutlined /> New Patient
            </>
          )}
        </Button>
      </Col>

      <Col span={24}>
        <Menu
          style={{ border: 0 }}
          onClick={onClick}
          selectedKeys={[router?.tab]}
          defaultSelectedKeys={[router?.tab]}
          items={items}
        />
      </Col>
      {open && (
        <AddPatientModal open={open} setOpen={setOpen} refresh={refresh} />
      )}
    </Row>
  );
};

export default ReceptionistSideMenu;
