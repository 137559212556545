import React, { useState } from "react";
import { Card, Radio, Space } from "antd";
import AdvancePayment from "./AdvancePayment";
import FinalSettlement from "./FinalSettlement";

const RecordPayment = ({ billData, refreshData }) => {
  const [value, setValue] = useState("advance");
  const activePatient = billData?.data?.admission?.is_active;
  const onChange = (e) => {
    setValue(e.target.value);
  };
  return (
    <Card
      title={
        activePatient ? (
          <Space>
            <Radio.Group onChange={onChange} value={value}>
              <Radio value="advance">Record Payment</Radio>
              <Radio value="final">Final Settlement</Radio>
            </Radio.Group>
          </Space>
        ) : null
      }
    >
      {value === "advance" && activePatient ? (
        <AdvancePayment billData={billData} refreshData={refreshData} />
      ) : (
        <FinalSettlement billData={billData} refreshData={refreshData} />
      )}
    </Card>
  );
};

export default RecordPayment;
