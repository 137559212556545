import React, { useContext } from "react";

import { useParams } from "react-router-dom";

import AccountDetails from "./Details";
import Password from "./Password";
import Experience from "./Experience";
import SideMenu from "../Layout/CustomLayout/SideMenu";
import { UserContext } from "../../context/UserContext";
import ReceptionistSideMenu from "../Layout/CustomLayout/ReceptionistSideMenu";

const AccountDashboard = () => {
  const { userDetails } = useContext(UserContext);
  const query = useParams();

  const CustomSideMenu =
    userDetails?.activeOrg?.default_role === "Receptionist" ? (
      <ReceptionistSideMenu />
    ) : (
      <SideMenu />
    );

  return (
    <>
      {query?.tab === "details" ? (
        <AccountDetails sideContent={CustomSideMenu} />
      ) : query?.tab === "password" ? (
        <Password sideContent={CustomSideMenu} />
      ) : (
        <Experience sideContent={CustomSideMenu} />
      )}
    </>
  );
};

export default AccountDashboard;
