import { HistoryOutlined } from "@ant-design/icons";
import { Descriptions, Image, Space } from "antd";
import React from "react";
import {
  getLabelTag,
  getLabelTagInput,
  getLabelTagYesNO,
  getLabelTagsArray,
  getLabelTagsWithDescription,
  getPastMedicalHistorySummary,
} from "../constructTextFromValues";
import {
  alcoholOptions,
  chiefComplaintsOptions,
  educationalOptions,
  functionalOptions,
  informantOptions,
  knownAllergiesOptions,
  maritalStatusOptions,
  medicalTreatmentOptions,
  modeOfArrivalOptions,
  pastMedicalOptions,
  presentIllnessOptions,
  relationOptions,
  smokingOptions,
  surgicalTreatmentOptions,
} from "../../Options/historyOptions";
import HistoryIcon from "../../../../assets/images/nurseIcons/history.svg";

const NurseHistorySummary = ({ formData }) => {
  const constructHistorySummaryItems = () => {
    const historySummaryItems = [];
    const history = formData?.history || {};
    const pushHistoryItem = (key, label, children) => {
      historySummaryItems.push({ key, label, children });
    };

    if (history.mode_of_arrival) {
      pushHistoryItem(
        "1",
        "Mode of Arrival",
        <>{getLabelTag(modeOfArrivalOptions, history?.mode_of_arrival)}</>
      );
    }
    if (
      history.informant ||
      history.relation_with_patient ||
      history.informant_name
    ) {
      pushHistoryItem(
        "2",
        "Informant",
        <>
          {getLabelTag(informantOptions, history?.informant)}
          {getLabelTag(relationOptions, history?.relation_with_patient)}
          {getLabelTagInput("Name", history?.informant_name)}
        </>
      );
    }
    if (history.chief_complaints || history?.others_chief_complaint) {
      pushHistoryItem(
        "3",
        "Chief Complaints",
        <>
          {getLabelTagsArray(
            chiefComplaintsOptions,
            history.chief_complaints,
            history
          )}
          {getLabelTagInput("Other Details", history?.others_chief_complaint)}
        </>
      );
    }
    if (
      getLabelTagsWithDescription(presentIllnessOptions, history)?.length > 0
    ) {
      pushHistoryItem(
        "4",
        "Present Illness",
        getLabelTagsWithDescription(presentIllnessOptions, history)
      );
    }
    if (
      getLabelTagsWithDescription(
        medicalTreatmentOptions,
        history?.treatment_history
      )?.length > 0
    ) {
      pushHistoryItem(
        "5",
        "Medical Treatment History",
        getLabelTagsWithDescription(
          medicalTreatmentOptions,
          history?.treatment_history
        )
      );
    }
    if (
      getLabelTagsWithDescription(
        surgicalTreatmentOptions,
        history?.treatment_history
      )?.length > 0
    ) {
      pushHistoryItem(
        "6",
        "Surgical Treatment History",
        getLabelTagsWithDescription(
          surgicalTreatmentOptions,
          history?.treatment_history
        )
      );
    }
    if (getPastMedicalHistorySummary(pastMedicalOptions, history)?.length > 0) {
      pushHistoryItem(
        "7",
        "Past Medical History",
        getPastMedicalHistorySummary(pastMedicalOptions, history)
      );
    }
    if (history.surgical_history || history.surgical_history_details) {
      pushHistoryItem(
        "8",
        "Past Surgical History",
        <>
          {getLabelTagYesNO(history.surgical_history)}
          {getLabelTagInput("Details", history.surgical_history_details)}
        </>
      );
    }
    if (
      history.marital_status ||
      history.education ||
      history.smoking ||
      history.alcohol
    ) {
      pushHistoryItem(
        "9",
        "Personal History",
        <>
          {getLabelTag(maritalStatusOptions, history.marital_status)}
          {getLabelTag(educationalOptions, history.education)}
          {getLabelTag(smokingOptions, history.smoking)}
          {getLabelTag(alcoholOptions, history.alcohol)}
        </>
      );
    }
    if (history.functional_capacity) {
      pushHistoryItem(
        "10",
        "Previous Functional Capacity",
        getLabelTag(functionalOptions, history.functional_capacity)
      );
    }
    if (
      getLabelTagsWithDescription(knownAllergiesOptions, history)?.length > 0
    ) {
      pushHistoryItem(
        "11",
        "Known Allergies",
        getLabelTagsWithDescription(knownAllergiesOptions, history)
      );
    }
    return historySummaryItems;
  };

  const historySummaryItems = constructHistorySummaryItems();
  return (
    <>
      {historySummaryItems.length !== 0 && (
        <Descriptions
          bordered
          column={1}
          title={
            <span style={{ fontWeight: "500", marginLeft: ".2rem" }}>
              <Space>
                <Image src={HistoryIcon} preview={false} />
                <span>History</span>
              </Space>
            </span>
          }
          items={historySummaryItems}
          contentStyle={{ padding: "4px 4px" }}
          className="initial-insp-desc"
        />
      )}
    </>
  );
};

export default NurseHistorySummary;
