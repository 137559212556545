import React, { useEffect } from "react";
import {
  Avatar,
  Card,
  List,
  Tag,
  Typography,
  Row,
  Col,
  Space,
  Select,
  Image,
} from "antd";
import { timeAgo } from "../../../../helpers/utility";
import filerIcon from "../../../../assets/images/nurseIcons/filter.svg";
import CustomAvatar from "../../../../blocks/Avatars/CustomAvatar";
import { useState } from "react";
import { getPatientMonitoringAPI } from "../../../../services/patient.services";
import useQuery from "../../../../hooks/useQuery";
import { FilterOutlined } from "@ant-design/icons";
import LoadingIndicator from "../../../Loaders/LoadingIndicator";

const App = () => {
  const [loading,setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [selectedRole, setSelectedRole] = useState("all");
  const params = useQuery();
  const patient = params?.patient;
  const type = params?.type;
  async function getData() {
    const params = { role: selectedRole };
    if (selectedRole === "all") {
      delete params.role;
    }
    try {
      const res = await getPatientMonitoringAPI(patient, params);
      
      setData(
        type === "landing" ? res.data?.data?.slice(0, 4) : res.data?.data
      );
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log(error);
    }
  }
  const options = [
    { label: "All", value: "all" },
    { label: "Doctor", value: "Doctor" },
    { label: "Nurse", value: "Nurse" },
    { label: "Physiotherapist", value: "Physiotherapist" },
    { label: "Speech Swallow Therapist", value: "Speech_Swallow_Therapist" },
    { label: "Respiratory Therapist", value: "Respiratory_Therapist" },
    { label: "Nutritionist", value: "Nutritionist" },
    { label: "Receptionist", value: "Receptionist" },
  ];
  useEffect(() => {
    if (selectedRole) getData();
  }, [selectedRole]);
  return (
    <>
     {loading ? 
    
        <LoadingIndicator message={"Fetching data..."} />
     
  :
    <Card
      styles={{
        header: { borderBottom: "none" },
        marginTop: "10px",
        body: { paddingTop: "0px" },
      }}
      title={
        <Row>
          <Col span={12}>
            <Typography style={{ fontSize: "16px" }}>Progress Notes</Typography>
          </Col>
          <Col span={12} className="flex-end">
            <Space>
              <Image
                src={filerIcon}
                style={{ fontSize: "18px" }}
                preview={false}
              />
              <Select
                options={options}
                onChange={setSelectedRole}
                defaultValue={selectedRole}
                style={{ minWidth: "230px" }}
              />
            </Space>
          </Col>
        </Row>
      }
      style={{ marginTop: "10px" }}
    >
      <List
        itemLayout="horizontal"
        size="small"
        style={{ margin: "0px", padding: "0px" }}
        dataSource={data}
        renderItem={(item) => (
          <List.Item
            style={{ padding: "10px 0px" }}
            key={item.title}
            extra={<Tag color="purple">{timeAgo(item?.created_at)}</Tag>}
          >
            <List.Item.Meta
              avatar={
                <CustomAvatar
                  name={`${item?.done_by_first_name} ${item?.done_by_last_name}`}
                  indexId={item?.id}
                  size="medium"
                />
              }
              description={
                <Typography style={{ marginTop: "4px" }}>
                  {item.notes}
                </Typography>
              }
            />
          </List.Item>
        )}
      />
    </Card>
  }
    </>
   
  );
};
export default App;
