import { HistoryOutlined } from "@ant-design/icons";
import { Descriptions } from "antd";
import React from "react";
import {
  getLabelTag,
  getLabelTagInput,
  getLabelTagsArray,
  getLabelTagYesNO,
  getPastMedicalHistorySummary,
} from "../constructTextFromValues";
import {
  appetiteOptions,
  gLSymptomsTimelineOptions,
  gLSymptomsTypeOptions,
  metabolicStressOptions,
  nutritionistFunctionalOptions,
  pastMedicalOptions,
} from "../../Options/historyOptions";

const NutritionistHistorySummary = ({ formData }) => {
  const constructHistorySummaryItems = () => {
    const historySummaryItems = [];
    const pushHistoryItem = (key, label, children) => {
      historySummaryItems.push({ key, label, children });
    };
    const history = formData?.history || {};
    if (getPastMedicalHistorySummary(pastMedicalOptions, history)?.length > 0) {
      pushHistoryItem(
        "1",
        "Past Medical History",
        getPastMedicalHistorySummary(pastMedicalOptions, history)
      );
    }
    if (history?.allergy_details?.food_allergies) {
      pushHistoryItem(
        "2",
        "Food Allergies",
        <>
          {getLabelTagYesNO(history?.allergy_details?.food_allergies)}
          {getLabelTagInput(
            "Details",
            history?.allergy_details?.allergy_description
          )}
        </>
      );
    }
    if (history?.functional_capacity) {
      pushHistoryItem(
        "3",
        "Functional Capacity",
        getLabelTag(nutritionistFunctionalOptions, history?.functional_capacity)
      );
    }
    if (history?.metabolic_stress) {
      pushHistoryItem(
        "4",
        "Metabolic Stress",
        getLabelTag(metabolicStressOptions, history?.metabolic_stress)
      );
    }
    if (history?.appetite_prior_to_admission) {
      pushHistoryItem(
        "5",
        "Appetite Prior to Admission",
        getLabelTag(appetiteOptions, history?.appetite_prior_to_admission)
      );
    }
    if (history?.gl_symptoms_details?.symptoms_timeline) {
      pushHistoryItem(
        "6",
        "GL Symptoms",
        <>
          {getLabelTag(
            gLSymptomsTimelineOptions,
            history?.gl_symptoms_details?.symptoms_timeline
          )}
          {getLabelTagsArray(
            gLSymptomsTypeOptions,
            history?.gl_symptoms_details?.symptoms
          )}
        </>
      );
    }
    return historySummaryItems;
  };
  const historySummaryItems = constructHistorySummaryItems();
  return (
    <div>
      <Descriptions
        bordered
        column={1}
        title={
          <span style={{ fontWeight: "normal" }}>
            <HistoryOutlined style={{ color: "#7F56D9" }} /> History
          </span>
        }
        items={historySummaryItems}
        style={{ margin: "0", border: "none", textAlign: "left" }}
        className="initial-insp-desc"
      />
    </div>
  );
};

export default NutritionistHistorySummary;
