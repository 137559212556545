import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Drawer,
  Row,
  Col,
  Typography,
  Form,
  Button,
  message,
  Steps,
} from "antd";
import { CloseOutlined } from "@ant-design/icons";
import btnStyles from "../../../styles/Custom/Button.module.css";
import MoreDetails from "./MoreDetails";
import PayerDetails from "./PayerDetails";
import BasicDetails from "./BasicDetails";
import {
  addIpAdmissionApi,
  addPatientApi,
  getInPatientDetailsApi,
  getPatientDetailsApi,
  updateIpAdmissionApi,
  updatePatientApi,
} from "../../../services/receptionist.services";
import { getCookie } from "../../../helpers/localStorage";
import { FacilityID } from "../../../constants/defaultKeys";
import { UserContext } from "../../../context/UserContext";
import { steps } from "../receptionKeys";
import dayjs from "dayjs";
import ClosePopup from "../../IpEMR/Doctor/InitialInspection/ClosePopup";

const InPatientModal = ({
  open,
  setOpen,
  editData,
  refresh,
  ipData,
  refreshIpPage,
}) => {
  const { userDetails } = useContext(UserContext);
  const [form] = Form.useForm();
  const formRef = useRef(null);
  const [patientForm] = Form.useForm();
  const patientFormRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [current, setCurrent] = useState(0);
  const [searchedText, setSearchedText] = useState("");
  const [selectedId, setSelectedId] = useState(null);
  const [isVisible, setIsVisible] = useState(false);
  const [selectedPractitioner, setSelectedPractitioner] = useState(null);

  useEffect(() => {
    if (editData?.id || selectedId || ipData?.patient_id) {
      getPatientDetailsApi(editData?.id || selectedId || ipData?.patient_id)
        .then((res) => {
          patientForm?.setFieldsValue({
            ...res?.data,
            mobile: res?.data?.mobile,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [editData?.id || selectedId || ipData?.patient_id]);

  useEffect(() => {
    if (ipData?.id) {
      getInPatientDetailsApi(ipData?.id)
        .then((res) => {
          setSelectedPractitioner(res?.data?.doctor);
          console.log(res?.data);
          form?.setFieldsValue({
            ...res?.data,
            admission_date: res?.data?.admission_date
              ? dayjs(res?.data?.admission_date)
              : null,
            name:
              res?.data?.doctor_first_name + " " + res?.data?.doctor_last_name,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [ipData?.id]);

  const onPatientFinish = (values) => {
    setLoading(true);
    const { ...formData } = values;
    formData.facility =
      Number(getCookie(FacilityID)) || userDetails?.currFacility;

    if (editData?.id || selectedId || ipData?.patient_id) {
      updatePatientApi(
        editData?.id || selectedId || ipData?.patient_id,
        formData
      )
        .then((res) => {
          message.success(res?.data?.message);
          setLoading(false);
          refresh && refresh();
          setCurrent(1);
        })
        .catch((err) => {
          message.error(err?.response?.data?.message);
          setLoading(false);
        });
    } else {
      addPatientApi(formData)
        .then((res) => {
          message.success(res?.data?.message);
          setLoading(false);
          setCurrent(1);
          setSelectedId(res?.data?.patient_id);
          refresh && refresh();
        })
        .catch((err) => {
          message.error(err?.response?.data?.message);
          setLoading(false);
        });
    }
  };

  const onFinish = (values) => {
    const formData = {
      ...values,
      patient_id: editData?.id || selectedId || ipData?.patient_id,
      patient: editData?.id || selectedId || ipData?.patient_id,
      facility: Number(getCookie(FacilityID)) || userDetails?.currFacility,
      admission_date: dayjs(
        values?.ipadmission_details?.admission_date
      )?.format("YYYY-MM-DD"),
      doctor: selectedPractitioner,
    };
    if (ipData?.id) {
      updateIpAdmissionApi(ipData?.id, formData)
        .then((res) => {
          message.success(res?.data?.message);
          setOpen(false);
          refreshIpPage && refreshIpPage();
        })
        .catch((err) => {
          message?.error(err?.response?.data?.message);
        });
    } else {
      addIpAdmissionApi(formData)
        .then((res) => {
          message.success(res?.data?.message);
          setOpen(false);
          refreshIpPage && refreshIpPage();
          refresh && refresh();
        })
        .catch((err) => {
          message?.error(err?.response?.data?.message);
        });
    }
  };

  return (
    <div>
      <Drawer
        width={"70%"}
        open={open}
        title={
          <Row>
            <Col span={4} className="flex-start">
              <Typography
                style={{
                  marginLeft: "12px",
                  fontWeight: "400",
                  fontSize: "20px",
                }}
              >
                In Patient
              </Typography>
            </Col>
            <Col span={16}>
              <Steps
                current={current}
                items={steps}
                onChange={(value) =>
                  (editData?.id || ipData?.id) && setCurrent(value)
                }
              />
            </Col>
            <Col span={4} className="flex-end">
              <CloseOutlined
                onClick={() =>
                  current === 0 ? setOpen(false) : setIsVisible(true)
                }
              />
            </Col>
          </Row>
        }
        onClose={() => setOpen(false)}
        closeIcon={null}
        footer={
          <div className="flex-end" style={{ gap: "24px" }}>
            {/* {editData?.id && current === 0 && (
              <Button
                type="primary"
                ghost
                className={btnStyles.mdBtn}
                onClick={() => setCurrent(1)}
              >
                Proceed
              </Button>
            )} */}

            <Button
              loading={loading}
              htmlType="submit"
              type="primary"
              className={btnStyles.mdBtn}
              onClick={() => {
                current === 0
                  ? patientFormRef.current.submit()
                  : formRef.current.submit();
              }}
            >
              {current === 0 ? "Save and Proceed" : "Submit"}
            </Button>
          </div>
        }
      >
        {current === 0 && (
          <Form
            layout="vertical"
            // requiredMark={false}
            form={patientForm}
            onFinish={onPatientFinish}
            ref={patientFormRef}
          >
            <Row gutter={[0, 12]}>
              <BasicDetails
                form={patientForm}
                searchedText={searchedText}
                setSearchedText={setSearchedText}
                setSelectedId={setSelectedId}
                selectedId={selectedId}
                editData={editData}
                ipData={ipData}
              />
            </Row>
          </Form>
        )}
        {current === 1 && (
          <Form
            layout="vertical"
            // requiredMark={false}
            form={form}
            onFinish={onFinish}
            ref={formRef}
            initialValues={{
              admission_date: dayjs(),
            }}
          >
            <Row gutter={[0, 12]}>
              <MoreDetails
                selectedPractitioner={selectedPractitioner}
                setSelectedPractitioner={setSelectedPractitioner}
              />
              <PayerDetails />
            </Row>
          </Form>
        )}
        {isVisible && (
          <ClosePopup
            open={isVisible}
            setOpen={setIsVisible}
            setVisible={setOpen}
            type="inPatient"
          />
        )}
      </Drawer>
    </div>
  );
};

export default InPatientModal;
