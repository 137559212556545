import React, { useEffect, useState } from "react";
import {
  Form,
  InputNumber,
  Button,
  Select,
  Row,
  Col,
  Typography,
  Card,
  Space,
  Divider,
} from "antd";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import inputStyles from "../../../styles/Custom/Input.module.css";
import { getPackages } from "../../../services/service.services";
import PackageTable from "./PackageTable";
import BillingInvSearch from "./BillingInvSearch";
import BillingServiceSearch from "./BillingServiceSearch";
import ServicePackageTable from "./ServicePackageTable";

const { Option } = Select;

const priceTagList = [
  { name: "₹", id: false },
  { name: "%", id: true },
];

const OpBillingFormField = ({
  form,
  totalPrice,
  setTotalPrice,
  finalDiscountedPrice,
  setFinalDiscountedPrice,
  id,
  paymentData,
}) => {
  const [packages, setPackages] = useState([]);
  const [typeMap, setTypeMap] = useState({});
  const [previousPrices, setPreviousPrices] = useState({});
  const [selectedPackages, setSelectedPackages] = useState({});
  const [viewDetails, setViewDetails] = useState({});

  useEffect(() => {
    getPackages()
      .then((res) => {
        setPackages(res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleTypeChange = (key, value) => {
    const currentRow = form?.getFieldValue("service_packages")?.[key];
    if (value !== "package") {
      setSelectedPackages({ [key]: null });
    }

    if (currentRow && currentRow.price) {
      setTotalPrice((prevTotal) => prevTotal - currentRow?.price);
      setFinalDiscountedPrice((prevTotal) => prevTotal - currentRow?.price);
    }

    setTypeMap((prevTypeMap) => ({ ...prevTypeMap, [key]: value }));

    form.setFieldsValue({
      service_packages: form
        .getFieldValue("service_packages")
        .map((item, index) => {
          if (index === key) {
            return {
              ...item,
              price: 0,
              package: null,
              investigation: null,
              name: null,
              service: null,
              discount: 0,
              is_percent: false,
            };
          }
          return item;
        }),
    });

    setPreviousPrices((prev) => ({ ...prev, [key]: 0 }));
  };

  const updatePrice = (rowIndex, newPrice) => {
    setTotalPrice((prevTotal) => {
      const oldPrice = previousPrices[rowIndex] || 0;
      return prevTotal - oldPrice + newPrice;
    });
    setFinalDiscountedPrice((prevTotal) => {
      const oldPrice = previousPrices[rowIndex] || 0;
      return prevTotal - oldPrice + newPrice;
    });

    setPreviousPrices((prev) => ({ ...prev, [rowIndex]: newPrice }));
  };

  const handlePackageChange = (name, value) => {
    // Update selectedPackages state for each row
    setSelectedPackages((prevSelectedPackages) => ({
      ...prevSelectedPackages,
      [name]: value,
    }));

    const selectedPackage = packages.find((item) => item.id === value);
    if (selectedPackage) {
      const newPrice = Number(selectedPackage.total_price);

      form.setFieldsValue({
        service_packages: form
          .getFieldValue("service_packages")
          .map((user, index) =>
            index === name ? { ...user, price: newPrice } : user
          ),
      });

      setPreviousPrices((prev) => ({ ...prev, [name]: newPrice }));
      updatePrice(name, newPrice);
    }
  };

  const handleRemove = (name) => {
    setPreviousPrices((prev) => {
      const updatedPrices = { ...prev };
      delete updatedPrices[name];
      return updatedPrices;
    });

    setTotalPrice((prevTotal) => prevTotal - (previousPrices[name] || 0));
    setFinalDiscountedPrice(
      (prevTotal) => prevTotal - (previousPrices[name] || 0)
    );
  };

  const toggleViewDetails = (key) => {
    setViewDetails((prevDetails) => ({
      ...prevDetails,
      [key]: !prevDetails[key], // Toggle the view state for the specific package
    }));
  };

  return (
    <>
      {id ? (
        <ServicePackageTable paymentData={paymentData} />
      ) : (
        <Card style={{ minHeight: "600px" }}>
          {id ? (
            <ServicePackageTable paymentData={paymentData} />
          ) : (
            <Form.List name="service_packages">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }) => (
                    <div key={key}>
                      <Row>
                        <Col span={6}>
                          <Form.Item
                            {...restField}
                            name={[name, "type"]}
                            // label="Choose Type"
                            initialValue="service"
                            rules={[
                              {
                                required: true,
                                message: "Please select a service type",
                              },
                            ]}
                          >
                            <Select
                              style={{ width: "180px" }}
                              onChange={(value) => handleTypeChange(key, value)}
                              className={inputStyles.mdSelect}
                            >
                              <Select.Option value="service">
                                Service
                              </Select.Option>
                              <Select.Option value="package">
                                Package
                              </Select.Option>
                              <Select.Option value="investigation">
                                Investigation
                              </Select.Option>
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col span={6}>
                          {typeMap[key] === "package" ? (
                            <Form.Item
                              {...restField}
                              name={[name, "package"]}
                              // label="Select Package"
                              rules={[
                                {
                                  required: true,
                                  message: "Please select a package",
                                },
                              ]}
                            >
                              <Select
                                // disabled={id}
                                className={inputStyles.mdSelect}
                                style={{ width: "180px" }}
                                onChange={(value) =>
                                  handlePackageChange(name, value)
                                }
                              >
                                {packages?.map((item) => (
                                  <Option key={item.id} value={item.id}>
                                    {item.name}
                                  </Option>
                                ))}
                              </Select>
                            </Form.Item>
                          ) : typeMap[key] === "investigation" ? (
                            <BillingInvSearch
                              roleType="receptionist"
                              id={id}
                              form={form}
                              name={name}
                              totalPrice={totalPrice}
                              setTotalPrice={setTotalPrice}
                              type="billing"
                              finalDiscountedPrice={finalDiscountedPrice}
                              setFinalDiscountedPrice={setFinalDiscountedPrice}
                            />
                          ) : (
                            <BillingServiceSearch
                              id={id}
                              form={form}
                              name={name}
                              totalPrice={totalPrice}
                              setTotalPrice={setTotalPrice}
                              type="billing"
                              finalDiscountedPrice={finalDiscountedPrice}
                              setFinalDiscountedPrice={setFinalDiscountedPrice}
                            />
                          )}
                        </Col>
                        <Col span={5}>
                          <Form.Item
                            {...restField}
                            // label="Price"
                            name={[name, "price"]}
                          >
                            <InputNumber
                              placeholder="Price"
                              prefix="Rs"
                              className={`${inputStyles.mdNumberInput}`}
                              style={{ width: "150px" }}
                              disabled
                            />
                          </Form.Item>
                        </Col>
                        <Col span={5}>
                          <Space.Compact>
                            <Form.Item
                              {...restField}
                              // label="Discount"
                              name={[name, "discount"]}
                              rules={[
                                {
                                  validator: (_, value, callback) => {
                                    const currentPrice = form.getFieldValue([
                                      "service_packages",
                                      name,
                                      "price",
                                    ]);
                                    const percentage = form.getFieldValue([
                                      "service_packages",
                                      name,
                                      "is_percent",
                                    ]);
                                    if (value && value !== "") {
                                      if (/^[0-9]*\.?[0-9]{1,3}$/.test(value)) {
                                        if (
                                          percentage === true &&
                                          Number(value) > 100
                                        ) {
                                          callback(
                                            "Discount cannot be greater than 100%"
                                          );
                                        } else if (
                                          percentage === false &&
                                          Number(value) > Number(currentPrice)
                                        ) {
                                          callback(
                                            "Discount cannot be greater than total amount"
                                          );
                                        } else {
                                          callback();
                                        }
                                      } else {
                                        callback(
                                          "Please enter a valid discount"
                                        );
                                      }
                                    } else {
                                      callback();
                                    }
                                  },
                                },
                              ]}
                            >
                              <InputNumber
                                // disabled={id}
                                placeholder="Enter Discount"
                                className={`${inputStyles.mdNumberInput}`}
                                style={{ width: "120px" }}
                                min={0}
                              />
                            </Form.Item>
                            <Form.Item
                              // label=" "
                              name={[name, "is_percent"]}
                              style={{ width: "50px" }}
                            >
                              <Select
                                className={inputStyles.mdSelect}
                                defaultValue={false}
                                onChange={() => {
                                  form.setFields([
                                    {
                                      name: [
                                        "service_packages",
                                        name,
                                        "discount",
                                      ],
                                      errors: [],
                                    },
                                  ]);

                                  form.validateFields([
                                    ["service_packages", name, "discount"],
                                  ]);
                                }}
                              >
                                {priceTagList?.map((data) => {
                                  return (
                                    <Select.Option
                                      key={data?.id}
                                      value={data?.id}
                                    >
                                      {data?.name}
                                    </Select.Option>
                                  );
                                })}
                              </Select>
                            </Form.Item>
                          </Space.Compact>
                        </Col>
                        {fields.length > 1 && (
                          <Col span={2} style={{ textAlign: "right" }}>
                            <Button
                              onClick={() => {
                                handleRemove(name);
                                remove(name);
                              }}
                              // style={{ marginTop: "25px" }}
                            >
                              <DeleteOutlined style={{ color: "red" }} />
                            </Button>
                          </Col>
                        )}
                      </Row>

                      {selectedPackages[name] && (
                        <>
                          {!viewDetails[key] ? (
                            <Typography.Link
                              onClick={() => toggleViewDetails(key)}
                            >
                              View More Details
                            </Typography.Link>
                          ) : (
                            <PackageTable
                              selectPackageId={selectedPackages[name]} // Use selected package ID for the specific row
                              setSelectPackageId={() => {}} // No need to change this from within PackageTable
                              setViewDetails={setViewDetails}
                              viewDetails={viewDetails[key]}
                            />
                          )}
                        </>
                      )}
                      <Divider style={{ margin: "8px 0px" }} />
                    </div>
                  ))}

                  {/* {!id && ( */}
                  <Form.Item style={{ textAlign: "center" }}>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      style={{ width: "60%", marginTop: "10px" }}
                      icon={<PlusOutlined />}
                    >
                      Item
                    </Button>
                  </Form.Item>
                  {/* )} */}
                </>
              )}
            </Form.List>
          )}
        </Card>
      )}
    </>
  );
};

export default OpBillingFormField;
