import { Tag } from "antd";
import { capitalizeFirstLetter } from "../../../../helpers/utility";

export const morningKeys = [
  {
    time: "06",
    items: [],
    proteins: 0,
    calories: 0,
    sodium: 0,
    potassium: 0,
    carbohydrates: 0,
    comment: "",
  },
  {
    time: "07",
    items: [],
    proteins: 0,
    calories: 0,
    sodium: 0,
    potassium: 0,
    carbohydrates: 0,
    comment: "",
  },
  {
    time: "08",
    items: [],
    proteins: 0,
    calories: 0,
    sodium: 0,
    potassium: 0,
    carbohydrates: 0,
    comment: "",
  },
  {
    time: "09",
    items: [],
    proteins: 0,
    calories: 0,
    sodium: 0,
    potassium: 0,
    carbohydrates: 0,
    comment: "",
  },
  {
    time: "10",
    items: [],
    proteins: 0,
    calories: 0,
    sodium: 0,
    potassium: 0,
    carbohydrates: 0,
    comment: "",
  },
  {
    time: "11",
    items: [],
    proteins: 0,
    calories: 0,
    sodium: 0,
    potassium: 0,
    carbohydrates: 0,
    comment: "",
  },
];
export const afternoonKeys = [
  {
    time: "12",
    items: [],
    proteins: 0,
    calories: 0,
    sodium: 0,
    potassium: 0,
    carbohydrates: 0,
    comment: "",
  },
  {
    time: "13",
    items: [],
    proteins: 0,
    calories: 0,
    sodium: 0,
    potassium: 0,
    carbohydrates: 0,
    comment: "",
  },
  {
    time: "14",
    items: [],
    proteins: 0,
    calories: 0,
    sodium: 0,
    potassium: 0,
    carbohydrates: 0,
    comment: "",
  },
  {
    time: "15",
    items: [],
    proteins: 0,
    calories: 0,
    sodium: 0,
    potassium: 0,
    carbohydrates: 0,
    comment: "",
  },
  {
    time: "16",
    items: [],
    proteins: 0,
    calories: 0,
    sodium: 0,
    potassium: 0,
    carbohydrates: 0,
    comment: "",
  },
  {
    time: "17",
    items: [],
    proteins: 0,
    calories: 0,
    sodium: 0,
    potassium: 0,
    carbohydrates: 0,
    comment: "",
  },
];
export const eveningKeys = [
  {
    time: "18",
    items: [],
    proteins: 0,
    calories: 0,
    sodium: 0,
    potassium: 0,
    carbohydrates: 0,
    comment: "",
  },
  {
    time: "19",
    items: [],
    proteins: 0,
    calories: 0,
    sodium: 0,
    potassium: 0,
    carbohydrates: 0,
    comment: "",
  },
  {
    time: "20",
    items: [],
    proteins: 0,
    calories: 0,
    sodium: 0,
    potassium: 0,
    carbohydrates: 0,
    comment: "",
  },
  {
    time: "21",
    items: [],
    proteins: 0,
    calories: 0,
    sodium: 0,
    potassium: 0,
    carbohydrates: 0,
    comment: "",
  },
  {
    time: "22",
    items: [],
    proteins: 0,
    calories: 0,
    sodium: 0,
    potassium: 0,
    carbohydrates: 0,
    comment: "",
  },
  {
    time: "23",
    items: [],
    proteins: 0,
    calories: 0,
    sodium: 0,
    potassium: 0,
    carbohydrates: 0,
    comment: "",
  },
];

export const timeMapping = {
  "00": "12:00 AM",
  1: "01:00 AM",
  2: "02:00 AM",
  3: "03:00 AM",
  4: "04:00 AM",
  5: "05:00 AM",
  6: "06:00 AM",
  7: "07:00 AM",
  8: "08:00 AM",
  9: "09:00 AM",
  10: "10:00 AM",
  11: "11:00 AM",
  12: "12:00 PM",
  13: "01:00 PM",
  14: "02:00 PM",
  15: "03:00 PM",
  16: "04:00 PM",
  17: "05:00 PM",
  18: "06:00 PM",
  19: "07:00 PM",
  20: "08:00 PM",
  21: "09:00 PM",
  22: "10:00 PM",
  23: "11:00 PM",
};

export const customTag = (item, val, color) => {
  return (
    <Tag color={color}>
      {capitalizeFirstLetter(item)} - {val?.toFixed(2)}
    </Tag>
  );
};
export const mergeDietData = (timeKeys, dietData) => {
  return timeKeys?.map((timeSlot) => {
    // Convert the 12-hour format time slot to a 24-hour format for comparison
    const [hourStr, period] = timeSlot?.time?.split(" ");
    let hour = parseInt(hourStr, 10);

    if (period === "PM" && hour < 12) {
      hour += 12; // Convert PM times to 24-hour format
    } else if (period === "AM" && hour === 12) {
      hour = 0; // Midnight case
    }

    // Find matching backend data for the current time slot
    const backendSlot = dietData?.find((slot) => slot.time === hour);

    if (!backendSlot) {
      return timeSlot; // Return the original timeSlot if no backend data is found
    }

    // Extract items and calculate totals
    const items = backendSlot.items || [];
    const totals = items.reduce(
      (acc, item) => ({
        proteins: acc.proteins + (item.diet__protien || 0),
        calories: acc.calories + (item.diet__calories || 0),
        sodium: acc.sodium + (item.diet__sodium || 0),
        potassium: acc.potassium + (item.diet__potassium || 0),
        carbohydrates: acc.carbohydrates + (item.diet__carbohydrates || 0),
      }),
      { proteins: 0, calories: 0, sodium: 0, potassium: 0, carbohydrates: 0 }
    );

    const comments = items[0]?.comments;

    return {
      ...timeSlot,
      items: items.map(({ diet__id }) => diet__id),
      ...totals,
      comments,
    };
  });
};

export function transformData(inputData) {
  let result = [];

  // Helper function to process each time slot
  const processSlot = (slotData, timeKey) => {
    const items = slotData?.items || slotData?.id || [];
    const comments = slotData?.comments || "";

    if (items.length > 0 || comments) {
      result.push({
        time: parseInt(timeKey, 10),
        items,
        comments,
      });
    }
  };

  // Iterate over each key (time) in the input data
  Object.keys(inputData).forEach((timeKey) => {
    const slotData = inputData[timeKey];

    // Process the slot directly without distinguishing between morning, afternoon, and evening
    processSlot(slotData, timeKey);
  });

  return result;
}
