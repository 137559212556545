import React, { useEffect, useRef, useState } from "react";
import {
  Drawer,
  Row,
  Col,
  Typography,
  Form,
  Input,
  Button,
  Switch,
  Select,
  InputNumber,
  message,
} from "antd";
import { CloseOutlined } from "@ant-design/icons";
import inputStyles from "../../../styles/Custom/Input.module.css";
import btnStyles from "../../../styles/Custom/Button.module.css";
import {
  createBedApi,
  getBuildingTreeApi,
  updateBedApi,
} from "../../../services/beds.services";
import numStyles from "../../../styles/Custom/Dashboard/Dashboard.module.css";
import { validateConfig } from "../../../config/validate.config";

const { Option } = Select;

const BedForm = ({
  isVisible,
  setIsVisible,
  editListData,
  facilityId,
  refreshBedPage,
}) => {
  const formRef = useRef(null);
  const [form] = Form.useForm();
  const [btnLoading, setBtnLoading] = useState(false);
  const [buildings, setBuildings] = useState([]);
  const [floors, setFloors] = useState([]);
  const [rooms, setRooms] = useState([]);

  useEffect(() => {
    getBuildingTreeApi(facilityId)
      .then((res) => setBuildings(res?.data || []))
      .catch((err) => {
        console.log(err);
        setBuildings([]);
      });
  }, [facilityId]);

  useEffect(() => {
    if (editListData) {
      console.log(editListData);
      form.setFieldsValue({
        ...editListData,
        building_name: editListData?.building_id,
        floor_no: editListData?.floor_id,
        room: editListData?.room_id,
      });
      const building = buildings.find(
        (item) => item?.building_id === editListData?.building_id
      );
      const newFloors = building ? building.floors : [];
      setFloors(newFloors);
      const floor = floors.find(
        (item) => item?.floor_id === editListData?.floor_id
      );
      setRooms(floor ? floor.rooms : []); // Populate rooms based on selected floor
    }
  }, [editListData, buildings, floors]);

  const onClose = () => {
    setIsVisible(false);
  };

  const handleBuildingChange = (val, isInit = false) => {
    const building = buildings.find((item) => item?.building_id === val);
    const newFloors = building ? building.floors : [];
    setFloors(newFloors);
    setRooms([]);
    if (!isInit) {
      form.setFieldsValue({ floor_no: undefined, room: undefined });
    }
  };

  const handleFloorChange = (val, isInit = false) => {
    const floor = floors.find((item) => item?.floor_id === val);
    setRooms(floor ? floor.rooms : []); // Populate rooms based on selected floor
    if (!isInit) {
      form.setFieldsValue({ room: undefined });
    }
  };

  const onFinish = (values) => {
    setBtnLoading(true);
    const apiCall = editListData?.bed_id
      ? updateBedApi(editListData?.bed_id, values)
      : createBedApi({ ...values, is_available: values.is_available ?? true });

    apiCall
      .then((res) => {
        refreshBedPage();
        message.success(res?.data?.message);
        setIsVisible(false);
        form.resetFields();
      })
      .catch((err) => {
        message.error(err?.response?.data?.message);
        console.error(err);
      })
      .finally(() => {
        setBtnLoading(false);
      });
  };

  return (
    <Drawer
      open={isVisible}
      width={"30%"}
      title={
        <Row>
          <Col span={12} className="flex-start">
            <Typography
              style={{
                marginLeft: "12px",
                fontWeight: "400",
                fontSize: "20px",
              }}
            >
              {editListData ? "Edit Bed" : "Add Bed"}
            </Typography>
          </Col>
          <Col span={12} className="flex-end">
            <CloseOutlined onClick={onClose} />
          </Col>
        </Row>
      }
      footer={
        <div style={{ textAlign: "right" }}>
          <Form.Item>
            <Button
              loading={btnLoading}
              className={btnStyles.lgBtn}
              type="primary"
              htmlType="submit"
              onClick={() => {
                if (formRef.current) {
                  formRef.current.submit();
                }
              }}
            >
              Submit
            </Button>
          </Form.Item>
        </div>
      }
      onClose={onClose}
      closeIcon={null}
    >
      <Form
        onFinish={onFinish}
        layout="vertical"
        form={form}
        ref={formRef}
        // requiredMark={false}
      >
        <Form.Item
          label="Choose Building"
          name="building_name"
          rules={validateConfig.isRequired("Building")}
        >
          <Select
            placeholder="Choose Building"
            size="large"
            onChange={(value) => handleBuildingChange(value)}
          >
            {buildings.map((item) => (
              <Option value={item?.building_id} key={item?.building_id}>
                {item?.building_name}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="Choose Floor"
          name="floor_no"
          rules={validateConfig.isRequired("Floor")}
        >
          <Select
            placeholder="Choose Floor"
            size="large"
            onChange={(value) => handleFloorChange(value)}
          >
            {floors.map((item) => (
              <Option value={item?.floor_id} key={item?.floor_id}>
                {item?.floor_no}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="Choose Room"
          name="room"
          rules={validateConfig.isRequired("Room")}
        >
          <Select placeholder="Choose room" size="large">
            {rooms?.map((item) => (
              <Option value={item?.room_id} key={item?.room_id}>
                {item?.room_no}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="Bed Number"
          name="bed_no"
          rules={validateConfig.isRequired("Bed Number")}
        >
          <Input className={inputStyles.mdInput} />
        </Form.Item>
        <Form.Item
          label="Cash Price"
          name="cash_price"
          rules={validateConfig.isRequired("Price")}
        >
          <InputNumber
            style={{ width: "200px" }}
            placeholder="Price"
            prefix="Rs"
            className={`${numStyles.mdNumberInput}`}
          />
        </Form.Item>
        <Form.Item
          label="Insurance Price"
          name="insurance_price"
          rules={validateConfig.isRequired("Price")}
        >
          <InputNumber
            placeholder="Price"
            style={{ width: "200px" }}
            prefix="Rs"
            className={`${numStyles.mdNumberInput}`}
          />
        </Form.Item>
        <Form.Item name="is_available" valuePropName="checked">
          <Switch
            checkedChildren="Active"
            unCheckedChildren="In active"
            defaultChecked
          />
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default BedForm;
