import { Card, Descriptions, Col, Row } from "antd";
import React from "react";
import { capitalizeFirstLetter, timeAgo } from "../../../../helpers/utility";

const SpecialAreas = ({ data }) => {
  const formatDaysAgo = (orderDate) => {
    const now = new Date();
    const date = new Date(orderDate);
    const timeDiff = Math.floor((now - date) / (1000 * 60 * 60 * 24)); 
    
    return `-${timeDiff} D`;
  };
  console.log(data);
  return (
    <Card title="Special Areas" style={{ marginTop: "10px"}} styles={{header:{borderBottom:"none"},body:{paddingTop:"0px"}}}>
      <Row gutter={[12, 12]}>
        {data?.[0]?.catheter && (
          <Col xs={8} sm={8} md={8} lg={6} xl={6} xxl={6}>
            <Card
              title="Urinary Catheter"
              size="small"
              className="tiny-graph-card"
              
            >
              {data?.[0]?.catheter && (
                <span >
                  {capitalizeFirstLetter(
                    data?.[0]?.catheter?.data?.urinary_catheter_type?.replaceAll(
                      "_",
                      " "
                    )
                  )}{" "}
                  {formatDaysAgo(data?.[0]?.catheter?.order_date)}
                </span>
              )}
            </Card>
          </Col>
        )}

        {data?.[1]?.central_line && (
          <Col xs={8} sm={8} md={8} lg={6} xl={6} xxl={6}>
           <Card title="Central Line" size="small" className="tiny-graph-card">
  {data?.[1]?.central_line && (
    <span >
      {capitalizeFirstLetter(
        data?.[1]?.central_line?.data?.preventive_care_comments?.replaceAll(
          "_",
          " "
        )
      )}{" "}
      {formatDaysAgo(data?.[1]?.central_line?.order_date)}
    </span>
  )}
</Card>

          </Col>
        )}

        {data?.[2]?.feeding_tube && (
          <Col xs={8} sm={8} md={8} lg={6} xl={6} xxl={6}>
            <Card title="Feeding Tube" size="small" className="tiny-graph-card">
              {data?.[2]?.feeding_tube && (
                <span >
                  {capitalizeFirstLetter(
                    data?.[2]?.feeding_tube?.data?.feeding_tube_type?.replaceAll(
                      "_",
                      " "
                    )
                  )}{" "}
                  {formatDaysAgo(data?.[2]?.feeding_tube?.order_date)}
                </span>
              )}
            </Card>
          </Col>
        )}

        {data?.[3]?.pressure_sore_care && (
          <Col xs={8} sm={8} md={8} lg={6} xl={6} xxl={6}>
            <Card
              title="Pressure Sore Care"
              size="small"
              className="tiny-graph-card"
            >
              {data?.[3]?.pressure_sore_care && (
                <Descriptions.Item>
                  <span >
                    {capitalizeFirstLetter(
                      data?.[3]?.pressure_sore_care?.data?.pressure_sore_area?.replaceAll(
                        "_",
                        " "
                      )
                    )}{" "}
                    -{" "}
                    {capitalizeFirstLetter(
                      data?.[3]?.pressure_sore_care?.data?.pressure_sore_grade?.replaceAll(
                        "_",
                        " "
                      )
                    )}{" "}
                    {formatDaysAgo(data?.[3]?.pressure_sore_care?.order_date)}
                  </span>
                </Descriptions.Item>
              )}
            </Card>
          </Col>
        )}
      </Row>
    </Card>
  );
};

export default SpecialAreas;
