import React, { useContext, useEffect, useState } from "react";
import { Form, Input, Radio } from "antd";
import { yesNoOptions } from "../../../../Options/commonOptions";

const Scar = ({ context }) => {
  const { formData } = useContext(context);
  const [showScarDetails, setShowScarDetails] = useState(
    formData?.systemic_examination?.scar === "yes"
  );
  console.log(formData);
  const handleScarChange = (e) => {
    if (e.target.value === "yes") {
      setShowScarDetails(true);
    } else {
      setShowScarDetails(false);
    }
  };

  useEffect(() => {
    setShowScarDetails(formData?.systemic_examination?.scar === "yes");
  }, [formData]);
  return (
    <>
      <Form.Item label="Scar" name="scar">
        <Radio.Group options={yesNoOptions} onChange={handleScarChange} />
      </Form.Item>
      {showScarDetails && (
        <Form.Item name="scar_description">
          <Input type="text" placeholder="Description" style={{width:"30%"}} />
        </Form.Item>
      )}
    </>
  );
};

export default Scar;
