import React from "react";
import {
  Row,
  Col,
  Card,
  Typography,
  Statistic,
  Space,
  Tooltip,
  Divider,
  Image,
} from "antd";
import CommonGraph from "./CommonGraph";
import eyeIcon from "../../../../../assets/images/patientIcons/eye.svg";
import verbalIcon from "../../../../../assets/images/patientIcons/verbal.svg";
import motorIcon from "../../../../../assets/images/patientIcons/motor.svg";
import vitalsIcon from "../../../../../assets/images/nurseIcons/heart-rate.svg";

const Graphs = ({ data }) => {
  const graphHeadings = [
    {
      heading: "Pulse Rate",
      graphData: data?.pulse_rate,
      lineColor: "#4169E1",
    },
    {
      heading: "Systolic BP",
      graphData: data?.["systolic BP"],
      lineColor: "#FF6977",
    },
    {
      heading: "Diastolic BP",
      graphData: data?.["diastolic BP"],
      lineColor: "#2DB181",
    },
    { heading: "Temp", graphData: data?.temperature, lineColor: "#6941C6" },
    {
      heading: "Respiratory Care",
      graphData: data?.respiratory_rate,
      lineColor: "#FDD330",
    },
    {
      heading: "SpO2",
      graphData: data?.oxygen_saturation,
      lineColor: "#31A7FB",
    },
  ];
  const balance =
    data?.["input/output"]?.input - data?.["input/output"]?.output || 0;
  return (
    <>
      <Row gutter={[8, 8]}>
        {graphHeadings?.map((data) => (
          <Col span={8}>
            <CommonGraph
              title={data?.heading}
              data={data?.graphData}
              lineColor={data?.lineColor}
            />
          </Col>
        ))}
        <Col span={8}>
          <Card
            style={{ height: "100%" }}
            size="small"
            className="tiny-graph-card"
            title={
              <Row>
                <Col span={12}>
                  <Typography>IO Balance</Typography>
                </Col>
                <Col span={12} style={{ textAlign: "right" }}>
                  <Statistic
                    value={balance}
                    valueStyle={{
                      color: balance < 0 ? "#FF4D4F" : "#52C41A",
                      fontWeight: 600,
                      fontSize: "24px",
                    }}
                  />
                </Col>
              </Row>
            }
          >
            <Typography style={{ textAlign: "center" }}>
              Input/Output
            </Typography>
            <Space
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              {data?.["input/output"]?.input &&
                data?.["input/output"]?.output && (
                  <>
                    <Typography
                      style={{
                        color: "#52C41A",
                        fontWeight: 600,
                        fontSize: "24px",
                      }}
                    >
                      {data?.["input/output"]?.input}
                    </Typography>
                    <Typography
                      style={{
                        fontSize: "24px",
                      }}
                    >
                      /
                    </Typography>
                    <Typography
                      style={{
                        color: "#FF4D4F",
                        fontWeight: 600,
                        fontSize: "24px",
                      }}
                    >
                      {data?.["input/output"]?.output}
                    </Typography>
                  </>
                )}
            </Space>

            {/* <Statistic
            value={`${data?.["input/output"]?.input} / ${data?.["input/output"]?.output}`}
            title="Input / Output"
            valueStyle={{
              color: "#52C41A",
              fontWeight: 600,
              fontSize: "24px",
            }}
          /> */}
          </Card>
        </Col>
        <Col span={8}>
          <CommonGraph title="GRBS" data={data?.grbs} lineColor="#FD30E8" />
        </Col>
        <Col span={8}>
          <Card
            size="small"
            className="tiny-graph-card"
            style={{ height: "100%" }}
            title={
              <Row>
                <Col span={12}>
                  <Typography>GCS</Typography>
                </Col>
              </Row>
            }
          >
            <Space
              size="middle"
              style={{
                textAlign: "center",
                display: "flex",
                justifyContent: "space-evenly",
                marginTop: "-20px",
              }}
            >
              <Space direction="vertical">
                <Tooltip title="Eye Opening Response">
                  <h2>E</h2>
                </Tooltip>
                <Statistic value={data?.gcs_scales?.eye_opening_response} />
              </Space>
              <Divider type="vertical" />
              <Space direction="vertical">
                <Tooltip title="Verbal Response">
                  <h2>V</h2>
                </Tooltip>
                <Statistic value={data?.gcs_scales?.motor_response} />
              </Space>
              <Divider type="vertical" />
              <Space direction="vertical">
                <Tooltip title="Motor Response">
                  <h2>M</h2>
                </Tooltip>
                <Statistic value={data?.gcs_scales?.verbal_response} />
              </Space>
            </Space>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Graphs;
