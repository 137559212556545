import { Button, Card, Divider, Form, message, Slider } from "antd";
import React, { useEffect, useState } from "react";
import ShoulderJoint from "./ROMScaleComponents/ShoulderJoint";
import ElbowJoint from "./ROMScaleComponents/ElbowJoint";
import ForearmJoint from "./ROMScaleComponents/ForearmJoint";
import WristJoint from "./ROMScaleComponents/WristJoint";
import ThumbJoint from "./ROMScaleComponents/ThumbJoint";
import MetacarpelJoint from "./ROMScaleComponents/MetacarpelJoint";
import ProximalJoint from "./ROMScaleComponents/ProximalJoint";
import DisitalJoint from "./ROMScaleComponents/DisitalJoint";
import HipJoint from "./ROMScaleComponents/HipJoint";
import KneeJoint from "./ROMScaleComponents/KneeJoint";
import AnkleJoint from "./ROMScaleComponents/AnkleJoint";
import FootJoint from "./ROMScaleComponents/FootJoint";
import GreatToeJoint from "./ROMScaleComponents/GreatToeJoint";
import InterPhallengealJoint from "./ROMScaleComponents/InterPhallengealJoint";
import CervicalSpine from "./ROMScaleComponents/CervicalSpine";
import ThoracoLumbarSpine from "./ROMScaleComponents/ThoracoLumbarSpine";
import {
  getRomScaleAPI,
  romScaleApi,
  updateRomScaleAPI,
} from "../../../../../services/physio.services";
import "./PhysioScales.css";

const ROMScale = ({
  admission,
  onScalesSubmit,
  scalesId,
  setScalesId,
  setScaleTab,
  setActiveTab,
  setVisible,
}) => {
  const [form] = Form.useForm();
  const [currentStep, setCurrentStep] = useState(0);
  const steps = [
    <ShoulderJoint />,
    <ElbowJoint />,
    <ForearmJoint />,
    <WristJoint />,
    <ThumbJoint />,
    <MetacarpelJoint />,
    <ProximalJoint />,
    <DisitalJoint />,
    <HipJoint />,
    <KneeJoint />,
    <AnkleJoint />,
    <FootJoint />,
    <GreatToeJoint />,
    <InterPhallengealJoint />,
    <CervicalSpine />,
    <ThoracoLumbarSpine />,
  ];

  const handleSubmitWithAction = async (values, action) => {
    const finalData = {
      ...values,
      ip_admission: admission,
    };
    try {
      if (scalesId?.rom) {
        await updateRomScaleAPI(scalesId?.rom, finalData);
      } else {
        const response = await romScaleApi(finalData);
        setScalesId((prev) => {
          const newScalesId = { ...prev, rom: response?.data?.id };
          onScalesSubmit(newScalesId);
          return newScalesId;
        });
      }
      // message.success("Form filled successfully");
      if (action === "continue") {
        if (currentStep === 15) {
          setScaleTab((prev) => (parseInt(prev) + 1).toString());
        } else {
          setCurrentStep((prev) => Math.min(prev + 1, steps.length - 1));
        }
      } else if (action === "close") {
        setVisible(false);
      }
    } catch (error) {
      console.log(`Error :${error}`);
      message.error("Failed to load data");
    }
  };

  const handleView = async () => {
    if (scalesId?.rom) {
      getRomScaleAPI(admission, scalesId?.rom)
        .then((response) => {
          form.setFieldsValue(response.data);
        })
        .catch((error) => {
          console.error(`Error: ${error}`);
        });
    }
  };

  useEffect(() => {
    handleView();
  }, [admission]);

  const marks = {
    0: {
      style: { marginTop: -70 },
      label: (
        <span>
          Shoulder
          <br />
          Joint
        </span>
      ),
    },
    1: {
      style: { marginBottom: -70 },
      label: (
        <span>
          Elbow
          <br />
          Joint
        </span>
      ),
    },
    2: {
      style: { marginTop: -70 },
      label: (
        <span>
          Forearm
          <br />
          Joint
        </span>
      ),
    },
    3: {
      style: { marginBottom: -70 },
      label: (
        <span>
          Wrist
          <br />
          Joint
        </span>
      ),
    },
    4: {
      style: { marginTop: -70 },
      label: (
        <span>
          Thumb
          <br />
          Joint
        </span>
      ),
    },
    5: {
      style: { marginBottom: -70 },
      label: (
        <span>
          Meta
          <br />
          Carpel
        </span>
      ),
    },
    6: {
      style: { marginTop: -70 },
      label: (
        <span>
          Proximal
          <br />
          Interphalangeal Joints
        </span>
      ),
    },
    7: {
      style: { marginBottom: -100 },
      label: (
        <span>
          Distal
          <br />
          Interphalangeal
          <br />
          Joints
        </span>
      ),
    },
    8: {
      style: { marginTop: -70 },
      label: (
        <span>
          Hip
          <br />
          Joint
        </span>
      ),
    },
    9: {
      style: { marginBottom: -70 },
      label: (
        <span>
          Knee
          <br />
          Joint
        </span>
      ),
    },
    10: {
      style: { marginTop: -70 },
      label: (
        <span>
          Ankle
          <br />
          Joint
        </span>
      ),
    },
    11: {
      style: { marginBottom: -70 },
      label: (
        <span>
          Foot
          <br />
          Joint
        </span>
      ),
    },
    12: {
      style: { marginTop: -70 },
      label: (
        <span>
          Great
          <br />
          Toe Joint
        </span>
      ),
    },
    13: {
      style: { marginBottom: -70 },
      label: (
        <span>
          Interphalangeal
          <br />
          Joint
        </span>
      ),
    },
    14: {
      style: { marginTop: -70 },
      label: (
        <span>
          Cervical
          <br />
          Spine
        </span>
      ),
    },
    15: {
      style: { marginBottom: -70 },
      label: (
        <span>
          Thoraco
          <br />
          Lumbar Spine
        </span>
      ),
    },
  };

  return (
    <>
      <div className="cardless-scale">
        <Slider
          min={0}
          max={steps.length - 1}
          marks={marks}
          value={currentStep}
          onChange={(value) => setCurrentStep(value)}
          // disabled={true}
          style={{ width: "90%" }}
          className="selection-slider stepper"
        />
        <br />
        <br />
        <br />
        <Form
          id="rom-scale"
          form={form}
          onFinish={(values) => handleSubmitWithAction(values, "continue")}
        >
          {steps[currentStep]}{" "}
          {/* Display the component based on the current step */}
          {currentStep < steps.length - 1 && <Divider />}
        </Form>
      </div>
      <div className="scale-footer">
        <Button
          style={{ marginRight: "8px" }}
          onClick={() =>
            form
              .validateFields()
              .then((values) => handleSubmitWithAction(values, "close"))
          }
        >
          Close
        </Button>

        <Button type="primary" onClick={() => form.submit()}>
          Continue
        </Button>
      </div>
    </>
  );
};

export default ROMScale;
