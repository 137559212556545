import React, { useEffect, useState } from "react";
import { Form, Input, Button, message } from "antd";
import {
  getRpeScaleAPI,
  rpeScaleAPI,
  updateRpeScaleAPI,
} from "../../../../../services/physio.services";
import veryLightSvg from "../../../../../assets/images/scalesImages/veryLight.svg";
import light1Svg from "../../../../../assets/images/scalesImages/light1.svg";
import light2Svg from "../../../../../assets/images/scalesImages/light2.svg";
import moderate1Svg from "../../../../../assets/images/scalesImages/moderate1.svg";
import moderate2Svg from "../../../../../assets/images/scalesImages/moderate2.svg";
import moderate3Svg from "../../../../../assets/images/scalesImages/moderate3.svg";
import vigorous1Svg from "../../../../../assets/images/scalesImages/vigorous1.svg";
import vigorous2Svg from "../../../../../assets/images/scalesImages/vigorous2.svg";
import veryHardSvg from "../../../../../assets/images/scalesImages/veryHard.svg";
import maxEffortSvg from "../../../../../assets/images/scalesImages/maxEffort.svg";
import RPESelection from "./RPEScaleComponents/RPESelection";
import RPEImg from "../../../../../assets/scalesInfo/RPEInfo.png";
import ScalesInfoViewer from "./ScalesInfoViewer";

const scaleInfo = {
  name: "RPE",
  type: "image",
  url: RPEImg,
};

const RPEScale = ({
  admission,
  onScalesSubmit,
  scalesId,
  setScalesId,
  setScaleTab,
  setVisible,
}) => {
  const [form] = Form.useForm();
  const [formData, setFormData] = useState({});
  const [openInfo, setOpenInfo] = useState(false);

  const rpeOptions = [
    {
      value: 1,
      label: "Very Light",
      image: veryLightSvg,
    },
    {
      value: 2,
      label: "Light",
      image: light1Svg,
    },
    {
      value: 3,
      label: "Light",
      image: light2Svg,
    },
    {
      value: 4,
      label: "Moderate",
      image: moderate1Svg,
    },
    {
      value: 5,
      label: "Moderate",
      image: moderate2Svg,
    },
    {
      value: 6,
      label: "Moderate",
      image: moderate3Svg,
    },
    {
      value: 7,
      label: "Vigorous",
      image: vigorous1Svg,
    },
    {
      value: 8,
      label: "Vigorous",
      image: vigorous2Svg,
    },
    {
      value: 9,
      label: "Very Hard",
      image: veryHardSvg,
    },
    {
      value: 10,
      label: "Max Effort",
      image: maxEffortSvg,
    },
  ];

  const handleSubmitWithAction = async (values, action) => {
    const finalData = {
      ...values,
      interpretation: rpeOptions[values?.exertion - 1].label,
      ip_admission: admission,
    };
    try {
      if (Object.keys(formData)?.length !== 0) {
        await updateRpeScaleAPI(scalesId?.rpe, finalData);
      } else {
        const response = await rpeScaleAPI(finalData);
        setScalesId((prev) => {
          const newScalesId = { ...prev, rpe: response?.data?.id };
          onScalesSubmit(newScalesId);
          return newScalesId;
        });
      }
      // message.success("Form filled successfully");
      if (action === "continue") {
        setScaleTab((prev) => (parseInt(prev) + 1).toString());
      } else if (action === "close") {
        setVisible(false);
      }
    } catch (error) {
      console.log(`Error:${error}`);
      message.error("Failed to load data");
    }
  };

  const handleView = async () => {
    if (scalesId?.rpe) {
      getRpeScaleAPI(admission, scalesId?.rpe)
        .then((response) => {
          form.setFieldsValue(response.data);
          setFormData(response.data);
        })
        .catch((error) => {
          console.error(`Error: ${error}`);
        });
    }
  };

  useEffect(() => {
    handleView();
  }, [admission]);

  return (
    <>
      <div className="cardless-scale">
        <Button
          className="scale-info"
          onClick={() => {
            setOpenInfo(true);
          }}
        >
          Info
        </Button>
        <Form
          id="rpe-scale"
          onFinish={(values) => handleSubmitWithAction(values, "continue")}
          form={form}
        >
          <RPESelection />

          <Form.Item className="scales-comments-formitem" name="comments">
            <Input.TextArea placeholder="Comments" rows={4} />
          </Form.Item>
        </Form>
      </div>
      <div className="scale-footer">
        <Button
          size="large"
          style={{ marginRight: "8px" }}
          onClick={() =>
            form
              .validateFields()
              .then((values) => handleSubmitWithAction(values, "close"))
          }
        >
          Close
        </Button>

        <Button size="large" type="primary" onClick={() => form.submit()}>
          Continue
        </Button>
        {openInfo && (
          <ScalesInfoViewer
            data={scaleInfo}
            open={openInfo}
            setOpen={setOpenInfo}
          />
        )}
      </div>
    </>
  );
};

export default RPEScale;
