import { Descriptions, Image, Space } from "antd";
import React, { useContext } from "react";
import { getLabelTagsWithDescription } from "../constructTextFromValues";
import { treatmentGivenOptions } from "../../Options/RespiratoryTherapistOptions";
import treatmentIcon from "../../../../assets/doctorIcons/treatment.svg";

const RespiratoryTreatmentPlanSummary = ({ context }) => {
  const { formData } = useContext(context);
  const constructTreatmentSummaryItems = () => {
    const treatmentSummaryItems = [];
    treatmentSummaryItems.push({
      key: "1",
      label: "Treatment Plan",
      children: (
        <>
          {getLabelTagsWithDescription(
            treatmentGivenOptions,
            formData?.treatment_plan
          )}
        </>
      ),
    });
    return treatmentSummaryItems;
  };
  const treatmentSummaryItems = constructTreatmentSummaryItems();
  return (
    <>
      <Descriptions
        bordered
        column={1}
        title={
          <span style={{ fontWeight: "500", marginLeft: ".2rem" }}>
            <Space>
              <Image src={treatmentIcon} width={18} preview={false} />
              <span style={{ fontSize: "16px", fontWeight: "normal" }}>
                Treatment
              </span>
            </Space>
          </span>
        }
        items={treatmentSummaryItems}
        style={{ margin: "0", border: "none" }}
        className="initial-insp-desc"
      />
    </>
  );
};

export default RespiratoryTreatmentPlanSummary;
