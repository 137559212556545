import {
  Button,
  Card,
  Divider,
  Form,
  Input,
  InputNumber,
  message,
  Radio,
  Select,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";

import {
  genderOptions,
  minsWalkedOptions,
  reasonOptions,
  rpeOptions,
} from "../../../Options/functionalAsessmentOptions";
import {
  getSixMinuteWalkAPI,
  sixMinuteWalkAPI,
  updateSixMinuteWalkAPI,
} from "../../../../../services/physio.services";
import SixMinWalkTestItems from "./SixMinWalkTest/SixMinWalkTestItems";

const SixMinuteWalkTest = ({
  admission,
  onScalesSubmit,
  scalesId,
  setScalesId,
  setScaleTab,
  setActiveTab,
  setVisible,
}) => {
  const [form] = Form.useForm();
  const [states, setStates] = useState({});

  const calculateDistance = () => {
    const { gender, age, height, weight } = states;
    return gender === "male adult"
      ? 7.57 * height - 5.02 * age - 1.76 * weight - 309
      : 2.11 * height - 2.29 * weight - 5.78 * age + 667;
  };

  const handleChange = (key, value) => {
    setStates((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleSubmitWithAction = async (values, action) => {
    const finalData = {
      ...values,
      predicted_distance: calculateDistance().toFixed(0),
      ip_admission: admission,
    };
    try {
      if (scalesId?.smwt) {
        await updateSixMinuteWalkAPI(scalesId?.smwt, finalData);
      } else {
        const response = await sixMinuteWalkAPI(finalData);
        setScalesId((prev) => {
          const newScalesId = { ...prev, smwt: response?.data?.id };
          onScalesSubmit(newScalesId);
          return newScalesId;
        });
      }
      // message.success("Form filled successfully");
      if (action === "continue") {
        setScaleTab((prev) => (parseInt(prev) + 1).toString());
      } else if (action === "close") {
        setVisible(false);
      }
    } catch (error) {
      console.log(`Error :${error}`);
      message.error("Failed to load data");
    }
  };

  const handleView = async () => {
    if (scalesId?.smwt) {
      getSixMinuteWalkAPI(admission, scalesId?.smwt)
        .then((response) => {
          const formData = response.data;
          form.setFieldsValue(formData);
          setStates({
            gender: formData?.gender,
            age: formData?.age,
            height: formData?.height,
            weight: formData?.weight,
          });
        })
        .catch((error) => {
          console.log(`Error :${error}`);
        });
    }
  };

  useEffect(() => {
    handleView();
  }, [admission]);

  const predictedDistance = calculateDistance().toFixed(0);

  return (
    <>
      <div className="cardless-scale">
        <Card style={{ maxWidth: "900px" }}>
          <Form
            id="six-min-walk"
            form={form}
            onFinish={(values) => handleSubmitWithAction(values, "continue")}
            layout="vertical"
          >
            <SixMinWalkTestItems
              predictedDistance={predictedDistance}
              handleChange={handleChange}
              states={states}
            />
          </Form>
        </Card>
      </div>
      <div className="scale-footer">
        <Button
          style={{ marginRight: "8px" }}
          onClick={() =>
            form
              .validateFields()
              .then((values) => handleSubmitWithAction(values, "close"))
          }
        >
          Close
        </Button>

        <Button type="primary" onClick={() => form.submit()}>
          Continue
        </Button>
      </div>
    </>
  );
};

export default SixMinuteWalkTest;
