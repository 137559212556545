import landingImg from "../../assets/images/patientIcons/landing.svg";
import landingActive from "../../assets/images/patientIcons/landingActive.svg";
import medicinesImg from "../../assets/images/patientIcons/medicine.svg";
import medicinesActiveImg from "../../assets/images/patientIcons/medicineActive.svg";
import invImg from "../../assets/images/patientIcons/investigation.svg";
import activeInvImg from "../../assets/images/patientIcons/invActive.svg";
import iaImg from "../../assets/images/patientIcons/initialAssessment.svg";
import activeIa from "../../assets/images/patientIcons/activeIa.svg";
import steth from "../../assets/images/patientIcons/steth.svg";
import stethActive from "../../assets/images/patientIcons/stethActive.svg";
import nurseImg from "../../assets/images/patientIcons/nurse.svg";
import nurseActiveImg from "../../assets/images/patientIcons/nurseActive.svg";
import heart from "../../assets/images/patientIcons/heart.svg";
import heartActive from "../../assets/images/patientIcons/heartActive.svg";
import diet from "../../assets/images/patientIcons/diet.svg";
import dietActive from "../../assets/images/patientIcons/dietActive.svg";
import progressImg from "../../assets/images/patientIcons/progress.svg";
import activeProgressImg from "../../assets/images/patientIcons/activeProgress.svg";
import logImg from "../../assets/images/patientIcons/log.svg";
import activeLogImg from "../../assets/images/patientIcons/activeLog.svg";
import gallery from "../../assets/images/patientIcons/gallery.svg";
import galleryActive from "../../assets/images/patientIcons/galleryActive.svg";
import packages from "../../assets/images/patientIcons/packages.svg";
import packagesActive from "../../assets/images/patientIcons/packagesActive.svg";
import bedManagement from "../../assets/images/patientIcons/bedManagement.svg";
import bedActive from "../../assets/images/patientIcons/bedActive.svg";
import services from "../../assets/images/patientIcons/services.svg";
import serviceActive from "../../assets/images/patientIcons/serviceActive.svg";
import activeBilling from "../../assets/images/patientIcons/activeBillingMenu.svg";
import billingMenu from "../../assets/images/patientIcons/billingMenu.svg";
import physioCareImg from "../../assets/images/patientIcons/physioCare.svg";
import physioCareActiveImg from "../../assets/images/patientIcons/physioCareActive.svg";
import { Image, Tooltip } from "antd";

export const getuserRoleTabs = (ROLE, tab, token) => {
  const activeStyles = {
    height: "50px",
    width: "auto",
    marginBottom: "8px",
    background: token.colorPrimary,
    padding: "10px",
    borderRadius: "6px",
  };
  const styles = {
    height: "50px",
    width: "auto",
    marginBottom: "8px",
    background: "#fff",
    padding: "10px",
    borderRadius: "6px",
    border: "1px solid #D9D9D9",
  };

  const allRoles = [
    "Doctor",
    "Nurse",
    "Speech_Swallow_Therapist",
    "Respiratory_Therapist",
    "Nutritionist",
    "Physiotherapist",
    "Psychologist",
    "Receptionist",
  ];
  const menuImages = (icon, styles, title) => {
    return (
      <Tooltip title={title}>
        <span
          role="img"
          aria-label="user"
          class="anticon anticon-user ant-menu-item-icon"
        >
          <Image src={icon} preview={false} style={styles} />
        </span>
      </Tooltip>
    );
  };
  const roleSpecificItems = {
    Doctor: [
      "landing",
      "logs",
      "ia",
      "review",
      "order-sheet",
      "medicine",
      "monitoring",
    ],
    Physiotherapist: ["ia", "review", "physio-care", "medicine"],
    Nutritionist: ["ia", "risk_assessment"],
    Nurse: ["order-sheet", "ia", "medicine", "monitoring"],
    Speech_Swallow_Therapist: ["ia", "review"],
    Respiratory_Therapist: ["ia", "review"],
    Receptionist: [
      "packages",
      "bed-management",
      "services",
      "billing",
      "gallery",
    ],
    Psychologist: ["ia", "review"],
  };

  const allItems = [
    {
      label:
        tab === "packages"
          ? menuImages(packagesActive, activeStyles, "Packages")
          : menuImages(packages, styles, "Packages"),
      key: "packages",
    },
    {
      label:
        tab === "bed-management"
          ? menuImages(bedActive, activeStyles, "Bed Management")
          : menuImages(bedManagement, styles, "Bed Management"),
      key: "bed-management",
    },
    {
      label:
        tab === "services"
          ? menuImages(serviceActive, activeStyles, "Services")
          : menuImages(services, styles, "Services"),
      key: "services",
    },
    {
      label:
        tab === "billing"
          ? menuImages(activeBilling, activeStyles, "Billing")
          : menuImages(billingMenu, styles, "Billing"),
      key: "billing",
    },
    {
      label:
        tab === "landing"
          ? menuImages(landingActive, activeStyles, "Summary")
          : menuImages(landingImg, styles, "Summary"),
      key: "landing",
    },
    // {
    //   label:
    //     tab === "logs"
    //       ? menuImages(activeLogImg, activeStyles, "Activity")
    //       : menuImages(logImg, styles, "Activity"),
    //   key: "logs",
    // },
    {
      label:
        tab === "ia"
          ? menuImages(activeIa, activeStyles, "Initial Assessment")
          : menuImages(iaImg, styles, "Initial Assessment"),
      key: "ia",
    },
    {
      label:
        tab === "review"
          ? menuImages(stethActive, activeStyles, "Review")
          : menuImages(steth, styles, "Review"),
      key: "review",
    },
    {
      label:
        tab === "risk_assessment"
          ? menuImages(stethActive, activeStyles, "Risk Assessment")
          : menuImages(steth, styles, "Risk Assessment"),
      key: "risk_assessment",
    },
    {
      label:
        tab === "physio-care"
          ? menuImages(physioCareActiveImg, activeStyles, "Physio Care")
          : menuImages(physioCareImg, styles, "Physio Care"),
      key: "physio-care",
    },
    {
      label:
        tab === "medicine"
          ? menuImages(medicinesActiveImg, activeStyles, "Medicine")
          : menuImages(medicinesImg, styles, "Medicine"),
      key: "medicine",
    },
    {
      label:
        tab === "investigations"
          ? menuImages(activeInvImg, activeStyles, "Investigations")
          : menuImages(invImg, styles, "Investigations"),
      key: "investigations",
    },
    {
      label:
        tab === "order-sheet"
          ? menuImages(nurseActiveImg, activeStyles, "Care Items")
          : menuImages(nurseImg, styles, "Care Items"),
      key: "order-sheet",
    },
    {
      label:
        tab === "diet"
          ? menuImages(dietActive, activeStyles, "Diet")
          : menuImages(diet, styles, "Diet"),
      key: "diet",
    },
    {
      label:
        tab === "progress_monitoring"
          ? menuImages(activeProgressImg, activeStyles, "Progress Notes")
          : menuImages(progressImg, styles, "Progress Notes"),
      key: "progress_monitoring",
    },
    {
      label:
        tab === "monitoring"
          ? menuImages(heartActive, activeStyles, "Monitoring")
          : menuImages(heart, styles, "Monitoring"),
      key: "monitoring",
    },
    {
      label:
        tab === "gallery"
          ? menuImages(galleryActive, activeStyles, "Gallery")
          : menuImages(gallery, styles, "Gallery"),
      key: "gallery",
    },
    // {
    //   label: "Other EMRs",
    //   key: "emr",
    //   children: allRoles
    //     .filter((role) => role !== ROLE)
    //     .map((role) => ({
    //       label: role.replace(/_/g, " "), // Convert underscores to spaces for labels
    //       key: role.toLowerCase(),
    //     })),
    // },
    // {
    //   label: "Forms",
    //   key: "forms",
    //   children: [
    //     {
    //       label: "Discharge Summary",
    //       key: "discharge-summary",
    //     },
    //     {
    //       label: "Review Form",
    //       key: "review-form",
    //     },
    //   ],
    // },
  ];

  const getFilteredItems = (role) => {
    const roleItems = roleSpecificItems[role] || [];

    return ROLE !== "Receptionist"
      ? [
          ...allItems.filter((item) => roleItems.includes(item.key)),
          ...allItems.filter((item) =>
            [
              "investigations",
              "forms",
              "diet",
              "progress_monitoring",
              // "monitoring",
              "gallery",
            ].includes(item.key)
          ),
          allItems.find((item) => item.key === "emr"),
        ]
      : [...allItems.filter((item) => roleItems.includes(item.key))];
  };

  return getFilteredItems(ROLE);
};
