import React, { useEffect, useState } from "react";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import {
  Divider,
  Image,
  Space,
  Typography,
  Card,
  Row,
  Col,
  Button,
  Tag,
} from "antd";
import { ArrowLeftOutlined, ExportOutlined } from "@ant-design/icons";
import UploadsView from "./UploadsView";
import GridPdfViewer from "./GridPdfViewer";
import PdfViewer from "./PdfViewer";
import "slick-carousel/slick/slick-theme.css";
import "./AllImageView.css";
import NoDataPage from "../../../NoDataPages/NoDataPage";
import GalleryIcon from "../../../../assets/images/nurseIcons/gallery.svg";

const { Meta } = Card;
const ImageMasonry = ({ data, onGroupSelect, setOpenPdf, setPdfData }) => {
  const [items, setItems] = useState(null);

  useEffect(() => {
    const groupedItems = {};
    if (data) {
      data.forEach((item) => {
        item?.report_path?.forEach((urlItem) => {
          const name = item?.name || item?.investigation_name;

          if (!groupedItems[name]) {
            groupedItems[name] = [];
          }

          if (urlItem?.type?.includes("image")) {
            groupedItems[name].push({
              type: "image",
              url: urlItem.report_url,
              path: urlItem?.path?.replace("curengo/", ""),
            });
          } else {
            groupedItems[name].push({
              type: "pdf",
              url: urlItem.report_url,
              path: urlItem?.path?.replace("curengo/", ""),
            });
          }
        });
      });
    }

    const groupedArray = Object.keys(groupedItems).map((name) => ({
      name,
      items: groupedItems[name],
    }));

    setItems(groupedArray);
  }, [data]);

  items?.forEach((item, index) => {
    console.log(`Item ${index + 1}:`, item);
  });

  

  return (<>
  {
    items?.length > 0 ? (
      <div className="all-img-container">
      {items?.map((group) => (
        <div key={group?.name}>
          <Typography style={{ color: "black", fontSize: "16px" }}>
            {group?.name}
          </Typography>
          {/* <ResponsiveMasonry
            columnsCountBreakPoints={{ 300: 3, 500: 4, 700: 3, 900: 5 }}
          > */}
            <div className="img-container" >
              <Row gutter={[20, 16]}>
                {group?.items.map((item, index) => (
                  <Col
                    key={index}
                    xs={12}
                    sm={6}
                    md={4}
                    lg={4}
                    xl={4}
                    className="five-columns"
                    style={{minWidth:"280px"}}
                  >
                    <div
                      className="masonry-item"
                      onClick={() => {
                        if (item.type === "pdf") {
                          setOpenPdf(true);
                          setPdfData(item);
                        }
                      }}
                    >
                      {item.type === "image" ? (
                        <>
                          <Image
                            alt="example"
                            style={{
                              width: "250px",
                              height: "150px",
                              cursor: "pointer",
                              marginRight:"10px"
                            }}
                            src={item?.url}
                            preview={false}
                            onClick={() => {
                              onGroupSelect(group, item);
                            }}
                          />
                          <Meta
                            title={
                              <Typography
                                className="meta-title"
                                style={{
                                  fontSize: "13px",
                                  marginTop: "5px",
                                }}
                              >
                                {item?.path}
                              </Typography>
                            }
                          />
                        </>
                      ) : (
                        <div
                        onClick={() => {
                          console.log("Click is working");
                          onGroupSelect(group, item);
                        }}
                      >
                        <GridPdfViewer
                          className="img-card"
                          data={[item]}
                          pageNumber={1}
                          height="120px"
                          width="200px"
                        />
                      </div>
                    )}
                  </div>
                </Col>
              ))}
            </Row>
          </div>
          <Divider />
        </div>
      ))}
    </div>
  ) : (
    <Row
    style={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      minHeight: "70vh",
    }}
  >
    <Col span={24} style={{ textAlign: "center" }}>
    <NoDataPage
      title={"Investigation Gallery Empty"}
      subtitle={"No images to be shown at the moment!"}
      image={GalleryIcon}
    />
    </Col>
    </Row>
  )
  }
  </>
 
  );
};

const MasonryImg = ({ data }) => {
  console.log("data", data);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [openPdf, setOpenPdf] = useState(false);
  const [pdfData, setPdfData] = useState([]);
  const [item, setItem] = useState(null);
  const handleGroupSelect = (group, item) => {
    console.log("item", item);
    setSelectedGroup(group);
    setItem(item);
  };

  return (
    <div>
      {selectedGroup ? (
        <>
          <div style={{ textAlign: "left" }}>
            <Button
              size="small"
              type="link"
              style={{
                fontSize: "16px",
                marginBottom: "10px",
              }}
              onClick={() => setSelectedGroup(null)}
            >
              <ArrowLeftOutlined />
              {selectedGroup?.name}
            </Button>
          </div>

          <UploadsView selectedGroup={selectedGroup} selectedItem={item} />
        </>
      ) : (
        //  openPdf ? (
        //   <>
        //     <Typography
        //       style={{
        //         cursor: "pointer",
        //         position: "fixed",
        //         zIndex: 1000,
        //       }}
        //       size="small"
        //       onClick={() => {
        //         setOpenPdf(false);
        //         setSelectedGroup(null);
        //         }}
        //       >
        //         <Space>
        //           <ArrowLeftOutlined />
        //           {pdfData?.path}
        //       </Space>
        //     </Typography>
        //     <PdfViewer pdfData={[pdfData]} />
        //   </>
        // ) :
        <ImageMasonry
          data={data}
          onGroupSelect={handleGroupSelect}
          setOpenPdf={setOpenPdf}
          setPdfData={setPdfData}
        />
      )}
    </div>
  );
};

export default MasonryImg;
