// Import SVG icons from the specified path
import pdfIcon from "../assets/fileicons/pdf-svgrepo-com.svg";
import docIcon from "../assets/fileicons/document-svgrepo-com.svg";
import imageIcon from "../assets/fileicons/image-svgrepo-com.svg";
import defaultIcon from "../assets/fileicons/image-svgrepo-com.svg";

// Define the file paths for each icon
const icons = {
  pdf: pdfIcon,
  doc: docIcon,
  image: imageIcon,
  default: defaultIcon,
};

// Function to get the file icon based on the file type
export const getFileIcon = (file) => {
  if (!file || !file.type) {
    return (
      <img
        src={icons.default}
        alt="Default icon"
        style={{ width: "48px", height: "48px" }}
      />
    );
  }

  console.log("File:", file);
  console.log("File Type:", file.type);

  const mimeType = file.type;

  if (mimeType === "application/pdf") {
    return (
      <img
        src={icons.pdf}
        alt="PDF icon"
        style={{ width: "48px", height: "48px" }}
      />
    );
  } else if (
    mimeType === "application/msword" ||
    mimeType ===
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
  ) {
    return (
      <img
        src={icons.doc}
        alt="Document icon"
        style={{ width: "48px", height: "48px" }}
      />
    );
  } else if (
    ["image/png", "image/jpeg", "image/jpg", "image/svg+xml"].includes(mimeType)
  ) {
    return (
      <img
        src={icons.image}
        alt="Image icon"
        style={{ width: "48px", height: "48px" }}
      />
    );
  } else {
    return (
      <img
        src={icons.default}
        alt="Default icon"
        style={{ width: "48px", height: "48px" }}
      />
    );
  }
};
