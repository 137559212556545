import { Form, InputNumber, Table, Typography } from "antd";
import React from "react";

const ShoulderJoint = ({ initial = false }) => {
  const columns = [
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
    },
    {
      title: "Left Active",
      dataIndex: "left_active",
      key: "left_active",
      render: (text, record) => (
        <Form.Item
          name={
            initial
              ? ["rom", "shoulder_joint", `left_active_${record.value}`]
              : ["shoulder_joint", `left_active_${record.value}`]
          }
        >
          <InputNumber
            min={record.min}
            max={record.max}
            placeholder={`${record.min} to ${record.max}`}
          />
        </Form.Item>
      ),
    },
    {
      title: "Left Passive",
      dataIndex: "left_passive",
      key: "left_passive",
      render: (text, record) => (
        <Form.Item
          name={
            initial
              ? ["rom", "shoulder_joint", `left_passive_${record.value}`]
              : ["shoulder_joint", `left_passive_${record.value}`]
          }
        >
          <InputNumber
            min={record.min}
            max={record.max}
            placeholder={`${record.min} to ${record.max}`}
          />
        </Form.Item>
      ),
    },
    {
      title: "Right Active",
      dataIndex: "right_active",
      key: "right_active",
      render: (text, record) => (
        <Form.Item
          name={
            initial
              ? ["rom", "shoulder_joint", `right_active_${record.value}`]
              : ["shoulder_joint", `right_active_${record.value}`]
          }
        >
          <InputNumber
            min={record.min}
            max={record.max}
            placeholder={`${record.min} to ${record.max}`}
          />
        </Form.Item>
      ),
    },
    {
      title: "Right Passive",
      dataIndex: "right_passive",
      key: "right_passive",
      render: (text, record) => (
        <Form.Item
          name={
            initial
              ? ["rom", "shoulder_joint", `right_passive_${record.value}`]
              : ["shoulder_joint", `right_passive_${record.value}`]
          }
        >
          <InputNumber
            min={record.min}
            max={record.max}
            placeholder={`${record.min} to ${record.max}`}
          />
        </Form.Item>
      ),
    },
  ];

  const data = [
    {
      key: "1",
      action: "Flexion",
      value: "flexion",
      min: 0,
      max: 180,
    },
    {
      key: "2",
      action: "Extension",
      value: "extension",
      min: 0,
      max: 60,
    },
    {
      key: "3",
      action: "Abduction",
      value: "abduction",
      min: 0,
      max: 180,
    },
    {
      key: "4",
      action: "Adduction",
      value: "adduction",
      min: 0,
      max: 180,
    },
    {
      key: "5",
      action: "Medial rotation",
      value: "medial_rotation",
      min: 0,
      max: 70,
    },
    {
      key: "6",
      action: "Lateral rotation",
      value: "lateral_rotation",
      min: 0,
      max: 90,
    },
  ];

  return (
    <>
      <Table columns={columns} dataSource={data} pagination={false} />
    </>
  );
};

export default ShoulderJoint;
