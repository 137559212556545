import React from "react";
import {
  getLabelTag,
  getLabelTagInput,
  getLabelTagsArray,
  getLabelTagsWithDescription,
  getLabelTagYesNO,
} from "../../InspectionSummaries/constructTextFromValues";
import {
  nutritionalRiskType,
  nutritionRiskFactorsOptions,
} from "../../Options/clinicalEvaluationOptions";
import { Descriptions, Image, Space } from "antd";
import { yesNoOptions } from "../../Options/commonOptions";
import {
  dietOptions,
  entrealDietOptions,
  entrealFeedTypeOptions,
  oralDietOptions,
  oralDietTypeOptions,
  otherSpecificationsOptions,
} from "../../Options/dietAdviceOptions";
import riskAssessmentIcon from "../../../../assets/doctorIcons/riskAssessment.svg";

const NutritionistRiskAssessmentSummary = ({ formData }) => {
  const constructRiskSummaryItems = () => {
    const riskSummaryItems = [];
    const functional_diagnosis = formData?.functional_diagnosis || {};
    const pushRiskAssessmentItem = (key, label, children) => {
      riskSummaryItems.push({ key, label, children });
    };

    if (functional_diagnosis?.bmi) {
      pushRiskAssessmentItem(
        "1",
        "BMI",
        <>
          {getLabelTagInput("Height", functional_diagnosis?.bmi?.height)}
          {getLabelTagInput("Weight", functional_diagnosis?.bmi?.weight)}
        </>
      );
    }
    if (functional_diagnosis?.nutrition_risk_factor_details?.risk_factors) {
      pushRiskAssessmentItem(
        "2",
        "Nutrition Risk Factors",
        <>
          {getLabelTagsArray(
            nutritionRiskFactorsOptions,
            functional_diagnosis?.nutrition_risk_factor_details?.risk_factors
          )}
          {getLabelTagInput(
            "Other Details",
            functional_diagnosis?.nutrition_risk_factor_details
              ?.others_description
          )}
        </>
      );
    }
    if (functional_diagnosis?.nutritional_risk_details?.risk) {
      pushRiskAssessmentItem(
        "3",
        "Nutritional Risk",
        <>
          {getLabelTagYesNO(
            functional_diagnosis?.nutritional_risk_details?.risk
          )}
          {getLabelTag(
            nutritionalRiskType,
            functional_diagnosis?.nutritional_risk_details?.risk_type
          )}
          {getLabelTagInput(
            "Reason for Risk",
            functional_diagnosis?.nutritional_risk_details?.risk_description
          )}
        </>
      );
    }
    if (functional_diagnosis?.calories) {
      pushRiskAssessmentItem(
        "4",
        "Calories & Protein",
        <>
          {getLabelTagInput("Calories", functional_diagnosis?.calories)}
          {getLabelTagInput("Protein", functional_diagnosis?.protein)}
        </>
      );
    }
    if (functional_diagnosis?.diet_details?.diet) {
      pushRiskAssessmentItem(
        "5",
        "Type of Diet",
        <>
          {getLabelTag(dietOptions, functional_diagnosis?.diet_details?.diet)}
          {getLabelTag(
            oralDietOptions,
            functional_diagnosis?.diet_details?.type_of_diet
          )}
          {getLabelTag(
            entrealDietOptions,
            functional_diagnosis?.diet_details?.type_of_diet
          )}
          {getLabelTag(
            oralDietTypeOptions,
            functional_diagnosis?.diet_details?.type_of_oral_diet
          )}
          {getLabelTag(
            entrealFeedTypeOptions,
            functional_diagnosis?.diet_details?.type_of_enteral_feed
          )}
        </>
      );
    }
    if (
      getLabelTagsWithDescription(
        otherSpecificationsOptions,
        functional_diagnosis?.other_specifications
      )
    ) {
      pushRiskAssessmentItem(
        "6",
        "Other Specifications",
        getLabelTagsWithDescription(
          otherSpecificationsOptions,
          functional_diagnosis?.other_specifications
        )
      );
    }
    if (functional_diagnosis?.patient_family_education) {
      pushRiskAssessmentItem(
        "4",
        "Patient Family Education",
        getLabelTag(
          yesNoOptions,
          functional_diagnosis?.patient_family_education
        )
      );
    }
    return riskSummaryItems;
  };
  const riskSummaryItems = constructRiskSummaryItems();
  return (
    <>
      {riskSummaryItems.length > 0 && (
        <Descriptions
          bordered
          title={
            <span style={{ fontWeight: "500", marginLeft: ".2rem" }}>
              <Space>
                <Image src={riskAssessmentIcon} width={18} preview={false} />
                <span style={{ fontSize: "16px", fontWeight: "normal" }}>
                  Risk Assessment
                </span>
              </Space>
            </span>
          }
          column={1}
          items={riskSummaryItems}
          style={{ margin: "0", border: "none" }}
          className="initial-insp-desc"
        />
      )}
    </>
  );
};

export default NutritionistRiskAssessmentSummary;
