import React, { useContext, useState, useEffect } from "react";
import { Form, Radio, InputNumber, DatePicker } from "antd";
import {
  feedingTubeOptions,
  feedingTubeTypeOptions,
  tubeAspirationOptions,
  tubePositionOptions,
} from "../../../../Options/genExamOptions";
import dayjs from "dayjs";

const FeedingTube = ({ dailyReview = false, context }) => {
  const { formData } = useContext(context);

  const [usingFeedingTube, setUsingFeedingTube] = useState(
    formData?.general_examination?.feeding_tube === "yes" ||
      formData?.examination?.feeding_tube === "yes"
  );
  useEffect(() => {
    setUsingFeedingTube(
      formData?.general_examination?.feeding_tube === "yes" ||
        formData?.examination?.feeding_tube === "yes"
    );
  }, [formData]);
  return (
    <>
      <Form.Item label="Feeding Tube" name="feeding_tube">
        <Radio.Group
          options={feedingTubeOptions}
          onChange={(e) => setUsingFeedingTube(e.target.value === "yes")}
        />
      </Form.Item>
      {usingFeedingTube && (
        <div className="subsection-card">
          <Form.Item name="feeding_tube_type">
            <Radio.Group options={feedingTubeTypeOptions} />
          </Form.Item>
          <Form.Item label="Position" name={`feedingtube_position`}>
            <Radio.Group options={tubePositionOptions} />
          </Form.Item>

          {dailyReview ? (
            <Form.Item name="feedingtube_start_date">
              <DatePicker
                disabledDate={(current) =>
                  current && current > dayjs().endOf("day")
                }
              />
            </Form.Item>
          ) : (
            <Form.Item name="feedingtube_no_days">
              <InputNumber addonBefore="No.of Days" style={{ width: "15%" }} />
            </Form.Item>
          )}

          <Form.Item label="Tube Aspirations" name={`feedingtube_aspirations`}>
            <Radio.Group options={tubeAspirationOptions} />
          </Form.Item>
        </div>
      )}
    </>
  );
};

export default FeedingTube;
