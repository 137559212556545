import React, { useContext, useEffect } from "react";
import { SpeechReviewContext } from "./SpeechSwallowReviewFormProvider";
import { Col, message, Row } from "antd";
import SpeechSwallowFunctionalDiagnosisSummary from "../../InspectionSummaries/FunctionalExaminationSummary/SpeechSwallowFunctionalDiagnosisSummary";
import SpeechSwallowScalesReviewSummary from "../../InspectionSummaries/Scales/SpeechSwallowScalesReviewSummary";
import { getSpeechReviewAPI } from "../../../../services/speech.services";
import { checkEmpty } from "../../../../helpers/utility";

const SpeechSwallowReviewSummary = ({ editId, dailyRefresh }) => {
  const { formData, setFormData } = useContext(SpeechReviewContext);
  const openEdit = async () => {
    if (editId) {
      try {
        const response = await getSpeechReviewAPI(editId);
        setFormData(response.data.data);
      } catch (error) {
        console.log(`Error: ${error}`);
        message.error("Unable to Fetch Data");
      }
    }
  };
  useEffect(() => {
    openEdit();
  }, [editId, dailyRefresh]);
  return (
    <Row className="summaries" gutter={[16, 16]}>
      <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
        {checkEmpty(formData?.functional_diagnosis) && (
          <SpeechSwallowFunctionalDiagnosisSummary formData={formData} />
        )}
      </Col>
      <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
        {checkEmpty(formData?.scales) && (
          <SpeechSwallowScalesReviewSummary formData={formData} />
        )}
      </Col>
    </Row>
  );
};

export default SpeechSwallowReviewSummary;
