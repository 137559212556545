import React, { useContext, useEffect, useState } from "react";
import { FacilityID } from "../../../constants/defaultKeys";
import { UserContext } from "../../../context/UserContext";
import { getCookie } from "../../../helpers/localStorage";
import { getAppointmentListApi } from "../../../services/receptionist.services";
import btnStyles from "../../../styles/Custom/Button.module.css";
import inputStyles from "../../../styles/Custom/Input.module.css";
import AppointmentsTable from "./AppointmentsTable";
import AppointsModal from "./AppointsModal";
import { PlusOutlined } from "@ant-design/icons";
import { Button, Row, Col, Input, Space, DatePicker } from "antd";
import { debounce } from "../../../helpers/utility";
import dayjs from "dayjs";

const dateFormat = "YYYY-MM-DD";
const Appointments = () => {
  const curDate = dayjs().format(dateFormat);
  const { userDetails } = useContext(UserContext);
  const [data, setData] = useState(null);
  const [date, setDate] = useState(curDate);
  const [open, setOpen] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [searchQuery, setSearchQuery] = useState(null);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);

  const refreshPage = () => {
    setRefresh((prev) => !prev);
  };

  useEffect(() => {
    let params = {
      page: page,
      facility: Number(getCookie(FacilityID)) || userDetails?.currFacility,
      appointment_date: date,
    };
    if (searchQuery) {
      params.search = searchQuery;
    }
    const queryString = new URLSearchParams(params);
    setLoading(true);
    getAppointmentListApi(queryString)
      .then((res) => {
        setData(res?.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, [
    date,
    refresh,
    searchQuery,
    page,
    Number(getCookie(FacilityID)) || userDetails?.currFacility,
  ]);
  const onContactSearch = (value) => {
    setSearchQuery(value?.target?.value);
  };
  const onDateChange = (date, dateString) => {
    setDate(date ? dateString : null);
  };
  return (
    <Row gutter={[12, 12]}>
      <Col span={24} className="flex-between">
        <Input.Search
          style={{ width: "30%", marginBottom: "10px" }}
          size="large"
          placeholder="Search for appointments"
          onChange={debounce(onContactSearch, 300)}
        ></Input.Search>
        <Space>
          <DatePicker
            // disabledDate={disabledDate}
            className={inputStyles.mdDateInput}
            onChange={onDateChange}
            defaultValue={dayjs(curDate)}
            allowClear={false}
          />
          <Button
            type="primary"
            className={btnStyles.mdBtn}
            onClick={() => setOpen(true)}
          >
            <PlusOutlined /> Appointments
          </Button>
        </Space>
      </Col>
      <Col span={24}>
        <AppointmentsTable
          loading={loading}
          data={data}
          refresh={refreshPage}
          page={page}
          setPage={setPage}
        />
      </Col>

      {open && (
        <AppointsModal open={open} setOpen={setOpen} refresh={refreshPage} />
      )}
    </Row>
  );
};

export default Appointments;
