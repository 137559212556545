import React, { useRef, useState, useEffect } from "react";
import {
  Drawer,
  Form,
  Space,
  Input,
  Button,
  Row,
  Col,
  Typography,
  Switch,
  message,
} from "antd";
import { PlusOutlined, CloseOutlined, DeleteOutlined } from "@ant-design/icons";
import btnStyles from "../../../styles/Custom/Button.module.css";
import { createRoomTypeApi } from "../../../services/beds.services";
import inputStyles from "../../../styles/Custom/Input.module.css";
import RoomTypeList from "./RoomTypeList";

const RoomTypeForm = ({
  open,
  setOpen,
  editListData,
  refreshPage,
  refresh,
}) => {
  const formRef = useRef(null);
  const [form] = Form.useForm();
  const [btnLoading, setBtnLoading] = useState(false);
  const [editingId, setEditingId] = useState(null);
  const [isRoomAdded, setIsRoomAdded] = useState(false); // Track if any room type is added

  useEffect(() => {
    const editingItem = editListData?.find((item) => item.id === editingId);
    if (editingItem) {
      form.setFieldsValue(editingItem);
    }
  }, [editingId, form, editListData, refresh]);

  const onFinish = (values) => {
    setBtnLoading(true);
    createRoomTypeApi(values?.users)
      .then((res) => {
        message.success(res?.data?.message);
        setOpen(false);
        refreshPage();
        setBtnLoading(false);
      })
      .catch((err) => {
        message.error(err?.response?.data?.message);
        console.log(err);
        setBtnLoading(false);
      });
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <Drawer
      open={open}
      width={"30%"}
      title={
        <Row>
          <Col span={12} className="flex-start">
            <Typography
              style={{
                fontWeight: "400",
                fontSize: "20px",
              }}
            >
              Manage Room Types
            </Typography>
          </Col>
          <Col span={12} className="flex-end">
            <CloseOutlined onClick={onClose} />
          </Col>
        </Row>
      }
      footer={
        <div style={{ textAlign: "right" }}>
          {!editingId && isRoomAdded && (
            <Form.Item>
              <Button
                loading={btnLoading}
                className={btnStyles.lgBtn}
                type="primary"
                htmlType="submit"
                onClick={() => {
                  if (formRef.current) {
                    formRef.current.submit();
                  }
                }}
              >
                Submit
              </Button>
            </Form.Item>
          )}
        </div>
      }
      onClose={onClose}
      closeIcon={null}
    >
      <RoomTypeList
        editListData={editListData}
        refreshPage={refreshPage}
        refresh={refresh}
        editingId={editingId}
        setEditingId={setEditingId}
      />

      {!editingId && (
        <Form
          ref={formRef}
          form={form}
          name="dynamic_form_nest_item"
          onFinish={onFinish}
          style={{ maxWidth: 600 }}
          autoComplete="off"
          layout="vertical"
          // requiredMark={false}
        >
          <Form.List name="users">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <Space
                    key={key}
                    style={{ display: "flex", marginBottom: 8 }}
                    align="baseline"
                  >
                    <Form.Item
                      {...restField}
                      name={[name, "name"]}
                      rules={[{ required: true, message: "Enter room type" }]}
                    >
                      <Input
                        placeholder="Enter room type"
                        className={inputStyles.mdInput}
                      />
                    </Form.Item>
                    <Form.Item name={[name, "ac"]} {...restField}>
                      <Switch
                        checkedChildren="AC"
                        unCheckedChildren="Non AC"
                        defaultChecked
                      />
                    </Form.Item>
                    <DeleteOutlined
                      onClick={() => {
                        remove(name);
                        if (fields.length === 1) {
                          setIsRoomAdded(false);
                        }
                      }}
                    />
                  </Space>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => {
                      add({ name: "", ac: true });
                      setIsRoomAdded(true);
                    }}
                    block
                    icon={<PlusOutlined />}
                  >
                    Add Room Type
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </Form>
      )}
    </Drawer>
  );
};

export default RoomTypeForm;
