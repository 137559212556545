import { Descriptions, Card, Button } from "antd";
import React from "react";
import useQuery from "../../../../hooks/useQuery";
import { useNavigate } from "react-router-dom";

const SummaryInvestigations = ({ data }) => {
  const params = useQuery();
  const navigate = useNavigate();
  const url = new URL(window?.location?.href);
  const patientType = url?.searchParams?.get("patient-type");
  const status = url?.searchParams?.get("status");
  console.log(status);

  return (
    <Card
      title="Recent Investigations"
      style={{ marginTop: "10px" }}
      styles={{ header: { borderBottom: "none" }, body: { paddingTop: "0px" } }}
    >
      <Descriptions bordered={false}>
        {data?.map((invs) => (
          <Descriptions.Item span={2} key={invs?.id}>
            <Button
              type="text"
              style={{ marginRight: "15px", textAlign: "left" }}
              onClick={() => navigate(`/doctor/home?patient-type=${patientType}&status=${status}&patient=${params?.patient}&type=investigations`)}
            >
              {invs?.name || invs?.investigation?.name}
            </Button>
          </Descriptions.Item>
        ))}
      </Descriptions>
    </Card>
  );
};

export default SummaryInvestigations;
