import React, { useContext, useRef, useState, useEffect } from "react";
import {
  Drawer,
  Row,
  Col,
  Typography,
  Form,
  Input,
  Button,
  DatePicker,
  message,
  InputNumber,
} from "antd";
import btnStyles from "../../../../styles/Custom/Button.module.css";
import { CloseOutlined } from "@ant-design/icons";
import inputStyles from "../../../../styles/Custom/Input.module.css";
import { validateConfig } from "../../../../config/validate.config";
import { UserContext } from "../../../../context/UserContext";
import { assignIpServicesApi } from "../../../../services/receptionist.services";
import useQuery from "../../../../hooks/useQuery";
import dayjs from "dayjs";
import { getCookie } from "../../../../helpers/localStorage";
import { FacilityID } from "../../../../constants/defaultKeys";

const AssignServiceModal = ({ open, setOpen, editData, refresh }) => {
  const params = useQuery();
  const [form] = Form.useForm();
  const formRef = useRef(null);
  const { userDetails } = useContext(UserContext);
  const [loading, setLoading] = useState(false);

  const onFinish = (values) => {
    setLoading(true);
    assignIpServicesApi(
      params?.patient,
      `facility=${Number(getCookie(FacilityID)) || userDetails?.currFacility}`,
      {
        ...values,
        service: editData?.id,
        from_date: dayjs(values?.from_date).format("YYYY-MM-DD"),
        till_date: dayjs(values?.till_date).format("YYYY-MM-DD"),
      }
    )
      .then((res) => {
        message.success(res?.data?.message);
        setLoading(false);
        setOpen(false);
        refresh();
      })
      .catch((err) => {
        message.error(err?.response?.data?.message);
        setLoading(false);
      });
  };
  return (
    <div>
      <Drawer
        width={"30%"}
        open={open}
        title={
          <Row>
            <Col span={12} className="flex-start">
              <Typography
                style={{
                  marginLeft: "12px",
                  fontWeight: "400",
                  fontSize: "20px",
                }}
              >
                {editData ? "Assign Service" : "Add Patient"}
              </Typography>
            </Col>
            <Col span={12} className="flex-end">
              <CloseOutlined onClick={() => setOpen(false)} />
            </Col>
          </Row>
        }
        footer={
          <div className="flex-end" style={{ gap: "24px" }}>
            <Form.Item>
              <Button
                type="primary"
                ghost
                className={btnStyles.mdBtn}
                onClick={() => setOpen(false)}
              >
                Cancel
              </Button>
            </Form.Item>
            <Form.Item>
              <Button
                loading={loading}
                htmlType="submit"
                type="primary"
                className={btnStyles.mdBtn}
                onClick={() => {
                  if (formRef.current) {
                    formRef.current.submit();
                  }
                }}
              >
                Save
              </Button>
            </Form.Item>
          </div>
        }
        onClose={() => setOpen(false)}
        closeIcon={null}
      >
        <Form
          layout="vertical"
          // requiredMark={false}
          onFinish={onFinish}
          form={form}
          ref={formRef}
          initialValues={{
            quantity: 1,
            from_date: dayjs(),
            till_date: dayjs(),
          }}
        >
          <Form.Item
            label="Number of times"
            name="quantity"
            rules={validateConfig.isRequired("No.Of Times")}
          >
            <InputNumber
              defaultValue={1}
              className={inputStyles.mdNumberInput}
              style={{ width: "300px" }}
              placeholder="No of times"
            />
          </Form.Item>
          <Form.Item
            label="From"
            name="from_date"
            rules={validateConfig.isRequired("From Date")}
          >
            <DatePicker
              defaultValue={dayjs(dayjs().format("YYYY-MM-DD"))}
              // showTime={{ format: "HH:mm" }}
              format="YYYY-MM-DD"
              className={inputStyles.mdDateInput}
              style={{ width: "300px" }}
            />
          </Form.Item>
          <Form.Item
            label="To"
            name="till_date"
            rules={validateConfig.isRequired("To Date")}
          >
            <DatePicker
              defaultValue={dayjs(dayjs().format("YYYY-MM-DD"))}
              // showTime={{ format: "HH:mm" }}
              format="YYYY-MM-DD"
              className={inputStyles.mdDateInput}
              style={{ width: "300px" }}
            />
          </Form.Item>
          <Form.Item label="Info" name="comments">
            <Input.TextArea
              placeholder="Enter Info"
              className={inputStyles.mdInput}
              style={{ width: "300px" }}
            />
          </Form.Item>
        </Form>
      </Drawer>
    </div>
  );
};

export default AssignServiceModal;
