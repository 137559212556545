import React from "react";
import { Form, Radio, Input } from "antd";
import { skullOptions } from "../../../../Options/systemicExamOptions";

const Skull = () => {
  return (
    <>
      <Form.Item label="Skull" name="skull">
        <Radio.Group options={skullOptions} />
      </Form.Item>
      <Form.Item name="skull_description">
        <Input type="text" placeholder="Description" style={{width:"30%"}} />
      </Form.Item>
    </>
  );
};

export default Skull;
