import React from "react";
import {
  Row,
  Col,
  Card,
  List,
  Form,
  InputNumber,
  Checkbox,
  Button,
  Select,
  Space,
} from "antd";
import inputStyles from "../../../styles/Custom/Input.module.css";
import btnStyles from "../../../styles/Custom/Button.module.css";
import { paymentKeys } from "../receptionKeys";
import RecordPayments from "./RecordPayments";
import { formatToINR } from "../../../helpers/utility";

const priceTagList = [
  { name: "₹", id: false },
  { name: "%", id: true },
];

const SummaryBillCards = ({
  totalPrice,
  finalDiscountedPrice,
  partialPayment,
  setPartialPayment,
  id,
  isSaving,
  isPrinting,
  paymentData,
  form,
  setPrintButton,
  amtPayable,
  doubleDiscount,
  setDoubleDiscount,
}) => {
  const paidAmount =
    paymentData?.gross_amount -
    paymentData?.total_discount -
    paymentData?.due_amount;
  return (
    <Row gutter={[12, 12]}>
      <Col span={24}>
        <Card title="Bill Summary">
          <List size="small" split={false}>
            <List.Item>
              <List.Item.Meta title="SubTotal (Including Taxes)" />
              <Form.Item name="gross_amount">
                <div style={{ paddingRight: "150px" }}>
                  {totalPrice < 0 ? 0 : formatToINR(totalPrice)}
                </div>
              </Form.Item>
            </List.Item>

            <List.Item>
              <List.Item.Meta title="Discount" />
              <Space.Compact>
                <Form.Item
                  name="total_discount"
                  rules={[
                    {
                      validator: (_, value, callback) => {
                        const percentage = form.getFieldValue("is_percent");
                        if (value && value !== "") {
                          if (/^[0-9]*\.?[0-9]{1,3}$/.test(value)) {
                            if (percentage === true && Number(value) > 100) {
                              callback("Discount cannot be greater than 100%");
                            } else if (
                              percentage === false &&
                              Number(value) > Number(finalDiscountedPrice)
                            ) {
                              callback(
                                "Discount cannot be greater than total amount"
                              );
                            } else {
                              callback();
                            }
                          } else {
                            callback("Please enter a valid discount");
                          }
                        } else {
                          callback();
                        }
                      },
                    },
                  ]}
                >
                  <InputNumber
                    disabled={id}
                    style={{ width: "150px" }}
                    min={0}
                    className={inputStyles.mdNumberInput}
                  />
                </Form.Item>
                <Form.Item name="is_percent" style={{ width: "50px" }}>
                  <Select
                    disabled={id}
                    className={inputStyles.mdSelect}
                    defaultValue={false}
                    onChange={() => {
                      form.setFields([
                        {
                          name: "total_discount",
                          errors: [],
                        },
                      ]);

                      form.validateFields(["total_discount"]);
                    }}
                  >
                    {priceTagList?.map((data) => {
                      return (
                        <Select.Option key={data?.id} value={data?.id}>
                          {data?.name}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Space.Compact>
            </List.Item>
            <List.Item>
              <List.Item.Meta title="Total" />
              <Form.Item name="total_amount">
                <div style={{ paddingRight: "150px" }}>
                  {finalDiscountedPrice < 0
                    ? 0
                    : formatToINR(finalDiscountedPrice)}
                </div>
              </Form.Item>
            </List.Item>
            <List.Item>
              <List.Item.Meta title="Amount Paid till date" />

              <div style={{ paddingRight: "150px" }}>
                {paidAmount < 0 ? 0 : formatToINR(paidAmount)}
              </div>
            </List.Item>
          </List>

          <Col span={24}>
            <List size="small" split={false}>
              <List.Item>
                <List.Item.Meta title="Due Amount" />
                <Form.Item
                  name={["payment", "amount"]}
                  rules={[
                    {
                      validator: (_, value) => {
                        if (
                          value &&
                          Number(value) > Number(finalDiscountedPrice)
                        ) {
                          return Promise.reject(
                            "Amount cannot be greater than total amount"
                          );
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <InputNumber
                    suffix="₹"
                    min={0}
                    disabled={!partialPayment}
                    style={{ width: "200px" }}
                    className={inputStyles.mdNumberInput}
                  />
                </Form.Item>
              </List.Item>
              <div className="flex-between">
                {" "}
                <Form.Item name="partial_payment">
                  <Checkbox
                    checked={partialPayment}
                    onChange={(e) => setPartialPayment(e.target.checked)}
                  />{" "}
                  Partial Payment
                </Form.Item>
                {id && (
                  <Form.Item name="partial_payment">
                    <Checkbox
                      checked={doubleDiscount}
                      onChange={(e) => setDoubleDiscount(e.target.checked)}
                    />{" "}
                    Double Discount
                  </Form.Item>
                )}
              </div>

              {id && doubleDiscount && (
                <List.Item>
                  <List.Item.Meta title="Double Discount" />
                  <Space.Compact>
                    <Form.Item
                      name="discount"
                      rules={[
                        {
                          validator: (_, value, callback) => {
                            const percentage =
                              form.getFieldValue("is_total_percent");
                            if (value && value !== "") {
                              if (/^[0-9]*\.?[0-9]{1,3}$/.test(value)) {
                                if (
                                  percentage === true &&
                                  Number(value) > 100
                                ) {
                                  callback(
                                    "Discount cannot be greater than 100%"
                                  );
                                } else if (
                                  percentage === true &&
                                  (Number(value) * totalPrice) / 100 >
                                    paymentData?.due_amount
                                ) {
                                  callback(
                                    "Discount cannot be greater than payable amount"
                                  );
                                } else if (
                                  percentage === false &&
                                  Number(value) >
                                    Number(paymentData?.due_amount)
                                ) {
                                  callback(
                                    "Discount cannot be greater than payment amount"
                                  );
                                } else {
                                  callback();
                                }
                              } else {
                                callback("Please enter a valid discount");
                              }
                            } else {
                              callback();
                            }
                          },
                        },
                      ]}
                    >
                      <InputNumber
                        style={{ width: "150px" }}
                        min={0}
                        className={inputStyles.mdNumberInput}
                      />
                    </Form.Item>
                    <Form.Item
                      name="is_total_percent"
                      style={{ width: "50px" }}
                    >
                      <Select
                        className={inputStyles.mdSelect}
                        defaultValue={false}
                        onChange={() => {
                          form.setFields([
                            {
                              name: "discount",
                              errors: [],
                            },
                          ]);

                          form.validateFields(["discount"]);
                        }}
                      >
                        {priceTagList?.map((data) => {
                          return (
                            <Select.Option key={data?.id} value={data?.id}>
                              {data?.name}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Space.Compact>
                </List.Item>
              )}
              {id && !partialPayment && doubleDiscount && (
                <List.Item>
                  <List.Item.Meta title="Final Amount Payable" />

                  <div style={{ paddingRight: "150px" }}>
                    {" "}
                    {formatToINR(amtPayable)}
                  </div>
                </List.Item>
              )}
              <List.Item>
                <List.Item.Meta title="Payment Mode" />
                <Form.Item
                  name={["payment", "mode"]}
                  rules={[
                    { required: true, message: "Please select a payment mode" },
                  ]}
                >
                  <Select
                    style={{ width: "200px" }}
                    options={paymentKeys}
                    placeholder="Select Payment Mode"
                    className={inputStyles.mdSelect}
                  />
                </Form.Item>
              </List.Item>
            </List>

            <div
              className="flex-between"
              style={{ justifyContent: "center", marginTop: "10px" }}
            >
              <Space>
                <Button
                  loading={isSaving}
                  type="primary"
                  htmlType="submit"
                  ghost
                  className={btnStyles.lgBtn}
                >
                  Save
                </Button>
                <Button
                  loading={isPrinting}
                  type="primary"
                  htmlType="submit"
                  className={btnStyles.lgBtn}
                  onClick={() => setPrintButton(true)}
                >
                  {partialPayment ? "Print Receipt" : "Print Bill"}
                </Button>
              </Space>
            </div>
          </Col>
        </Card>
      </Col>
      <Col span={24}>
        {id && (
          <RecordPayments
            paymentData={paymentData?.payments}
            bill_id={paymentData?.id}
          />
        )}
      </Col>
    </Row>
  );
};

export default SummaryBillCards;
