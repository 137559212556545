export const checkboxOptions = [
  { label: "Cardio", value: "cardio" },
  { label: "Ortho", value: "ortho" },
  { label: "Sports", value: "sports" },
  { label: "Neuro", value: "neuro" },
];

export const cardioDiagnosisOptions = [
  { label: "Post Surgical Rehab", value: "post_surgical_rehab" },
  { label: "Pre Surgical Rehab", value: "pre_surgical_rehab" },
  { label: "Asthma", value: "asthma" },
  { label: "COPD", value: "copd" },
  { label: "ILD", value: "ild" },
  { label: "ICU Acquired Weakness", value: "icu_acquired_weakness" },
];

export const orthoDiagnosisOptions = [
  { label: "Cervical Spondylosis", value: "cervical_spondylosis" },
  { label: "Muscle Stiffness", value: "muscle_stiffness" },
  { label: "Trapezitis", value: "trapezitis" },
  { label: "Facet Joint Arthralgia", value: "facet_joint_arthralgia" },
  { label: "Kyphosis", value: "kyphosis" },
  { label: "Scoliosis", value: "scoliosis" },
  { label: "Athritis", value: "athritis" },
  { label: "Tendinitis", value: "tendinitis" },
  { label: "Bursitis", value: "bursitis" },
  {
    label: "Capsular Pattern Joint Tightness",
    value: "Capsular Pattern Joint Tightness",
  },
  { label: "Subluxation", value: "subluxation" },
  { label: "Tennis Elbow", value: "tennis_elbow" },
  { label: "Golfers Elbow", value: "golfers_elbow" },
  { label: "Fracture Joint", value: "fracture_joint" },
  { label: "Fascitis", value: "fascitis" },
  { label: "Frozen Shoulder", value: "frozen_shoulder" },
  { label: "Ligament Injury", value: "ligament_injury" },
  { label: "Fibromyalgia", value: "figromyalgia" },
];

export const sportsDiagnosisOptions = [
  { label: "Cervical Spondylosis", value: "cervical_spondylosis" },
  { label: "Muscle Stiffness", value: "muscle_stiffness" },
  { label: "Trapezitis", value: "trapezitis" },
  { label: "Facet Joint Arthralgia", value: "facet_joint_arthralgia" },
  { label: "Kyphosis", value: "kyphosis" },
  { label: "Scoliosis", value: "scoliosis" },
  { label: "Athritis", value: "athritis" },
  { label: "Tendinitis", value: "tendinitis" },
  { label: "Bursitis", value: "bursitis" },
  {
    label: "Capsular Pattern Joint Tightness",
    value: "Capsular Pattern Joint Tightness",
  },
  { label: "Subluxation", value: "subluxation" },
  { label: "Tennis Elbow", value: "tennis_elbow" },
  { label: "Golfers Elbow", value: "golfers_elbow" },
  { label: "Fracture Joint", value: "fracture_joint" },
  { label: "Fascitis", value: "fascitis" },
  { label: "Frozen Shoulder", value: "frozen_shoulder" },
  { label: "Ligament Injury", value: "ligament_injury" },
  { label: "Fibromyalgia", value: "figromyalgia" },
  { label: "Muscle Sprain/Strain", value: "muscle_sprain" },
  { label: "Hamstring Pull", value: "hamstring_pull" },
  { label: "Muscle Tear", value: "muscle_tear" },
  { label: "Achilles Tendinitis", value: "achilles_tendinitis" },
  { label: "Patellar Syndrome", value: "patellar_syndrome" },
  { label: "Others", value: "others" },
];

export const neuroDiagnosisOptions = [
  {
    label: "Amyotrophic Lateral Sclerosis",
    value: "amyotrophic_lateral_sclerosis",
  },
  { label: "Stroke", value: "stroke" },
  { label: "Head Injury", value: "head_injury" },
  { label: "Spinal Cord Injury", value: "spinal_cord_injury" },
  { label: "Nerve Compression", value: "nerve_compression" },
  { label: "Carpel Tunnel Syndrome", value: "carpel_tunnel_syndrome" },
  { label: "Aphasia", value: "aphasia" },
  { label: "Ataxia", value: "ataxia" },
  { label: "Parkinson's Disease", value: "parkinsons_disease" },
  { label: "Multiple Sclerosis", value: "multiple_sclerosis" },
  { label: "ADHD", value: "adhd" },
  { label: "Autism", value: "autism" },
  { label: "Bells Palsy", value: "bells_palsy" },
  { label: "Cranial Nerve Injury", value: "cranial_nerve_injury" },
  { label: "Brachial Plexus Injury", value: "brachial_plexus_injury" },
  { label: "Cerebral Palsy", value: "cerebral_palsy" },
  { label: "Dementia", value: "dementia" },
  { label: "Alzheimer's", value: "alzheimers" },
  { label: "GBS", value: "gbs" },
  { label: "Transverse Myelitis", value: "transverse_myelitis" },
  { label: "Others", value: "others" },
];

export const orthoGoalsOptions = [
  { label: "Flexibility and Joint ROM", value: "flexibility_joint_rom" },
  { label: "Strength and Endurance", value: "strength_endurance" },
  { label: "Control Pain and Swelling", value: "control_pain_swelling" },
  {
    label:
      "Activities to Improve Mobility (Movement), Muscle Control,Gait (Walking), and Balance",
    value: "mobility",
  },
  { label: "Others", value: "others" },
];

export const cardioGoalsOptions = [
  {
    label:
      "Return to work if appropriate and/or previous level of functional capacity",
    value: "return_to_work",
  },
  {
    label: "To promote independence in ADLs for those who are compromised",
    value: "promote_independence",
  },
  {
    label:
      "To reduce number of hospital admissions due to SOB, Chest congestion",
    value: "chest_congestion",
  },
  {
    label:
      "Learn healthy behaviors, such as regular exercise and a hearth-healthy diet",
    value: "healthy_behaviors",
  },
  { label: "Quit Smoking / Alcohol / Tobacco", value: "quit_smoking" },
  { label: "Flexibility and Joint ROM", value: "flexibility_joint_rom" },
  { label: "Strength and Endurance", value: "strength_endurance" },
  { label: "Others", value: "others" },
];

export const sportsGoalsOptions = [
  { label: "Flexibility and Joint ROM", value: "flexibility_joint_rom" },
  { label: "Strength and Endurance", value: "strength_endurance" },
  {
    label: "Proprioception and Coordination",
    value: "proprioception_coordination",
  },
  { label: "Control Pain and Swelling", value: "control_pain_swelling" },
  { label: "Return to Sports", value: "return_sports" },
  {
    label: "Correction of the Movement Stereotypes",
    value: "correction_movement_stereotypes",
  },
  { label: "Others", value: "others" },
];

export const neuroGoalsOptions = [
  {
    label:
      "Return to work if appropriate and/or previous level of functional capacity",
    value: "return_to_work",
  },
  {
    label: "To promote independence in ADLs for those who are compromised",
    value: "promote_independence",
  },
  {
    label:
      "To reduce number of hospital admissions due to SOB, Chest congestion",
    value: "chest_congestion",
  },
  {
    label:
      "Learn healthy behaviors, such as regular exercise and a hearth-healthy diet",
    value: "healthy_behaviors",
  },
  { label: "Quit Smoking / Alcohol / Tobacco", value: "quit_smoking" },
  { label: "Flexibility and Joint ROM", value: "flexibility_joint_rom" },
  { label: "Strength and Endurance", value: "strength_endurance" },
  {
    label: "Proprioception and Coordination",
    value: "proprioception_coordination",
  },
  { label: "Control Pain and Swelling", value: "control_pain_swelling" },
  { label: "Bladder and Bowel Retraining", value: "bladder_bowel_retraining" },
  {
    label:
      "Activities to Improve Mobility (Movement), Muscle Control,Gait (Walking), and Balance",
    value: "mobility",
  },
  {
    label: "Social and behavioral skills retraining",
    value: "behavioral_skills",
  },
  { label: "Normalization of the muscle tone", value: "muscle_tone" },
  {
    label: "Development of fine motor skills and self-care abilities",
    value: "fine_motor_skills",
  },
  {
    label: "Promoting cognitive and speech skills",
    value: "cognitive_speech_skills",
  },
  {
    label: "Stimulation of the long-term CNS development",
    value: "cns_development",
  },
  {
    label: "Correction of the Movement Stereotypes",
    value: "movement_stereotypes",
  },
  { label: "Others", value: "others" },
];

export const daysUptoFifteenOptions = [
  { label: "7 days", value: "seven_days" },
  { label: "10 days", value: "ten_days" },
  { label: "15 days", value: "fifteen_days" },
];

// export const daysUptoThirtyOptions = [
//   { label: "7 days", value: "seven_days" },
//   { label: "10 days", value: "ten_days" },
//   { label: "15 days", value: "fifteen_days" },
//   { label: "30 days", value: "thirty_days" },
// ];

export const weeksStartingFourOptions = [
  { label: "4 weeks", value: "four_weeks" },
  { label: "6 weeks", value: "six_weeks" },
  { label: "8 weeks", value: "eight_weeks" },
  { label: "12 weeks", value: "twelve_weeks" },
];

export const weeksStartingTwoOptions = [
  { label: "2 weeks", value: "two_weeks" },
  { label: "4 weeks", value: "four_weeks" },
  { label: "6 weeks", value: "six_weeks" },
  { label: "8 weeks", value: "eight_weeks" },
  { label: "12 weeks", value: "twelve_weeks" },
];

// export const phaseOptions = [
//   { label: "Phase I", value: "phase_i" },
//   { label: "Phase II", value: "phase_ii" },
//   { label: "Phase III", value: "phase_iii" },
//   { label: "Phase IV", value: "phase_iv" },
// ];

// export const weeksUptosixteenOptions = [
//   { label: "4 weeks", value: "four_weeks" },
//   { label: "6 weeks", value: "six_weeks" },
//   { label: "8 weeks", value: "eight_weeks" },
//   { label: "12 weeks", value: "twelve_weeks" },
//   { label: "16 weeks", value: "sixteen_weeks" },
// ];

export const twiceThriceOptions = [
  { label: "Twice a day", value: "twice" },
  { label: "Thrice a day", value: "thrice" },
];

export const onceTwiceOptions = [
  { label: "Once a day", value: "once" },
  { label: "Twice a day", value: "twice" },
];

export const hourlyOptions = [
  { label: "2nd Hourly", value: "second_hourly" },
  { label: "4th Hourly", value: "fourth_hourly" },
  { label: "6th Hourly", value: "sixth_hourly" },
];

export const orthoTherapyOptions = [
  {
    label: "IFT",
    name_key: "ift",
    options: [
      { label: "7 days", value: "7_days" },
      { label: "10 days", value: "10_days" },
      { label: "15 days", value: "15_days" },
    ],
  },
  {
    label: "Ultrasound",
    name_key: "ultrasound",
    options: [
      { label: "7 days", value: "7_days" },
      { label: "10 days", value: "10_days" },
      { label: "15 days", value: "15_days" },
    ],
  },
  {
    label: "SWD",
    name_key: "swd",
    options: [
      { label: "7 days", value: "7_days" },
      { label: "10 days", value: "10_days" },
      { label: "15 days", value: "15_days" },
    ],
  },
  {
    label: "LASER",
    name_key: "laser",
    options: [
      { label: "7 days", value: "7_days" },
      { label: "10 days", value: "10_days" },
      { label: "15 days", value: "15_days" },
    ],
  },
  {
    label: "Trigger Release",
    name_key: "trigger_release",
    options: [
      { label: "7 days", value: "7_days" },
      { label: "10 days", value: "10_days" },
      { label: "15 days", value: "15_days" },
    ],
  },
  {
    label: "Conditioning Training",
    name_key: "conditioning_training",
    options: [
      { label: "7 days", value: "7_days" },
      { label: "10 days", value: "10_days" },
      { label: "15 days", value: "15_days" },
      { label: "30 days", value: "30_days" },
    ],
  },
  {
    label: "Dry needling",
    name_key: "dry_needling",
    options: [
      { label: "7 days", value: "7_days" },
      { label: "10 days", value: "10_days" },
      { label: "15 days", value: "15_days" },
    ],
  },
  {
    label: "Post Fracture Rehab",
    name_key: "post_fracture_rehab",
    options: [
      { label: "4 weeks", value: "4_weeks" },
      { label: "6 weeks", value: "6_weeks" },
      { label: "8 weeks", value: "8_weeks" },
      { label: "12 weeks", value: "12_weeks" },
    ],
  },
  {
    label: "Post Joint Replacement Rehab",
    name_key: "post_joint_replacement_rehab",
    options: [
      { label: "4 weeks", value: "4_weeks" },
      { label: "6 weeks", value: "6_weeks" },
      { label: "8 weeks", value: "8_weeks" },
      { label: "12 weeks", value: "12_weeks" },
    ],
  },
  {
    label: "Joint /Spine Mobilization",
    name_key: "joint_spine_mobilization",
    options: [
      { label: "7 days", value: "7_days" },
      { label: "10 days", value: "10_days" },
      { label: "15 days", value: "15_days" },
    ],
  },
  {
    label: "Stretch Therapy",
    name_key: "stretch_therapy",
    options: [
      { label: "7 days", value: "7_days" },
      { label: "10 days", value: "10_days" },
      { label: "15 days", value: "15_days" },
    ],
  },
  {
    label: "Muscle Stimulation",
    name_key: "muscle_stimulation",
    options: [
      { label: "7 days", value: "7_days" },
      { label: "10 days", value: "10_days" },
      { label: "15 days", value: "15_days" },
    ],
  },
  {
    label: "TENS",
    name_key: "tens",
    options: [
      { label: "7 days", value: "7_days" },
      { label: "10 days", value: "10_days" },
      { label: "15 days", value: "15_days" },
    ],
  },
  {
    label: "Splinting / Bracing",
    name_key: "splinting_bracing",
    options: [
      { label: "2 weeks", value: "2_weeks" },
      { label: "4 weeks", value: "4_weeks" },
      { label: "6 weeks", value: "6_weeks" },
      { label: "8 weeks", value: "8_weeks" },
      { label: "12 weeks", value: "12_weeks" },
    ],
  },
  {
    label: "MET",
    name_key: "met",
    options: [
      { label: "4 weeks", value: "4_weeks" },
      { label: "6 weeks", value: "6_weeks" },
      { label: "8 weeks", value: "8_weeks" },
      { label: "12 weeks", value: "12_weeks" },
    ],
  },
  {
    label: "Vacuum Therapy",
    name_key: "vacuum_therapy",
    options: [
      { label: "7 days", value: "7_days" },
      { label: "10 days", value: "10_days" },
      { label: "15 days", value: "15_days" },
    ],
  },
];

export const cardioTherapyOptions = [
  {
    label: "Post Surgical Cardiac Rehab",
    name_key: "post_surgical_cardiac_rehab",
    options: [
      { label: "Phase I", value: "phase1" },
      { label: "Phase II", value: "phase2" },
      { label: "Phase III", value: "phase3" },
      { label: "Phase IV", value: "phase4" },
    ],
  },
  {
    label: "Pre Surgical Rehab",
    name_key: "pre_surgical_rehab",
    options: [
      { label: "Phase I", value: "phase1" },
      { label: "Phase II", value: "phase2" },
      { label: "Phase III", value: "phase3" },
      { label: "Phase IV", value: "phase4" },
    ],
  },
  {
    label: "Respiratory Rehab",
    name_key: "respiratory_rehab",
    options: [
      { label: "4 weeks", value: "4_weeks" },
      { label: "6 weeks", value: "6_weeks" },
      { label: "8 weeks", value: "8_weeks" },
      { label: "12 weeks", value: "12_weeks" },
    ],
  },
  {
    label: "Conditioning Training",
    name_key: "conditioning_training",
    options: [
      { label: "4 weeks", value: "4_weeks" },
      { label: "6 weeks", value: "6_weeks" },
      { label: "8 weeks", value: "8_weeks" },
      { label: "12 weeks", value: "12_weeks" },
    ],
  },
  {
    label: "Endurance Training",
    name_key: "endurance_training",
    options: [
      { label: "4 weeks", value: "4_weeks" },
      { label: "6 weeks", value: "6_weeks" },
      { label: "8 weeks", value: "8_weeks" },
      { label: "12 weeks", value: "12_weeks" },
    ],
  },
];

export const sportsTherapyOptions = [
  {
    label: "Ligament Injury Rehab",
    name_key: "ligament_injury_rehab",
    options: [
      { label: "Phase I", value: "phase1" },
      { label: "Phase II", value: "phase2" },
      { label: "Phase III", value: "phase3" },
      { label: "Phase IV", value: "phase4" },
    ],
  },
  {
    label: "Ligament Injury Rehab Duration",
    name_key: "ligament_injury_rehab_duration",
    options: [
      { label: "4 weeks", value: "4_weeks" },
      { label: "6 weeks", value: "6_weeks" },
      { label: "8 weeks", value: "8_weeks" },
      { label: "12 weeks", value: "12_weeks" },
      { label: "16 weeks", value: "16_weeks" },
    ],
  },
  {
    label: "Muscle Sprain/Strain Rehab",
    name_key: "muscle_sprain_strain_rehab",
    options: [
      { label: "2 weeks", value: "2_weeks" },
      { label: "4 weeks", value: "4_weeks" },
      { label: "6 weeks", value: "6_weeks" },
      { label: "8 weeks", value: "8_weeks" },
      { label: "12 weeks", value: "12_weeks" },
    ],
  },
  {
    label: "Return To Sports Training",
    name_key: "return_to_sports_training",
    options: [
      { label: "4 weeks", value: "4_weeks" },
      { label: "6 weeks", value: "6_weeks" },
      { label: "8 weeks", value: "8_weeks" },
      { label: "12 weeks", value: "12_weeks" },
    ],
  },
  {
    label: "Proprioception Training",
    name_key: "proprioception_training",
    options: [
      { label: "4 weeks", value: "4_weeks" },
      { label: "6 weeks", value: "6_weeks" },
      { label: "8 weeks", value: "8_weeks" },
      { label: "12 weeks", value: "12_weeks" },
    ],
  },
  {
    label: "Agility Training",
    name_key: "agility_training",
    options: [
      { label: "4 weeks", value: "4_weeks" },
      { label: "6 weeks", value: "6_weeks" },
      { label: "8 weeks", value: "8_weeks" },
      { label: "12 weeks", value: "12_weeks" },
    ],
  },
  {
    label: "IFT",
    name_key: "ift",
    options: [
      { label: "7 days", value: "7_days" },
      { label: "10 days", value: "10_days" },
      { label: "15 days", value: "15_days" },
    ],
  },
  {
    label: "Ultrasound",
    name_key: "ultrasound",
    options: [
      { label: "7 days", value: "7_days" },
      { label: "10 days", value: "10_days" },
      { label: "15 days", value: "15_days" },
    ],
  },
  {
    label: "LASER",
    name_key: "laser",
    options: [
      { label: "7 days", value: "7_days" },
      { label: "10 days", value: "10_days" },
      { label: "15 days", value: "15_days" },
    ],
  },
  {
    label: "Trigger Release",
    name_key: "trigger_release",
    options: [
      { label: "7 days", value: "7_days" },
      { label: "10 days", value: "10_days" },
      { label: "15 days", value: "15_days" },
    ],
  },
  {
    label: "Conditioning Training",
    name_key: "conditioning_training",
    options: [
      { label: "7 days", value: "7_days" },
      { label: "10 days", value: "10_days" },
      { label: "15 days", value: "15_days" },
      { label: "30 days", value: "30_days" },
    ],
  },
  {
    label: "Dry Needling",
    name_key: "dry_needling",
    options: [
      { label: "7 days", value: "7_days" },
      { label: "10 days", value: "10_days" },
      { label: "15 days", value: "15_days" },
    ],
  },
  {
    label: "Post Fracture Rehab",
    name_key: "post_fracture_rehab",
    options: [
      { label: "4 weeks", value: "4_weeks" },
      { label: "6 weeks", value: "6_weeks" },
      { label: "8 weeks", value: "8_weeks" },
      { label: "12 weeks", value: "12_weeks" },
    ],
  },
  {
    label: "Post Joint Replacement Rehab",
    name_key: "post_joint_replacement_rehab",
    options: [
      { label: "4 weeks", value: "4_weeks" },
      { label: "6 weeks", value: "6_weeks" },
      { label: "8 weeks", value: "8_weeks" },
      { label: "12 weeks", value: "12_weeks" },
    ],
  },
  {
    label: "Joint /Spine Mobilization",
    name_key: "joint_spine_mobilization",
    options: [
      { label: "7 days", value: "7_days" },
      { label: "10 days", value: "10_days" },
      { label: "15 days", value: "15_days" },
    ],
  },
  {
    label: "Stretch Therapy",
    name_key: "stretch_therapy",
    options: [
      { label: "7 days", value: "7_days" },
      { label: "10 days", value: "10_days" },
      { label: "15 days", value: "15_days" },
    ],
  },
  {
    label: "Muscle Stimulation",
    name_key: "muscle_stimulation",
    options: [
      { label: "7 days", value: "7_days" },
      { label: "10 days", value: "10_days" },
      { label: "15 days", value: "15_days" },
    ],
  },
  {
    label: "TENS",
    name_key: "tens",
    options: [
      { label: "7 days", value: "7_days" },
      { label: "10 days", value: "10_days" },
      { label: "15 days", value: "15_days" },
    ],
  },
  {
    label: "Splinting/ Bracing",
    name_key: "splinting",
    options: [
      { label: "2 weeks", value: "2_weeks" },
      { label: "4 weeks", value: "4_weeks" },
      { label: "6 weeks", value: "6_weeks" },
      { label: "8 weeks", value: "8_weeks" },
      { label: "12 weeks", value: "12 weeks" },
    ],
  },
  {
    label: "MET",
    name_key: "met",
    options: [
      { label: "4 weeks", value: "4_weeks" },
      { label: "6 weeks", value: "6_weeks" },
      { label: "8 weeks", value: "8_weeks" },
      { label: "12 weeks", value: "12 weeks" },
    ],
  },
];

export const tcmOptions = [
  {
    label: "Static Sitting Balance",
    value: "sitting_balance",
    min: 0,
    max: 20,
  },
  {
    label: "Selective Movement Control",
    value: "selective_movement_control",
    min: 0,
    max: 28,
  },
  {
    label: "Dynamic Reaching",
    value: "sitting_to_standing",
    min: 0,
    max: 10,
  },
];

export const postureOptions = [
  { label: "Ideal", value: "ideal" },
  { label: "Lordotic", value: "lordotic" },
  { label: "Kyphotic", value: "kyphotic" },
  { label: "Scoliosis", value: "scoliosis" },
  { label: "Military", value: "military" },
  { label: "Flatback", value: "flat_back" },
  { label: "Swayback", value: "swayback" },
  { label: "Rounded Shoulders", value: "rounded_shoulders" },
  { label: "Forward Neck", value: "forward_neck" },
  { label: "Others", value: "others" },
];

export const bergScaleOptions = [
  {
    label: "Sitting to Standing",
    value: "sitting_to_standing",
  },
  {
    label: "Standing unsupported",
    value: "standing_unsopported",
  },
  {
    label: "Sitting unsupported feet on floor",
    value: "sitting_unsupported_feet_on_floor",
  },
  {
    label: "Standing to Sitting",
    value: "standing_to_sitting",
  },
  {
    label: "Transfers",
    value: "transfers",
  },
  {
    label: "Standing unsupported with eyes closed",
    value: "standing_unsupported_with_eyes_closed",
  },
  {
    label: "Standing unsupported with feet together",
    value: "standing_unsupported_with_feet_together",
  },
  {
    label: "Reaching forward with outstretched arms",
    value: "reaching_forward_with_outstretched_arms",
  },
  {
    label: "Pick up object from the floor",
    value: "pick_up_object_from_the_floor",
  },
  {
    label: "Turning to look behind / over left and right shoulders",
    value: "turning_to_look_behing",
  },
  {
    label: "Turn 360 degrees",
    value: "turn_360_degrees",
  },
  {
    label: "Count number of times step touch measured stool",
    value: "count_number_of_times_step_touch_measured_stool",
  },
  {
    label: "Standing unsupported, one foot in front",
    value: "standing_unsupported_one_foot_in_front",
  },
  {
    label: "Standing on one leg",
    value: "standing_on_one_leg",
  },
];

export const nihssOptions = [
  {
    label: "Level of Consciousness",
    value: "level_of_Consiousness",

    max: 3,
  },
  { label: "LOC Questions", value: "loc_questions", max: 2 },
  { label: "LOC Commands", value: "loc_commands", max: 4 },
  { label: "Best Gaze", value: "best_gaze", max: 2 },
  { label: "Visual", value: "visual", max: 3 },
  { label: "Facial Palsy", value: "facial_palsy", max: 3 },
  { label: "Motor Arm-Left", value: "motor_arm_left", max: 4 },
  { label: "Motor Arm-Right", value: "motor_arm_right", max: 4 },
  { label: "Motor Leg-Left", value: "motor_leg_left", max: 4 },
  { label: "Motor Leg-Right", value: "motor_leg_right", max: 4 },
  { label: "Limb Ataxia", value: "limb_ataxia", max: 2 },
  { label: "Sensory", value: "sensory", max: 2 },
  { label: "Best Language", value: "best_language", max: 3 },
  { label: "Dysarthria", value: "dysarthria", max: 2 },
  {
    label: "Extinction and Inattention (formerly neglect)",
    value: "extinction_and_inattention",
    max: 2,
  },
];

export const visualFieldsOptions = [
  { label: "Begin-End", value: "begin_end" },
  { label: "Copy Cube", value: "copy_cube" },
  { label: "Contour", value: "contour" },
  { label: "Numbers", value: "numbers" },
  { label: "Hands", value: "hands" },
];

export const namingFieldsOptions = [
  { label: "Lion", value: "lion" },
  { label: "Rhino", value: "rhino" },
  { label: "Camel", value: "camel" },
];

export const memoryFieldsOptions = [
  { label: "Face", value: "face" },
  { label: "Velvet", value: "velvet" },
  { label: "Church", value: "church" },
  { label: "Daisy", value: "daisy" },
  { label: "Red", value: "red" },
];

export const attentionFields = [
  {
    label: "Repeat the following in forward order 2 1 8 5 4",
    value: "attention_1",
  },
  {
    label: "Repeat the following in backward order 7 4 2",
    value: "attention_2",
  },
  {
    label:
      "Repeat the list and tap hand on occurrence of A \n F B A C M N A A J K L B A F A K D E A A A J A M O F A A B",
    value: "attention_3",
  },
  { label: "Serial 7 subtraction starting from 100", value: "attention_4" },
];
export const attentionCheckboxOptions = [
  { label: "93", value: "93" },
  { label: "86", value: "86" },
  { label: "79", value: "79" },
  { label: "72", value: "72" },
  { label: "65", value: "65" },
];
export const languageFields = [
  {
    label: "Repeat Test 1 I only know that John is the one to help today",
    value: "language_1",
  },
  {
    label:
      "Repeat Test 2 The cat always hid under the couch when the dogs were in the room",
    value: "language_2",
  },
  {
    label:
      "Letter starting with F Fluency/Name maximum number of words in one minute that begin with letter F",
    value: "language_3",
  },
];
export const abstractionFields = [
  {
    label: "Similarity Test 1",
    sequence: "Train - Bicycle",
    value: "abstraction_1",
  },
  {
    label: "Similarity Test 2",
    sequence: "Watch - Ruler",
    value: "abstraction_2",
  },
];
export const orientationFieldsOptions = [
  { label: "Date", value: "date" },
  { label: "Month", value: "month" },
  { label: "Year", value: "year" },
  { label: "Day", value: "day" },
  { label: "Place", value: "place" },
  { label: "City", value: "city" },
];
export const segmentsOptions = [
  { label: "C2", value: "C2" },
  { label: "C3", value: "C3" },
  { label: "C4", value: "C4" },
  { label: "C5", value: "C5" },
  { label: "C6", value: "C6" },
  { label: "C7", value: "C7" },
  { label: "C8", value: "C8" },
  { label: "T1", value: "T1" },
  { label: "T2", value: "T2" },
  { label: "T3", value: "T3" },
  { label: "T4", value: "T4" },
  { label: "T5", value: "T5" },
  { label: "T6", value: "T6" },
  { label: "T7", value: "T7" },
  { label: "T8", value: "T8" },
  { label: "T9", value: "T9" },
  { label: "T10", value: "T10" },
  { label: "T11", value: "T11" },
  { label: "T12", value: "T12" },
  { label: "L1", value: "L1" },
  { label: "L2", value: "L2" },
  { label: "L3", value: "L3" },
  { label: "L4", value: "L4" },
  { label: "L5", value: "L5" },
  { label: "S1", value: "S1" },
  { label: "S2", value: "S2" },
  { label: "S3", value: "S3" },
  { label: "S4-S5", value: "S4-S5" },
];
export const asiaImpairmentGradeOptions = [
  { label: "A", value: "A" },
  { label: "B", value: "B" },
  { label: "C", value: "C" },
  { label: "D", value: "D" },
  { label: "E", value: "E" },
];

export const neurologicalOptions = [
  { label: "C1", value: "C1" },
  { label: "C2", value: "C2" },
  { label: "C3", value: "C3" },
  { label: "C4", value: "C4" },
  { label: "C5", value: "C5" },
  { label: "C6", value: "C6" },
  { label: "C7", value: "C7" },
  { label: "C8", value: "C8" },
  { label: "T1", value: "T1" },
  { label: "T2", value: "T2" },
  { label: "T3", value: "T3" },
  { label: "T4", value: "T4" },
  { label: "T5", value: "T5" },
  { label: "T6", value: "T6" },
  { label: "T7", value: "T7" },
  { label: "T8", value: "T8" },
  { label: "T9", value: "T9" },
  { label: "T10", value: "T10" },
  { label: "T11", value: "T11" },
  { label: "T12", value: "T12" },
  { label: "L1", value: "L1" },
  { label: "L2", value: "L2" },
  { label: "L3", value: "L3" },
  { label: "L4", value: "L4" },
  { label: "L5", value: "L5" },
  { label: "S1", value: "S1" },
  { label: "S2", value: "S2" },
  { label: "S3", value: "S3" },
  { label: "S4-S5", value: "S4-S5" },
];

export const maScaleOptions = [
  { label: "0/5(No increase in muscle tone)", value: 0 },
  {
    label: "1/5(Slight increase in muscle tone, at the end of the ROM)",
    value: 1,
  },
  {
    label:
      "2/5(Slight increase in muscle tone, through(less than half) of the ROM)",
    value: 2,
  },
  {
    label:
      "3/5(More marked increase in muscle tone through most of the ROM, but passive movement is easy)",
    value: 3,
  },
  {
    label:
      "4/5(Considerable increase in muscle tone, passive movement difficult)",
    value: 4,
  },
  { label: "5/5(Affected part(s) rigid in flexion and extension)", value: 5 },
];

export const mmtScaleOptions = [
  { label: "0/5 (Total Paralysis)", value: "0" },
  { label: "1/5 (Flicker movement only)", value: "1" },
  { label: "2/5 (Assisted range of motion)", value: "2" },
  { label: "3/5 (Able to overcome gravity but not resistance)", value: "3" },
  { label: "4/5 (Able to overcome gravity and mild resistance)", value: "4" },
  {
    label: "4+/5 (Able to overcome gravity and moderate resistance)",
    value: "4+",
  },
  { label: "4++/5 (Able to overcome significant resistance)", value: "4++" },
  { label: "5/5 (Normal Motor Power)", value: "5" },
];

export const vcgScaleOptions = [
  { label: "1/6 (Movement can only be a reflex action)", value: 1 },
  {
    label: "2/6 (Movement can be performed when placed in a mass pattern)",
    value: 2,
  },
  {
    label: "3/6 (Movement can be performed over a limited range with synergy)",
    value: 3,
  },
  {
    label:
      "4/6 (Movement can be performed in a facilitated position with synergy)",
    value: 4,
  },
  {
    label:
      "5/6 (Movement can be performed in the prescribed range with slight synergy)",
    value: 5,
  },
  {
    label:
      "6/6 (Movement can be performed in full range in positions without any synergy)",
    value: 6,
  },
];
