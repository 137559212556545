import { Card, Typography } from "antd";
import React from "react";

const ClinicalCard = ({ data }) => {
  return (
    <Card 
  title="Clinical Diagnosis" 
  bordered 
  style={{marginTop:"10px"}}
  styles={{ header: { borderBottom: "none" } ,body:{paddingTop:"0px"}}}
>
  <Typography>{data}</Typography>
</Card>
  );
};

export default ClinicalCard;
// border: "1px solid #ccc"
