import { ArrowRightOutlined } from "@ant-design/icons";
import {
  Col,
  Image,
  List,
  Pagination,
  Row,
  Segmented,
  Space,
  Tag,
  theme,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import CustomAvatar from "../../blocks/Avatars/CustomAvatar";
import dayjs from "dayjs";
import tagStyles from "../../styles/Custom/Tag.module.css";
import { useNavigate } from "react-router-dom";
import { getOutPatientListApi } from "../../services/patient.services";
import { getCookie } from "../../helpers/localStorage";
import { FacilityID } from "../../constants/defaultKeys";
import docIcon from "../../assets/doctorIcons/consultant.svg";
import appointmentIcon from "../../assets/doctorIcons/appointment.svg";
import "./PatientList.css";
import NoDataPage from "../NoDataPages/NoDataPage";
import PatientDataIcon from "../../assets/images/nurseIcons/patientdata.svg";
import LoadingIndicator from "../Loaders/LoadingIndicator";

const { useToken } = theme;
const OutPatientsCard = ({ userDetails, role }) => {
  const url = new URL(window?.location?.href);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const { token } = useToken();
  const [activeOutSegment, setActiveOutSegment] = useState(
    new URLSearchParams(document?.location?.search)?.get("status")
  );
  const [outPatientList, setOutPatientList] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const outPatientLabels = [
    { label: "Today", value: "today" },
    { label: "Week", value: "week" },
  ];

  const onOutPatientSegmentChange = (value) => {
    url?.searchParams?.set("status", value);
    navigate(`${document?.location.pathname}?${url.searchParams.toString()}`, {
      replace: true,
      // This prevents adding a new entry in the history stack
    });
  };

  const facilityId = Number(getCookie(FacilityID)) || userDetails?.currFacility;
  useEffect(() => {
    getOutPatientListApi(`facility=${facilityId}`, activeOutSegment, pageNo)
      .then((res) => {
        setOutPatientList(res?.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setLoading(false));
  }, [facilityId, activeOutSegment, pageNo]);

  useEffect(() => {
    const params = new URLSearchParams(document?.location?.search);
    const status = params?.get("status");
    setActiveOutSegment(status);
  }, [url]);
  return (
    <>
      {loading ? (
        <LoadingIndicator message={"Loading data, please wait..."} />
      ) : (
        <Row gutter={[0, 16]}>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={{ span: 20, offset: 2 }}
            xl={{ span: 20, offset: 2 }}
          >
            <List
              style={{ background: "#fff" }}
              className="patient-list"
              // pagination={{
              //   onChange: (page) => {
              //     console.log(page);
              //     setPageNo(page);
              //   },
              //   pageSize: 20,
              // }}
              pagination={false}
              bordered
              header={
                <Row>
                  <Col span={12}>Out Patients</Col>
                  <Col span={12} style={{ textAlign: "right" }}>
                    <Segmented
                      options={outPatientLabels}
                      value={activeOutSegment}
                      onChange={onOutPatientSegmentChange}
                    />
                  </Col>
                </Row>
              }
              itemLayout="horizontal"
              size="small"
              dataSource={outPatientList?.data}
            >
              {outPatientList?.data?.length > 0 ? (
                outPatientList.data.map((item) => (
                  <List.Item
                    key={item?.title}
                    extra={
                      <Typography.Link
                        style={{ color: token.colorPrimary }}
                        onClick={() =>
                          navigate(`/${role}/home?patient=${item?.id}&type=op`)
                        }
                      >
                        Start Consultation <ArrowRightOutlined />
                      </Typography.Link>
                    }
                  >
                    <Col span={6}>
                      <List.Item.Meta
                        avatar={
                          <CustomAvatar
                            name={`${item?.patient_name}`}
                            indexId={item?.id}
                            size="medium"
                          />
                        }
                        title={
                          <Space size="small">
                            <Typography>
                              {item?.patient_first_name}{" "}
                              {item?.patient_last_name}
                            </Typography>
                          </Space>
                        }
                      />
                    </Col>
                    <Tag>IP ID:{item?.id}</Tag>

                    {(item?.patient_age || item?.patient_gender) && (
                      <Tag color="blue">
                        {item?.patient_gender} {item?.patient_age}
                      </Tag>
                    )}
                    <Tag className={tagStyles.smTag}>
                      <Image
                        src={appointmentIcon}
                        style={{ width: "12px" }}
                        preview={false}
                      />{" "}
                      {item?.appointment_date &&
                        dayjs(item?.appointment_date).fromNow()}
                    </Tag>
                    <Tag color="blue">
                      <Image
                        src={docIcon}
                        style={{ width: "12px" }}
                        preview={false}
                      />{" "}
                      {item?.doctor_first_name} {item?.doctor_last_name}
                    </Tag>
                  </List.Item>
                ))
              ) : (
                <List.Item>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                      width: "100%",
                      padding: "20px",
                      color: "#999",
                    }}
                  >
                    <NoDataPage
                      title="No Out Patients Available"
                      subtitle="Please check back later or adjust your filters."
                      image={PatientDataIcon}
                    />
                  </div>{" "}
                </List.Item>
              )}
            </List>
          </Col>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={{ span: 4, offset: 20 }}
            xl={{ span: 4, offset: 20 }}
            // xl={16}
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            {loading ? (
              <span>loading...</span>
            ) : (
              outPatientList?.total_count > 50 && (
                <Pagination
                  current={pageNo}
                  onChange={(value) => setPageNo(value)}
                  pageSize={50}
                  total={outPatientList?.total_count}
                  showSizeChanger={false}
                />
              )
            )}
          </Col>
        </Row>
      )}
    </>
  );
};

export default OutPatientsCard;
