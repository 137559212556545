import React, {
  useState,
  useRef,
  useContext,
  useEffect,
  useCallback,
} from "react";
import {
  Button,
  Typography,
  Form,
  message,
  Row,
  Col,
  AutoComplete,
  Input,
  Modal,
} from "antd";
import btnStyles from "../../../styles/Custom/Button.module.css";
import inputStyles from "../../../styles/Custom/Input.module.css";
import { updateMedicationPrescribeAPI } from "../../../services/patient.services";
import { CloseOutlined, SearchOutlined } from "@ant-design/icons";
import { UserContext } from "../../../context/UserContext";
import { useLocation } from "react-router-dom";
import { getCookie } from "../../../helpers/localStorage";
import { FacilityID, ORGANISATONID } from "../../../constants/defaultKeys";
import { searchMedicationApi } from "../../../services/medication.services";
import MedicineFields from "./AddMedicine/MedicineFields";
import { debounce } from "../../../helpers/utility";
import dayjs from "dayjs";

const setSearchOptions = (data) => {
  const searchOptions = data.map((options) => {
    return {
      value: options?.name,
      key: options?.record_id,
      name: options?.name,
      drug: options?.drug1_name,
      label: (
        <Row>
          <Col span={10}>
            <span>{options?.name}</span>
          </Col>
        </Row>
      ),
    };
  });
  return searchOptions;
};
const AddMedicineModal = ({
  open,
  setOpen,
  editData,
  refreshPage,
  initial,
}) => {
  const location = useLocation();
  const params = Object.fromEntries(new URLSearchParams(location?.search));
  const { userDetails } = useContext(UserContext);
  const [form] = Form.useForm();
  const formRef = useRef(null);
  const [add, setAdd] = useState(true);
  const [selectedId, setSelectedId] = useState(null);
  const [data, setData] = useState(null);
  const [searchedText, setSearchedText] = useState("");
  const [formValues, setFormValues] = useState(null);
  const [startMedicine, setStartMedicine] = useState(
    editData?.is_stat === true ? true : false
  );
  const [selectedFrequency, setSelectedFrequency] = useState(null);

  useEffect(() => {
    if (editData?.id) {
      console.log(editData);
      form.setFieldsValue({
        ...editData,
        name: editData?.medicine_name || editData?.name,
        schedule: {
          times: editData?.schedule?.times?.map((time) => dayjs(time, "HH")),
        },
      });
      setSelectedId(editData?.medicine);
      setStartMedicine(editData?.is_stat);
    }
  }, [editData?.id]);

  const onFinish = (values) => {
    const times = values?.schedule?.times.map((time) =>
      dayjs(time)?.format("HH")
    );

    const { schedule, ...restValues } = values;

    const formData = {
      facility: Number(getCookie(FacilityID)) || userDetails?.currFacility,
      ip_admission: Number(params?.patient),
      medicine: selectedId,
      days_left_initial: values?.days_left_initial
        ? values?.days_left_initial
        : values?.duration
        ? values?.duration
        : editData?.days_left_initial,
      schedule: {
        times: times,
      },
      ...restValues,
    };
    if (selectedId) {
      if (editData?.id) {
        updateMedicationPrescribeAPI(editData?.id, formData)
          .then((res) => {
            message.success(res?.data?.message);
            setOpen(false);
            refreshPage();
          })
          .catch((err) => message.error(err?.response?.data?.message));
      }
    } else {
      message.error("Please select medicine from search list");
    }
  };
  const handleSearch = (searchedValue) => {
    searchMedicationApi(
      `query=${searchedValue.toLowerCase()}&organization_id=${getCookie(
        ORGANISATONID
      )}`
    )
      .then((res) => {
        setData(setSearchOptions(res?.data));
      })
      .catch((err) => console.log(err));
  };

  const optimizedSearch = useCallback(debounce(handleSearch, 300), []);

  const handleSelect = (value, option) => {
    setSelectedId(option?.key);
    form.setFieldsValue({ name: option?.value, drug: option?.drug });
  };
  const onValuesChange = (allValues, changedValues) => {
    setFormValues({ ...allValues, ...changedValues });
  };

  return (
    <Modal
      keyboard={false}
      width={"100%"}
      open={open}
      onCancel={() => setOpen(false)}
      footer={
        <div className="flex-end" style={{ gap: "24px" }}>
          {!editData?.id && (
            <Form.Item>
              <Button
                type="primary"
                ghost
                className={btnStyles.mdBtn}
                onClick={() => {
                  setAdd(true);
                  if (formRef.current) {
                    formRef.current.submit();
                  }
                }}
              >
                Submit and Add Another
              </Button>
            </Form.Item>
          )}

          <Form.Item>
            <Button
              htmlType="submit"
              type="primary"
              className={btnStyles.mdBtn}
              onClick={() => {
                setAdd(false);

                if (formRef.current) {
                  formRef.current.submit();
                }
              }}
            >
              Submit and Close
            </Button>
          </Form.Item>
        </div>
      }
      title={
        <Row>
          <Col span={12} className="flex-start">
            <Typography
              style={{
                marginLeft: "12px",
                fontWeight: "400",
                fontSize: "20px",
              }}
            >
              {editData?.id ? "Edit Medicine" : "Add Medicine"}
            </Typography>
          </Col>
          <Col span={12} className="flex-end">
            <CloseOutlined
              onClick={() => {
                setOpen(false);
              }}
            />
          </Col>
        </Row>
      }
      closeIcon={null}
    >
      <>
        <Form
          layout="vertical"
          onFinish={onFinish}
          form={form}
          ref={formRef}
          style={{ marginTop: "20px" }}
          onValuesChange={onValuesChange}
        >
          <Form.Item
            name="name"
            rules={[{ required: true, message: "Please enter medicine" }]}
            required
          >
            <AutoComplete
              options={data && data}
              className="search"
              onSearch={optimizedSearch}
              onSelect={handleSelect}
              onChange={(value) => {
                setSearchedText(value);
                optimizedSearch(value);
              }}
              value={searchedText}
            >
              <Input
                size="large"
                prefix={<SearchOutlined />}
                placeholder="Search Medicine"
                style={{ width: "400px", marginTop: "-10px" }}
                className={inputStyles.mdInput}
              />
            </AutoComplete>
          </Form.Item>
          {selectedId && (
            <>
              <MedicineFields
                initial={initial}
                selectedId={selectedId}
                editData={editData}
                form={form}
                addMedicine={true}
                startMedicine={startMedicine}
                setStartMedicine={setStartMedicine}
                selectedFrequency={selectedFrequency}
                setSelectedFrequency={setSelectedFrequency}
              />
            </>
          )}
        </Form>
      </>
    </Modal>
  );
};

export default AddMedicineModal;
