import React, { useContext } from "react";
import { DoctorInitialContext } from "../../InspectionForms/HistoryForm/DoctorHistoryFormProvider";
import {
  getEdemaSummary,
  getLabelTag,
  getLabelTagInput,
  getLabelTagTable,
  getLabelTagYesNO,
  getLabelTagsArray,
  getLabelTagsWithRadioOptions,
} from "../constructTextFromValues";
import {
  builtOptions,
  cannulaOptions,
  consiousnessOptions,
  cyanosisOptions,
  edemaOptions,
  feedingTubeTypeOptions,
  foleysCatheterTypeOptions,
  hydrationOptions,
  moreConsiousnessOptions,
  moreOstomiesOptions,
  nmmsOptions,
  ostomiesOptions,
  pupilOptions,
  pupilRadioOptions,
  pupilSideOptions,
  respiratoryDistressOptions,
  respiratorySupportOptions,
  tracheostomySecretionOptions,
  tracheostomyTypeOptions,
  urinaryCatheterTypeOptions,
  urineTypeOptions,
} from "../../Options/genExamOptions";
import { Descriptions, Image, Space, Tag } from "antd";
import dayjs from "dayjs";
import {
  normalAbnormalOptions,
  yesNoOptions,
} from "../../Options/commonOptions";
import { FileSearchOutlined } from "@ant-design/icons";

const DrGeneralExaminationSummary = () => {
  const { formData } = useContext(DoctorInitialContext);

  const getIVAccessSummary = () => {
    return (
      <>
        {getLabelTagInput(
          "Peripheral Cannula",
          getLabelTagYesNO(formData?.general_examination?.peripheral_cannula)
        )}
        {getLabelTagInput(
          formData?.general_examination?.cannula_site1,
          getLabelTagsArray(
            cannulaOptions,
            formData?.general_examination?.cannula_type1
          )?.length === 0
            ? null
            : getLabelTagsArray(
                cannulaOptions,
                formData?.general_examination?.cannula_type1
              )
        )}
        {getLabelTagInput(
          formData?.general_examination?.cannula_site2,
          getLabelTagsArray(
            cannulaOptions,
            formData?.general_examination?.cannula_type2
          )?.length === 0
            ? null
            : getLabelTagsArray(
                cannulaOptions,
                formData?.general_examination?.cannula_type2
              )
        )}
        {getLabelTagInput(
          "Central Line",
          getLabelTagYesNO(formData?.general_examination?.central_line)
        )}
        {getLabelTagInput(
          "Central Line Cumulative Days",
          formData?.general_examination?.central_line_days
        )}
      </>
    );
  };

  const getResipiratorySupportSummary = () => {
    const temp = formData?.general_examination?.respiratory_support_type;
    return (
      <>
        {getLabelTag(
          respiratorySupportOptions.respiratorySupport,
          formData?.general_examination?.respiratory_support_type
        )}
        {[
          "oxygen_nasal_prongs",
          "thermo_ventilator",
          "oxygen_hudson_mask",
          "oxygen_hi_flow_mask",
          "oxygen_rebreathing_mask",
        ].includes(temp) && (
          <Tag
            bordered={false}
            className="break-line-tag"
            key={`respiratory_support_type_${temp}`}
          >
            {
              formData?.general_examination?.[
                `respiratory_support_type_${temp}`
              ]
            }
          </Tag>
        )}
        {temp === "bipap_support" && (
          <>
            <Tag bordered={false} className="break-line-tag" key={"epap"}>
              EPAP : {formData?.general_examination?.bipap_epap}Cm of H2O
            </Tag>
            <Tag bordered={false} className="break-line-tag" key={"ipap"}>
              IPAP : {formData?.general_examination?.bipap_ipap}Cm of H2O
            </Tag>
            <Tag bordered={false} className="break-line-tag" key={"rr"}>
              RR : {formData?.general_examination?.bipap_rr} /min
            </Tag>
            <Tag bordered={false} className="break-line-tag" key={"FiO2"}>
              FiO2 : {formData?.general_examination?.bipap_fio2} %
            </Tag>
          </>
        )}
        {temp === "cpap_support" && (
          <>
            <Tag bordered={false} className="break-line-tag" key={"peep"}>
              PEEP : {formData?.general_examination?.cpap_support_peep}Cm of H2O
            </Tag>
            <Tag
              bordered={false}
              className="break-line-tag"
              key={"ps_above_peep"}
            >
              PS above PEEP :
              {formData?.general_examination?.cpap_support_ps_peep}Cm of H2O
            </Tag>
            <Tag bordered={false} className="break-line-tag" key={"rr"}>
              RR : {formData?.general_examination?.cpap_support_rr} /min
            </Tag>
            <Tag bordered={false} className="break-line-tag" key={"FiO2"}>
              FiO2 : {formData?.general_examination?.cpap_support_fio2} %
            </Tag>
          </>
        )}
        {temp === "mechanical_ventilation" && (
          <>
            {getLabelTag(
              respiratorySupportOptions.mechanicalVentilation,
              formData?.general_examination?.mechanical_ventilation_mode
            )}
            <Tag bordered={false} className="break-line-tag" key={"peep"}>
              PEEP :{" "}
              {formData?.general_examination?.mechanical_ventilation_peep}
              Cm of H2O
            </Tag>
            <Tag bordered={false} className="break-line-tag" key={"tv"}>
              TV :{" "}
              {formData?.general_examination?.mechanical_ventilation_ps_peep} Cm
              of H2O
            </Tag>
            <Tag bordered={false} className="break-line-tag" key={"rr"}>
              RR : {formData?.general_examination?.mechanical_ventilation_rr}
              /min
            </Tag>
            <Tag bordered={false} className="break-line-tag" key={"FiO2"}>
              FiO2 :{" "}
              {formData?.general_examination?.mechanical_ventilation_fio2} %
            </Tag>
          </>
        )}
      </>
    );
  };

  const generalExaminationSummaryItems = [];

  const constructGeneralExaminationSummaryOptions = () => {
    if (formData?.general_examination?.built) {
      generalExaminationSummaryItems.push({
        key: "1",
        label: "Built",
        children: getLabelTag(
          builtOptions,
          formData?.general_examination?.built
        ),
      });
    }
    if (formData?.general_examination?.respiratory_distress) {
      generalExaminationSummaryItems.push({
        key: "2",
        label: "Respiratory Distress",
        children: getLabelTag(
          respiratoryDistressOptions,
          formData?.general_examination?.respiratory_distress
        ),
      });
    }
    if (formData?.general_examination?.respiratory_support) {
      generalExaminationSummaryItems.push({
        key: "3",
        label: "Respiratory Support",
        children: (
          <>
            {getLabelTagYesNO(
              formData?.general_examination?.respiratory_support
            )}
            {getResipiratorySupportSummary()}
          </>
        ),
      });
    }
    if (
      getLabelTagYesNO(formData?.general_examination?.tracheostomy) ||
      formData?.general_examination?.tracheostomy_type ||
      formData?.general_examination?.tracheostomy_number_of_days ||
      formData?.general_examination?.tracheostomy_secretion
    ) {
      generalExaminationSummaryItems.push({
        key: "4",
        label: "Tracheostomy",
        children: (
          <>
            {getLabelTagYesNO(formData?.general_examination?.tracheostomy)}
            {getLabelTag(
              tracheostomyTypeOptions,
              formData?.general_examination?.tracheostomy_type
            )}
            {getLabelTagInput(
              "No of Days",
              formData?.general_examination?.tracheostomy_number_of_days
            )}
            {getLabelTag(
              tracheostomySecretionOptions,
              formData?.general_examination?.tracheostomy_secretion
            )}
          </>
        ),
      });
    }
    if (formData?.general_examination?.level_of_consciousness) {
      generalExaminationSummaryItems.push({
        key: "5",
        label: "Level of Consciousness",
        children: (
          <>
            {getLabelTag(
              consiousnessOptions,
              formData?.general_examination?.level_of_consciousness
            )}
            {getLabelTag(
              moreConsiousnessOptions,
              formData?.general_examination?.level_of_consciousness_options
            )}
          </>
        ),
      });
    }
    if (
      formData?.general_examination?.gcs?.eye_opening_response ||
      formData?.general_examination?.gcs?.verbal_response ||
      formData?.general_examination?.gcs?.motor_response
    ) {
      generalExaminationSummaryItems.push({
        key: "6",
        label: "Glasgow Coma Scale",
        children: (
          <>
            {getLabelTagInput(
              "Eye Opening Response",
              formData?.general_examination?.gcs?.eye_opening_response
            )}
            {getLabelTagInput(
              "Verbal Response",
              formData?.general_examination?.gcs?.verbal_response
            )}
            {getLabelTagInput(
              "Motor Response",
              formData?.general_examination?.gcs?.motor_response
            )}
          </>
        ),
      });
    }
    if (formData?.general_examination?.pupil_condition) {
      generalExaminationSummaryItems.push({
        key: "7",
        label: "Pupils",
        children: (
          <>
            {getLabelTag(
              pupilOptions,
              formData?.general_examination?.pupil_condition
            )}
            {getLabelTagsWithRadioOptions(
              pupilSideOptions,
              pupilRadioOptions,
              formData?.general_examination
            )}
          </>
        ),
      });
    }
    if (formData?.general_examination?.hydration) {
      generalExaminationSummaryItems.push({
        key: "8",
        label: "Hydration",
        children: getLabelTag(
          hydrationOptions,
          formData?.general_examination?.hydration
        ),
      });
    }
    if (formData?.general_examination?.pallor) {
      generalExaminationSummaryItems.push({
        key: "9",
        label: "Pallor",
        children: getLabelTag(
          nmmsOptions,
          formData?.general_examination?.pallor
        ),
      });
    }
    if (formData?.general_examination?.icterus) {
      generalExaminationSummaryItems.push({
        key: "10",
        label: "Icterus",
        children: getLabelTag(
          nmmsOptions,
          formData?.general_examination?.icterus
        ),
      });
    }
    if (formData?.general_examination?.cyanosis) {
      generalExaminationSummaryItems.push({
        key: "11",
        label: "Cyanosis",
        children: getLabelTag(
          cyanosisOptions,
          formData?.general_examination?.cyanosis
        ),
      });
    }
    if (getEdemaSummary(edemaOptions, formData?.general_examination)) {
      generalExaminationSummaryItems.push({
        key: "12",
        label: "Edema",
        children: getEdemaSummary(edemaOptions, formData?.general_examination),
      });
    }
    if (
      getLabelTagsWithRadioOptions(
        ostomiesOptions,
        moreOstomiesOptions,
        formData?.general_examination
      ) ||
      formData?.general_examination?.ostomies_others
    ) {
      generalExaminationSummaryItems.push({
        key: "13",
        label: "Ostomies",
        children: (
          <>
            {getLabelTagsWithRadioOptions(
              ostomiesOptions,
              moreOstomiesOptions,
              formData?.general_examination
            )}
            {getLabelTagInput(
              "Other Details",
              formData?.general_examination?.ostomies_others
            )}
          </>
        ),
      });
    }
    if (
      formData?.general_examination?.peripheral_cannula ||
      formData?.general_examination?.central_line
    ) {
      generalExaminationSummaryItems.push({
        key: "14",
        label: "IV Access",
        children: getIVAccessSummary(),
      });
    }
    if (formData?.general_examination?.feeding_tube) {
      generalExaminationSummaryItems.push({
        key: "15",
        label: "Feeding Tube",
        children: (
          <>
            {getLabelTagYesNO(formData?.general_examination?.feeding_tube)}
            {getLabelTag(
              feedingTubeTypeOptions,
              formData?.general_examination?.feeding_tube_type
            )}
            {getLabelTagInput(
              "Position",
              formData?.general_examination?.feedingtube_position
            )}
            {getLabelTagInput(
              "No of Days",
              formData?.general_examination?.feedingtube_no_days
            )}
            {getLabelTagInput(
              "Tube Aspiration",
              formData?.general_examination?.feedingtube_aspirations
            )}
          </>
        ),
      });
    }
    if (formData?.general_examination?.drain?.length > 0) {
      generalExaminationSummaryItems.push({
        key: "16",
        label: "Drain",
        children: getLabelTagTable(formData?.general_examination?.drain),
      });
    }
    if (formData?.general_examination?.urinary_catheter) {
      generalExaminationSummaryItems.push({
        key: "17",
        label: "Urinary Catheter",
        children: (
          <>
            {getLabelTagYesNO(formData?.general_examination?.urinary_catheter)}
            {getLabelTag(
              urinaryCatheterTypeOptions,
              formData?.general_examination?.urinary_catheter_type
            )}
            {formData?.general_examination?.urinary_catheter_type ===
            "foleys_catheter"
              ? getLabelTag(
                  foleysCatheterTypeOptions,
                  formData?.general_examination?.foleys_catheter_type
                )
              : ""}
            {getLabelTagInput(
              "Days on Catheter",
              formData?.general_examination?.days_on_catheter
            )}
          </>
        ),
      });
    }
    if (formData?.general_examination?.urobag_datepicker) {
      generalExaminationSummaryItems.push({
        key: "18",
        label: "Urobag",
        children: (
          <>
            {formData?.general_examination?.urobag_datepicker && (
              <Tag bordered={false} className="break-line-tag" key={"urobag"}>
                started on{" "}
                {dayjs(formData?.general_examination?.urobag_datepicker).format(
                  "YYYY-MM-DD"
                )}
              </Tag>
            )}
            {getLabelTagInput(
              "Comment",
              formData?.general_examination?.urobag_comments
            )}
          </>
        ),
      });
    }
    if (
      formData?.general_examination?.urine_output ||
      formData?.general_examination?.urinary_leakage
    ) {
      generalExaminationSummaryItems.push({
        key: "19",
        label: "24 Hr Urine Output",
        children: (
          <>
            {getLabelTagInput(
              "Urine Output",
              formData?.general_examination?.urine_output
            )}
            {getLabelTagInput(
              "Urinary Leakage",
              getLabelTag(
                yesNoOptions,
                formData?.general_examination?.urinary_leakage
              )
            )}
          </>
        ),
      });
    }
    if (formData?.general_examination?.urine_examination) {
      generalExaminationSummaryItems.push({
        key: "20",
        label: "Urine Examination",
        children: (
          <>
            {getLabelTag(
              normalAbnormalOptions,
              formData?.general_examination?.urine_examination
            )}
            {formData?.general_examination?.urine_examination === "abnormal"
              ? getLabelTagsArray(
                  urineTypeOptions,
                  formData?.general_examination?.urine_type
                )
              : ""}
          </>
        ),
      });
    }
    if (formData?.general_examination?.external_splints) {
      generalExaminationSummaryItems.push({
        key: "21",
        label: "External Splints",
        children: (
          <>
            {getLabelTagYesNO(formData?.general_examination?.external_splints)}
            {getLabelTagInput(
              "Details",
              formData?.general_examination?.splints_details
            )}
          </>
        ),
      });
    }
    if (
      formData?.general_examination?.temperature ||
      formData?.general_examination?.pulse_rate ||
      formData?.general_examination?.respiratory_rate ||
      formData?.general_examination?.systolic_bp ||
      formData?.general_examination?.diastolic_bp ||
      formData?.general_examination?.spo2
    ) {
      generalExaminationSummaryItems.push({
        key: "22",
        label: "Vitals at the Time of Admission",
        children: (
          <>
            {getLabelTagInput(
              "Temperature",
              formData?.general_examination?.temperature
            )}
            {getLabelTagInput(
              "Pulse Rate",
              formData?.general_examination?.pulse_rate
            )}
            {getLabelTagInput(
              "Respiratory Rate",
              formData?.general_examination?.respiratory_rate
            )}
            {getLabelTagInput(
              "Systolic BP",
              formData?.general_examination?.systolic_bp
            )}
            {getLabelTagInput(
              "Diastolic BP",
              formData?.general_examination?.diastolic_bp
            )}
            {getLabelTagInput("spO2", formData?.general_examination?.spo2)}
          </>
        ),
      });
    }
    if (formData?.general_examination?.pain_scale) {
      generalExaminationSummaryItems.push({
        key: "23",
        label: "Pain Scale",
        children: (
          <>
            {getLabelTagYesNO(formData?.general_examination?.pain_scale)}
            {getLabelTagInput(
              "Pain level",
              formData?.general_examination?.pain
            )}
          </>
        ),
      });
    }
    return generalExaminationSummaryItems;
  };

  return (
    <div>
      <Descriptions
        bordered
        column={1}
        title={
          <Space>
            <FileSearchOutlined style={{ color: "#7F56D9" }} />
            <span style={{ fontWeight: 500 }}>General Examination</span>
          </Space>
        }
        items={constructGeneralExaminationSummaryOptions()}
        style={{ margin: "0", border: "none", textAlign: "left" }}
        
        className="initial-insp-desc"
      />
    </div>
  );
};

export default DrGeneralExaminationSummary;
