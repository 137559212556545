import React, { useState, useEffect } from "react";
import { Row, Col, Card, Statistic, Typography, Skeleton } from "antd";
import { getIPBillDetailsApi } from "../../../../services/receptionist.services";
import useQuery from "../../../../hooks/useQuery";
import RecordPayment from "./RecordPayment";
import { formatToINR } from "../../../../helpers/utility";
import RecordPayments from "../../../Receptionist/Billing/RecordPayments";
import ServicesTableIp from "./ServicesTableIp";
import BedsIp from "./BedsIp";

const BillingIp = () => {
  const params = useQuery();
  const [billData, setBillData] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false);

  const refreshData = () => {
    setRefresh((prev) => !prev);
  };

  useEffect(() => {
    // if (params?.patient) {
    setLoading(true);
    getIPBillDetailsApi(params?.patient)
      .then((res) => {
        setBillData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
    // }
  }, [params?.patient, refresh]);

  const customCards = (title, value) => {
    return (
      <Card style={{ border: "1px solid #D9D9D9" }}>
        <Row>
          <Col span={12}>
            <Typography>{title}</Typography>
          </Col>
          <Col span={12} style={{ textAlign: "center" }}>
            {title === "Admitted Since" ||
            title === "No of Services Availed" ? (
              <Typography style={{ fontWeight: 600, fontSize: "16px" }}>
                {value} {title === "Admitted Since" && "days"}
              </Typography>
            ) : title === "Bill Amount" ? (
              <Statistic
                value={formatToINR(value)}
                // suffix={"/-"}
                valueStyle={{
                  fontSize: "18px",
                }}
              />
            ) : (
              <Typography style={{ fontWeight: 600, fontSize: "16px" }}>
                {value * -1 < 0 ? (
                  <Statistic
                    value={formatToINR(value * -1)}
                    // suffix={"/-"}
                    valueStyle={{
                      color: "#FD6050",
                      fontSize: "18px",
                    }}
                  />
                ) : value * -1 > 0 ? (
                  <Statistic
                    value={formatToINR(value * -1) || 0}
                    prefix="+"
                    valueStyle={{ color: "#40C164", fontSize: "18px" }}
                  />
                ) : (
                  <Statistic
                    value={formatToINR(value) || 0}
                    valueStyle={{ color: "#4C164", fontSize: "18px" }}
                  />
                )}
              </Typography>
            )}
          </Col>
        </Row>
      </Card>
    );
  };
  const cardData = billData?.data;

  return (
    <>
      {loading ? (
        <Skeleton active />
      ) : (
        <Row gutter={[12, 12]}>
          <Col span={24}>
            <Row gutter={[12, 12]}>
              <Col span={6}>
                {customCards("Admitted Since", cardData?.admission?.no_of_days)}
              </Col>
              <Col span={6}>
                {customCards(
                  "No of Services Availed",
                  cardData?.service_details?.services_count
                )}
              </Col>
              <Col span={6}>
                {customCards(
                  "Bill Amount",
                  cardData?.payment_details?.total_amount
                )}
              </Col>
              <Col span={6}>
                {customCards(
                  "Balance Amount",
                  cardData?.payment_details?.total_due_amount
                )}
              </Col>
            </Row>
          </Col>
          <Col span={12}>
            <Row gutter={[8, 8]}>
              <Col span={24}>
                <BedsIp billData={billData} />
              </Col>
              <Col span={24}>
                <ServicesTableIp billData={billData} />
              </Col>
            </Row>
          </Col>
          <Col span={12}>
            <Row gutter={[8, 8]}>
              <Col span={24}>
                <RecordPayments
                  paymentData={billData?.data?.payment_details?.payments}
                  bill_id={billData?.data?.bill?.id}
                  type="ip"
                />
              </Col>
              <Col span={24}>
                <RecordPayment billData={billData} refreshData={refreshData} />
              </Col>
            </Row>
          </Col>
        </Row>
      )}
    </>
  );
};

export default BillingIp;
