import { ArrowRightOutlined, CalendarOutlined } from "@ant-design/icons";
import {
  Col,
  Image,
  List,
  Pagination,
  Row,
  Segmented,
  Tag,
  Tooltip,
  Typography,
  theme,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import CustomAvatar from "../../blocks/Avatars/CustomAvatar";
import { useNavigate } from "react-router-dom";
import bedImg from "../../assets/images/patientIcons/bed.svg";
import docIcon from "../../assets/doctorIcons/consultant.svg";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import tagStyles from "../../styles/Custom/Tag.module.css";
import { getPatientListApi } from "../../services/patient.services";
import { getCookie } from "../../helpers/localStorage";
import { FacilityID } from "../../constants/defaultKeys";
import "./PatientList.css";
import LoadingIndicator from "../Loaders/LoadingIndicator";
import NoDataPage from "../NoDataPages/NoDataPage";
import PatientDataIcon from "../../assets/images/nurseIcons/patientdata.svg";
import useQuery from "../../hooks/useQuery";

dayjs.extend(relativeTime);

const { useToken } = theme;

const InPatientsCard = ({ userDetails, role }) => {
  const navigate = useNavigate();
  const params = useQuery();
  const [loading, setLoading] = useState(true);
  const { token } = useToken();
  const [activeInSegment, setActiveInSegment] = useState(params?.status);
  const [patientList, setPatientList] = useState([]);
  const [pageNo, setPageNo] = useState(1);

  const inPatientLabels = [
    { label: "Active", value: "current" },
    { label: "Discharged", value: "older" },
  ];

  const facilityId = Number(getCookie(FacilityID)) || userDetails?.currFacility;
  const fetchPatientList = () => {
    setLoading(true);
    getPatientListApi(`facility=${facilityId}`, activeInSegment, pageNo)
      .then((res) => {
        setPatientList(res?.data);
      })
      .catch((err) => {
        message.error("Failed to fetch patient list.");
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchPatientList();
  }, [activeInSegment, pageNo, facilityId]);

  const onInpatientSegmentChange = (value) => {
    const newUrl = new URL(window.location.href);
    newUrl.searchParams.set("status", value);
    navigate(`${newUrl.pathname}${newUrl.search}`, { replace: true });
    setActiveInSegment(value);
  };

  const truncateName = (name) =>
    name && name.length > 20 ? `${name.slice(0, 20)}...` : name;

  const handlePatientViewClick = (item) => {
    const newUrl = new URL(window.location.href);
    newUrl.searchParams.set("patient", item?.id);
    newUrl.searchParams.set(
      "type",
      [
        "physiotherapist",
        "speech-swallow-therapist",
        "respiratory-therapist",
        "nutritionist",
        "psychologist",
      ].includes(role)
        ? "ia"
        : "landing"
    );
    navigate(`${newUrl.pathname}${newUrl.search}`, { replace: true });
  };

  return (
    <>
      {loading ? (
        <LoadingIndicator message="Loading data, please wait..." />
      ) : (
        <Row>
          <Col></Col>
          <Col xs={24} lg={{ span: 20, offset: 2 }}>
            <List
              className="patient-list"
              bordered
              pagination={false}
              header={
                <Row className="list-header">
                  <Col span={12}>In Patients</Col>
                  <Col span={12} style={{ textAlign: "right" }}>
                    <Segmented
                      options={inPatientLabels}
                      value={activeInSegment}
                      onChange={onInpatientSegmentChange}
                    />
                  </Col>
                </Row>
              }
              itemLayout="horizontal"
              size="small"
              dataSource={patientList?.data}
              style={{ background: "#fff" }}
            >
              {patientList?.data?.length > 0 ? (
                patientList.data.map((item) => (
                  <List.Item
                    key={item?.id}
                    extra={
                      <Typography.Link
                        style={{ color: token.colorPrimary }}
                        onClick={() => handlePatientViewClick(item)}
                      >
                        View EMR <ArrowRightOutlined />
                      </Typography.Link>
                    }
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <Col span={8} style={{ flex: 1, textAlign: "left" }}>
                      <List.Item.Meta
                        avatar={
                          <CustomAvatar
                            name={`${item?.patient_name}`}
                            indexId={item?.id}
                            size="medium"
                          />
                        }
                        title={
                          <Typography>
                            {truncateName(item?.patient_name)}
                          </Typography>
                        }
                        description={
                          item?.patient_age &&
                          item?.patient_gender && (
                            <Tag>
                              {item?.patient_age} {item?.patient_gender}
                            </Tag>
                          )
                        }
                        style={{ maxWidth: "200px", cursor: "pointer" }}
                      />
                    </Col>

                    <Col span={4} style={{ flex: 1 }}>
                      {item?.ip_id && <Tag>IP ID: {item?.ip_id}</Tag>}
                    </Col>

                    <Col span={4} style={{ flex: 1 }}>
                      {item?.bed_name && (
                        <Tag color="orange">
                          <Image src={bedImg} preview={false} />{" "}
                          {item?.bed_name}
                        </Tag>
                      )}
                    </Col>

                    <Col span={4} style={{ flex: 1 }}>
                      <Tag className={tagStyles.smTag} color="purple">
                        <CalendarOutlined style={{ marginRight: "4px" }} />
                        {item?.discharge_date
                          ? dayjs(item?.discharge_date).format("DD MMM, YYYY")
                          : dayjs(item?.date_of_admission).format(
                              "DD MMM, YYYY"
                            )}
                      </Tag>
                    </Col>

                    <Col span={4} style={{ flex: 1 }}>
                      {item?.primary_consultant_name && (
                        <Tooltip
                          placement="top"
                          title={item?.primary_consultant_name}
                          color="#E6F4FF"
                          overlayInnerStyle={{ color: "#0d439e" }}
                        >
                          <Tag color="blue" style={{ cursor: "pointer" }}>
                            <Image
                              src={docIcon}
                              style={{ width: "12px", marginRight: "4px" }}
                              preview={false}
                            />
                            {truncateName(item?.primary_consultant_name)}
                          </Tag>
                        </Tooltip>
                      )}
                    </Col>
                  </List.Item>
                ))
              ) : (
                <List.Item>
                  <NoDataPage
                    title="No In Patients Available"
                    subtitle="Please check back later or adjust your filters."
                    image={PatientDataIcon}
                  />
                </List.Item>
              )}
            </List>
          </Col>

          <Col
            xs={24}
            lg={{ span: 20, offset: 2 }}
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "10px",
            }}
          >
            {patientList?.total_pages > 1 && (
              <Pagination
                current={pageNo}
                onChange={(value) => setPageNo(value)}
                pageSize={20}
                total={patientList?.total_count}
                showSizeChanger={false}
              />
            )}
          </Col>
        </Row>
      )}
    </>
  );
};

export default InPatientsCard;
