import React from "react";
import PressureSore from "./PressureSore";
import OpenWounds from "./OpenWounds";
import SoftTissueInjury from "./SoftTissueInjury";
import Scar from "./Scar";
import SurgicalIncision from "./SurgicalIncision";
import Swellings from "./Swellings";
import { Divider } from "antd";
import { DoctorInitialContext } from "../../../HistoryForm/DoctorHistoryFormProvider";
import NursePressureSore from "./NursePressureSore";

const SkinForm = ({
  pressureSore,
  setPressureSore,
  openWounds,
  setOpenWounds,
  context,
}) => {
  return (
    <>
      {context ? (
        <NursePressureSore context={context} />
      ) : (
        <>
          <PressureSore
            pressureSore={pressureSore}
            setPressureSore={setPressureSore}
          />
          <Divider />
          <OpenWounds openWounds={openWounds} setOpenWounds={setOpenWounds} />
        </>
      )}

      <Divider />
      <SoftTissueInjury context={context ? context : DoctorInitialContext} />
      <Divider />
      <Scar context={context ? context : DoctorInitialContext} />
      <Divider />
      <SurgicalIncision context={context ? context : DoctorInitialContext} />
      <Divider />
      <Swellings context={context ? context : DoctorInitialContext} />
    </>
  );
};

export default SkinForm;
