import React, { useContext, useEffect, useState } from "react";
import { Col, Row, Image, Typography } from "antd";
import appLogo from "../../../assets/images/curengoLogo.png";
import MobilePassword from "./MobilePassword";
import "./Login.css";
import { useLocation, useParams } from "react-router-dom";
import EmailPassword from "./EmailPassword";
import MobileLogin from "./MobileLogin";
import { UserContext } from "../../../context/UserContext";
import { orgVerificationApi } from "../../../services/auth.services";

const Login = () => {
  const location = useLocation();
  const params = Object.fromEntries(new URLSearchParams(location?.search));
  const [orgDetails, setOrgDetails] = useState({});
  const { userDetails } = useContext(UserContext);
  const domain = window.location.hostname;

  const orgVerification = async (domain) => {
    try {
      const resp = await orgVerificationApi(domain);
      setOrgDetails(resp?.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (domain !== process.env.REACT_APP_PUBLIC_DOMAIN) {
      if (domain.split(".")[0] !== "app") {
        orgVerification(domain.split(".")[0]);
      }
    }
  }, []);

  return (
    <div className="parentContainer">
      <Image
        preview={false}
        alt="Logo"
        src={orgDetails?.logo || appLogo}
        style={{
          height: "120px",
          width: "auto",
          marginLeft: "60px",
          cursor: "pointer",
        }}
        className="mobileHidden"
      />
      <div className="webHidden">
        <Image
          preview={false}
          alt="Logo"
          src={orgDetails?.logo || appLogo}
          // width={100}
          style={{
            height: "100px",
            width: "300px",
            paddingLeft: "20%",
            cursor: "pointer",
          }}
        />
      </div>
      <Row
        style={{
          minHeight: "calc(100vh - 400px)",
          justifyContent: "center",
        }}
      >
        <Col
          xs={{ span: 24, offset: 0 }}
          md={{ span: 24, offset: 0 }}
          lg={{ span: 6 }}
          xl={{ span: 6 }}
          style={{
            alignSelf: "center",
          }}
        >
          {params?.type === "email" ? (
            <EmailPassword orgID={orgDetails?.id} />
          ) : params?.type === "mobile" ? (
            <MobileLogin orgID={orgDetails?.id} />
          ) : (
            <MobilePassword orgID={orgDetails?.id} />
          )}
        </Col>
      </Row>
    </div>
  );
};

export default Login;
