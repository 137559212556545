import React, { useEffect, useState, useRef, useContext } from "react";
import {
  Drawer,
  Row,
  Col,
  Typography,
  Form,
  Button,
  message,
  Steps,
} from "antd";
import { appointmentSteps } from "../receptionKeys";
import { CloseOutlined } from "@ant-design/icons";
import AppointmentFormList from "./AppointmentFormList";
import PatientDetailsForm from "./PatientDetailsForm";
import {
  addPatientApi,
  getPatientDetailsApi,
  updateAppointmentApi,
  updatePatientApi,
} from "../../../services/receptionist.services";
import btnStyles from "../../../styles/Custom/Button.module.css";
import { capitalizeFirstLetter } from "../../../helpers/auth/helper";
import { FacilityID } from "../../../constants/defaultKeys";
import { getCookie } from "../../../helpers/localStorage";
import { UserContext } from "../../../context/UserContext";
import dayjs from "dayjs";

const EditAppointmentModal = ({ open, setOpen, editData, refresh }) => {
  const { userDetails } = useContext(UserContext);
  const [form] = Form.useForm();
  const formRef = useRef(null);
  const [patientForm] = Form.useForm();
  const patientFormRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [searchedText, setSearchedText] = useState("");
  const [selectedId, setSelectedId] = useState(null);
  const [formValues, setFormValues] = useState(null);
  const [selectedPractitioner, setSelectedPractitioner] = useState(null);
  const [facilityId, setFacilityId] = useState(null);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [selectedEndSlot, setSelectedEndSlot] = useState(null);
  const [current, setCurrent] = useState(0);

  useEffect(() => {
    if (editData?.id) {
      form?.setFieldsValue({
        ...editData,
        appointment_date: dayjs(editData?.appointment_date),
        name: `${capitalizeFirstLetter(
          editData?.doctor_name.split(" ")[0]
        )} ${capitalizeFirstLetter(editData?.doctor_name.split(" ")[1])}`,
        patient_mobile: editData?.patient_mobile,
      });
      if (!editData?.patient) {
        patientForm?.setFieldsValue({
          first_name: editData?.patient_first_name,
          last_name: editData?.patient_last_name,
          age: editData?.patient_age,
          gender: editData?.patient_gender,
          mobile: editData?.patient_mobile,
        });
      }
      setFacilityId(editData?.facility);
      setSelectedPractitioner(editData?.doctor);
      setSelectedId(editData?.patient);
      setSelectedSlot(
        editData?.appointment_time.split(":").slice(0, 2).join(":")
      );
      setSelectedEndSlot(editData?.appointment_end_time);
    }
  }, [editData?.id]);

  useEffect(() => {
    if (editData?.patient) {
      getPatientDetailsApi(editData?.patient)
        .then((res) => {
          patientForm?.setFieldsValue({
            ...res?.data,
            dummyMobile: `${editData?.patient_mobile}(${editData?.patient_first_name})`,
            first_name: editData?.patient_first_name,
            last_name: editData?.patient_last_name,
            age: editData?.patient_age,
            gender: editData?.patient_gender,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [editData?.patient]);

  const onPatientFinish = (values) => {
    setLoading(true);
    const { ...formData } = values;
    formData.mobile = editData?.patient_mobile;
    formData.facility =
      Number(getCookie(FacilityID)) || userDetails?.currFacility;
    if (editData?.patient || selectedId) {
      updatePatientApi(editData?.patient || selectedId, formData)
        .then((res) => {
          message.success(res?.data?.message);
          setLoading(false);
          setCurrent(1);
        })
        .catch((err) => {
          message.error(err?.response?.data?.message);
          setLoading(false);
        });
    } else {
      addPatientApi(formData)
        .then((res) => {
          message.success(res?.data?.message);
          setLoading(false);
          setCurrent(1);
          setSelectedId(res?.data?.patient_id);
        })
        .catch((err) => {
          message.error(err?.response?.data?.message);
          setLoading(false);
        });
    }
  };

  const onFinish = async (values) => {
    try {
      setLoading(true);

      const { dummyMobile, name, appointment_date, ...formData } = values;

      const formattedAppointmentDate = appointment_date
        ? dayjs(appointment_date).format("YYYY-MM-DD")
        : editData?.appointment_date;

      const updatedFormData = {
        ...formData,
        appointment_date: formattedAppointmentDate,
        doctor: selectedPractitioner,
        appointment_time: selectedSlot,
        appointment_end_time: selectedEndSlot,
        facility: facilityId,
        patient: selectedId,
        patient_first_name: editData?.patient_first_name,
        patient_last_name: editData?.patient_last_name,
        patient_age: editData?.patient_age,
        patient_gender: editData?.patient_gender,
        patient_mobile: editData?.patient_mobile,
        appointment_id: editData?.id,
      };

      const response = await updateAppointmentApi(updatedFormData);
      message.success(response?.data?.message);
      setOpen(false);
      refresh();
    } catch (error) {
      message.error(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };
  const onValuesChange = (changedValues, values) => {
    setFormValues({ ...values, ...changedValues });
  };

  return (
    <>
      <Drawer
        width={"70%"}
        open={open}
        onClose={() => setOpen(false)}
        closeIcon={null}
        title={
          <Row>
            <Col span={6} className="flex-start">
              <Typography
                style={{
                  marginLeft: "12px",
                  fontWeight: "400",
                  fontSize: "20px",
                }}
              >
                Edit Appointment
              </Typography>
            </Col>
            <Col span={14}>
              <Steps
                current={current}
                items={appointmentSteps}
                onChange={(value) => editData?.patient && setCurrent(value)}
              />
            </Col>
            <Col span={4} className="flex-end">
              <CloseOutlined onClick={() => setOpen(false)} />
            </Col>
          </Row>
        }
        footer={
          <div className="flex-end" style={{ gap: "24px" }}>
            <Button
              loading={loading}
              htmlType="submit"
              type="primary"
              className={btnStyles.mdBtn}
              onClick={() => {
                current === 0
                  ? patientFormRef.current.submit()
                  : formRef.current.submit();
              }}
            >
              {current === 0 ? "Save and Proceed" : "Save"}
            </Button>
          </div>
        }
      >
        {current === 0 && (
          <Form
            layout="vertical"
            // requiredMark={false}
            form={patientForm}
            onFinish={onPatientFinish}
            ref={patientFormRef}
          >
            <PatientDetailsForm
              searchedText={searchedText}
              setSearchedText={setSearchedText}
              selectedId={selectedId}
              setSelectedId={setSelectedId}
              editData={editData}
            />
          </Form>
        )}
        {current === 1 && (
          <Form
            layout="vertical"
            // requiredMark={false}
            form={form}
            ref={formRef}
            onFinish={onFinish}
            onValuesChange={onValuesChange}
          >
            <AppointmentFormList
              facilityId={facilityId}
              setFacilityId={setFacilityId}
              formValues={formValues}
              selectedPractitioner={selectedPractitioner}
              setSelectedPractitioner={setSelectedPractitioner}
              selectedSlot={selectedSlot}
              setSelectedSlot={setSelectedSlot}
              selectedEndSlot={selectedEndSlot}
              setSelectedEndSlot={setSelectedEndSlot}
              selectedId={selectedId}
              editData={editData}
            />
          </Form>
        )}
      </Drawer>
    </>
  );
};

export default EditAppointmentModal;
