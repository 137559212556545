import React, { useContext, useEffect, useState } from "react";
import { Form, Checkbox, Input } from "antd";
import { validateConfig } from "../../../../../config/validate.config";
import { chiefComplaintsOptions } from "../../../Options/historyOptions";
const ChiefComplaints = ({ context }) => {
  const { formData } = useContext(context);
  const [complaintOthers, setComplaintOthers] = useState(
    formData?.history?.chief_complaints?.includes("others")
  );

  useEffect(() => {
    setComplaintOthers(formData?.history?.chief_complaints?.includes("others"));
  }, [formData]);

  const handleChiefComplaintsChange = (selectedOptions) => {
    const isOthersSelected = selectedOptions.includes("others");
    setComplaintOthers(isOthersSelected);
  };
  return (
    <>
      <Form.Item
        label="Chief Complaints"
        name="chief_complaints"
        // rules={validateConfig.isRequired("Chief Complaints")}
      >
        <Checkbox.Group onChange={handleChiefComplaintsChange}>
          {chiefComplaintsOptions.map((option) => (
            <div style={{ minWidth: "220px", padding: "6px" }}>
              <Checkbox key={option.value} value={option.value}>
                {option.label}
              </Checkbox>
            </div>
          ))}
        </Checkbox.Group>
      </Form.Item>
      {complaintOthers && (
        <Form.Item
          name="others_chief_complaint"
          rules={[{ required: true, message: "Please enter the details" }]}
        >
          <Input placeholder="Enter others" style={{ width: "50%" }} />
        </Form.Item>
      )}
    </>
  );
};

export default ChiefComplaints;
