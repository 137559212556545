import React, { useEffect, useState } from "react";
import { Row, Col, Card, Image } from "antd";
import ClinicalCard from "./ClinicalCard";
import Graphs from "./Graphs/Graphs";
import ProgressData from "./ProgressData";
import { getPatientInfoApi } from "../../../../services/patient.services";
import useQuery from "../../../../hooks/useQuery";
import SummaryMedicines from "./SummaryMedicines";
import SummaryInvestigations from "./SummaryInvestigations";
import specialAreaImage from "../../../../assets/doctorIcons/special care 1.svg";
import "./Landing.css";
import SpecialAreas from "./SpecialAreas";
import LoadingIndicator from "../../../Loaders/LoadingIndicator";
const Landing = () => {
  const [patientData, setPatientData] = useState(null);
  const [loading, setLoading] = useState(true);

  const params = useQuery();
  console.log("Patients : ", params?.patient);
  useEffect(() => {
    setLoading(true);
    getPatientInfoApi(params?.patient)
      .then((res) => {
        setPatientData(res?.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [params?.patient]);
  return (
    <>
      {loading ? (
        <LoadingIndicator message={"Loading data, please wait..."} />
      ) : (
        <Row gutter={[8, 2]}>
          <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
            {patientData?.data?.clinical_diagnosis && (
              <ClinicalCard data={patientData?.data?.clinical_diagnosis} />
            )}
            {patientData?.data?.progress_notes && (
              <ProgressData data={patientData?.data?.progress_notes} />
            )}
            {patientData?.data?.medicines[0] && (
              <SummaryMedicines data={patientData?.data?.medicines} />
            )}
            {patientData?.data?.investigations[0] && (
              <SummaryInvestigations data={patientData?.data?.investigations} />
            )}
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
            {patientData?.data && <Graphs data={patientData?.data} />}
            {patientData?.data?.care_items?.[0]?.catheter ? (
              <SpecialAreas data={patientData?.data?.care_items} />
            ) : (
              <Card
                style={{
                  marginTop: "10px",
                  textAlign: "center",
                  padding: "50px",
                }}
              >
                {" "}
                <Image src={specialAreaImage} width={50} preview={false} />
                <h3 style={{ color: "#a3a2a2", fontWeight: "300" }}>
                  {" "}
                  Special area not added
                </h3>
              </Card>
            )}
          </Col>
        </Row>
      )}
    </>
  );
};

export default Landing;
