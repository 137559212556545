import React, { useContext, useEffect, useState } from "react";
import { Button, Row, Col } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import PackagesForm from "./PackagesForm";
import btnStyles from "../../../../styles/Custom/Button.module.css";
import { UserContext } from "../../../../context/UserContext";
import { getPackages } from "../../../../services/service.services";
import PackageTable from "./PackageTable";

const Packages = () => {
  const { userDetails } = useContext(UserContext);
  const [visible, setVisible] = useState(false);
  const [packageData, setPackageData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const refreshPackages = () => {
    setRefresh((prev) => !prev);
  };
  useEffect(() => {
    setLoading(true);
    getPackages()
      .then((res) => {
        setPackageData(res?.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, [refresh]);
  return (
    <>
      <Row gutter={[12, 12]}>
        {userDetails?.activeOrg?.default_role !== "Facility_Admin" && (
          <Col span={24} style={{ textAlign: "right" }}>
            <Button
              type="primary"
              className={btnStyles.mdBtn}
              onClick={() => setVisible(true)}
            >
              <PlusOutlined /> Package
            </Button>
          </Col>
        )}

        <Col span={24}>
          <PackageTable
            data={packageData}
            loading={loading}
            refreshPackages={refreshPackages}
            userDetails={userDetails}
          />
        </Col>
      </Row>
      {visible && (
        <PackagesForm
          visible={visible}
          setVisible={setVisible}
          refreshPackages={refreshPackages}
        />
      )}
    </>
  );
};

export default Packages;
