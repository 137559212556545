import React, { useState } from "react";
import { Form, List, InputNumber, Select, Button, message } from "antd";
import inputStyles from "../../../../styles/Custom/Input.module.css";
import {
  paymentKeys,
  printBill,
} from "../../../../components/Receptionist/receptionKeys";
import btnStyles from "../../../../styles/Custom/Button.module.css";
import { addPaymentApi } from "../../../../services/receptionist.services";

const AdvancePayment = ({ billData, refreshData }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const onFinish = (values) => {
    setLoading(true);
    addPaymentApi(billData?.data?.bill?.id, values)
      .then((res) => {
        message.success(res.data.message);
        form.resetFields();
        refreshData();
        printBill(res?.data?.data?.bill, res?.data?.data?.id, true);
        setLoading(false);
      })
      .catch((err) => {
        message.error(err.response.data.message);
        setLoading(false);
      });
  };

  return (
    <>
      <Form layout="vertical" onFinish={onFinish} form={form}>
        <List>
          <List.Item>
            <List.Item.Meta title="Enter Amount" />
            <Form.Item name="amount">
              <InputNumber
                style={{ width: "300px" }}
                min={0}
                className={inputStyles.mdNumberInput}
              />
            </Form.Item>
          </List.Item>
          <List.Item>
            <List.Item.Meta title="Payment Mode" />
            <Form.Item
              name="mode"
              rules={[
                { required: true, message: "Please select a payment mode" },
              ]}
            >
              <Select
                style={{ width: "300px" }}
                options={paymentKeys}
                placeholder="Select Payment Mode"
                className={inputStyles.mdSelect}
              />
            </Form.Item>
          </List.Item>
        </List>
        <div style={{ textAlign: "center" }}>
          <Button
            loading={loading}
            type="primary"
            htmlType="submit"
            className={btnStyles.lgBtn}
          >
            Generate Receipt
          </Button>
        </div>
      </Form>
    </>
  );
};

export default AdvancePayment;
