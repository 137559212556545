import { Descriptions, Image, Space } from "antd";
import React from "react";
import {
  getLabelTagInput,
  getLabelTagsArray,
} from "../constructTextFromValues";
import { symptomsOptions } from "../../Options/psychologistOptions";
import symptomsIcon from "../../../../assets/doctorIcons/symptoms.svg";

const PsychologistSymptomsSummary = ({ formData }) => {
  const constructSymptomsSummaryItems = () => {
    const symptomsSummaryItems = [];
    if (formData?.symptoms) {
      symptomsSummaryItems.push({
        key: "1",
        label: "Symptoms",
        children: (
          <>
            {getLabelTagsArray(symptomsOptions, formData?.symptoms?.symptoms)}
            {getLabelTagInput("Others", formData?.symptoms?.others_description)}
          </>
        ),
      });
    }
    return symptomsSummaryItems;
  };
  const symptomsSummaryItems = constructSymptomsSummaryItems();
  return (
    <>
      <Descriptions
        bordered
        column={1}
        title={
          <span style={{ fontWeight: "500", marginLeft: ".2rem" }}>
            <Space>
              <Image src={symptomsIcon} width={18} preview={false} />
              <span style={{ fontSize: "16px", fontWeight: "normal" }}>
                Symptoms/Impressions
              </span>
            </Space>
          </span>
        }
        items={symptomsSummaryItems}
        style={{ margin: "0", border: "none" }}
        className="initial-insp-desc"
      />
    </>
  );
};

export default PsychologistSymptomsSummary;
