import React, { useContext } from "react";
import {
  getEdemaSummary,
  getLabelTag,
  getLabelTagInput,
  getLabelTagsWithRadioOptions,
} from "../../InspectionSummaries/constructTextFromValues";
import {
  consiousnessOptions,
  cyanosisOptions,
  edemaOptions,
  hydrationOptions,
  moreConsiousnessOptions,
  nmmsOptions,
  pupilOptions,
  pupilRadioOptions,
  pupilSideOptions,
} from "../../Options/genExamOptions";
import examinationIcon from "../../../../assets/doctorIcons/examination.svg"

import { DailyReviewContext } from "../../Doctor/DailyReview/DailyReviewFormProvider";
import { Descriptions, Image, Space } from "antd";
import { FileSearchOutlined } from "@ant-design/icons";

const DrGeneralExaminationSummaryDR = () => {
  const { formData } = useContext(DailyReviewContext);
  const constructGeneralExaminatinSummaryDRItems = () => {
    const generalExaminationSummaryDRItems = [];

    if (formData?.general_examination?.level_of_consciousness) {
      generalExaminationSummaryDRItems.push({
        key: "1",
        label: "Level of Consciousness",
        children: (
          <>
            {getLabelTag(
              consiousnessOptions,
              formData?.general_examination?.level_of_consciousness
            )}
            {formData?.general_examination?.level_of_consciousness === "p"
              ? getLabelTag(
                  moreConsiousnessOptions,
                  formData?.general_examination?.level_of_consciousness_options
                )
              : ""}
          </>
        ),
      });
    }
    if (
      formData?.general_examination?.eye_opening_response ||
      formData?.general_examination?.verbal_response ||
      formData?.general_examination?.motor_response
    ) {
      generalExaminationSummaryDRItems.push({
        key: "2",
        label: "Glasgow Coma Scale",
        children: (
          <>
            {getLabelTagInput(
              "Eye Opening Response",
              formData?.general_examination?.eye_opening_response
            )}
            {getLabelTagInput(
              "Verbal Response",
              formData?.general_examination?.verbal_response
            )}
            {getLabelTagInput(
              "Motor Response",
              formData?.general_examination?.motor_response
            )}
          </>
        ),
      });
    }
    if (formData?.general_examination?.pupil_condition) {
      generalExaminationSummaryDRItems.push({
        key: "3",
        label: "Pupils",
        children: (
          <>
            {getLabelTag(
              pupilOptions,
              formData?.general_examination?.pupil_condition
            )}
            {getLabelTagsWithRadioOptions(
              pupilSideOptions,
              pupilRadioOptions,
              formData?.general_examination
            )}
          </>
        ),
      });
    }
    if (formData?.general_examination?.hydration) {
      generalExaminationSummaryDRItems.push({
        key: "4",
        label: "Hydration",
        children: getLabelTag(
          hydrationOptions,
          formData?.general_examination?.hydration
        ),
      });
    }
    if (formData?.general_examination?.pallor) {
      generalExaminationSummaryDRItems.push({
        key: "5",
        label: "Pallor",
        children: getLabelTag(
          nmmsOptions,
          formData?.general_examination?.pallor
        ),
      });
    }
    if (formData?.general_examination?.icterus) {
      generalExaminationSummaryDRItems.push({
        key: "6",
        label: "Icterus",
        children: getLabelTag(
          nmmsOptions,
          formData?.general_examination?.icterus
        ),
      });
    }
    if (formData?.general_examination?.cyanosis) {
      generalExaminationSummaryDRItems.push({
        key: "7",
        label: "Cyanosis",
        children: getLabelTag(
          cyanosisOptions,
          formData?.general_examination?.cyanosis
        ),
      });
    }
    if (getEdemaSummary(edemaOptions, formData?.general_examination)) {
      generalExaminationSummaryDRItems.push({
        key: "8",
        label: "Edema",
        children: getEdemaSummary(edemaOptions, formData?.general_examination),
      });
    }
    if (
      formData?.general_examination?.temperature ||
      formData?.general_examination?.pulse_rate ||
      formData?.general_examination?.respiratory_rate ||
      formData?.general_examination?.systolic_bp ||
      formData?.general_examination?.diastolic_bp ||
      formData?.general_examination?.spo2
    ) {
      generalExaminationSummaryDRItems.push({
        key: "9",
        label: "Vitals at the Time of Admission",
        children: (
          <>
            {getLabelTagInput(
              "Temperature",
              formData?.general_examination?.temperature
            )}
            {getLabelTagInput(
              "Pulse Rate",
              formData?.general_examination?.pulse_rate
            )}
            {getLabelTagInput(
              "Respiratory Rate",
              formData?.general_examination?.respiratory_rate
            )}
            {getLabelTagInput(
              "Systolic BP",
              formData?.general_examination?.systolic_bp
            )}
            {getLabelTagInput(
              "Diastolic BP",
              formData?.general_examination?.diastolic_bp
            )}
            {getLabelTagInput("spO2", formData?.general_examination?.spo2)}
          </>
        ),
      });
    }
    if (formData?.general_examination?.pain) {
      generalExaminationSummaryDRItems.push({
        key: "10",
        label: "Pain Scale",
        children: (
          <>
            {getLabelTagInput(
              "Pain level",
              formData?.general_examination?.pain
            )}
          </>
        ),
      });
    }
    return generalExaminationSummaryDRItems;
  };

  const generalExaminationSummaryDRItems =
    constructGeneralExaminatinSummaryDRItems();

  return (
    <div>
      {generalExaminationSummaryDRItems?.length > 0 && (
       <Descriptions
       bordered
       title={
        <span>
            <FileSearchOutlined style={{ color: "#7F56D9" }} />
            <span style={{ fontSize: "16px", fontWeight: "normal" ,marginLeft:"5px" }}>General Examination</span>
        </span>
           
       }
       column={1}
       items={generalExaminationSummaryDRItems}
       className="initial-insp-desc"

     />
     
      )}
    </div>
  );
};

export default DrGeneralExaminationSummaryDR;
