import React, { useContext, useEffect, useState } from "react";
import DrHistorySummaryDR from "../../DailyReviewSummaries/HistorySummaryDR/DrHistorySummaryDR";
import DrGeneralExaminationSummaryDR from "../../DailyReviewSummaries/GeneralExaminationSummaryDR/DrGeneralExaminationSummaryDR";
import DrSystemicExaminationSummaryDR from "../../DailyReviewSummaries/SystemicExaminationSummaryDR/DrSystemicExaminationSummaryDR";
import DrClinicalImpressionSummaryDR from "../../DailyReviewSummaries/ClinicalImpressionSummaryDR/DrClinicalImpressionSummaryDR";
import { getDoctorDailyReviewAPI } from "../../../../services/doctor.services";
import { DailyReviewContext } from "./DailyReviewFormProvider";
import { Col, Row, Typography, message } from "antd";
import "../../summary.css";
import { checkEmpty } from "../../../../helpers/utility";

const DailyReviewSummary = ({ editId, dailyRefresh }) => {
  const { formData, setFormData } = useContext(DailyReviewContext);
  const openEdit = async () => {
    if (editId) {
      try {
        const response = await getDoctorDailyReviewAPI(editId);
        setFormData(response.data.data);
      } catch (error) {
        console.log(`Error: ${error}`);
        message.error("Unable to Fetch Data");
      }
    }
  };

  useEffect(() => {
    openEdit();
  }, [editId, dailyRefresh]);

  return (
    <Row className="summaries" gutter={[16, 16]}>
      <Col span={12}>
        {checkEmpty(formData?.clinical_diagnosis) && (
          <DrClinicalImpressionSummaryDR context={DailyReviewContext} />
        )}
        {checkEmpty(formData?.history) && <DrHistorySummaryDR />}
      </Col>
      <Col span={12}>
        {checkEmpty(formData?.general_examination) && (
          <DrGeneralExaminationSummaryDR />
        )}

        {checkEmpty(formData?.systemic_examination) && (
          <DrSystemicExaminationSummaryDR />
        )}
      </Col>
    </Row>
  );
};

export default DailyReviewSummary;
